import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';
import { navigate } from '@reach/router';
import * as CasInterop from 'helpers/cas4/interop';

import http from 'http/index';
import * as api from 'http/auth';

import { actions as snackbarActions } from 'redux/snackbar';
import { actions } from './index';

export const getLoginPayload = (profileData, jwt, additionalData) => {
  const profileObject = {
    ...profileData,
    jwt,
    welcomeMessage: profileData.tenants.message,
    ruleset: profileData.tenants.ruleset,
    configuration: profileData.tenants.configuration,
    username: `${profileData.firstName} ${profileData.lastName}`,
    activeRole: profileData.roles.indexOf('ROLE_TEACHER') > -1 ? 'ROLE_TEACHER' : profileData.roles[0],
    tenantId: profileData.tenantMemberId,
    features: profileData.features,
  };

  if (additionalData) {
    return {
      ...profileObject,
      ...additionalData,
    };
  }

  return {
    ...profileObject,
  };
};

export function* loginUser({ payload: { data } }) {
  try {
    const loginResponse = yield call(api.login, data);

    const { profileData, jwt } = loginResponse;

    if (http.interceptors.request.handlers) {
      http.interceptors.request.handlers = [];
    }

    http.interceptors.request.use((config) => ({
      ...config,
      headers: {
        ...config.headers,
        authorization: `Bearer: ${jwt}`,
      },
    }));

    yield put(actions.loginSuccess(getLoginPayload(profileData, jwt)));

    const { roles } = profileData;
    if (!roles.length) {
      yield put(actions.setActiveRole({ data: 'none' }));
    } else if (roles.indexOf('ROLE_SYSTEM_ADMIN') > -1) {
      yield put(actions.setActiveRole({ data: 'ROLE_SYSTEM_ADMIN' }));
      navigate('/system');
    } else if (roles.indexOf('ROLE_ADMIN') > -1) {
      yield put(actions.setActiveRole({ data: 'ROLE_ADMIN' }));
      navigate('/administrator');
    } else if (roles.indexOf('ROLE_TEACHER') > -1) {
      yield put(actions.setActiveRole({ data: 'ROLE_TEACHER' }));
      navigate('/teacher');
    } else {
      yield put(actions.setActiveRole({ data: roles[0] }));
    }
  } catch (error) {
    yield put(actions.loginError({ data: error }));
  }
}

export function* logoutUser() {
  try {
    const impersonateMode = yield select((state) => state.auth.impersonateMode);
    if (impersonateMode) {
      const { jwt, profileData } = yield call(api.endImpersonate);

      if (http.interceptors.request.handlers) {
        http.interceptors.request.handlers = [];
      }

      http.interceptors.request.use((config) => ({
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer: ${jwt}`,
        },
      }));
      yield put(actions.loginSuccess(getLoginPayload(profileData, jwt, { impersonateMode: false })));
      navigate('/administrator/dashboard');
    } else {
      yield call(api.logout);
      if (http.interceptors.request.handlers) {
        http.interceptors.request.handlers = [];
      }
      yield put(actions.logoutSuccess());
      CasInterop.eraseAuth();
      CasInterop.navigate('/sign-in', { reason: 'logout' });
    }
  } catch (error) {
    yield put(actions.logoutError(error));
  }
}

export function* fetchCurrentUser() {
  try {
    const { profileData, jwt, impersonated } = yield call(api.currentUser);

    http.interceptors.request.use((config) => ({
      ...config,
      headers: {
        ...config.headers,
        authorization: `Bearer: ${jwt}`,
      },
    }));

    yield put(actions.loginSuccess(getLoginPayload(profileData, jwt, { impersonateMode: impersonated })));

    const { roles } = profileData;
    if (!roles.length) {
      yield put(actions.setActiveRole({ data: 'none' }));
    } else if (roles.indexOf('ROLE_SYSTEM_ADMIN') > -1) {
      yield put(actions.setActiveRole({ data: 'ROLE_SYSTEM_ADMIN' }));
    } else if (roles.indexOf('ROLE_ADMIN') > -1) {
      yield put(actions.setActiveRole({ data: 'ROLE_ADMIN' }));
    } else if (roles.indexOf('ROLE_TEACHER') > -1) {
      yield put(actions.setActiveRole({ data: 'ROLE_TEACHER' }));
    } else {
      yield put(actions.setActiveRole({ data: roles[0] }));
    }
  } catch (error) {
    if (!/forgot/.test(window.location.pathname)) {
      CasInterop.eraseAuth();
      CasInterop.navigate('/sign-in', { reason: 'bad-session' });
    }
    yield put(actions.getCurrentUserError({ error: null }));
  }
}

export function* getTentantConfig({ payload: { data } }) {
  try {
    const result = yield call(api.getTenantConfiguration, data);

    yield put(actions.tentantConfigurationSuccess({ data: result.data }));
  } catch (error) {
    yield put(actions.tentantConfigurationFail(error));
  }
}

export function* forgotPasswordSaga({ payload }) {
  try {
    const { email, isAuthorized, successHandle } = payload;
    yield call(api.forgotPassword, { email });

    yield put(actions.forgotPasswordSuccess({ isAuthorized }));
    if (successHandle) successHandle();
  } catch (error) {
    if (payload?.errorHandle) payload?.errorHandle();
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
    yield put(actions.forgotPasswordFailure({ error, isAuthorized: payload?.isAuthorized }));
  }
}

export function* resetPasswordSaga({ payload }) {
  try {
    const {
      confirmationToken,
      password,
      callback,
      path,
    } = payload;

    if (path.includes('user-registration')) {
      yield call(api.userRegistrationPassword, { confirmationToken, password });
    } else {
      yield call(api.resetPassword, { confirmationToken, password });
    }

    yield put(actions.resetPasswordSuccess());
    if (callback) callback();
  } catch (error) {
    yield put(actions.resetPasswordFailure(error));
  }
}

export function* onSaveProfile({ payload }) {
  try {
    const requestData = {
      disableTransferEmails: payload.disable_transfer_emails,
      email: payload.email,
      firstName: payload.first_name,
      id: payload.id,
      lastName: payload.last_name,
      password: payload.n_password,
      tenantMemberId: payload.tenant_member_id,
    };
    yield call(api.saveProfile, requestData);

    yield put(actions.getCurrentUser());
    yield put(actions.saveProfileSuccess());
  } catch (error) {
    yield put(actions.saveProfileFailure());
  }
}

export function* impersonateRequest({ payload }) {
  try {
    const { userIdToImpersonate } = payload;
    const { jwt, profileData } = yield call(api.startImpersonate, { userIdToImpersonate });

    if (http.interceptors.request.handlers) {
      http.interceptors.request.handlers = [];
    }

    http.interceptors.request.use((config) => ({
      ...config,
      headers: {
        ...config.headers,
        authorization: `Bearer: ${jwt}`,
      },
    }));

    yield navigate('/');

    yield put(actions.impersonateRequestSuccess(getLoginPayload(profileData, jwt, { impersonateMode: true })));

    const { roles } = profileData;
    if (!roles.length) {
      yield put(actions.setActiveRole({ data: 'none' }));
    } else if (roles.indexOf('ROLE_SYSTEM_ADMIN') > -1) {
      yield put(actions.setActiveRole({ data: 'ROLE_SYSTEM_ADMIN' }));
    } else if (roles.indexOf('ROLE_ADMIN') > -1) {
      yield put(actions.setActiveRole({ data: 'ROLE_ADMIN' }));
    } else if (roles.indexOf('ROLE_TEACHER') > -1) {
      yield put(actions.setActiveRole({ data: 'ROLE_TEACHER' }));
    } else {
      yield put(actions.setActiveRole({ data: roles[0] }));
    }
  } catch (error) {
    yield put(actions.impersonateRequestFailure(error));
  }
}

export default function* sagas() {
  yield takeLatest(actions.login, loginUser);
  yield takeLatest(actions.logout, logoutUser);
  yield takeLatest(actions.getTentantConfig, getTentantConfig);
  yield takeLatest(actions.getCurrentUser, fetchCurrentUser);
  yield takeLatest(actions.resetPasswordRequest, resetPasswordSaga);
  yield takeLatest(actions.forgotPasswordRequest, forgotPasswordSaga);
  yield takeLatest(actions.saveProfileRequest, onSaveProfile);
  yield takeLatest(actions.impersonateRequest, impersonateRequest);
}
