import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const GET_REGIONS_BY_TENANT = 'GET_REGIONS_BY_TENANT';
export const GET_REGIONS_BY_TENANT_SUCCESS = 'GET_REGIONS_BY_TENANT_SUCCESS';
export const GET_REGIONS_BY_TENANT_FAILURE = 'GET_REGIONS_BY_TENANT_FAILURE';

export const CREATE_REGION = 'CREATE_REGION';
export const CREATE_REGION_SUCCESS = 'CREATE_REGION_SUCCESS';
export const CREATE_REGION_FAILURE = 'CREATE_REGION_FAILURE';

export const GET_REGION_DETAILS = 'GET_REGION_DETAILS';
export const GET_REGION_DETAILS_SUCCESS = 'GET_REGION_DETAILS_SUCCESS';
export const GET_REGION_DETAILS_FAILURE = 'GET_REGION_DETAILS_FAILURE';

export const UPDATE_REGION = 'UPDATE_REGION';
export const UPDATE_REGION_SUCCESS = 'UPDATE_REGION_SUCCESS';
export const UPDATE_REGION_FAILURE = 'UPDATE_REGION_FAILURE';

export const REFRESH_REGION_DETAILS = 'REFRESH_REGION_DETAILS';

export const actions = createActions({
  [GET_REGIONS_BY_TENANT]: undefined,
  [GET_REGIONS_BY_TENANT_SUCCESS]: undefined,
  [GET_REGIONS_BY_TENANT_FAILURE]: undefined,

  [CREATE_REGION]: undefined,
  [CREATE_REGION_SUCCESS]: undefined,
  [CREATE_REGION_FAILURE]: undefined,

  [GET_REGION_DETAILS]: undefined,
  [GET_REGION_DETAILS_SUCCESS]: undefined,
  [GET_REGION_DETAILS_FAILURE]: undefined,

  [UPDATE_REGION]: undefined,
  [UPDATE_REGION_SUCCESS]: undefined,
  [UPDATE_REGION_FAILURE]: undefined,

  [REFRESH_REGION_DETAILS]: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getRegionsByTenant, handlers.getRegionsByTenant],
    [actions.getRegionsByTenantSuccess, handlers.getRegionsByTenantSuccess],
    [actions.getRegionsByTenantFailure, handlers.getRegionsByTenantFailure],

    [actions.createRegion, handlers.createRegion],
    [actions.createRegionSuccess, handlers.createRegionSuccess],
    [actions.createRegionFailure, handlers.createRegionFailure],

    [actions.getRegionDetails, handlers.getRegionDetails],
    [actions.getRegionDetailsSuccess, handlers.getRegionDetailsSuccess],
    [actions.getRegionDetailsFailure, handlers.getRegionDetailsFailure],

    [actions.updateRegion, handlers.updateRegion],
    [actions.updateRegionSuccess, handlers.updateRegionSuccess],
    [actions.updateRegionFailure, handlers.updateRegionFailure],

    [actions.refreshRegionDetails, handlers.refreshRegionDetails],
  ]),
  initialState,
);

export default reducer;
