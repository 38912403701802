import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_ASSESSMENTS_REQUEST: undefined,
  GET_ASSESSMENTS_SUCCESS: undefined,
  GET_ASSESSMENTS_ERROR: undefined,

  GET_RACES_REQUEST: undefined,
  GET_RACES_SUCCESS: undefined,
  GET_RACES_ERROR: undefined,

  GET_PRIOR_CARES_REQUEST: undefined,
  GET_PRIOR_CARES_SUCCESS: undefined,
  GET_PRIOR_CARES_ERROR: undefined,

  GET_DISTRICT_REQUEST: undefined,
  GET_DISTRICT_SUCCESS: undefined,

  GET_ORGS_REQUEST: undefined,
  GET_ORGS_SUCCESS: undefined,
  GET_ORGS_ERROR: undefined,

  GET_GENDERS_REQUEST: undefined,
  GET_GENDERS_SUCCESS: undefined,
  GET_GENDERS_ERROR: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getAssessmentsRequest, handlers.getAssessmentsRequest],
    [actions.getAssessmentsSuccess, handlers.getAssessmentsSuccess],
    [actions.getAssessmentsError, handlers.getAssessmentsError],

    [actions.getRacesRequest, handlers.getRacesRequest],
    [actions.getRacesSuccess, handlers.getRacesSuccess],
    [actions.getRacesError, handlers.getRacesError],

    [actions.getPriorCaresRequest, handlers.getPriorCaresRequest],
    [actions.getPriorCaresSuccess, handlers.getPriorCaresSuccess],
    [actions.getPriorCaresError, handlers.getPriorCaresError],

    [actions.getDistrictRequest, handlers.getDistrictRequest],
    [actions.getDistrictSuccess, handlers.getDistrictSuccess],

    [actions.getOrgsRequest, handlers.getOrgsRequest],
    [actions.getOrgsSuccess, handlers.getOrgsSuccess],
    [actions.getOrgsError, handlers.getOrgsError],

    [actions.getGendersRequest, handlers.getGendersRequest],
    [actions.getGendersSuccess, handlers.getGendersSuccess],
    [actions.getGendersError, handlers.getGendersError],
  ]),
  initialState,
);

export default reducer;
