import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

import { format } from './formatters';

export const getGroups = (id) => http.get(
  cas3ApiRoute(`/data-collections/${id}/groups`),
).then((res) => format(res.data));

export const postStudentGrade = (id, body) => http.post(
  cas3ApiRoute(`/data-collections/${id}/scores`),
  body,
);

export const deleteStudentGrade = ({ collectionId, gradeId }) => http.delete(
  cas3ApiRoute(`/data-collections/${collectionId}/scores/${gradeId}`),
);

export const getArtifacts = ({ collectionId, gradeId }) => http.get(
  cas3ApiRoute(`/data-collections/${collectionId}/scores/${gradeId}/artifacts`),
).then((res) => res.data.artifacts);

export const saveScoreComment = ({ collectionId, gradeId, comment }) => http.put(
  cas3ApiRoute(`/data-collections/${collectionId}/scores`),
  { id: gradeId, comment },
);

export const deleteArtifact = ({ collectionId, gradeId, id }) => http.delete(
  cas3ApiRoute(`/data-collections/${collectionId}/scores/${gradeId}/artifacts/${id}`),
);

export const saveArtifacts = ({ collectionId, gradeId, files }) => http.post(
  cas3ApiRoute(`/data-collections/${collectionId}/scores/${gradeId}/artifacts`),
  { files },
);
