const initialState = {
  collectionAssignments: [],
  collectionAssignmentsLoading: false,

  locations: [],
  locationsLoading: false,
  locationsUpdatingIds: [],

  districts: [],
  districtsLoading: false,
  districtsUpdatingIds: [],

  regions: [],
  regionsLoading: false,
  regionsUpdatingIds: [],

  error: null,
};

export const getCollectionAssignments = (state) => ({
  ...state,
  collectionAssignmentsLoading: true,
});

export const getCollectionAssignmentsFailure = (state, { payload }) => ({
  ...state,
  collectionAssignmentsLoading: false,
  error: payload,
});

export const getCollectionAssignmentsSuccess = (state, { payload }) => ({
  ...state,
  collectionAssignments: payload,
  collectionAssignmentsLoading: false,
});

export const getCollectionLocations = (state) => ({
  ...state,
  locationsLoading: true,
});

export const getCollectionLocationsFailure = (state, { payload }) => ({
  ...state,
  locationsLoading: false,
  error: payload,
});

export const getCollectionLocationsSuccess = (state, { payload }) => ({
  ...state,
  locations: payload,
  locationsLoading: false,
});

export const getCollectionDistricts = (state) => ({
  ...state,
  districtsLoading: true,
});

export const getCollectionDistrictsFailure = (state, { payload }) => ({
  ...state,
  districtsLoading: false,
  error: payload,
});

export const getCollectionDistrictsSuccess = (state, { payload }) => ({
  ...state,
  districts: payload,
  districtsLoading: false,
});

export const getCollectionRegions = (state) => ({
  ...state,
  regionsLoading: true,
});

export const getCollectionRegionsFailure = (state, { payload }) => ({
  ...state,
  regionsLoading: false,
  error: payload,
});

export const getCollectionRegionsSuccess = (state, { payload }) => ({
  ...state,
  regions: payload,
  regionsLoading: false,
});

export const assignLocation = (state, { payload }) => ({
  ...state,
  locationsLoading: true,
  locationsUpdatingIds: [...state.locationsUpdatingIds, payload.locationId],
});

export const assignLocationFailure = (state, { payload }) => ({
  ...state,
  locationsLoading: false,
  error: payload.error,
  locationsUpdatingIds: [...state.locationsUpdatingIds].filter((id) => id !== payload.locationId),
});

export const assignLocationSuccess = (state, { payload }) => ({
  ...state,
  locationsLoading: false,
  locationsUpdatingIds: [...state.locationsUpdatingIds].filter((id) => id !== payload.locationId),
});

export const assignLocationsByDistrict = (state, { payload }) => ({
  ...state,
  districtsLoading: true,
  districtsUpdatingIds: [...state.districtsUpdatingIds, payload.districtId],
});

export const assignLocationsByDistrictFailure = (state, { payload }) => ({
  ...state,
  districtsLoading: false,
  error: payload.error,
  districtsUpdatingIds: [...state.districtsUpdatingIds].filter((id) => id !== payload.districtId),

});

export const assignLocationsByDistrictSuccess = (state, { payload }) => ({
  ...state,
  districtsLoading: false,
  districtsUpdatingIds: [...state.districtsUpdatingIds].filter((id) => id !== payload.districtId),
});

export const assignLocationsByRegion = (state, { payload }) => ({
  ...state,
  regionsLoading: true,
  regionsUpdatingIds: [...state.regionsUpdatingIds, payload.regionId],
});

export const assignLocationsByRegionFailure = (state, { payload }) => ({
  ...state,
  regionsLoading: false,
  error: payload.error,
  regionsUpdatingIds: [...state.regionsUpdatingIds].filter((id) => id !== payload.regionId),
});

export const assignLocationsByRegionSuccess = (state, { payload }) => ({
  ...state,
  regionsLoading: false,
  regionsUpdatingIds: [...state.regionsUpdatingIds].filter((id) => id !== payload.regionId),
});

export const unassignLocation = (state, { payload }) => ({
  ...state,
  locationsLoading: true,
  locationsUpdatingIds: [...state.locationsUpdatingIds, payload.locationId],
});

export const unassignLocationFailure = (state, { payload }) => ({
  ...state,
  locationsLoading: false,
  error: payload.error,
  locationsUpdatingIds: [...state.locationsUpdatingIds].filter((id) => id !== payload.locationId),
});

export const unassignLocationSuccess = (state, { payload }) => ({
  ...state,
  locationsLoading: false,
  locationsUpdatingIds: [...state.locationsUpdatingIds].filter((id) => id !== payload.locationId),
});

export const refreshCollectionAssignments = (state) => ({
  ...state,
  collectionAssignments: initialState.collectionAssignments,
});

export const refreshCollectionLocations = (state) => ({
  ...state,
  locations: initialState.locations,
});

export const refreshCollectionDistricts = (state) => ({
  ...state,
  districts: initialState.districts,
});

export const refreshCollectionRegions = (state) => ({
  ...state,
  regions: initialState.regions,
});

export default initialState;
