const initialState = {
  tenants: [],
  tenantAdmins: [],
  loading: false,
  error: null,
  tenant: {
    id: null,
    name: null,
    active: false,
    bulkloader: null,
    message: null,
    organizationalType: null,
    uniqueId: null,
  },
};

const mapTenantToState = (item) => ({
  id: item.id,
  name: item.nameLabel,
  active: item.active === 1,
  bulkloader: item.bulkLoadRuleset,
  message: item.message,
  organizationalType: item.orgType,
  uniqueId: item.uniqueId,
});

export const systemSaveTenant = (state) => ({
  ...state,
  loading: true,
});

export const systemSaveTenantSuccess = (state) => ({
  ...state,
  loading: false,
});

export const systemSaveTenantError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.data,
});

export const systemUpdateTenant = (state) => ({
  ...state,
  loading: true,
});

export const systemUpdateTenantSuccess = (state) => ({
  ...state,
  loading: false,
});

export const systemUpdateTenantError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.data,
});

export const systemGetTenants = (state) => ({
  ...state,
  loading: true,
  error: null,
});

export const systemGetTenantsError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.data,
});

export const systemGetTenantsSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  error: null,
  tenants: payload.map(mapTenantToState), // payload.data['hydra:member'].map(mapTenantToState),
});

export const systemCreateTenantAdmin = (state) => ({
  ...state,
  loading: true,
});

export const systemCreateTenantAdminSuccess = (state) => ({
  ...state,
  loading: false,
});

export const systemCreateTenantAdminError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.data,
});

export const systemGetTenantAdmins = (state) => ({
  ...state,
  loading: true,
});

export const systemGetTenantAdminsSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  tenantAdmins: payload,
});

export const systemGetTenantAdminsError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.data,
});

export const systemUpdateTenantAdmin = (state) => ({
  ...state,
  loading: true,
});

export const systemUpdateTenantAdminSuccess = (state) => ({
  ...state,
  loading: false,
});

export const systemUpdateTenantAdminError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.data,
});

export const systemCreateSchoolYear = (state) => ({
  ...state,
  loading: true,
});

export const systemCreateSchoolYearSuccess = (state) => ({
  ...state,
  loading: false,
});

export const systemCreateSchoolYearError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.data,
});

export default initialState;
