import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formGroupLabel: {
    height: '1.5rem',
  },
  label: {
    marginRight: '1rem',
    '& > span': {
      padding: theme.spacing(),
      marginLeft: theme.spacing(),
      '& + span': {
        paddingLeft: 0,
        marginLeft: 0,
      },
    },
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  group: {
    margin: '0 0',
  },
});

const RadioInput = (props) => {
  const {
    options,
    classes,
    input: { name, value, onChange },
    label,
  } = props;

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.marginTop}>
        <FormLabel component="legend" className={classes.formGroupLabel}>
          {label}
        </FormLabel>
        <RadioGroup aria-label={label} name={name} className={classes.group}>
          {options.map((option) => {
            const isChecked = value.toString() === option.value.toString();
            return (
              <FormControlLabel
                className={classes.label}
                key={`${name}-radio-${option.label}`}
                name={name}
                onChange={onChange}
                value={option.value.toString()}
                control={<Radio checked={isChecked} color="primary" />}
                label={option.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

RadioInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  input: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(RadioInput);
