const initialState = {
  links: [
    { description: 'Main content', target: 'main', visible: false },
    { description: 'Application Navigation', target: 'application-navigation', visible: false },
    { description: 'Assessment Navigation', target: 'assessment-navigation', visible: false },
  ],
};

export const pollSkiplinksSuccess = (state, { payload }) => ({
  ...state,
  list: payload.data.notifications,
  unread: payload.data.unread,
});

export default initialState;
