const initialState = {
  data: [],
  isLoading: false,
  error: null,

  isNeedUpdateUploads: false,
};

export const getBulkloadsByTenant = (state, { payload: { isReload = false } }) => ({
  ...state,
  ...(isReload ? { } : { data: [] }),
  isLoading: true,
  isNeedUpdateUploads: false,
});

export const getBulkloadsByTenantSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload,
});

export const getBulkloadsByTenantFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const uploadBulkload = (state) => state;

export const uploadBulkloadSuccess = (state) => ({
  ...state,
  isNeedUpdateUploads: true,
});

export const uploadBulkloadFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const getBulkloadTemplate = (state) => ({
  ...state,
  isLoading: true,
});

export const getBulkloadTemplateSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const getBulkloadTemplateFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const downloadBulkload = (state) => ({
  ...state,
  isLoading: true,
});

export const downloadBulkloadSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const downloadBulkloadFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export default initialState;
