import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_FILTERS_BY_COLLECTION: undefined,
  GET_FILTERS_BY_COLLECTION_SUCCESS: undefined,
  GET_FILTERS_BY_COLLECTION_FAILURE: undefined,

  CREATE_FILTER_BY_COLLECTION: undefined,
  CREATE_FILTER_BY_COLLECTION_SUCCESS: undefined,
  CREATE_FILTER_BY_COLLECTION_FAILURE: undefined,

  GET_FILTER_BY_ID: undefined,
  GET_FILTER_BY_ID_SUCCESS: undefined,
  GET_FILTER_BY_ID_FAILURE: undefined,

  UPDATE_FILTER_BY_ID: undefined,
  UPDATE_FILTER_BY_ID_SUCCESS: undefined,
  UPDATE_FILTER_BY_ID_FAILURE: undefined,

  DELETE_FILTER_BY_ID: undefined,
  DELETE_FILTER_BY_ID_SUCCESS: undefined,
  DELETE_FILTER_BY_ID_FAILURE: undefined,

  SET_SELECTED_FILTER: undefined,
  UPDATE_SELECTED_FILTER: undefined,

  REFRESH_DATA_DISPLAYS_FILTERS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getFiltersByCollection, handlers.getFiltersByCollection],
    [actions.getFiltersByCollectionSuccess, handlers.getFiltersByCollectionSuccess],
    [actions.getFiltersByCollectionFailure, handlers.getFiltersByCollectionFailure],

    [actions.createFilterByCollection, handlers.createFilterByCollection],
    [actions.createFilterByCollectionSuccess, handlers.createFilterByCollectionSuccess],
    [actions.createFilterByCollectionFailure, handlers.createFilterByCollectionFailure],

    [actions.getFilterById, handlers.getFilterById],
    [actions.getFilterByIdSuccess, handlers.getFilterByIdSuccess],
    [actions.getFilterByIdFailure, handlers.getFilterByIdFailure],

    [actions.updateFilterById, handlers.updateFilterById],
    [actions.updateFilterByIdSuccess, handlers.updateFilterByIdSuccess],
    [actions.updateFilterByIdFailure, handlers.updateFilterByIdFailure],

    [actions.deleteFilterById, handlers.deleteFilterById],
    [actions.deleteFilterByIdSuccess, handlers.deleteFilterByIdSuccess],
    [actions.deleteFilterByIdFailure, handlers.deleteFilterByIdFailure],

    [actions.setSelectedFilter, handlers.setSelectedFilter],
    [actions.updateSelectedFilter, handlers.updateSelectedFilter],

    [actions.refreshDataDisplaysFilters, handlers.refreshDataDisplaysFilters],
  ]),
  initialState,
);

export default reducer;
