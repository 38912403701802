import { takeLatest, call, put } from 'redux-saga/effects';

import { actions as snackbarActions } from 'redux/snackbar';
import * as api from 'http/groups';

import { actions } from './index';

function* getGroupsList({ payload }) {
  try {
    const { collectionId } = payload;

    const data = yield call(api.getGroups, { collectionId });

    yield put(actions.getGroupsListSuccess(data));
  } catch (error) {
    yield put(actions.getGroupsListFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* updateGroup({ payload }) {
  try {
    const {
      collectionId,
      groupId,
      data,
      successHandle,
    } = payload;

    yield call(api.updateGroup, { collectionId, groupId, data });

    yield put(actions.updateGroupSuccess());
    yield put(actions.getGroupsList({ collectionId }));

    if (successHandle) {
      successHandle();
    }
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Group successfully updated' } }));
  } catch (error) {
    yield put(actions.updateGroupFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* createGroup({ payload }) {
  try {
    const { collectionId, data, successHandle } = payload;

    yield call(api.createGroup, { collectionId, data });

    yield put(actions.createGroupSuccess());
    yield put(actions.getGroupsList({ collectionId }));

    if (successHandle) {
      successHandle();
    }
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Group successfully created' } }));
  } catch (error) {
    yield put(actions.createGroupFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* deleteGroup({ payload }) {
  try {
    const { collectionId, groupId, successHandle } = payload;

    yield call(api.deleteGroup, { collectionId, groupId });

    yield put(actions.deleteGroupSuccess());
    yield put(actions.getGroupsList({ collectionId }));

    if (successHandle) {
      successHandle();
    }
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Group successfully deleted' } }));
  } catch (error) {
    yield put(actions.deleteGroupFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.getGroupsList, getGroupsList);
  yield takeLatest(actions.updateGroup, updateGroup);
  yield takeLatest(actions.createGroup, createGroup);
  yield takeLatest(actions.deleteGroup, deleteGroup);
}
