import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const GET_JOB_STATUS = 'GET_JOB_STATUS';
export const GET_JOB_STATUS_SUCCESS = 'GET_JOB_STATUS_SUCCESS';
export const GET_JOB_STATUS_FAILURE = 'GET_JOB_STATUS_FAILURE';

export const actions = createActions({
  [GET_JOB_STATUS]: undefined,
  [GET_JOB_STATUS_SUCCESS]: undefined,
  [GET_JOB_STATUS_FAILURE]: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getJobStatus, handlers.getJobStatus],
    [actions.getJobStatusSuccess, handlers.getJobStatusSuccess],
    [actions.getJobStatusFailure, handlers.getJobStatusFailure],
  ]),
  initialState,
);

export default reducer;
