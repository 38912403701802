/* eslint max-len: 0 */

import { createMuiTheme } from '@material-ui/core/styles';

const defaultFonts = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

const fontFamilyNormal = ['LatoWeb', ...defaultFonts].join(',');
const fontFamilyMedium = ['LatoWebMedium', ...defaultFonts].join(',');
const fontFamilySemiBold = ['LatoWebSemibold', ...defaultFonts].join(',');

const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: 'hsl(199, 96%, 31%)',
      lighter: 'hsla(199, 96%, 31%, 50%)',
      contrastText: '#FFFFFF',
      highlightBlue: 'rgba(232, 240, 245, 1)',
      dark: '#222222',
      addButton: {
        background: 'rgba(165, 214, 86, 1)',
        hoverBackground: 'rgba(165, 214, 86, .8)',
        textColor: '#000',
        disabledBackground: 'rgba(0, 0, 0, 0.04)',
      },
      removeButton: {
        background: 'rgba(213, 86, 83, 1)',
        hoverBackground: 'rgba(213, 86, 83, .8)',
        textColor: '#FFFFFF',
      },
      sidebarMenu: {
        drawerBackground: '#e8f0f5',
        defaultTextColor: '#000',
        activeTextColor: '#fff',
        activeBackground: '#036a9b',
        hoverBackground: '#c6dbe8',
        defaultIconColor: '#036a9b',
        activeIconColor: '#fff',
      },
    },
    secondary: {
      main: '#70a823',
    },
    darkButtons: {
      main: '#222',
      contrastText: '# FFFFFF',
    },
    error: {
      main: '#c21509',
    },
    loader: {
      main: '#ffa401',
    },
    loaderBackground: {
      main: 'rgba(255,255,255, .5)',
    },
    itemColors: {
      blue: '#036B9B',
      orange: '#ffa500',
      purple: '#800080',
      red: '#D7371D',
      default: '#d4d4d4',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: fontFamilyNormal,
    fontFamilyBold: fontFamilySemiBold,
    table: {
      headers: {
        textColor: 'rgba(0, 0, 0, 0.54)',
        fontSize: '.9rem',
      },
      pagination: {
        fontSize: '.75rem',
      },
      titles: {
        textColor: 'rgb(51, 51, 51)',
      },
    },
  },

  spacing: 1,
  overrides: {
    MuiTypography: {
      h1: { fontFamily: fontFamilySemiBold, fontSize: '2.5rem' },
      h2: { fontFamily: fontFamilySemiBold, fontSize: '2rem' },
      h3: { fontFamily: fontFamilySemiBold, fontSize: '1.75rem' },
      h4: { fontFamily: fontFamilyMedium, fontSize: '1.5rem' },
      h5: { fontFamily: fontFamilyMedium, fontSize: '1.25rem' },
      h6: { fontFamily: fontFamilySemiBold, fontSize: '1rem' },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        '&$disabled': { color: 'black' },
      },
    },
    MuiTab: {
      labelIcon: {
        textTransform: 'none',
      },
    },
    MuiButtonBase: {
      root: {
        marginRight: '8px',
      },
    },
    MuiLink: {
      root: { marginBottom: '0.5rem' },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1rem',
        color: 'rgba(0, 0, 0, 0.9)',
      },
      asterisk: {
        color: 'red',
        '&$error': {
          color: 'red',
        },
      },
    },
    MuiFormControl: {
      root: { marginBottom: '8px' },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 16px) scale(1)',
      },
    },
    MuiDialogContentText: {
      root: {
        color: 'rgba(0, 0, 0, 0.9)',
      },
    },
    MuiOutlinedInput: {
      input: { padding: '14px 14px' },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        paddingRight: '25px !important',
        paddingLeft: '25px !important',
      },
    },
    SelectOptions: {
      defaultColor: '#ffffff',
      focusedColor: 'rgba(0, 0, 0, 0.04)',
      selectedColor: 'rgba(0, 0, 0, 0.08)',
      activeColor: 'rgba(0, 0, 0, 0.12)',
      linedColor: '#036b9b',
      outlineColor: 'rgba(0, 0, 0, 0.4)',
      mainColor: '#000',
    },
  },
  custom: {
    sidebarWidth: 240,
    sidebarMiniWidth: 65,
    blue: {
      light: 'rgba(46, 175, 250, 0.2)',
      default: 'rgba(46, 175, 250, 1)',
    },
    lightBlue: {
      light: 'rgba(8, 133, 191, 0.2)',
      default: 'rgba(46, 175, 250, 1)',
    },
    green: {
      light: 'rgba(153, 216, 70, 0.2)',
      default: 'rgba(153, 216, 70, 1)',
    },
    orange: {
      light: 'rgba(237, 173, 87, 0.2)',
      default: 'rgba(237, 173, 87, 1)',
    },
    purple: {
      light: 'rgba(152, 59, 201, 0.2)',
      default: 'rgba(152, 59, 201, 1)',
    },
    red: {
      light: 'rgba(215, 55, 29, 0.2)',
      default: 'rgba(215, 55, 29, 1)',
    },
    primary: {
      light: 'rgba(3, 107, 155, 0.2)',
      default: 'rgba(3, 107, 155, 1)',
    },
  },
});

export default MuiTheme;
