const {
  REACT_APP_CAS_V4_UI_PATH,
} = process.env;

// TODO: Where this function is used, we need to pass in the current subdomain (or provide a better default)
export default function url(path, cas4 = false) {
  // Absolute URLs
  // TODO: This won't account for non-HTTP(s) protocols
  if (/^https?:\/\//gi.test(path)) {
    return path;
  }

  if (cas4) {
    let base = 'https://';

    base += window.location.hostname;

    const parts = [REACT_APP_CAS_V4_UI_PATH, path].join('/').replace(/\/{2,}/g, '/');
    const dest = new URL(parts, base);
    return dest.href;
  }

  // TODO: If CAS3 ever has base paths, prepend it to this array
  const parts = [path].join('/').replace(/\/{2,}/g, '/');
  // The test domain is just to construct a valid URL, and is unused
  const merged = new URL(parts, 'https://test');

  if (merged.search) {
    return `${merged.pathname}?${merged.search}`;
  }

  return merged.pathname;
}
