import authSaga from './auth/sagas';
import systemSaga from './system/sagas';
import teacherSaga from './teacher/sagas';
import commonData from './common-data/sagas';
import skiplinksSaga from './skiplinks/sagas';
import dataDisplayFiltersSaga from './data-display-filters/sagas';
import notificationSaga from './notifications/sagas';

import filtersSaga from './filters/sagas';
import optionsSaga from './options/sagas';
import studentSaga from './students/sagas';
import adminSaga from './admin/sagas';
import groupSaga from './groups/sagas';

export default function* saga() {
  yield* filtersSaga();
  yield* optionsSaga();
  yield* studentSaga();
  yield* dataDisplayFiltersSaga();

  yield* authSaga();
  yield* adminSaga();
  yield* commonData();
  yield* systemSaga();
  yield* teacherSaga();
  yield* skiplinksSaga();
  yield* notificationSaga();
  yield* groupSaga();
}
