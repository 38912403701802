const initialState = {
  generatingReport: false,
  reportId: null,
  error: '',
  isLoading: false,
  list: [],
};

export const clearReportId = (state) => ({
  ...state,
  reportId: null,
  error: '',
  generatingReport: false,
});

export const getInfoSheetRequest = (state, { payload }) => ({
  ...state,
  tenant: payload.tenant,
  error: '',
});

export const getInfoSheetSuccess = (state) => ({
  ...state,
  error: '',
});

export const getInfoSheetFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
});

export const getReportRequest = (state) => ({
  ...state,
  generatingReport: true,
  error: '',
});

export const getReportSuccess = (state, { payload }) => ({
  ...state,
  generatingReport: false,
  reportId: payload,
  error: '',
});

export const getReportFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getReportsRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getReportsSuccess = (state, { payload }) => ({
  ...state,
  list: payload,
  isLoading: false,
});

export const getReportsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export default initialState;
