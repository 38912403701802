import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  CLEAR_REPORT_ID: undefined,

  GET_INFO_SHEET_REQUEST: undefined,
  GET_INFO_SHEET_SUCCESS: undefined,
  GET_INFO_SHEET_FAILURE: undefined,

  GET_REPORT_REQUEST: undefined,
  GET_REPORT_SUCCESS: undefined,
  GET_REPORT_FAILURE: undefined,

  GET_REPORTS_REQUEST: undefined,
  GET_REPORTS_SUCCESS: undefined,
  GET_REPORTS_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.clearReportId, handlers.clearReportId],

    [actions.getInfoSheetRequest, handlers.getInfoSheetRequest],
    [actions.getInfoSheetSuccess, handlers.getInfoSheetSuccess],
    [actions.getInfoSheetFailure, handlers.getInfoSheetFailure],

    [actions.getReportRequest, handlers.getReportRequest],
    [actions.getReportSuccess, handlers.getReportSuccess],
    [actions.getReportFailure, handlers.getReportFailure],

    [actions.getReportsRequest, handlers.getReportsRequest],
    [actions.getReportsSuccess, handlers.getReportsSuccess],
    [actions.getReportsFailure, handlers.getReportsFailure],
  ]),
  initialState,
);

export default reducer;
