import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  SYSTEM_NEW_ASSESSMENT: undefined,

  SYSTEM_GET_ASSESSMENT: undefined,
  SYSTEM_GET_ASSESSMENT_ERROR: undefined,
  SYSTEM_GET_ASSESSMENT_SUCCESS: undefined,

  SYSTEM_GET_ASSESSMENTS: undefined,
  SYSTEM_GET_ASSESSMENTS_ERROR: undefined,
  SYSTEM_GET_ASSESSMENTS_SUCCESS: undefined,

  SYSTEM_SAVE_ASSESSMENT: undefined,
  SYSTEM_SAVE_ASSESSMENT_ERROR: undefined,
  SYSTEM_SAVE_ASSESSMENT_SUCCESS: undefined,

  SYSTEM_UPDATE_ASSESSMENT: undefined,
  SYSTEM_UPDATE_ASSESSMENT_ERROR: undefined,
  SYSTEM_UPDATE_ASSESSMENT_SUCCESS: undefined,

  SYSTEM_GET_ASSESSMENT_ITEMS: undefined,
  SYSTEM_GET_ASSESSMENT_ITEMS_ERROR: undefined,
  SYSTEM_GET_ASSESSMENT_ITEMS_SUCCESS: undefined,

  SYSTEM_SAVE_ASSESSMENT_ITEM: undefined,
  SYSTEM_SAVE_ASSESSMENT_ITEM_ERROR: undefined,
  SYSTEM_SAVE_ASSESSMENT_ITEM_SUCCESS: undefined,

  SYSTEM_UPDATE_ASSESSMENT_ITEM: undefined,
  SYSTEM_UPDATE_ASSESSMENT_ITEM_ERROR: undefined,
  SYSTEM_UPDATE_ASSESSMENT_ITEM_SUCCESS: undefined,

  SYSTEM_DELETE_ASSESSMENT_ITEM: undefined,
  SYSTEM_DELETE_ASSESSMENT_ITEM_ERROR: undefined,
  SYSTEM_DELETE_ASSESSMENT_ITEM_SUCCESS: undefined,

  SYSTEM_SAVE_ASSESSMENT_HIERARCHY_ITEM: undefined,
  SYSTEM_SAVE_ASSESSMENT_HIERARCHY_ITEM_ERROR: undefined,
  SYSTEM_SAVE_ASSESSMENT_HIERARCHY_ITEM_SUCCESS: undefined,

  SYSTEM_UPDATE_ASSESSMENT_HIERARCHY_ITEM: undefined,
  SYSTEM_UPDATE_ASSESSMENT_HIERARCHY_ITEM_ERROR: undefined,
  SYSTEM_UPDATE_ASSESSMENT_HIERARCHY_ITEM_SUCCESS: undefined,

  SYSTEM_DELETE_ASSESSMENT_HIERARCHY_ITEM: undefined,
  SYSTEM_DELETE_ASSESSMENT_HIERARCHY_ITEM_ERROR: undefined,
  SYSTEM_DELETE_ASSESSMENT_HIERARCHY_ITEM_SUCCESS: undefined,

  SYSTEM_IMPORT_ASSESSMENT: undefined,
  SYSTEM_IMPORT_ASSESSMENT_ERROR: undefined,
  SYSTEM_IMPORT_ASSESSMENT_SUCCESS: undefined,

  SYSTEM_GET_DATACOLLECTIONS: undefined,
  SYSTEM_GET_DATACOLLECTIONS_ERROR: undefined,
  SYSTEM_GET_DATACOLLECTIONS_SUCCESS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.systemNewAssessment, handlers.systemNewAssessment],

    [actions.systemGetAssessment, handlers.systemGetAssessment],
    [actions.systemGetAssessmentError, handlers.systemGetAssessmentError],
    [actions.systemGetAssessmentSuccess, handlers.systemGetAssessmentSuccess],

    [actions.systemGetAssessments, handlers.systemGetAssessments],
    [actions.systemGetAssessmentsError, handlers.systemGetAssessmentsError],
    [actions.systemGetAssessmentsSuccess, handlers.systemGetAssessmentsSuccess],

    [actions.systemSaveAssessment, handlers.systemSaveAssessment],
    [actions.systemSaveAssessmentError, handlers.systemSaveAssessmentError],
    [actions.systemSaveAssessmentSuccess, handlers.systemSaveAssessmentSuccess],

    [actions.systemUpdateAssessment, handlers.systemUpdateAssessment],
    [actions.systemUpdateAssessmentError, handlers.systemUpdateAssessmentError],
    [actions.systemUpdateAssessmentSuccess, handlers.systemUpdateAssessmentSuccess],

    [actions.systemGetAssessmentItems, handlers.systemGetAssessmentItems],
    [actions.systemGetAssessmentItemsError, handlers.systemGetAssessmentItemsError],
    [actions.systemGetAssessmentItemsSuccess, handlers.systemGetAssessmentItemsSuccess],

    [actions.systemSaveAssessmentItem, handlers.systemSaveAssessmentItem],
    [actions.systemSaveAssessmentItemSuccess, handlers.systemSaveAssessmentItemSuccess],
    [actions.systemSaveAssessmentItemError, handlers.systemSaveAssessmentItemError],

    [actions.systemUpdateAssessmentItem, handlers.systemUpdateAssessmentItem],
    [actions.systemUpdateAssessmentItemSuccess, handlers.systemUpdateAssessmentItemSuccess],
    [actions.systemUpdateAssessmentItemError, handlers.systemUpdateAssessmentItemError],

    [actions.systemDeleteAssessmentItem, handlers.systemDeleteAssessmentItem],
    [actions.systemDeleteAssessmentItemSuccess, handlers.systemDeleteAssessmentItemSuccess],
    [actions.systemDeleteAssessmentItemError, handlers.systemDeleteAssessmentItemError],

    [actions.systemSaveAssessmentHierarchyItem, handlers.systemSaveAssessmentHierarchyItem],
    [actions.systemSaveAssessmentHierarchyItemSuccess, handlers.systemSaveAssessmentHierarchyItemSuccess],
    [actions.systemSaveAssessmentHierarchyItemError, handlers.systemSaveAssessmentHierarchyItemError],

    [actions.systemUpdateAssessmentHierarchyItem, handlers.systemUpdateAssessmentHierarchyItem],
    [actions.systemUpdateAssessmentHierarchyItemSuccess, handlers.systemUpdateAssessmentHierarchyItemSuccess],
    [actions.systemUpdateAssessmentHierarchyItemError, handlers.systemUpdateAssessmentHierarchyItemError],

    [actions.systemDeleteAssessmentHierarchyItem, handlers.systemDeleteAssessmentHierarchyItem],
    [actions.systemDeleteAssessmentHierarchyItemSuccess, handlers.systemDeleteAssessmentHierarchyItemSuccess],
    [actions.systemDeleteAssessmentHierarchyItemError, handlers.systemDeleteAssessmentHierarchyItemError],

    [actions.systemImportAssessment, handlers.systemImportAssessment],
    [actions.systemImportAssessmentSuccess, handlers.systemImportAssessmentSuccess],
    [actions.systemImportAssessmentError, handlers.systemImportAssessmentError],

    [actions.systemGetDatacollections, handlers.systemGetDatacollections],
    [actions.systemGetDatacollectionsError, handlers.systemGetDatacollectionsError],
    [actions.systemGetDatacollectionsSuccess, handlers.systemGetDatacollectionsSuccess],
  ]),
  initialState,
);

export default reducer;
