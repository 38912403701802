import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_BULKLOADS_BY_TENANT: undefined,
  GET_BULKLOADS_BY_TENANT_SUCCESS: undefined,
  GET_BULKLOADS_BY_TENANT_FAILURE: undefined,

  GET_BULKLOADS_ADMIN_BY_TENANT: undefined,
  GET_BULKLOADS_ADMIN_BY_TENANT_SUCCESS: undefined,
  GET_BULKLOADS_ADMIN_BY_TENANT_FAILURE: undefined,

  UPLOAD_BULKLOAD: undefined,
  UPLOAD_BULKLOAD_SUCCESS: undefined,
  UPLOAD_BULKLOAD_FAILURE: undefined,

  GET_BULKLOAD_TEMPLATE: undefined,
  GET_BULKLOAD_TEMPLATE_SUCCESS: undefined,
  GET_BULKLOAD_TEMPLATE_FAILURE: undefined,

  DOWNLOAD_BULKLOAD: undefined,
  DOWNLOAD_BULKLOAD_SUCCESS: undefined,
  DOWNLOAD_BULKLOAD_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getBulkloadsByTenant, handlers.getBulkloadsByTenant],
    [actions.getBulkloadsByTenantSuccess, handlers.getBulkloadsByTenantSuccess],
    [actions.getBulkloadsByTenantFailure, handlers.getBulkloadsByTenantFailure],

    [actions.getBulkloadsAdminByTenant, handlers.getBulkloadsByTenant],
    [actions.getBulkloadsAdminByTenantSuccess, handlers.getBulkloadsByTenantSuccess],
    [actions.getBulkloadsAdminByTenantFailure, handlers.getBulkloadsByTenantFailure],

    [actions.uploadBulkload, handlers.uploadBulkload],
    [actions.uploadBulkloadSuccess, handlers.uploadBulkloadSuccess],
    [actions.uploadBulkloadFailure, handlers.uploadBulkloadFailure],

    [actions.getBulkloadTemplate, handlers.getBulkloadTemplate],
    [actions.getBulkloadTemplateSuccess, handlers.getBulkloadTemplateSuccess],
    [actions.getBulkloadTemplateFailure, handlers.getBulkloadTemplateFailure],

    [actions.downloadBulkload, handlers.downloadBulkload],
    [actions.downloadBulkloadSuccess, handlers.downloadBulkloadSuccess],
    [actions.downloadBulkloadFailure, handlers.downloadBulkloadFailure],
  ]),
  initialState,
);

export default reducer;
