import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm, Form } from 'redux-form';

import { actions } from 'src/redux/auth';
// Importing for validation translation without the HOC
import i18n from 'src/i18n';
import { TextInput } from 'src/components';

const styles = () => ({
  marginTop: {
    marginTop: '1rem',
  },
  form: {
    marginBottom: '1rem',
  },
});

const LoginForm = (props) => {
  const {
    dispatch, t, formdata, invalid, loading, loginError, classes,
  } = props;

  const handleSubmit = (event) => {
    event.preventDefault();

    const {
      values: { username, password },
    } = formdata;

    dispatch(actions.login({ data: { username, password } }));
  };

  return (
    <Form data-testid="loginForm" onSubmit={handleSubmit} className={classes.form}>
      <Field name="username" id="username" autoFocus required component={TextInput} label={t('Username')} data-testid="login-username" />
      <Field
        name="password"
        id="password"
        required
        component={TextInput}
        label={t('Password')}
        adornmentText={t('Toggle password visibility')}
        type="password"
        data-testid="login-password"
      />
      <label id="csrf-label" htmlFor="csrf" aria-hidden="true" hidden>
        {t('CSRF Token')}
        <Field name="csrf" id="csrf" hidden aria-hidden="true" component="input" />
      </label>
      <div>
        {loginError && (
          <Typography data-testid="login-error" color="error" variant="body2">
            {t(loginError)}
          </Typography>
        )}
      </div>

      <Button
        type="submit"
        disabled={invalid || loading}
        variant="contained"
        size="medium"
        color="primary"
        className={classes.marginTop}
        data-testid="login-submit"
      >
        {t('Login')}
      </Button>
    </Form>
  );
};

LoginForm.defaultProps = {
  formdata: {
    username: '',
    password: '',
  },
  loginError: '',
};

LoginForm.propTypes = {
  t: PropTypes.func.isRequired,
  formdata: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loginError: PropTypes.string,
};

const validate = (values) => {
  const errors = {};

  if (!values.username) {
    errors.username = i18n.t('Username is required');
  }
  if (values.username && !/^['A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)) {
    errors.username = i18n.t('Invalid username format');
  }
  return errors;
};

const mapStateToProps = ({ form, auth }) => ({
  formdata: form.login,
  loading: auth.loading,
  loginError: auth.loginError,
});
export default connect(
  mapStateToProps,
  (dispatch) => ({ dispatch }),
)(
  withTranslation()(
    reduxForm({
      form: 'login',
      validate,
      enableReinitialize: true,
    })(withStyles(styles)(LoginForm)),
  ),
);
