import localStorage from 'local-storage';

const experimentalFeatures = {

  /**
    * Checks to see if the site is in "legacy" mode or not.  This should wrap any experimental features.
    *
    * @returns {Boolean} - true if the site is in legacy mode
    */
  siteInLegacyMode: () => localStorage.get('legacy'),

  /**
    * Puts the site in legacy mode
    *
    * @returns {Boolean} - true if it succeeded
    */
  putSiteInLegacyMode: () => localStorage.set('legacy', true),

  /**
    * Puts the site into beta mode
    *
    * @returns {Boolean} - true if it succeeded
    */
  putSiteInBetaMode: () => localStorage.remove('legacy'),

  /**
    * Removes the legacy flag, letting it fall back to environment settings
    *
    * @returns {Boolean} - true if it succeeded in deleting the value
    */
  removeLegacyFlag: () => localStorage.remove('legacy'),
};

export default experimentalFeatures;
