/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const arrowGenerator = (color) => ({
  '&[x-placement*="bottom"] $arrow': {
    top: 0,
    left: 0,
    marginTop: '-0.95em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent ${color} transparent`,
    },
  },
  '&[x-placement*="top"] $arrow': {
    bottom: 0,
    left: 0,
    marginBottom: '-0.95em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '1em 1em 0 1em',
      borderColor: `${color} transparent transparent transparent`,
    },
  },
  '&[x-placement*="right"] $arrow': {
    left: 0,
    marginLeft: '-0.95em',
    height: '3em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 1em 1em 0',
      borderColor: `transparent ${color} transparent transparent`,
    },
  },
  '&[x-placement*="left"] $arrow': {
    right: 0,
    marginRight: '-0.95em',
    height: '3em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 0 1em 1em',
      borderColor: `transparent transparent transparent ${color}`,
    },
  },
});

const useStylesArrow = makeStyles((theme) => ({
  touch: {
    fontSize: '0.625rem',
  },
  tooltip: {
    maxWidth: 150,
    padding: '10px 20px',
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
  },
  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popper: arrowGenerator(theme.palette.grey[800]),
}));

const CustomTooltip = ({
  title,
  disabled,
  disableOnTouchDevices,
  ...rest
}) => {
  const { arrow, ...classes } = useStylesArrow();
  const [arrowRef, setArrowRef] = React.useState(null);

  return (
    <Tooltip
      classes={classes}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef,
            },
          },
        },
      }}
      {...rest}
      disableTouchListener={disableOnTouchDevices}
      disableFocusListener={disableOnTouchDevices}
      disableHoverListener={disabled}
      title={(
        <>
          {title}
          <span className={arrow} ref={setArrowRef} />
        </>
      )}
    />
  );
};

CustomTooltip.defaultProps = {
  title: '',
  disabled: false,
  disableOnTouchDevices: false,
};

CustomTooltip.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  disableOnTouchDevices: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default CustomTooltip;
