// eslint-disable-next-line import/prefer-default-export
export const getEnv = () => {
  const { host } = window.location;

  if (host.includes('dev') || host.includes('localhost')) return 'dev';

  if (host.includes('qa')) return 'qa';

  return 'prod';
};
