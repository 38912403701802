import React, { useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, Form } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import PV from 'password-validator';

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';

import { actions } from 'redux/auth';
import { TextInput } from 'components';
import { navigate } from '@reach/router';
import useStyles from './styled';
import Container from '../Container';

const schema = new PV();

schema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(30) // Maximum length 30
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits() // Must have digits
  .has()
  .not()
  .spaces();

const renderField = ({
  dirtyForm,
  children,
  classes,
  meta: { error },
}) => {
  const classNameError = (dirtyForm && (error ? classes.errorRule : classes.successRule)) || '';

  return (
    <div className={classNameError}>{ children }</div>
  );
};

renderField.defaultProps = {
  children: null,
};

renderField.propTypes = {
  dirtyForm: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const Reset = ({
  token,
  dirty,
  invalid,
  resetPasswordError,
  loading,
  t,
  handleSubmit,
  path,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [resetSuccess, setResetSuccess] = useState(false);

  const submit = useCallback(
    (value) => {
      dispatch(actions.resetPasswordRequest({
        confirmationToken: token,
        password: value.new_password,
        callback: () => setResetSuccess(true),
        path,
      }));
    },
  );

  const handleConfirm = () => {
    navigate('/sign-in');
  };

  if (resetSuccess) {
    return (
      <Container>
        <div className={classes.forgotSuccess}>
          {path.includes('reset') && t('Password has been successfully reset.')}
          {path.includes('user-registration') && t('User registration completed.')}
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className={classes.button}
            onClick={handleConfirm}
            data-testid="forgot-cancel"
          >
            {t('Confirm')}
          </Button>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {loading && <Box width="100%" display="flex" justifyContent="center"><CircularProgress /></Box>}
      {!loading && (
        <>
          <Typography component="h2" variant="h5">
            {path.includes('reset') && t('Reset Password')}
            {path.includes('user-registration') && t('User Registration')}
          </Typography>
          <Form onSubmit={handleSubmit(submit)} className={classes.form}>
            <Field
              id="new_password"
              name="new_password"
              required
              autoFocus
              component={TextInput}
              label={t('New password')}
              adornmentText={t('Toggle password visibility')}
              type="password"
              data-testid="reset-password"
            />
            <Field
              id="repeat_password"
              name="repeat_password"
              required
              component={TextInput}
              label={t('Repeat new password')}
              adornmentText={t('Toggle password visibility')}
              type="password"
              data-testid="reset-repeat-password"
            />
            <div>
              {resetPasswordError && (
                <Typography data-testid="reset-error" color="error" variant="body2">
                  {t(resetPasswordError)}
                </Typography>
              )}
            </div>
            <div className={classes.validateOptions}>
              <div>Passwords must:</div>
              <div className={classes.validateRules}>
                <Field name="min" classes={classes} dirtyForm={dirty} component={renderField}>8 characters long</Field>
                <Field name="uppercase" classes={classes} dirtyForm={dirty} component={renderField}>
                  contain at least 1 uppercase letter
                </Field>
                <Field name="lowercase" classes={classes} dirtyForm={dirty} component={renderField}>
                  contain at least 1 lowercase letter
                </Field>
                <Field name="digits" classes={classes} dirtyForm={dirty} component={renderField}>
                  contain at least 1 number
                </Field>
                <Field name="match" classes={classes} dirtyForm={dirty} component={renderField}>
                  password and confirm password fields match
                </Field>
              </div>
            </div>
            <Grid>
              <Button
                type="submit"
                disabled={invalid || loading}
                variant="contained"
                size="medium"
                color="primary"
                className={classes.button}
                data-testid="reset-submit"
              >
                {t('Change password')}
              </Button>
            </Grid>
          </Form>
        </>

      )}
    </Container>
  );
};

Reset.defaultProps = {
  token: '',
  resetPasswordError: '',
};

Reset.propTypes = {
  token: PropTypes.string,
  resetPasswordError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dirty: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
};

const validate = (values) => {
  const errors = {};

  schema.validate(values.new_password, { list: true }).forEach((error) => { errors[error] = true; });

  if (values.new_password === values.repeat_password) {
    errors.match = false;
  } else {
    errors.match = true;
  }

  return errors;
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  resetPasswordError: state.auth.resetPasswordError,
});

export default connect(
  mapStateToProps,
  (dispatch) => ({ dispatch }),
)(
  withTranslation()(
    reduxForm({
      form: 'reset',
      validate,
      enableReinitialize: true,
    })(Reset),
  ),
);
