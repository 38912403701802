import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';
import moment from 'moment';

import {
  formatDistrict,
  formatDistricts,
  formatIntegration,
  formatIntegrationUsers,
} from './formatter';

export const getDistricts = (params) => http.get(
  cas3ApiRoute('/districts'),
  { params },
).then((res) => formatDistricts(res.data.districts));

export const getDistrictDetails = ({ districtId }) => http.get(
  cas3ApiRoute(`/districts/${districtId}`),
).then((res) => formatDistrict(res.data.district));

export const createDistrict = (data) => http.post(cas3ApiRoute('/districts'), data);

export const updateDistrict = (data) => http.put(
  cas3ApiRoute('/districts'),
  data,
).then((res) => formatDistrict(res.data.district));

export const getIntegrationByDistrict = ({ districtId }) => http.get(cas3ApiRoute('/data-integrations'), {
  params: {
    tenantsDistrictsId: districtId,
    active: 1,
  },
}).then((res) => (res?.data ? formatIntegration(res.data.dataIntegrations) : {}));

export const saveIntegrationByDistrict = ({ districtId, data }) => http.post(
  cas3ApiRoute('/data-integrations'),
  {
    tenantsDistrictsId: +districtId,
    ...data,
  },
).then((res) => (res?.data ? formatIntegration(res.data.dataIntegration) : {}));

export const getIntegrationFtpByDistrict = ({
  tenantId,
  regionId,
  districtId,
}) => http.get(`/admin/tenants/${tenantId}/regions/${regionId}/districts/${districtId}/integration/ftp.json`, {
  params: {
    time: moment().unix(),
  },
}).then((res) => res.data || []);

export const getIntegrationUsersByDistrict = ({ districtId }) => http.get(cas3ApiRoute('/data-integrations/users'), {
  params: {
    districtId,
  },
}).then((res) => formatIntegrationUsers(res.data.users || []));

export const removeIntegrationByDistrict = ({ districtId }) => http.delete(
  cas3ApiRoute('/data-integrations'),
  {
    params: { districtId },
  },
);
