const initialState = {
  assessments: [],
  assessmentItemsHierarchy: [],
  dataCollections: [],
  loading: false,
  error: '',
  assessment: {
    name: '',
    description: '',
    author: '',
    isEncapsulated: null,
    taxonomy: null,
  },
};

// const mapDataCollectionsToStore = (item) => ({
//   id: item.id,
//   assessment: { ...item.assessment },
//   name: item.collectionName,
//   cycleStart: item.cycle_start_date,
//   cycleEnd: item.cycle_end_date,
//   enrollmentEnd: item.enrollment_end_date,
// });

// const mapAssessmentToStore = (item) => ({
//   id: item.id,
//   author: item.contentAuthor,
//   name: item.name,
//   isEncapsulated: item.isEncapsulated === 1,
//   taxonomy: item.taxonomy,
//   description: item.description,
// });

export const systemNewAssessment = (state) => ({
  ...state,
  assessment: { ...initialState.assessment },
});

export const systemGetAssessment = (state) => ({
  ...state,
  assessment: { ...initialState.assessment },
  loading: true,
  error: '',
});

export const systemGetAssessmentError = (state) => ({
  ...state,
  loading: false,
  error: '',
});

export const systemGetAssessmentSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  assessment: payload,
  error: '',
});

export const systemGetAssessments = (state) => ({
  ...state,
  loading: true,
  error: '',
});

export const systemGetAssessmentsError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.error,
});

export const systemGetAssessmentsSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  assessments: payload,
  error: '',
});

export const systemSaveAssessment = (state) => ({
  ...state,
  loading: true,
  error: null,
});

export const systemSaveAssessmentError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.error,
});

export const systemSaveAssessmentSuccess = (state) => ({
  ...state,
  loading: false,
  error: null,
});

export const systemUpdateAssessment = (state) => ({
  ...state,
  loading: true,
  error: null,
});

export const systemUpdateAssessmentError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.error,
});

export const systemUpdateAssessmentSuccess = (state) => ({
  ...state,
  loading: false,
  error: null,
});

export const systemGetAssessmentItems = (state) => ({
  ...state,
  loading: true,
  error: null,
});

export const systemGetAssessmentItemsSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  assessmentItemsHierarchy: payload.data.assessmentItemsHierarchy,
  error: null,
});

export const systemGetAssessmentItemsError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.error,
});

export const systemSaveAssessmentItem = (state) => ({
  ...state,
  loading: true,
  error: null,
});

export const systemSaveAssessmentItemSuccess = (state) => ({
  ...state,
  loading: false,
  error: null,
});

export const systemSaveAssessmentItemError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.error,
});

export const systemUpdateAssessmentItem = (state) => ({
  ...state,
  loading: true,
  error: null,
});

export const systemUpdateAssessmentItemSuccess = (state) => ({
  ...state,
  loading: false,
  error: null,
});

export const systemUpdateAssessmentItemError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.error,
});

export const systemDeleteAssessmentItem = (state) => ({
  ...state,
  loading: true,
  error: null,
});

export const systemDeleteAssessmentItemSuccess = (state) => ({
  ...state,
  loading: false,
  error: null,
});

export const systemDeleteAssessmentItemError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.error,
});

export const systemSaveAssessmentHierarchyItem = (state) => ({
  ...state,
  loading: true,
  error: null,
});

export const systemSaveAssessmentHierarchyItemSuccess = (state) => ({
  ...state,
  loading: false,
  error: null,
});

export const systemSaveAssessmentHierarchyItemError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.error,
});

export const systemUpdateAssessmentHierarchyItem = (state) => ({
  ...state,
  loading: true,
  error: null,
});

export const systemUpdateAssessmentHierarchyItemSuccess = (state) => ({
  ...state,
  loading: false,
  error: null,
});

export const systemUpdateAssessmentHierarchyItemError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.error,
});

export const systemDeleteAssessmentHierarchyItem = (state) => ({
  ...state,
  loading: true,
  error: null,
});

export const systemDeleteAssessmentHierarchyItemSuccess = (state) => ({
  ...state,
  loading: false,
  error: null,
});

export const systemDeleteAssessmentHierarchyItemError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.error,
});

export const systemImportAssessment = (state) => ({
  ...state,
  loading: true,
});

export const systemImportAssessmentSuccess = (state) => ({
  ...state,
  loading: false,
});

export const systemImportAssessmentError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.error,
});

export const systemGetDatacollections = (state) => ({
  ...state,
  loading: true,
  error: '',
});

export const systemGetDatacollectionsError = (state) => ({
  ...state,
  loading: false,
  error: '',
});

export const systemGetDatacollectionsSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  dataCollections: payload.data.dataCollections,
  error: '',
});

export default initialState;
