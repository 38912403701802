const initialState = {
  list: [],
};

export const getNotificationsSuccess = (state, { payload }) => ({
  ...state,
  list: payload,
});

export const receiveNotificationSuccess = (state, { payload }) => ({
  ...state,
  list: [...state.list, payload],
});

export const removeNotificationSuccess = (state, { payload }) => {
  const { notificationId } = payload;
  return {
    ...state,
    list: state.list.filter((notification) => notification.id !== notificationId),
  };
};

export const acknowledgeNotificationSuccess = (state, { payload }) => {
  const { notificationId } = payload;

  return ({
    ...state,
    list: state.list.map((n) => {
      if (n.id === notificationId) {
        return {
          ...n,
          acknowledged: 1,
        };
      }

      return n;
    }),
  });
};

export default initialState;
