import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';
import { formatLocation, formatLocations } from './formatter';

export const getLocations = (params) => http.get(
  cas3ApiRoute('/locations'),
  { params },
).then((res) => formatLocations(res.data.locations));

export const getLocationDetails = ({ locationId }) => http.get(
  cas3ApiRoute(`/locations/${locationId}`),
).then((res) => formatLocation(res.data.location));

export const createLocation = (data) => http.post(cas3ApiRoute('/locations'), data);

export const updateLocation = (data) => http.put(
  cas3ApiRoute('/locations'),
  data,
).then((res) => formatLocation(res.data.location));

export const deleteLocation = ({ locationId }) => http.put(
  cas3ApiRoute('/locations'),
  {
    id: locationId,
    active: false,
  },
).then((res) => res.data.location);
