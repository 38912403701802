import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'react-proptypes';
import { withTranslation } from 'react-i18next';
import { Link as RouterLink } from '@reach/router';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

import { actions } from 'src/redux/auth';

import Downloads from 'src/components/Downloads';
import Loading from 'components/Loading';

import LoginForm from '../LoginForm';
import Container from '../Container';

const styles = (theme) => ({
  divider: {
    margin: `${theme.spacing(4)}px 0`,
  },
  pageTitle: {
    marginBottom: theme.spacing(4),
  },
  logo: {
    height: 'auto',
    width: `calc(100% - ${theme.spacing(2)}px)`,
    maxWidth: '200px',
    padding: '0 0 10px 0',
  },
  downloadButton: {
    display: 'inline-block',
  },
  downloadLogo: {
    width: 50,
    height: 50,
  },
  downloadLink: {
    color: theme.palette.primary.contrastText,
    textTransform: 'none',
  },
  downloadIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: 18,
    height: 18,
  },
});

export const tenantIdMap = {
  mdcchs: 'maryland',
};

export const tenantLogoMap = {
  ohio: { main: { src: 'tenant-logos/login-logo-r4k.png' }, sub: { src: 'tenant-logos/login-logo-oh.png' } },
  hawaii: {
    main: { src: 'tenant-logos/login-logo-r4k.png' },
    sub: { src: 'tenant-logos/login-logo-hi.png', style: { width: '50%' } },
  },
  indiana: { main: { src: 'tenant-logos/login-logo-isprout.png' }, sub: { src: 'tenant-logos/login-logo-in.png' } },
  maryland: { main: { src: 'tenant-logos/login-logo-r4k-md.png' }, sub: { src: 'tenant-logos/login-logo-msde.png' } },
  michigan: { main: { src: 'tenant-logos/login-logo-r4k.png' }, sub: { src: 'tenant-logos/login-logo-mi.png' } },
  southcarolina: {
    main: { src: 'tenant-logos/login-logo-r4k.png' },
    sub: { src: 'tenant-logos/login-logo-sc.png', style: { width: '100px', height: '100px' } },
  },
  default: { main: { src: 'tenant-logos/login-logo-r4k.png' } },
};

const Login = (props) => {
  const {
    t, classes, dispatch, loading,
  } = props;

  const getLogos = (tenant) => {
    const mainImg = tenantLogoMap[tenant] && tenantLogoMap[tenant].main ? tenantLogoMap[tenant].main : tenantLogoMap.default.main;
    return (
      <div>
        <img
          className={classes.logo}
          alt=""
          role="presentation"
          src={`assets/${mainImg.src}`}
          data-testid="login-tenant-logo"
        />
        { tenantLogoMap[tenant] && tenantLogoMap[tenant].sub
          ? (
            <img
              className={classes.logo}
              style={tenantLogoMap[tenant].sub.style
                ? tenantLogoMap[tenant].sub.style : null}
              alt=""
              role="presentation"
              src={`assets/${tenantLogoMap[tenant].sub.src}`}
              data-testid="login-sub-tenant-logo"
            />
          ) : null }
      </div>
    );
  };

  // Get the tenant from the url.
  const urlTenant = window.location.host.split('.')[0];
  const tenant = tenantIdMap[urlTenant] ? tenantIdMap[urlTenant] : urlTenant;

  useEffect(() => {
    // TODO: verify this
    if (
      (tenant === 'localhost:3000' || tenant === 'dev')
      && process.env.NODE_ENV === 'development'
    ) {
      dispatch(actions.getTentantConfig({ data: 'maryland' }));
    } else {
      dispatch(actions.getTentantConfig({ data: tenant }));
    }
  }, []);

  if (loading) { return <Loading title="Logging in, please wait..." />; }

  return (
    <Container>
      <Grid container>
        <Grid item md={4} sm={12}>
          {getLogos(tenant)}
        </Grid>
        <Grid item md={8} sm={12}>
          <Typography component="h1" variant="srOnly" className={classes.pageTitle}>
            {t('Welcome to Ready for Kindergarten')}
          </Typography>
          <LoginForm />
          <Link variant="body1" block="true" component={RouterLink} to="/forgot">
            {t('Forgot password?')}
          </Link>
          <br />
          <Link variant="body1" block="true" href="https://www.hopkinslearning.org/learn/article/kready-technical-support">
            {t('Technical support is available.')}
          </Link>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Downloads />
    </Container>
  );
};

Login.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
});

export default withTranslation()(
  withStyles(styles)(
    connect(
      mapStateToProps,
      (dispatch) => ({ dispatch }),
    )(Login),
  ),
);
