import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_COLLECTION_ASSIGNMENTS: undefined,
  GET_COLLECTION_ASSIGNMENTS_FAILURE: undefined,
  GET_COLLECTION_ASSIGNMENTS_SUCCESS: undefined,

  GET_COLLECTION_LOCATIONS: undefined,
  GET_COLLECTION_LOCATIONS_FAILURE: undefined,
  GET_COLLECTION_LOCATIONS_SUCCESS: undefined,

  GET_COLLECTION_DISTRICTS: undefined,
  GET_COLLECTION_DISTRICTS_FAILURE: undefined,
  GET_COLLECTION_DISTRICTS_SUCCESS: undefined,

  GET_COLLECTION_REGIONS: undefined,
  GET_COLLECTION_REGIONS_FAILURE: undefined,
  GET_COLLECTION_REGIONS_SUCCESS: undefined,

  ASSIGN_LOCATION: undefined,
  ASSIGN_LOCATION_FAILURE: undefined,
  ASSIGN_LOCATION_SUCCESS: undefined,

  ASSIGN_LOCATIONS_BY_DISTRICT: undefined,
  ASSIGN_LOCATIONS_BY_DISTRICT_FAILURE: undefined,
  ASSIGN_LOCATIONS_BY_DISTRICT_SUCCESS: undefined,

  ASSIGN_LOCATIONS_BY_REGION: undefined,
  ASSIGN_LOCATIONS_BY_REGION_FAILURE: undefined,
  ASSIGN_LOCATIONS_BY_REGION_SUCCESS: undefined,

  UNASSIGN_LOCATION: undefined,
  UNASSIGN_LOCATION_FAILURE: undefined,
  UNASSIGN_LOCATION_SUCCESS: undefined,

  REFRESH_COLLECTION_ASSIGNMENTS: undefined,
  REFRESH_COLLECTION_LOCATIONS: undefined,
  REFRESH_COLLECTION_DISTRICTS: undefined,
  REFRESH_COLLECTION_REGIONS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getCollectionAssignments, handlers.getCollectionAssignments],
    [actions.getCollectionAssignmentsFailure, handlers.getCollectionAssignmentsFailure],
    [actions.getCollectionAssignmentsSuccess, handlers.getCollectionAssignmentsSuccess],

    [actions.getCollectionLocations, handlers.getCollectionLocations],
    [actions.getCollectionLocationsFailure, handlers.getCollectionLocationsFailure],
    [actions.getCollectionLocationsSuccess, handlers.getCollectionLocationsSuccess],

    [actions.getCollectionDistricts, handlers.getCollectionDistricts],
    [actions.getCollectionDistrictsFailure, handlers.getCollectionDistrictsFailure],
    [actions.getCollectionDistrictsSuccess, handlers.getCollectionDistrictsSuccess],

    [actions.getCollectionRegions, handlers.getCollectionRegions],
    [actions.getCollectionRegionsFailure, handlers.getCollectionRegionsFailure],
    [actions.getCollectionRegionsSuccess, handlers.getCollectionRegionsSuccess],

    [actions.assignLocation, handlers.assignLocation],
    [actions.assignLocationFailure, handlers.assignLocationFailure],
    [actions.assignLocationSuccess, handlers.assignLocationSuccess],

    [actions.assignLocationsByDistrict, handlers.assignLocationsByDistrict],
    [actions.assignLocationsByDistrictFailure, handlers.assignLocationsByDistrictFailure],
    [actions.assignLocationsByDistrictSuccess, handlers.assignLocationsByDistrictSuccess],

    [actions.assignLocationsByRegion, handlers.assignLocationsByRegion],
    [actions.assignLocationsByRegionFailure, handlers.assignLocationsByRegionFailure],
    [actions.assignLocationsByRegionSuccess, handlers.assignLocationsByRegionSuccess],

    [actions.unassignLocation, handlers.unassignLocation],
    [actions.unassignLocationFailure, handlers.unassignLocationFailure],
    [actions.unassignLocationSuccess, handlers.unassignLocationSuccess],

    [actions.refreshCollectionAssignments, handlers.refreshCollectionAssignments],
    [actions.refreshCollectionLocations, handlers.refreshCollectionLocations],
    [actions.refreshCollectionDistricts, handlers.refreshCollectionDistricts],
    [actions.refreshCollectionRegions, handlers.refreshCollectionRegions],
  ]),
  initialState,
);

export default reducer;
