const initialState = {
  customFields: [],
  isLoading: false,
  error: null,
};

export const getCustomFields = (state) => ({
  ...state,
  isLoading: true,
});

export const getCustomFieldsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  customFields: payload,
});

export const getCustomFieldsFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const refreshCustomFields = (state) => ({
  ...state,
  customFields: initialState.customFields,
  error: initialState.error,
});
export default initialState;
