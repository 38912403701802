import {
  takeLatest,
  select,
  call,
  put,
} from 'redux-saga/effects';
import { navigate } from '@reach/router';

import * as collectionsApi from 'http/admin/collections';

import { actions as snackbarActions } from 'redux/snackbar';
import { actions } from './index';

export function* fetchCollections({ payload }) {
  try {
    const dataCollections = yield call(collectionsApi.getCollections, payload);

    yield put(actions.fetchCollectionsSuccess(dataCollections));
  } catch (error) {
    yield put(actions.fetchCollectionsFail(error));
  }
}

export function* getCollectionsList() {
  const { tenantId } = yield select((state) => state.auth);

  try {
    const response = yield call(collectionsApi.getCollections, tenantId);

    yield put(actions.getCollectionsListSuccess(response));
  } catch (error) {
    yield put(actions.getCollectionsListFailure(error));
  }
}

export function* getCollectionsByRegion({ payload }) {
  const { tenantId, regionId } = payload;

  try {
    const response = yield call(collectionsApi.getCollectionsByRegion, { tenantId, regionId });

    yield put(actions.getCollectionsByRegionSuccess(response));
  } catch (error) {
    yield put(actions.getCollectionsByRegionFailure(error));
  }
}

export function* getCollectionsByDistrict({ payload }) {
  const { tenantId, regionId, districtId } = payload;

  try {
    const response = yield call(collectionsApi.getCollectionsByDistrict, { tenantId, regionId, districtId });

    yield put(actions.getCollectionsByDistrictSuccess(response));
  } catch (error) {
    yield put(actions.getCollectionsByDistrictFailure(error));
  }
}

export function* getCollectionsByLocation({ payload }) {
  const {
    tenantId, regionId, districtId, locationId,
  } = payload;

  try {
    const response = yield call(collectionsApi.getCollectionsByLocation, {
      tenantId, regionId, districtId, locationId,
    });

    yield put(actions.getCollectionsByLocationSuccess(response));
  } catch (error) {
    yield put(actions.getCollectionsByLocationFailure(error));
  }
}

export function* getCollectionDetails({ payload }) {
  try {
    const {
      tenantId, collectionId,
    } = payload;

    const result = yield call(collectionsApi.getCollectionDetails, {
      tenantId, collectionId,
    });

    yield put(actions.getCollectionDetailsSuccess(result));
  } catch (error) {
    yield put(actions.getCollectionDetailsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* createCollection({ payload }) {
  const { data: payloadData, successHandle, isOpenAfter } = payload;
  const { tenantId } = yield select((state) => state.auth);

  try {
    const response = yield call(collectionsApi.createCollection, { payloadData, tenantId });

    yield put(actions.createCollectionSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Collection successfully created' } }));
    yield put(actions.getCollectionsList());

    if (isOpenAfter) navigate(`/administrator/collections/${response.data.id}/profile/details`);
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.createCollectionFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* updateCollection({ payload }) {
  try {
    const {
      data: payloadData,
      collectionId,
      successHandle,
      successMessage,
    } = payload;

    const result = yield call(collectionsApi.updateCollection, { collectionId, payloadData });

    yield put(actions.updateCollectionSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: successMessage || 'Data Collection successfully updated' } }));

    if (successHandle) successHandle();
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.updateCollectionFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* watchSagas() {
  yield takeLatest(actions.fetchCollections, fetchCollections);
  yield takeLatest(actions.getCollectionsList, getCollectionsList);
  yield takeLatest(actions.getCollectionsByRegion, getCollectionsByRegion);
  yield takeLatest(actions.getCollectionsByDistrict, getCollectionsByDistrict);
  yield takeLatest(actions.getCollectionsByLocation, getCollectionsByLocation);
  yield takeLatest(actions.getCollectionDetails, getCollectionDetails);
  yield takeLatest(actions.createCollection, createCollection);
  yield takeLatest(actions.updateCollection, updateCollection);
}
