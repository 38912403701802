import randomcolor from 'randomcolor';

const initialState = {
  error: '',
  count: 0,
  filters: {
    scores: false,
    colors: true,
    stages: true,
    levels: true,
    numeric: false,
    comments: false,
    parentSignature: false,
    student: {},
    items: 'all',
    periods: {},
    checkpointPeriods: [],
    classroomReport: { // ToDo: refactor
      filter: {},
      filters: {},
      filtersList: [],
    },
  },
  lists: [],
  students: [], // ToDo: rename
};

export const setFilter = (state, { payload }) => {
  const { filters } = state;

  return ({
    ...state,
    filters: {
      ...filters,
      ...payload,
    },
  });
};

export const setStudent = (state, { payload }) => {
  const { filters } = state;

  return ({
    ...state,
    filters: {
      ...filters,
      student: payload,
    },
  });
};

export const addList = (state, { payload }) => {
  const { count } = state;
  const { lists } = state;

  return ({
    ...state,
    count: count + 1,
    lists: [...lists, { id: count, ...payload }],
  });
};

export const editList = (state, { payload }) => {
  const { lists, filters } = state;
  const updatedLists = lists.map((elem) => {
    if (elem.id === payload.id) {
      return payload;
    }

    return elem;
  });

  return ({
    ...state,
    filters: {
      ...filters,
      items: filters.items.id === payload.id ? payload : filters.items,
    },
    lists: updatedLists,
  });
};

export const deleteList = (state, { payload }) => {
  const { lists, filters } = state;
  const newList = lists.filter(({ id }) => id !== payload);

  return ({
    ...state,
    filters: {
      ...filters,
      items: filters.items.id === payload ? 'all' : filters.items,
    },
    lists: newList,
  });
};

export const addCheckpointPeriod = (state, { payload }) => {
  const { count, filters } = state;
  const { checkpointPeriods } = filters;
  const color = randomcolor();

  const nextPeriodId = count;
  return ({
    ...state,
    count: count + 1,
    filters: {
      ...filters,
      checkpointPeriods: [...checkpointPeriods, { id: nextPeriodId, color, ...payload }],
      periods: {
        ...filters.periods,
        [nextPeriodId]: true,
      },
    },
  });
};

export const editCheckpointPeriod = (state, { payload }) => {
  const { filters } = state;
  const { checkpointPeriods } = filters;
  const updatedCheckpointPeriods = checkpointPeriods.map((elem) => {
    if (elem.id === payload.id) {
      return ({
        ...elem,
        ...payload,
      });
    }

    return elem;
  });

  return ({
    ...state,
    filters: {
      ...filters,
      checkpointPeriods: updatedCheckpointPeriods,
    },
  });
};

export const deleteCheckpointPeriod = (state, { payload }) => {
  const { filters } = state;
  const { checkpointPeriods } = filters;

  return ({
    ...state,
    filters: {
      ...filters,
      periods: {
        ...filters.periods,
        [payload]: undefined,
      },
      checkpointPeriods: checkpointPeriods.filter(({ id }) => id !== payload),
    },
  });
};

export const setClassroomReportFilter = (state, { payload }) => {
  const { count, filters } = state;
  const { classroomReport } = filters;
  const filter = classroomReport.filtersList.find(({ id }) => payload === id);

  return ({
    ...state,
    count: count + 1,
    filters: {
      ...filters,
      classroomReport: {
        ...classroomReport,
        filter,
      },
    },
  });
};

export const addClassroomReportFilter = (state, { payload }) => {
  const { count, filters } = state;
  const { classroomReport } = filters;
  const color = randomcolor();
  const newFilter = { id: count, color, ...payload };

  return ({
    ...state,
    count: count + 1,
    filters: {
      ...filters,
      classroomReport: {
        ...classroomReport,
        filter: newFilter,
        filtersList: [...classroomReport.filtersList, newFilter],
      },
    },
  });
};

export const editClassroomReportFilter = (state, { payload }) => {
  const { filters } = state;
  const { classroomReport } = filters;
  const updatedFiltersList = classroomReport.filtersList.map((elem) => {
    if (elem.id === payload.id) {
      return ({
        ...elem,
        ...payload,
      });
    }
    return elem;
  });

  return ({
    ...state,
    filters: {
      ...filters,
      classroomReport: {
        ...classroomReport,
        filter: classroomReport.filter.id === payload.id ? { ...classroomReport.filter, ...payload } : classroomReport.filter,
        filtersList: updatedFiltersList,
      },
    },
  });
};

export const deleteClassroomReportFilter = (state, { payload }) => {
  const { filters } = state;
  const { classroomReport } = filters;

  return ({
    ...state,
    filters: {
      ...filters,
      classroomReport: {
        ...classroomReport,
        filters: {
          ...classroomReport.filters,
          [payload]: undefined,
        },
        filtersList: classroomReport.filtersList.filter(({ id }) => id !== payload),
      },
    },
  });
};

export const refreshDataDisplayState = () => ({
  ...initialState,
});

export default initialState;
