import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_COLLECTION_FIELD_TESTS: undefined,
  GET_COLLECTION_FIELD_TESTS_FAILURE: undefined,
  GET_COLLECTION_FIELD_TESTS_SUCCESS: undefined,

  GET_COLLECTION_AVAILABLE_FIELD_TESTS: undefined,
  GET_COLLECTION_AVAILABLE_FIELD_TESTS_FAILURE: undefined,
  GET_COLLECTION_AVAILABLE_FIELD_TESTS_SUCCESS: undefined,

  ASSIGN_LOCATION_FIELD_TESTS: undefined,
  ASSIGN_LOCATION_FIELD_TESTS_FAILURE: undefined,
  ASSIGN_LOCATION_FIELD_TESTS_SUCCESS: undefined,

  UNASSIGN_LOCATION_FIELD_TESTS: undefined,
  UNASSIGN_LOCATION_FIELD_TESTS_FAILURE: undefined,
  UNASSIGN_LOCATION_FIELD_TESTS_SUCCESS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getCollectionFieldTests, handlers.getCollectionFieldTests],
    [actions.getCollectionFieldTestsFailure, handlers.getCollectionFieldTestsFailure],
    [actions.getCollectionFieldTestsSuccess, handlers.getCollectionFieldTestsSuccess],

    [actions.getCollectionAvailableFieldTests, handlers.getCollectionAvailableFieldTests],
    [actions.getCollectionAvailableFieldTestsFailure, handlers.getCollectionAvailableFieldTestsFailure],
    [actions.getCollectionAvailableFieldTestsSuccess, handlers.getCollectionAvailableFieldTestsSuccess],

    [actions.assignLocationFieldTests, handlers.assignLocationFieldTests],
    [actions.assignLocationFieldTestsFailure, handlers.assignLocationFieldTestsFailure],
    [actions.assignLocationFieldTestsSuccess, handlers.assignLocationFieldTestsSuccess],

    [actions.unassignLocationFieldTests, handlers.unassignLocationFieldTests],
    [actions.unassignLocationFieldTestsFailure, handlers.unassignLocationFieldTestsFailure],
    [actions.unassignLocationFieldTestsSuccess, handlers.unassignLocationFieldTestsSuccess],
  ]),
  initialState,
);

export default reducer;
