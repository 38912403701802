import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  SET_VIEW: undefined,
  SET_PLD_VERSION: undefined,
  SET_DOMAIN_VERSION: undefined,
  SET_DOMAIN_SINGLE_VIEW: undefined,
  SET_PANEL_STATE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.setView, handlers.setView],
    [actions.setPldVersion, handlers.setPldVersion],
    [actions.setDomainVersion, handlers.setDomainVersion],
    [actions.setDomainSingleView, handlers.setDomainSingleView],
    [actions.setPanelState, handlers.setPanelState],
  ]),
  initialState,
);

export default reducer;
