import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  SYSTEM_NEW_ACKNOWLEDGEMENT: undefined,

  SYSTEM_GET_ACKNOWLEDGEMENT: undefined,
  SYSTEM_GET_ACKNOWLEDGEMENT_ERROR: undefined,
  SYSTEM_GET_ACKNOWLEDGEMENT_SUCCESS: undefined,

  SYSTEM_GET_ACKNOWLEDGEMENTS: undefined,
  SYSTEM_GET_ACKNOWLEDGEMENTS_ERROR: undefined,
  SYSTEM_GET_ACKNOWLEDGEMENTS_SUCCESS: undefined,

  SYSTEM_SAVE_ACKNOWLEDGEMENT: undefined,
  SYSTEM_SAVE_ACKNOWLEDGEMENT_SUCCESS: undefined,
  SYSTEM_SAVE_ACKNOWLEDGEMENT_ERROR: undefined,

  SYSTEM_UPDATE_ACKNOWLEDGEMENT: undefined,
  SYSTEM_UPDATE_ACKNOWLEDGEMENT_SUCCESS: undefined,
  SYSTEM_UPDATE_ACKNOWLEDGEMENT_ERROR: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.systemNewAcknowledgement, handlers.systemNewAcknowledgement],

    [actions.systemGetAcknowledgement, handlers.systemGetAcknowledgement],
    [actions.systemGetAcknowledgementError, handlers.systemGetAcknowledgementError],
    [actions.systemGetAcknowledgementSuccess, handlers.systemGetAcknowledgementSuccess],

    [actions.systemGetAcknowledgements, handlers.systemGetAcknowledgements],
    [actions.systemGetAcknowledgementsError, handlers.systemGetAcknowledgementsError],
    [actions.systemGetAcknowledgementsSuccess, handlers.systemGetAcknowledgementsSuccess],

    [actions.systemSaveAcknowledgement, handlers.systemSaveAcknowledgement],
    [actions.systemSaveAcknowledgementSuccess, handlers.systemSaveAcknowledgementSuccess],
    [actions.systemSaveAcknowledgementError, handlers.systemSaveAcknowledgementError],

    [actions.systemUpdateAcknowledgement, handlers.systemUpdateAcknowledgement],
    [actions.systemUpdateAcknowledgementSuccess, handlers.systemUpdateAcknowledgementSuccess],
    [actions.systemUpdateAcknowledgementError, handlers.systemUpdateAcknowledgementError],
  ]),
  initialState,
);

export default reducer;
