const initialState = {
  data: [],
  isLoading: false,
  error: null,

  locationDetails: {},
  detailsIsLoading: false,
  detailsIsUpdating: false,
  isDeleting: false,
  detailsError: null,
};

export const getLocationsByDistrict = (state) => ({
  ...state,
  isLoading: true,
});

export const getLocationsByDistrictSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload,
});

export const getLocationsByDistrictFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const createLocation = (state) => ({
  ...state,
  isLoading: true,
});

export const createLocationSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const createLocationFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const getLocationDetails = (state) => ({
  ...state,
  detailsIsLoading: true,
});

export const getLocationDetailsSuccess = (state, { payload }) => ({
  ...state,
  detailsIsLoading: false,
  locationDetails: payload,
});

export const getLocationDetailsFailure = (state, { payload }) => ({
  ...state,
  detailsIsLoading: false,
  detailsError: payload,
});

export const updateLocation = (state) => ({
  ...state,
  detailsIsUpdating: true,
});

export const updateLocationSuccess = (state, { payload }) => ({
  ...state,
  detailsIsUpdating: false,
  locationDetails: payload,
});

export const updateLocationFailure = (state, { payload }) => ({
  ...state,
  detailsIsUpdating: false,
  detailsError: payload,
});

export const deleteLocation = (state) => ({
  ...state,
  isDeleting: true,
});

export const deleteLocationSuccess = (state) => ({
  ...state,
  isDeleting: false,
});

export const deleteLocationFailure = (state, { payload }) => ({
  ...state,
  isDeleting: false,
  detailsError: payload,
});

export const refreshLocationDetails = (state) => ({
  ...state,
  locationDetails: initialState.locationDetails,
});

export default initialState;
