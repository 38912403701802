import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from 'redux/auth';

const AdaptiveWatcher = ({ children }) => {
  const dispatch = useDispatch();
  const {
    auth: { resolutionMode },
  } = useSelector(({ auth }) => ({
    auth,
  }));

  useEffect(() => {
    const checkResolution = () => {
      const width = window.innerWidth;

      if (width >= 1200 && resolutionMode !== 'desktop') {
        dispatch(actions.setResolutionMode('desktop'));
      } else if (width >= 768 && width < 1200 && resolutionMode !== 'tablet') {
        dispatch(actions.setResolutionMode('tablet'));
      } else if (width < 768 && resolutionMode !== 'mobile') {
        dispatch(actions.setResolutionMode('mobile'));
      }
    };

    checkResolution();
    window.addEventListener('resize', checkResolution);

    return function cleanup() {
      window.removeEventListener('resize', checkResolution);
    };
  });

  return <div>{children}</div>;
};

AdaptiveWatcher.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdaptiveWatcher;
