const initialState = {
  isLoading: false,
  isGenerating: false,
  isIsrUpdate: false,
  isGeneratingScoresheet: false,
  error: '',
  hierarchies: [],
  sets: {},
  students: [],
  studentsByItem: [],
  items: [],
  setsTotals: [],
  resultByItems: {},
};

export const generateIsrRequest = (state, { payload }) => ({
  ...state,
  isGenerating: payload.generatingIndex,
});

export const generateIsrFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isGenerating: false,
});

export const generateIsrSuccess = (state) => ({
  ...state,
  isGenerating: false,
});

export const generateAllIsrs = (state) => ({
  ...state,
  isLoading: true,
});

export const generateAllIsrsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const generateAllIsrsSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const updateIsrOutputRequest = (state) => ({
  ...state,
  isIsrUpdate: true,
});

export const updateIsrOutputFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isIsrUpdate: false,
});

export const updateIsrOutputSuccess = (state) => ({
  ...state,
  isIsrUpdate: false,
});

export const generateScoresheetRequest = (state, { payload }) => ({
  ...state,
  isGeneratingScoresheet: payload.generatingIndex,
});

export const generateScoresheetFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isGeneratingScoresheet: false,
});

export const generateScoresheetSuccess = (state) => ({
  ...state,
  isGeneratingScoresheet: false,
});

export const getSetsTotalsRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getSetsTotalsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getSetsTotalsSuccess = (state, { payload }) => ({
  ...state,
  setsTotals: payload,
  isLoading: false,
});

export const getCompletionItemsRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getCompletionItemsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getCompletionItemsSuccess = (state, { payload }) => ({
  ...state,
  items: payload,
  isLoading: false,
});

export const getCompletionStudentsRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getCompletionStudentsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getCompletionStudentsSuccess = (state, { payload }) => ({
  ...state,
  students: payload,
  isLoading: false,
});

export const getCompletionHierarchyRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getCompletionHierarchyFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getCompletionHierarchySuccess = (state, { payload }) => ({
  ...state,
  sets: payload.sets,
  hierarchies: payload.data,
  isLoading: false,
});

export const getCompletionResultByItemsRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getCompletionResultByItemsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getCompletionResultByItemsSuccess = (state, { payload }) => ({
  ...state,
  resultByItems: payload,
  isLoading: false,
});

export const getCompletionStudentsByItemsRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getCompletionStudentsByItemsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getCompletionStudentsByItemsSuccess = (state, { payload }) => ({
  ...state,
  studentsByItem: payload,
  isLoading: false,
});

export const refreshCompletionState = () => ({
  ...initialState,
});

export default initialState;
