import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import { actions } from 'redux/snackbar';

const styles = (theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(10),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    flexWrap: 'nowrap',
  },
});

const SnackbarMessage = (props) => {
  const {
    classes, message, type, show, dispatch, t,
  } = props;

  const handleClose = () => {
    dispatch(actions.hideSnackbar());
  };

  setTimeout(() => {
    dispatch(actions.hideSnackbar());
  }, 6000);

  const getIcon = () => {
    if (type === 'success') return <CheckCircleIcon className={classes.icon} />;
    if (type === 'warning') return <WarningIcon className={classes.icon} />;
    if (type === 'error') return <ErrorIcon className={classes.icon} />;
    return <InfoIcon className={classes.icon} />;
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={show}
      autoHideDuration={6000}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'snackbar-message',
      }}
    >
      <SnackbarContent
        className={`${classes[type]} ${classes.content}`}
        aria-describedby="client-snackbar"
        message={(
          <span id="snackbar-message" className={classes.message}>
            {getIcon(type)}
            {t(message)}
          </span>
        )}
        action={[
          <IconButton key="close" aria-label={t('Close')} color="inherit" className={classes.close} onClick={handleClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

SnackbarMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ snackbar: { message, type, show } }) => ({
  message,
  type,
  show,
});

export default connect(
  mapStateToProps,
  (dispatch) => ({ dispatch }),
)(withStyles(styles)(withTranslation()(SnackbarMessage)));
