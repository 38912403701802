import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: 10,
    marginBottom: 20,
    fontSize: 24,
  },
  button: {
    position: 'absolute',
    bottom: -15,
    right: -15,
  },
  wrapper: {
    marginBottom: '80px',
  },
  itemDetailsRoot: {
    display: 'flex',
    [theme.breakpoints.down(900)]: {
      flexDirection: 'column',
    },
    marginBottom: '30px',
  },
  forgotSuccess: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: 161,
  },
}));

export default useStyles;
