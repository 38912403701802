import { combineReducers } from 'redux';

import tenants from './tenants';
import assessments from './assessments';
import acknowledgements from './acknowledgements';

export default combineReducers({
  tenants,
  assessments,
  acknowledgements,
});
