import { takeLatest, call, put } from 'redux-saga/effects';

import * as api from 'http/admin/merge';

import { actions } from './index';

function* mergeStudents({ payload }) {
  try {
    const {
      tenantId,
      primaryStudentId,
      secondaryStudentId,
      successHandler,
    } = payload;

    yield call(api.mergeStudents, { tenantId, primaryStudentId, secondaryStudentId });

    yield put(actions.mergeStudentsSuccess());

    if (successHandler) successHandler();
  } catch (error) {
    if (payload?.errorHandler) payload?.errorHandler(error);

    yield put(actions.mergeStudentsFailure(error));
  }
}

export default function* sagas() {
  yield takeLatest(actions.mergeStudents, mergeStudents);
}
