import { takeLatest, call, put } from 'redux-saga/effects';

import * as api from 'http/teacher/completions';
import { actions as assessmentActions } from 'redux/teacher/assessment';
import { actions as snackbarActions } from 'redux/snackbar';
import { actions } from './index';

export function* updateIsrOutputRequest({ payload }) {
  try {
    const data = yield call(api.getIsrInfo, payload);

    yield put(actions.updateIsrOutputSuccess());
    yield put(assessmentActions.updateStudentOutput({
      id: data.studentsId,
      output_id: data.id,
      output_status: data.status,
      created_at: data.createdAt,
      updated_at: data.updatedAt,
    }));
  } catch (error) {
    yield put(actions.updateIsrOutputFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* generateIsrRequest({ payload }) {
  try {
    yield call(api.generateIsr, payload);

    yield put(actions.generateIsrSuccess());
    yield put(assessmentActions.teacherGetCollectionStudents({ data: payload.dataCollectionId }));
  } catch (error) {
    yield put(actions.generateIsrFailure(error));
  }
}

export function* generateAllIsrRequest({ payload }) {
  try {
    const { dataCollectionId } = payload;
    yield call(api.generateIsr, payload);

    yield put(actions.generateAllIsrsSuccess());
    yield put(assessmentActions.teacherGetCollectionStudents({ data: dataCollectionId }));
  } catch (error) {
    yield put(actions.generateIsrFailure(error));
  }
}

export function* generateScoresheetRequest({ payload }) {
  try {
    yield call(api.generateStudentScoresheet, payload);

    yield put(actions.generateScoresheetSuccess());
  } catch (error) {
    yield put(actions.generateScoresheetFailure(error));
  }
}

export function* getSetsTotalsRequest({ payload }) {
  try {
    const result = yield call(api.getSetsTotals, payload);

    yield put(actions.getSetsTotalsSuccess(result.data));
  } catch (error) {
    yield put(actions.getSetsTotalsFailure(error));
  }
}

export function* getItemsRequest({ payload }) {
  try {
    const result = yield call(api.getSets, payload);

    yield put(actions.getCompletionItemsSuccess(result));
  } catch (error) {
    yield put(actions.getCompletionItemsFailure(error));
  }
}

export function* getStudentsRequest({ payload }) {
  try {
    const result = yield call(api.getStudents, payload);

    yield put(actions.getCompletionStudentsSuccess(result.data));
  } catch (error) {
    yield put(actions.getCompletionStudentsFailure({ data: error }));
  }
}

export function* getHierarchyRequest({ payload }) {
  try {
    const result = yield call(api.getHierarchy, payload);

    yield put(actions.getCompletionHierarchySuccess(result));
  } catch (error) {
    yield put(actions.getCompletionHierarchyFailure(error));
  }
}

export function* getResultByItems({ payload }) {
  try {
    const result = yield call(api.getResultByItems, payload);

    yield put(actions.getCompletionResultByItemsSuccess(result));
  } catch (error) {
    yield put(actions.getCompletionResultByItemsFailure(error));
  }
}

export function* getStudentsByItems({ payload }) {
  try {
    const result = yield call(api.getStudentByItems, payload);

    yield put(actions.getCompletionStudentsByItemsSuccess(result));
  } catch (error) {
    yield put(actions.getCompletionStudentsByItemsFailure(error));
  }
}

export default function* watchDashboardCalls() {
  yield takeLatest(actions.updateIsrOutputRequest, updateIsrOutputRequest);
  yield takeLatest(actions.generateIsrRequest, generateIsrRequest);
  yield takeLatest(actions.generateAllIsrs, generateAllIsrRequest);
  yield takeLatest(actions.generateScoresheetRequest, generateScoresheetRequest);
  yield takeLatest(actions.getSetsTotalsRequest, getSetsTotalsRequest);
  yield takeLatest(actions.getCompletionItemsRequest, getItemsRequest);
  yield takeLatest(actions.getCompletionStudentsRequest, getStudentsRequest);
  yield takeLatest(actions.getCompletionHierarchyRequest, getHierarchyRequest);
  yield takeLatest(actions.getCompletionResultByItemsRequest, getResultByItems);
  yield takeLatest(actions.getCompletionStudentsByItemsRequest, getStudentsByItems);
}
