import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

export const subscribeToNotifications = (userId, tenantId) => {
  const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET);
  ws.onopen = () => {
    ws.send(JSON.stringify({ userId, tenantId, type: 'open-connection' }));
  };

  return ws;
};

export const getNotifications = () => http.get(
  cas3ApiRoute('/notifications'),
).then((res) => res.data.notifications);

export const acknowledgeNotification = (notificationId) => http.put(
  cas3ApiRoute(`/notifications/${notificationId}`),
  { acknowledged: true },
);

export const removeNotification = (notificationId) => http.delete(
  cas3ApiRoute(`/notifications/${notificationId}`),
);
