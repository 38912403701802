const initialState = {
  statuses: {},
  isLoading: false,
  error: null,
};

export const getJobStatus = (state) => ({
  ...state,
  isLoading: true,
});

export const getJobStatusSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  statuses: payload,
});

export const getJobStatusFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export default initialState;
