export const formatRegion = (region) => ({
  id: region.id,
  name: region.nameLabel,
  tenantParentId: region.parentTenantId,
  parentId: region.parentId,
  uniqueId: region.uniqueId,
});

export const formatRegions = (regions) => regions.map((region) => formatRegion(region));

export default formatRegions;
