import reports from './reports/sagas';
import dashboard from './dashboard/sagas';
import assessment from './assessment/sagas';
import spreadsheet from './spreadsheet/sagas';
import completions from './completions/sagas';
import studentProfile from './studentProfile/sagas';

export default function* watchSagas() {
  yield* reports();
  yield* dashboard();
  yield* assessment();
  yield* spreadsheet();
  yield* completions();
  yield* studentProfile();
}
