const initialState = {
  fieldTests: [],
  isFieldTestsLoading: false,

  isNeedFieldTestsReloading: false,

  availableFieldTests: [],
  isAvailableFieldTestsLoading: false,

  error: null,
};

export const getCollectionFieldTests = (state) => ({
  ...state,
  isFieldTestsLoading: true,
  isNeedFieldTestsReloading: false,
});

export const getCollectionFieldTestsFailure = (state, { payload }) => ({
  ...state,
  isFieldTestsLoading: false,
  error: payload,
});

export const getCollectionFieldTestsSuccess = (state, { payload }) => ({
  ...state,
  fieldTests: payload,
  isFieldTestsLoading: false,
});

export const getCollectionAvailableFieldTests = (state) => ({
  ...state,
  availableFieldTests: initialState.availableFieldTests,
  isAvailableFieldTestsLoading: true,
});

export const getCollectionAvailableFieldTestsFailure = (state, { payload }) => ({
  ...state,
  isAvailableFieldTestsLoading: false,
  error: payload,
});

export const getCollectionAvailableFieldTestsSuccess = (state, { payload }) => ({
  ...state,
  availableFieldTests: payload,
  isAvailableFieldTestsLoading: false,
});

export const assignLocationFieldTests = (state) => state;

export const assignLocationFieldTestsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const assignLocationFieldTestsSuccess = (state) => ({
  ...state,
  isNeedFieldTestsReloading: true,
});

export const unassignLocationFieldTests = (state) => state;

export const unassignLocationFieldTestsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const unassignLocationFieldTestsSuccess = (state) => ({
  ...state,
  isNeedFieldTestsReloading: true,
});

export default initialState;
