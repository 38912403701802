import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_COLLECTION_SUPPORTS: undefined,
  GET_COLLECTION_SUPPORTS_FAILURE: undefined,
  GET_COLLECTION_SUPPORTS_SUCCESS: undefined,

  CREATE_COLLECTION_SUPPORT: undefined,
  CREATE_COLLECTION_SUPPORT_FAILURE: undefined,
  CREATE_COLLECTION_SUPPORT_SUCCESS: undefined,

  UPDATE_COLLECTION_SUPPORT: undefined,
  UPDATE_COLLECTION_SUPPORT_FAILURE: undefined,
  UPDATE_COLLECTION_SUPPORT_SUCCESS: undefined,

  DELETE_COLLECTION_SUPPORT: undefined,
  DELETE_COLLECTION_SUPPORT_FAILURE: undefined,
  DELETE_COLLECTION_SUPPORT_SUCCESS: undefined,

  REFRESH_COLLECTION_SUPPORTS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getCollectionSupports, handlers.getCollectionSupports],
    [actions.getCollectionSupportsFailure, handlers.getCollectionSupportsFailure],
    [actions.getCollectionSupportsSuccess, handlers.getCollectionSupportsSuccess],

    [actions.createCollectionSupport, handlers.createCollectionSupport],
    [actions.createCollectionSupportFailure, handlers.createCollectionSupportFailure],
    [actions.createCollectionSupportSuccess, handlers.createCollectionSupportSuccess],

    [actions.updateCollectionSupport, handlers.updateCollectionSupport],
    [actions.updateCollectionSupportFailure, handlers.updateCollectionSupportFailure],
    [actions.updateCollectionSupportSuccess, handlers.updateCollectionSupportSuccess],

    [actions.deleteCollectionSupport, handlers.deleteCollectionSupport],
    [actions.deleteCollectionSupportFailure, handlers.deleteCollectionSupportFailure],
    [actions.deleteCollectionSupportSuccess, handlers.deleteCollectionSupportSuccess],

    [actions.refreshCollectionSupports, handlers.refreshCollectionSupports],
  ]),
  initialState,
);

export default reducer;
