import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

import { reportsFormatter } from './formatter';

export const getReportsByTenant = () => http.get(
  cas3ApiRoute('/reports'),
).then((res) => reportsFormatter(res.data.reports));

export const requestCas3ApiReport = (route, body) => http.post(
  cas3ApiRoute(`/reports/${route}`),
  body,
);

export const requestCas2ApiReport = ({ tenantId, data }) => http.post(`/admin/tenants/${tenantId}/reports.json`, data);
