const initialOptionsState = {
  assessments: [],
  assessmentsError: null,
  assessmentsLoading: false,
  priorCares: [],
  priorCaresError: null,
  priorCaresLoading: false,
  races: [],
  racesError: null,
  racesLoading: false,
  regions: [],
  regionsLoading: false,
  regionsError: null,
  districts: [],
  districtsLoading: false,
  districtsError: null,
  locations: [],
  locationsLoading: false,
  locationsError: null,
  genders: [],
  gendersLoading: false,
  gendersError: null,
};

export const getAssessmentsRequest = (state) => ({
  ...state,
  assessmentsLoading: true,
  assessmentsError: null,
});

export const getAssessmentsSuccess = (state, { payload }) => ({
  ...state,
  assessmentsLoading: false,
  assessmentsError: null,
  assessments: payload.data,
});

export const getAssessmentsError = (state, { payload }) => ({
  ...state,
  assessmentsLoading: false,
  assessmentsError: payload.error,
});

export const getRacesRequest = (state) => ({
  ...state,
  racesLoading: true,
  racesError: null,
});

export const getRacesSuccess = (state, { payload }) => ({
  ...state,
  racesLoading: false,
  racesError: null,
  races: payload.data,
});

export const getRacesError = (state, { payload }) => ({
  ...state,
  racesLoading: false,
  racesError: payload.error,
});

export const getPriorCaresRequest = (state) => ({
  ...state,
  priorCaresLoading: true,
  priorCaresError: null,
});

export const getPriorCaresSuccess = (state, { payload }) => ({
  ...state,
  priorCaresLoading: false,
  priorCaresError: null,
  priorCares: [...payload.data],
});

export const getPriorCaresError = (state, { payload }) => ({
  ...state,
  priorCaresLoading: false,
  priorCaresError: payload,
});

export const getRegionsRequest = (state) => ({
  ...state,
  regionsLoading: true,
  regionsError: null,
});

export const getRegionsSuccess = (state, { payload }) => ({
  ...state,
  regionsLoading: false,
  regionsError: null,
  regions: [...payload.data.map((r) => ({
    value: r.tenants_regions.uniqueId,
    label: r.tenants_regions.name,
    id: r.tenants_regions.id,
  }))],
});

export const getRegionsError = (state, { payload }) => ({
  ...state,
  regionsLoading: false,
  regionsError: payload.error,
});

export const getDistrictsRequest = (state) => ({
  ...state,
  districtsLoading: true,
  districtsError: null,
});

export const getDistrictsSuccess = (state, { payload }) => ({
  ...state,
  districtsLoading: false,
  districtsError: null,
  districts: [...payload.data.map((r) => ({
    value: r.tenants_districts.uniqueId,
    label: r.tenants_districts.name,
    id: r.tenants_districts.id,
    administrative: r.administrative,
  }))],
});

export const getDistrictsError = (state, { payload }) => ({
  ...state,
  districtsLoading: false,
  districtsError: payload.error,
});

export const getDistrictRequest = (state) => ({
  ...state,
  districtsLoading: true,
  districtsError: null,
});

export const getDistrictSuccess = (state) => ({
  ...state,
  districtsLoading: false,
  districtsError: null,
});

export const getOrgsRequest = (state) => ({
  ...state,
  locationsLoading: true,
  locationsError: null,
});

export const getOrgsSuccess = (state, { payload }) => ({
  ...state,
  locationsLoading: false,
  locationsError: null,
  regions: payload.data.regions,
  districts: payload.data.districts,
  locations: payload.data.locations.map((l) => {
    const district = payload.data.districts.find((d) => d.id === l.parentId);
    return {
      id: l.id,
      value: l.uniqueId,
      label: `${l.nameLabel} (District: ${district.nameLabel})`,
      district: district.uniqueId,
      name: l.nameLabel,
    };
  }),
});

export const getOrgsError = (state, { payload }) => ({
  ...state,
  locationsLoading: false,
  locationsError: payload.error,
});

export const getGendersRequest = (state) => ({
  ...state,
  gendersLoading: true,
  gendersError: null,
});

export const getGendersSuccess = (state, { payload }) => ({
  ...state,
  gendersLoading: false,
  gendersError: null,
  genders: [...payload.data],
});

export const getGendersError = (state, { payload }) => ({
  ...state,
  gendersLoading: false,
  gendersError: payload.error,
});

export default initialOptionsState;
