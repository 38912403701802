import { takeLatest, call, put } from 'redux-saga/effects';

import { actions as snackbarActions } from 'redux/snackbar';
import * as api from 'http/admin/archive';

import { actions } from './index';

function* getSchoolYearsByTenant({ payload }) {
  try {
    const { tenantId } = payload;
    const result = yield call(api.getSchoolYearsByTenant, { tenantId });

    yield put(actions.getSchoolYearsByTenantSuccess(result));
  } catch (error) {
    yield put(actions.getSchoolYearsByTenantFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getArchivesByYearId({ payload }) {
  try {
    const { tenantId, yearId } = payload;
    const result = yield call(api.getArchivesByYearId, { tenantId, yearId });

    yield put(actions.getArchivesByYearIdSuccess(result));
  } catch (error) {
    yield put(actions.getArchivesByYearIdFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.getSchoolYearsByTenant, getSchoolYearsByTenant);
  yield takeLatest(actions.getArchivesByYearId, getArchivesByYearId);
}
