import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  SUBSCRIBE_TO_NOTIFICATIONS: undefined,
  SUBSCRIBE_TO_NOTIFICATIONS_FAIL: undefined,
  SUBSCRIBE_TO_NOTIFICATIONS_SUCCESS: undefined,

  GET_NOTIFICATIONS: undefined,
  GET_NOTIFICATIONS_FAIL: undefined,
  GET_NOTIFICATIONS_SUCCESS: undefined,

  RECEIVE_NOTIFICATION_SUCCESS: undefined,

  ACKNOWLEDGE_NOTIFICATION: undefined,
  ACKNOWLEDGE_NOTIFICATION_FAIL: undefined,
  ACKNOWLEDGE_NOTIFICATION_SUCCESS: undefined,

  REMOVE_NOTIFICATION: undefined,
  REMOVE_NOTIFICATION_FAIL: undefined,
  REMOVE_NOTIFICATION_SUCCESS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.receiveNotificationSuccess, handlers.receiveNotificationSuccess],
    [actions.removeNotificationSuccess, handlers.removeNotificationSuccess],
    [actions.acknowledgeNotificationSuccess, handlers.acknowledgeNotificationSuccess],
    [actions.getNotificationsSuccess, handlers.getNotificationsSuccess],
  ]),
  initialState,
);

export default reducer;
