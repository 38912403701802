import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

const cas2 = process.env.REACT_APP_CAS2_LOGIN === 'true';

export const currentUser = (params) => http.get(
  cas3ApiRoute(`/login/me/${cas2}`),
  { params },
).then((res) => res.data);

export const saveProfile = (data) => http.put(cas3ApiRoute('/users'), data);

export const getTenantConfiguration = (tenant) => http.get(`/tenant_search/${tenant}`);

export const login = ({ username, password }) => http.post(cas3ApiRoute('/login'), {
  username,
  password,
  cas2,
}).then((res) => res.data);

export const logout = () => http.get(cas3ApiRoute('/logout'));

export const forgotPassword = (data) => http.post(
  cas3ApiRoute('/forgot-password/email'),
  data,
);

export const resetPassword = (data) => http.post(
  cas3ApiRoute('/forgot-password/reset'),
  data,
);

export const userRegistrationPassword = (data) => http.post(
  cas3ApiRoute('/user-registration'),
  data,
);

export const startImpersonate = (data) => http.post(
  cas3ApiRoute(`/login/impersonate/${cas2}`),
  data,
).then((res) => res.data);

export const endImpersonate = (data) => http.post(
  cas3ApiRoute(`/logout/impersonate/${cas2}`),
  data,
).then((res) => res.data);
