const initialViewState = {
  report: 'PLD',
  pldVersion: 1,
  domainVersion: 1,
  domainSingleView: 'LL',
  isFilterPanelOpen: true,
};

export const setPanelState = (state, { payload }) => ({
  ...state,
  isFilterPanelOpen: payload.data,
});

export const setView = (state, { payload }) => ({
  ...state,
  report: payload.data,
});

export const setPldVersion = (state, { payload }) => ({
  ...state,
  pldVersion: parseInt(payload.data, 10),
});

export const setDomainVersion = (state, { payload }) => ({
  ...state,
  domainVersion: parseInt(payload.data, 10),
});

export const setDomainSingleView = (state, { payload }) => ({
  ...state,
  domainSingleView: payload.data,
});

export default initialViewState;
