const getUsersRolesToDisplay = (roles) => {
  const rolesString = [];

  if (roles.indexOf('ROLE_ADMIN') > -1) {
    rolesString.push('Admin');
  }
  if (roles.indexOf('ROLE_TEACHER') > -1) {
    rolesString.push('Teacher');
  }
  if (roles.indexOf('ROLE_DATA_MANAGER') > -1) {
    rolesString.push('Data Manager');
  }

  return rolesString.join(', ');
};

export const formatUser = (user) => ({
  ...user,
  isActive: user.enabled ? 'Active' : 'Inactive',
  rolesToDisplay: getUsersRolesToDisplay(user.roles),
});

export const formatUsers = (users) => users.map((user) => formatUser(user));

export const formatPayloadData = (user) => {
  const userEnabled = parseInt(user.enabled, 10);

  return ({
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
    location_id: user.location,
    roles: user.roles,
    // hardcode for update user details request
    enabled: (userEnabled === 1) ? true : (false).toString(),
    n_password: (user.password && user.password.length) ? user.password : undefined,
    n_password_confirm: (user.confirmPassword && user.confirmPassword.length) ? user.confirmPassword : undefined,
  });
};

export const formatUserRegions = (response) => response.data.map((region) => ({
  id: region.id,
  name: region.name,
  tenantParentId: region.tenant_parent_id,
  parentId: region.parent_id,
  uniqueId: region.unique_id,
  administrative: Number.isInteger(parseInt(region.administrative, 10)) ? parseInt(region.administrative, 10) : 0,
  memberId: parseInt(region.member_id, 10),
}));

export const formatUserDistricts = (response) => response.data.map((district) => ({
  id: district.id,
  name: district.name,
  tenantParentId: district.tenant_parent_id,
  parentId: district.parent_id,
  uniqueId: district.unique_id,
  memberId: district.member_id ? parseInt(district.member_id, 10) : null,
  administrative: Number.isInteger(parseInt(district.administrative, 10)) ? parseInt(district.administrative, 10) : 0,
  memberUniqueId: district.member_unique_id,
  administrative2: Number.isInteger(parseInt(district.administrative_2, 10)) ? parseInt(district.administrative_2, 10) : 0,
  memberUniqueId2: district.member_unique_id_2,
}));

export const formatUserLocations = (response) => response.data.map((location) => ({
  id: location.id,
  name: location.name,
  tenantParentId: location.tenant_parent_id,
  parentId: location.parent_id,
  uniqueId: location.unique_id,
  memberId: parseInt(location.member_id, 10),
  administrative: Number.isInteger(parseInt(location.administrative, 10)) ? parseInt(location.administrative, 10) : 0,
}));

export default formatUsers;
