import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

export const getCustomFields = ({ tenantId, entity, entityField }) => http.get(
  cas3ApiRoute('/tenants-fields'),
  {
    params: { tenantsId: tenantId, entity, entityField },
  },
).then((res) => res.data.tenantsFields);

export default {
  getCustomFields,
};
