const initialState = {
  assessmentItemSets: [],
  isAssessmentItemSetsLoading: false,

  isNeedAssessmentItemSetsReloading: false,

  setItems: [],
  isSetItemsLoading: false,

  error: null,
};

export const getCollectionAssessmentItemSets = (state) => ({
  ...state,
  isAssessmentItemSetsLoading: true,
  isNeedAssessmentItemSetsReloading: false,
});

export const getCollectionAssessmentItemSetsFailure = (state, { payload }) => ({
  ...state,
  isAssessmentItemSetsLoading: false,
  error: payload,
});

export const getCollectionAssessmentItemSetsSuccess = (state, { payload }) => ({
  ...state,
  assessmentItemSets: payload,
  isAssessmentItemSetsLoading: false,
});

export const postCollectionAssessmentItemSet = (state) => state;

export const postCollectionAssessmentItemSetFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const postCollectionAssessmentItemSetSuccess = (state) => ({
  ...state,
  isNeedAssessmentItemSetsReloading: true,
});

export const putCollectionAssessmentItemSet = (state) => state;

export const putCollectionAssessmentItemSetFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const putCollectionAssessmentItemSetSuccess = (state) => ({
  ...state,
  isNeedAssessmentItemSetsReloading: true,
});

export const deleteCollectionAssessmentItemSet = (state) => state;

export const deleteCollectionAssessmentItemSetFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const deleteCollectionAssessmentItemSetSuccess = (state) => ({
  ...state,
  isNeedAssessmentItemSetsReloading: true,
});

export const getUniqueItemId = ({ id, code, parentAssessmentItemId: itemParentId }) => `${id}-${code}-${itemParentId}`;

const sortById = ({ id: idA }, { id: idB }) => {
  if (idA < idB) return -1;
  if (idA > idB) return 1;

  return 0;
};

export const getCollectionAssessmentSetItems = (state) => ({
  ...state,
  setItems: [],
  isSetItemsLoading: true,
});

export const getCollectionAssessmentSetItemsSuccess = (state, { payload }) => {
  const items = payload.map((item) => {
    item.key = getUniqueItemId(item);
    return item;
  });

  items.sort(sortById);

  return ({
    ...state,
    setItems: items,
    isSetItemsLoading: false,
  });
};

export const getCollectionAssessmentSetItemsFailure = (state, { payload }) => ({
  ...state,
  isSetItemsLoading: false,
  error: payload,
});

export const postCollectionAssessmentSetItem = (state) => ({
  ...state,
  isSetItemsLoading: true,
});

export const postCollectionAssessmentSetItemSuccess = (state) => ({
  ...state,
  isSetItemsLoading: false,
});

export const postCollectionAssessmentSetItemFailure = (state, { payload }) => ({
  ...state,
  isSetItemsLoading: false,
  error: payload,
});

export const putCollectionAssessmentSetItem = (state) => ({
  ...state,
  isSetItemsLoading: true,
});

export const putCollectionAssessmentSetItemSuccess = (state, { payload }) => {
  const { id, label, priority } = payload;
  const newData = state.setItems.map((item) => {
    if (item.id === id) {
      const newItemValue = {
        ...item,
        id,
        label,
        priority,
      };

      newItemValue.key = getUniqueItemId(newItemValue);
      return newItemValue;
    }
    return item;
  });

  return ({
    ...state,
    isSetItemsLoading: false,
    setItems: newData,
  });
};

export const putCollectionAssessmentSetItemFailure = (state, { payload }) => ({
  ...state,
  isSetItemsLoading: false,
  error: payload,
});

export const deleteCollectionAssessmentSetItem = (state) => ({
  ...state,
  isSetItemsLoading: true,
});

export const deleteCollectionAssessmentSetItemFailure = (state, { payload }) => ({
  ...state,
  isSetItemsLoading: false,
  error: payload,
});

export const deleteCollectionAssessmentSetItemSuccess = (state) => ({
  ...state,
  isSetItemsLoading: false,
});

export default initialState;
