import moment from 'moment';
import bitAnalyses from 'helpers/bitAnalyses';

const NO_POA_SPECIFIED = 'No POA Specified';

export const formatStudent = (student) => {
  if (!student) return null;

  const statusObject = (student.user_association_count > 0) ? {
    status: 'Active',
    statusColor: '#0f4fd1',
  } : {
    status: 'Inactive',
    statusColor: '#777',
  };

  return ({
    id: student.student_id,
    firstName: student.student_first_name,
    lastName: student.student_last_name,
    middleName: student.student_middle_name,
    enrollmentId: student.student_enrollment_id,
    specialEducation: (parseInt(student.student_special_education, 10) === 1) ? 1 : 0,
    dob: moment.unix(student.student_dob).utc(),
    currentAgeYears: student.student_current_age_years,
    currentAgeMonths: student.student_current_age_months,
    gender: student.student_gender,
    ell: (parseInt(student.student_english_language_learner, 10) === 1) ? 1 : 0,
    lowSes: (parseInt(student.student_low_ses, 10) === 1) ? 1 : 0,
    race: bitAnalyses(student.student_race),
    disabilityCode: student.student_special_education_disability_code,
    studentEnrollmentId: student.student_studentEnrollmentId,
    studentStateId: student.student_tenant_unique_id,
    isEnrollmentsExisting: student.isEnrollmentsExisting,
    enrollmentsCount: student.student_number_of_enrollments,
    poaLocationName: student.poa_location_name || NO_POA_SPECIFIED,
    userAssociationCount: student.user_association_count,
    regionId: student.region_id,
    districtId: student.district_id,
    poaLocationId: student.poa_location_id,
    ...statusObject,
  });
};

export const formatStudentWithEnrollments = (student) => {
  if (!student) return null;

  const statusObject = (student.userAssociationCount > 0) ? {
    status: 'Active',
    statusColor: '#0f4fd1',
  } : {
    status: 'Inactive',
    statusColor: '#777',
  };

  return ({
    ...student,
    id: student.id,
    dob: moment.unix(student.dob).utc(),
    ell: student.englishLanguageLearner.toString(),
    race: bitAnalyses(student.race),
    studentStateId: student.tenantUniqueId,
    currentAgeMonths: moment().diff(moment.unix(student.dob), 'M'),
    currentAgeYears: moment().diff(moment.unix(student.dob), 'Y'),
    lowSes: student.lowSes.toString(),
    specialEducation: student.specialEducation.toString(),
    // isEnrollmentsExisting: student.isEnrollmentsExisting,
    ...statusObject,
  });
};

export const formatStudentRosterItem = (student, isAvailableStudents) => ({
  ...student,
  id: isAvailableStudents ? student.enrollmentId : `${student.studentId} - ${student.associationId}`,
});

export const formatStudentsRosters = (students, isAvailableStudents) => students.map((s) => formatStudentRosterItem(s, isAvailableStudents));

export const formatStudentsWithEnrollments = (students) => students.map((student) => formatStudentWithEnrollments(student));

export const formatStudents = ({ data }) => data.map((student) => formatStudent(student));

export const formatExitingStudent = (exitingStudent) => {
  if (!exitingStudent) return null;

  return formatStudentWithEnrollments(exitingStudent);
};

export const formatStudentRequestData = ({
  regionId,
  districtId,
  locationId,
  dob,
  ell,
  firstName,
  lastName,
  middleName,
  gender,
  lowSes,
  race,
  studentStateId,
  specialEducation,
  disabilityCode,
  id,
  studentEnrollmentId,
}) => ({
  did: districtId,
  lid: locationId,
  rid: regionId,
  students: {
    id,
    dob: moment(dob).unix(),
    dob_hr: moment(dob).format('MM/DD/YYYY'),
    english_language_learner: ell,
    first_name: firstName,
    last_name: lastName,
    middle_name: middleName,
    gender,
    low_ses: lowSes,
    race: race.reduce((accum, value) => accum + value, 0),
    tenant_unique_id: studentStateId,
  },
  students_enrollments: {
    id: studentEnrollmentId,
    student_id: id,
    special_education: specialEducation,
    special_education_disability_code: disabilityCode,
  },
});

const formatPoaEnrollment = (enrollment) => ({
  id: enrollment.student_enrollment_id,
  studentEnrollmentId: enrollment.student_enrollment_id,
  locationName: enrollment.location_name,
  districtUniqueId: enrollment.student_unique_id || 'N/A',
  assignedTeachers: parseInt(enrollment.assigned_teachers, 10) || 0,
});

export const formatPoaEnrollements = (enrollments) => enrollments.map((enrollment) => formatPoaEnrollment(enrollment));

const formatStudentOutput = (output) => ({
  ...output,
  outputId: output.id,
  outputStatus: output.status,
  collectionId: output.dataCollectionsId,
});

export const formatStudentOutputs = (data) => data.map((output) => formatStudentOutput(output));

const dateFormatter = (date) => (date ? moment.unix(date).format('ll') : null);

export const formatStudentAssignments = (assignments) => assignments.map(({
  id,
  createdAt,
  dataCollections: {
    collectionName: name,
    token,
    cycleStartDate,
    enrollmentEndDate,
    cycleEndDate,
    hideToken,
    ...otherFields
  },
}) => ({
  id,
  createdAt: dateFormatter(createdAt),
  name,
  token,
  cycleStartDate: dateFormatter(cycleStartDate),
  enrollmentEndDate: dateFormatter(enrollmentEndDate),
  cycleEndDate: dateFormatter(cycleEndDate),
  hideToken: !!hideToken,
  dataCollections: {
    ...otherFields,
  },
}));

export const formatStudentEnrollments = (enrollments) => enrollments.map((enrollment) => ({
  ...enrollment,
  // studentUniqueId: enrollment.student_unique_id,
  enrollmentPriorCare: enrollment.priorCare,
  regionId: enrollment.parentRegionId,
  districtId: enrollment.parentDistrictId,
  locationId: enrollment.parentLocationId,
  // authorityCount: enrollment.authority_count,
  input_1: enrollment.input1,
  input_2: enrollment.input2,
  input_3: enrollment.input3,
  input_4: enrollment.input4,
  input_5: enrollment.input5,
  input_6: enrollment.input6,
  input_7: enrollment.input7,
  input_8: enrollment.input8,
  input_9: enrollment.input9,
  input_10: enrollment.input10,
  option_1: enrollment.option1,
  option_2: enrollment.option2,
  option_3: enrollment.option3,
  option_4: enrollment.option4,
  option_5: enrollment.option5,
  option_6: enrollment.option6,
  option_7: enrollment.option7,
  option_8: enrollment.option8,
  option_9: enrollment.option9,
  option_10: enrollment.option10,
}));

export const formatEnrollmentAssociations = (associations) => associations.map((association) => ({
  ...association,
  associationDate: association.createdAt,
  teacherFullName: `${association.teacherFirstName} ${association.teacherLastName}`,
}));

export const formatEnrollmentRequestData = ({
  id,
  studentUniqueId,
  districtUniqueId,
  enrollmentPriorCare,
  regionId,
  districtId,
  locationId,
  locationName,
  authorityCount,
  assignedTeachers,
  ...customFields
}) => ({
  // Features of the API
  //   get -> student_unique_id
  //   put -> district_unique_id

  district_unique_id: studentUniqueId,

  assigned_teachers: assignedTeachers,
  authority_count: authorityCount,
  district_id: districtId,
  enrollment_prior_care: enrollmentPriorCare,
  location_id: locationId,
  location_name: locationName,
  student_enrollment_id: id,
  student_unique_id: null,
  ...customFields,
});

export const formatLocationMembers = ({ data }) => data.map((member) => ({
  id: member.id,
  email: member.email,
  firstName: member.first_name,
  lastName: member.last_name,
}));
