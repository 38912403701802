const initialState = {
  isMerging: false,

  error: null,
};

export const mergeStudents = (state) => ({
  ...state,
  isMerging: true,
});

export const mergeStudentsSuccess = (state) => ({
  ...state,
  isMerging: false,
});

export const mergeStudentsFailure = (state, { payload }) => ({
  ...state,
  isMerging: false,
  error: payload,
});

export default initialState;
