import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  SET_FILTER: undefined,

  GET_SUPPORTS_REQUEST: undefined,
  GET_SUPPORTS_SUCCESS: undefined,
  GET_SUPPORTS_FAILURE: undefined,

  SAVE_COMMENT_REQUEST: undefined,
  SAVE_COMMENT_SUCCESS: undefined,
  SAVE_COMMENT_FAILURE: undefined,

  SAVE_SUPPORTS_REQUEST: undefined,
  SAVE_SUPPORTS_SUCCESS: undefined,
  SAVE_SUPPORTS_FAILURE: undefined,

  GET_SCORESHEET_HISTORY_REQUEST: undefined,
  GET_SCORESHEET_HISTORY_SUCCESS: undefined,
  GET_SCORESHEET_HISTORY_FAILURE: undefined,

  GET_ITEM_HISTORY_REQUEST: undefined,
  GET_ITEM_HISTORY_SUCCESS: undefined,
  GET_ITEM_HISTORY_FAILURE: undefined,

  GET_STUDENT_ITEMS_HISTORY_REQUEST: undefined,
  GET_STUDENT_ITEMS_HISTORY_SUCCESS: undefined,
  GET_STUDENT_ITEMS_HISTORY_FAILURE: undefined,

  SET_STUDENT_DETAILS: undefined,

  GET_EVIDENCE_REQUEST: undefined,
  GET_EVIDENCE_SUCCESS: undefined,
  GET_EVIDENCE_FAILURE: undefined,

  SAVE_EVIDENCE_REQUEST: undefined,
  SAVE_EVIDENCE_SUCCESS: undefined,
  SAVE_EVIDENCE_FAILURE: undefined,

  DELETE_EVIDENCE_REQUEST: undefined,
  DELETE_EVIDENCE_SUCCESS: undefined,
  DELETE_EVIDENCE_FAILURE: undefined,

  GET_CUSTOM_FIELDS_REQUEST: undefined,
  GET_CUSTOM_FIELDS_SUCCESS: undefined,
  GET_CUSTOM_FIELDS_FAILURE: undefined,

  UPDATE_STUDENT_OPTIONS_AND_INPUTS: undefined,
  UPDATE_STUDENT_OPTIONS_AND_INPUTS_SUCCESS: undefined,
  UPDATE_STUDENT_OPTIONS_AND_INPUTS_FAILURE: undefined,

  SET_EVIDENCE_LOADING: undefined,
  UNSET_EVIDENCE_LOADING: undefined,

  OPEN_SUPPORTS_POPUP: undefined,
  CLOSE_SUPPORTS_POPUP: undefined,

  OPEN_COMMENTS_POPUP: undefined,
  CLOSE_COMMENTS_POPUP: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.setStudentDetails, handlers.setStudentDetails],

    [actions.setFilter, handlers.setFilter],

    [actions.getSupportsRequest, handlers.getSupportsRequest],
    [actions.getSupportsSuccess, handlers.getSupportsSuccess],
    [actions.getSupportsFailure, handlers.getSupportsFailure],

    [actions.saveCommentRequest, handlers.saveCommentRequest],
    [actions.saveCommentSuccess, handlers.saveCommentSuccess],
    [actions.saveCommentFailure, handlers.saveCommentFailure],

    [actions.saveSupportsRequest, handlers.saveSupportsRequest],
    [actions.saveSupportsSuccess, handlers.saveSupportsSuccess],
    [actions.saveSupportsFailure, handlers.saveSupportsFailure],

    [actions.getScoresheetHistoryRequest, handlers.getScoresheetHistoryRequest],
    [actions.getScoresheetHistorySuccess, handlers.getScoresheetHistorySuccess],
    [actions.getScoresheetHistoryFailure, handlers.getScoresheetHistoryFailure],

    [actions.getItemHistoryRequest, handlers.getItemHistoryRequest],
    [actions.getItemHistorySuccess, handlers.getItemHistorySuccess],
    [actions.getItemHistoryFailure, handlers.getItemHistoryFailure],

    [actions.getStudentItemsHistoryRequest, handlers.getStudentItemsHistoryRequest],
    [actions.getStudentItemsHistorySuccess, handlers.getStudentItemsHistorySuccess],
    [actions.getStudentItemsHistoryFailure, handlers.getStudentItemsHistoryFailure],

    [actions.getEvidenceRequest, handlers.getEvidenceRequest],
    [actions.getEvidenceSuccess, handlers.getEvidenceSuccess],
    [actions.getEvidenceFailure, handlers.getEvidenceFailure],

    [actions.saveEvidenceRequest, handlers.saveEvidenceRequest],
    [actions.saveEvidenceSuccess, handlers.saveEvidenceSuccess],
    [actions.saveEvidenceFailure, handlers.saveEvidenceFailure],

    [actions.deleteEvidenceRequest, handlers.deleteEvidenceRequest],
    [actions.deleteEvidenceSuccess, handlers.deleteEvidenceSuccess],
    [actions.deleteEvidenceFailure, handlers.deleteEvidenceFailure],

    [actions.setEvidenceLoading, handlers.setEvidenceLoading],
    [actions.unsetEvidenceLoading, handlers.unsetEvidenceLoading],

    [actions.openSupportsPopup, handlers.openSupportsPopup],
    [actions.closeSupportsPopup, handlers.closeSupportsPopup],

    [actions.openCommentsPopup, handlers.openCommentsPopup],
    [actions.closeCommentsPopup, handlers.closeCommentsPopup],

    [actions.getCustomFieldsRequest, handlers.getCustomFieldsRequest],
    [actions.getCustomFieldsSuccess, handlers.getCustomFieldsSuccess],
    [actions.getCustomFieldsFailure, handlers.getCustomFieldsFailure],

    [actions.updateStudentOptionsAndInputs, handlers.updateStudentOptionsAndInputs],
    [actions.updateStudentOptionsAndInputsSuccess, handlers.updateStudentOptionsAndInputsSuccess],
    [actions.updateStudentOptionsAndInputsFailure, handlers.updateStudentOptionsAndInputsFailure],
  ]),
  initialState,
);

export default reducer;
