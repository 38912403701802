import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const GET_AVAILABLE_ROSTERS = 'GET_AVAILABLE_ROSTERS';
export const GET_AVAILABLE_ROSTERS_SUCCESS = 'GET_AVAILABLE_ROSTERS_SUCCESS';
export const GET_AVAILABLE_ROSTERS_FAILURE = 'GET_AVAILABLE_ROSTERS_FAILURE';

export const GET_STUDENTS_ROSTERS = 'GET_STUDENTS_ROSTERS';
export const GET_STUDENTS_ROSTERS_SUCCESS = 'GET_STUDENTS_ROSTERS_SUCCESS';
export const GET_STUDENTS_ROSTERS_FAILURE = 'GET_STUDENTS_ROSTERS_FAILURE';

export const UPDATE_STUDENTS_ROSTERS = 'UPDATE_STUDENTS_ROSTERS';
export const UPDATE_STUDENTS_ROSTERS_SUCCESS = 'UPDATE_STUDENTS_ROSTERS_SUCCESS';
export const UPDATE_STUDENTS_ROSTERS_FAILURE = 'UPDATE_STUDENTS_ROSTERS_FAILURE';

export const GET_STUDENTS_BY_TENANT = 'GET_STUDENTS_BY_TENANT';
export const GET_STUDENTS_BY_TENANT_SUCCESS = 'GET_STUDENTS_BY_TENANT_SUCCESS';
export const GET_STUDENTS_BY_TENANT_FAILURE = 'GET_STUDENTS_BY_TENANT_FAILURE';

export const GET_STUDENTS_BY_LOCATION = 'GET_STUDENTS_BY_LOCATION';
export const GET_STUDENTS_BY_LOCATION_SUCCESS = 'GET_STUDENTS_BY_LOCATION_SUCCESS';
export const GET_STUDENTS_BY_LOCATION_FAILURE = 'GET_STUDENTS_BY_LOCATION_FAILURE';

export const GET_STUDENT_DETAILS = 'GET_STUDENT_DETAILS';
export const GET_STUDENT_DETAILS_SUCCESS = 'GET_STUDENT_DETAILS_SUCCESS';
export const GET_STUDENT_DETAILS_FAILURE = 'GET_STUDENT_DETAILS_FAILURE';

export const CREATE_STUDENT = 'CREATE_STUDENT';
export const CREATE_STUDENT_SUCCESS = 'CREATE_STUDENT_SUCCESS';
export const CREATE_STUDENT_FAILURE = 'CREATE_STUDENT_FAILURE';

export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS';
export const UPDATE_STUDENT_FAILURE = 'UPDATE_STUDENT_FAILURE';

export const REMOVE_STUDENT = 'REMOVE_STUDENT';
export const REMOVE_STUDENT_SUCCESS = 'REMOVE_STUDENT_SUCCESS';
export const REMOVE_STUDENT_FAILURE = 'REMOVE_STUDENT_FAILURE';

export const CHECK_STUDENT_EXITING = 'CHECK_STUDENT_EXITING';
export const CHECK_STUDENT_EXITING_SUCCESS = 'CHECK_STUDENT_EXITING_SUCCESS';
export const CHECK_STUDENT_EXITING_FAILURE = 'CHECK_STUDENT_EXITING_FAILURE';

export const SET_CURRENT_STUDENT = 'SET_CURRENT_STUDENT';

export const REFRESH_STUDENTS_DATA = 'REFRESH_STUDENTS_DATA';
export const REFRESH_EXITING_STUDENT_DATA = 'REFRESH_EXITING_STUDENT_DATA';
export const REFRESH_STUDENT_DETAILS = 'REFRESH_STUDENT_DETAILS';

export const GET_ENROLLMENTS_BY_STUDENT = 'GET_ENROLLMENTS_BY_STUDENT';
export const GET_ENROLLMENTS_BY_STUDENT_SUCCESS = 'GET_ENROLLMENTS_BY_STUDENT_SUCCESS';
export const GET_ENROLLMENTS_BY_STUDENT_FAILURE = 'GET_ENROLLMENTS_BY_STUDENT_FAILURE';

export const GET_POAS_BY_STUDENT = 'GET_POAS_BY_STUDENT';
export const GET_POAS_BY_STUDENT_SUCCESS = 'GET_POAS_BY_STUDENT_SUCCESS';
export const GET_POAS_BY_STUDENT_FAILURE = 'GET_POAS_BY_STUDENT_FAILURE';

export const MAKE_POA_BY_STUDENT = 'MAKE_POA_BY_STUDENT';
export const MAKE_POA_BY_STUDENT_SUCCESS = 'MAKE_POA_BY_STUDENT_SUCCESS';
export const MAKE_POA_BY_STUDENT_FAILURE = 'MAKE_POA_BY_STUDENT_FAILURE';

export const VALIDATE_POA = 'VALIDATE_POA';
export const VALIDATE_POA_SUCCESS = 'VALIDATE_POA_SUCCESS';
export const VALIDATE_POA_FAILURE = 'VALIDATE_POA_FAILURE';

export const GET_STUDENT_OUTPUTS = 'GET_STUDENT_OUTPUTS';
export const GET_STUDENT_OUTPUTS_SUCCESS = 'GET_STUDENT_OUTPUTS_SUCCESS';
export const GET_STUDENT_OUTPUTS_FAILURE = 'GET_STUDENT_OUTPUTS_FAILURE';

export const GET_STUDENT_OUTPUTS_FOR_UPDATE = 'GET_STUDENT_OUTPUTS_FOR_UPDATE';
export const GET_STUDENT_OUTPUTS_FOR_UPDATE_SUCCESS = 'GET_STUDENT_OUTPUTS_FOR_UPDATE_SUCCESS';
export const GET_STUDENT_OUTPUTS_FOR_UPDATE_FAILURE = 'GET_STUDENT_OUTPUTS_FOR_UPDATE_FAILURE';

export const GENERATE_STUDENT_OUTPUT = 'GENERATE_STUDENT_OUTPUT';
export const GENERATE_STUDENT_OUTPUT_SUCCESS = 'GENERATE_STUDENT_OUTPUT_SUCCESS';
export const GENERATE_STUDENT_OUTPUT_FAILURE = 'GENERATE_STUDENT_OUTPUT_FAILURE';

export const CLEAR_STUDENT_OUTPUT = 'CLEAR_STUDENT_OUTPUT';
export const CLEAR_STUDENT_OUTPUT_SUCCESS = 'CLEAR_STUDENT_OUTPUT_SUCCESS';
export const CLEAR_STUDENT_OUTPUT_FAILURE = 'CLEAR_STUDENT_OUTPUT_FAILURE';

export const GET_STUDENT_ASSIGNMENTS_BY_ENROLLMENT = 'GET_STUDENT_ASSIGNMENTS_BY_ENROLLMENT';
export const GET_STUDENT_ASSIGNMENTS_BY_ENROLLMENT_SUCCESS = 'GET_STUDENT_ASSIGNMENTS_BY_ENROLLMENT_SUCCESS';
export const GET_STUDENT_ASSIGNMENTS_BY_ENROLLMENT_FAILURE = 'GET_STUDENT_ASSIGNMENTS_BY_ENROLLMENT_FAILURE';

export const ASSIGN_DATA_COLLECTION_BY_ASSIGNMENT = 'ASSIGN_DATA_COLLECTION_BY_ASSIGNMENT';
export const ASSIGN_DATA_COLLECTION_BY_ASSIGNMENT_SUCCESS = 'ASSIGN_DATA_COLLECTION_BY_ASSIGNMENT_SUCCESS';
export const ASSIGN_DATA_COLLECTION_BY_ASSIGNMENT_FAILURE = 'ASSIGN_DATA_COLLECTION_BY_ASSIGNMENT_FAILURE';

export const UNASSIGN_DATA_COLLECTION_BY_ASSIGNMENT = 'UNASSIGN_DATA_COLLECTION_BY_ASSIGNMENT';
export const UNASSIGN_DATA_COLLECTION_BY_ASSIGNMENT_SUCCESS = 'UNASSIGN_DATA_COLLECTION_BY_ASSIGNMENT_SUCCESS';
export const UNASSIGN_DATA_COLLECTION_BY_ASSIGNMENT_FAILURE = 'UNASSIGN_DATA_COLLECTION_BY_ASSIGNMENT_FAILURE';

export const GET_STUDENT_ENROLLMENTS = 'GET_STUDENT_ENROLLMENTS';
export const GET_STUDENT_ENROLLMENTS_SUCCESS = 'GET_STUDENT_ENROLLMENTS_SUCCESS';
export const GET_STUDENT_ENROLLMENTS_FAILURE = 'GET_STUDENT_ENROLLMENTS_FAILURE';

export const GET_ENROLLMENT_ASSOCIATIONS = 'GET_ENROLLMENT_ASSOCIATIONS';
export const GET_ENROLLMENT_ASSOCIATIONS_SUCCESS = 'GET_ENROLLMENT_ASSOCIATIONS_SUCCESS';
export const GET_ENROLLMENT_ASSOCIATIONS_FAILURE = 'GET_ENROLLMENT_ASSOCIATIONS_FAILURE';

export const UPDATE_ENROLLMENT = 'UPDATE_ENROLLMENT';
export const UPDATE_ENROLLMENT_SUCCESS = 'UPDATE_ENROLLMENT_SUCCESS';
export const UPDATE_ENROLLMENT_FAILURE = 'UPDATE_ENROLLMENT_FAILURE';

export const REMOVE_ENROLLMENT_ASSOCIATION = 'REMOVE_ENROLLMENT_ASSOCIATION';
export const REMOVE_ENROLLMENT_ASSOCIATION_SUCCESS = 'REMOVE_ENROLLMENT_ASSOCIATION_SUCCESS';
export const REMOVE_ENROLLMENT_ASSOCIATION_FAILURE = 'REMOVE_ENROLLMENT_ASSOCIATION_FAILURE';

export const SET_CURRENT_ENROLLMENT = 'SET_CURRENT_ENROLLMENT';

export const GET_STUDENT_LOCATION_MEMBERS = 'GET_STUDENT_LOCATION_MEMBERS';
export const GET_STUDENT_LOCATION_MEMBERS_SUCCESS = 'GET_STUDENT_LOCATION_MEMBERS_SUCCESS';
export const GET_STUDENT_LOCATION_MEMBERS_FAILURE = 'GET_STUDENT_LOCATION_MEMBERS_FAILURE';

export const CREATE_ENROLLMENT_ASSOCIATION = 'CREATE_ENROLLMENT_ASSOCIATION';
export const CREATE_ENROLLMENT_ASSOCIATION_SUCCESS = 'CREATE_ENROLLMENT_ASSOCIATION_SUCCESS';
export const CREATE_ENROLLMENT_ASSOCIATION_FAILURE = 'CREATE_ENROLLMENT_ASSOCIATION_FAILURE';

export const REFRESH_STUDENT_ENROLLMENTS = 'REFRESH_STUDENT_ENROLLMENTS';
export const REFRESH_ENROLLMENT_ASSOCIATIONS = 'REFRESH_ENROLLMENT_ASSOCIATIONS';

export const actions = createActions({
  [GET_AVAILABLE_ROSTERS]: undefined,
  [GET_AVAILABLE_ROSTERS_SUCCESS]: undefined,
  [GET_AVAILABLE_ROSTERS_FAILURE]: undefined,

  [GET_STUDENTS_ROSTERS]: undefined,
  [GET_STUDENTS_ROSTERS_SUCCESS]: undefined,
  [GET_STUDENTS_ROSTERS_FAILURE]: undefined,

  [UPDATE_STUDENTS_ROSTERS]: undefined,
  [UPDATE_STUDENTS_ROSTERS_SUCCESS]: undefined,
  [UPDATE_STUDENTS_ROSTERS_FAILURE]: undefined,

  [GET_STUDENTS_BY_TENANT]: undefined,
  [GET_STUDENTS_BY_TENANT_SUCCESS]: undefined,
  [GET_STUDENTS_BY_TENANT_FAILURE]: undefined,

  [GET_STUDENTS_BY_LOCATION]: undefined,
  [GET_STUDENTS_BY_LOCATION_SUCCESS]: undefined,
  [GET_STUDENTS_BY_LOCATION_FAILURE]: undefined,

  [GET_STUDENT_DETAILS]: undefined,
  [GET_STUDENT_DETAILS_SUCCESS]: undefined,
  [GET_STUDENT_DETAILS_FAILURE]: undefined,

  [CREATE_STUDENT]: undefined,
  [CREATE_STUDENT_SUCCESS]: undefined,
  [CREATE_STUDENT_FAILURE]: undefined,

  [UPDATE_STUDENT]: undefined,
  [UPDATE_STUDENT_SUCCESS]: undefined,
  [UPDATE_STUDENT_FAILURE]: undefined,

  [REMOVE_STUDENT]: undefined,
  [REMOVE_STUDENT_SUCCESS]: undefined,
  [REMOVE_STUDENT_FAILURE]: undefined,

  [CHECK_STUDENT_EXITING]: undefined,
  [CHECK_STUDENT_EXITING_SUCCESS]: undefined,
  [CHECK_STUDENT_EXITING_FAILURE]: undefined,

  [SET_CURRENT_STUDENT]: undefined,

  [REFRESH_STUDENTS_DATA]: undefined,
  [REFRESH_EXITING_STUDENT_DATA]: undefined,
  [REFRESH_STUDENT_DETAILS]: undefined,

  [GET_ENROLLMENTS_BY_STUDENT]: undefined,
  [GET_ENROLLMENTS_BY_STUDENT_SUCCESS]: undefined,
  [GET_ENROLLMENTS_BY_STUDENT_FAILURE]: undefined,

  [GET_POAS_BY_STUDENT]: undefined,
  [GET_POAS_BY_STUDENT_SUCCESS]: undefined,
  [GET_POAS_BY_STUDENT_FAILURE]: undefined,

  [MAKE_POA_BY_STUDENT]: undefined,
  [MAKE_POA_BY_STUDENT_SUCCESS]: undefined,
  [MAKE_POA_BY_STUDENT_FAILURE]: undefined,

  [VALIDATE_POA]: undefined,
  [VALIDATE_POA_SUCCESS]: undefined,
  [VALIDATE_POA_FAILURE]: undefined,

  [GET_STUDENT_OUTPUTS]: undefined,
  [GET_STUDENT_OUTPUTS_SUCCESS]: undefined,
  [GET_STUDENT_OUTPUTS_FAILURE]: undefined,

  [GET_STUDENT_OUTPUTS_FOR_UPDATE]: undefined,
  [GET_STUDENT_OUTPUTS_FOR_UPDATE_SUCCESS]: undefined,
  [GET_STUDENT_OUTPUTS_FOR_UPDATE_FAILURE]: undefined,

  [GENERATE_STUDENT_OUTPUT]: undefined,
  [GENERATE_STUDENT_OUTPUT_SUCCESS]: undefined,
  [GENERATE_STUDENT_OUTPUT_FAILURE]: undefined,

  [CLEAR_STUDENT_OUTPUT]: undefined,
  [CLEAR_STUDENT_OUTPUT_SUCCESS]: undefined,
  [CLEAR_STUDENT_OUTPUT_FAILURE]: undefined,

  [GET_STUDENT_ASSIGNMENTS_BY_ENROLLMENT]: undefined,
  [GET_STUDENT_ASSIGNMENTS_BY_ENROLLMENT_SUCCESS]: undefined,
  [GET_STUDENT_ASSIGNMENTS_BY_ENROLLMENT_FAILURE]: undefined,

  [ASSIGN_DATA_COLLECTION_BY_ASSIGNMENT]: undefined,
  [ASSIGN_DATA_COLLECTION_BY_ASSIGNMENT_SUCCESS]: undefined,
  [ASSIGN_DATA_COLLECTION_BY_ASSIGNMENT_FAILURE]: undefined,

  [UNASSIGN_DATA_COLLECTION_BY_ASSIGNMENT]: undefined,
  [UNASSIGN_DATA_COLLECTION_BY_ASSIGNMENT_SUCCESS]: undefined,
  [UNASSIGN_DATA_COLLECTION_BY_ASSIGNMENT_FAILURE]: undefined,

  [GET_STUDENT_ENROLLMENTS]: undefined,
  [GET_STUDENT_ENROLLMENTS_SUCCESS]: undefined,
  [GET_STUDENT_ENROLLMENTS_FAILURE]: undefined,

  [GET_ENROLLMENT_ASSOCIATIONS]: undefined,
  [GET_ENROLLMENT_ASSOCIATIONS_SUCCESS]: undefined,
  [GET_ENROLLMENT_ASSOCIATIONS_FAILURE]: undefined,

  [UPDATE_ENROLLMENT]: undefined,
  [UPDATE_ENROLLMENT_SUCCESS]: undefined,
  [UPDATE_ENROLLMENT_FAILURE]: undefined,

  [REMOVE_ENROLLMENT_ASSOCIATION]: undefined,
  [REMOVE_ENROLLMENT_ASSOCIATION_SUCCESS]: undefined,
  [REMOVE_ENROLLMENT_ASSOCIATION_FAILURE]: undefined,

  [SET_CURRENT_ENROLLMENT]: undefined,

  [GET_STUDENT_LOCATION_MEMBERS]: undefined,
  [GET_STUDENT_LOCATION_MEMBERS_SUCCESS]: undefined,
  [GET_STUDENT_LOCATION_MEMBERS_FAILURE]: undefined,

  [CREATE_ENROLLMENT_ASSOCIATION]: undefined,
  [CREATE_ENROLLMENT_ASSOCIATION_SUCCESS]: undefined,
  [CREATE_ENROLLMENT_ASSOCIATION_FAILURE]: undefined,

  [REFRESH_STUDENT_ENROLLMENTS]: undefined,
  [REFRESH_ENROLLMENT_ASSOCIATIONS]: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getAvailableRosters, handlers.getAvailableRosters],
    [actions.getAvailableRostersSuccess, handlers.getAvailableRostersSuccess],
    [actions.getAvailableRostersFailure, handlers.getAvailableRostersFailure],

    [actions.getStudentsRosters, handlers.getStudentsRosters],
    [actions.getStudentsRostersSuccess, handlers.getStudentsRostersSuccess],
    [actions.getStudentsRostersFailure, handlers.getStudentsRostersFailure],

    [actions.updateStudentsRosters, handlers.updateStudentsRosters],
    [actions.updateStudentsRostersSuccess, handlers.updateStudentsRostersSuccess],
    [actions.updateStudentsRostersFailure, handlers.updateStudentsRostersFailure],

    [actions.getStudentsByTenant, handlers.getStudentsByTenant],
    [actions.getStudentsByTenantSuccess, handlers.getStudentsByTenantSuccess],
    [actions.getStudentsByTenantFailure, handlers.getStudentsByTenantFailure],

    [actions.getStudentsByLocation, handlers.getStudentsByLocation],
    [actions.getStudentsByLocationSuccess, handlers.getStudentsByLocationSuccess],
    [actions.getStudentsByLocationFailure, handlers.getStudentsByLocationFailure],

    [actions.getStudentDetails, handlers.getStudentDetails],
    [actions.getStudentDetailsSuccess, handlers.getStudentDetailsSuccess],
    [actions.getStudentDetailsFailure, handlers.getStudentDetailsFailure],

    [actions.createStudent, handlers.createStudent],
    [actions.createStudentSuccess, handlers.createStudentSuccess],
    [actions.createStudentFailure, handlers.createStudentFailure],

    [actions.updateStudent, handlers.updateStudent],
    [actions.updateStudentSuccess, handlers.updateStudentSuccess],
    [actions.updateStudentFailure, handlers.updateStudentFailure],

    [actions.removeStudent, handlers.removeStudent],
    [actions.removeStudentSuccess, handlers.removeStudentSuccess],
    [actions.removeStudentFailure, handlers.removeStudentFailure],

    [actions.checkStudentExiting, handlers.checkStudentExiting],
    [actions.checkStudentExitingSuccess, handlers.checkStudentExitingSuccess],
    [actions.checkStudentExitingFailure, handlers.checkStudentExitingFailure],

    [actions.setCurrentStudent, handlers.setCurrentStudent],

    [actions.refreshStudentsData, handlers.refreshStudentsData],
    [actions.refreshExitingStudentData, handlers.refreshExitingStudentData],
    [actions.refreshStudentDetails, handlers.refreshStudentDetails],

    [actions.getEnrollmentsByStudent, handlers.getEnrollmentsByStudent],
    [actions.getEnrollmentsByStudentSuccess, handlers.getEnrollmentsByStudentSuccess],
    [actions.getEnrollmentsByStudentFailure, handlers.getEnrollmentsByStudentFailure],

    [actions.getPoasByStudent, handlers.getPoasByStudent],
    [actions.getPoasByStudentSuccess, handlers.getPoasByStudentSuccess],
    [actions.getPoasByStudentFailure, handlers.getPoasByStudentFailure],

    [actions.makePoaByStudent, handlers.makePoaByStudent],
    [actions.makePoaByStudentSuccess, handlers.makePoaByStudentSuccess],
    [actions.makePoaByStudentFailure, handlers.makePoaByStudentFailure],

    [actions.validatePoa, handlers.validatePoa],
    [actions.validatePoaSuccess, handlers.validatePoaSuccess],
    [actions.validatePoaFailure, handlers.validatePoaFailure],
    [actions.getStudentOutputs, handlers.getStudentOutputs],

    [actions.getStudentOutputsSuccess, handlers.getStudentOutputsSuccess],
    [actions.getStudentOutputsFailure, handlers.getStudentOutputsFailure],

    [actions.getStudentOutputsForUpdate, handlers.getStudentOutputsForUpdate],
    [actions.getStudentOutputsForUpdateSuccess, handlers.getStudentOutputsForUpdateSuccess],
    [actions.getStudentOutputsForUpdateFailure, handlers.getStudentOutputsForUpdateFailure],

    [actions.generateStudentOutput, handlers.generateStudentOutput],
    [actions.generateStudentOutputSuccess, handlers.generateStudentOutputSuccess],
    [actions.generateStudentOutputFailure, handlers.generateStudentOutputFailure],

    [actions.clearStudentOutput, handlers.clearStudentOutput],
    [actions.clearStudentOutputSuccess, handlers.clearStudentOutputSuccess],
    [actions.clearStudentOutputFailure, handlers.clearStudentOutputFailure],

    [actions.getStudentAssignmentsByEnrollment, handlers.getStudentAssignmentsByEnrollment],
    [actions.getStudentAssignmentsByEnrollmentSuccess, handlers.getStudentAssignmentsByEnrollmentSuccess],
    [actions.getStudentAssignmentsByEnrollmentFailure, handlers.getStudentAssignmentsByEnrollmentFailure],

    [actions.assignDataCollectionByAssignment, handlers.assignDataCollectionByAssignment],
    [actions.assignDataCollectionByAssignmentSuccess, handlers.assignDataCollectionByAssignmentSuccess],
    [actions.assignDataCollectionByAssignmentFailure, handlers.assignDataCollectionByAssignmentFailure],

    [actions.unassignDataCollectionByAssignment, handlers.unassignDataCollectionByAssignment],
    [actions.unassignDataCollectionByAssignmentSuccess, handlers.unassignDataCollectionByAssignmentSuccess],
    [actions.unassignDataCollectionByAssignmentFailure, handlers.unassignDataCollectionByAssignmentFailure],

    [actions.getStudentEnrollments, handlers.getStudentEnrollments],
    [actions.getStudentEnrollmentsSuccess, handlers.getStudentEnrollmentsSuccess],
    [actions.getStudentEnrollmentsFailure, handlers.getStudentEnrollmentsFailure],

    [actions.getEnrollmentAssociations, handlers.getEnrollmentAssociations],
    [actions.getEnrollmentAssociationsSuccess, handlers.getEnrollmentAssociationsSuccess],
    [actions.getEnrollmentAssociationsFailure, handlers.getEnrollmentAssociationsFailure],

    [actions.updateEnrollment, handlers.updateEnrollment],
    [actions.updateEnrollmentSuccess, handlers.updateEnrollmentSuccess],
    [actions.updateEnrollmentFailure, handlers.updateEnrollmentFailure],

    [actions.removeEnrollmentAssociation, handlers.removeEnrollmentAssociation],
    [actions.removeEnrollmentAssociationSuccess, handlers.removeEnrollmentAssociationSuccess],
    [actions.removeEnrollmentAssociationFailure, handlers.removeEnrollmentAssociationFailure],

    [actions.setCurrentEnrollment, handlers.setCurrentEnrollment],

    [actions.getStudentLocationMembers, handlers.getStudentLocationMembers],
    [actions.getStudentLocationMembersSuccess, handlers.getStudentLocationMembersSuccess],
    [actions.getStudentLocationMembersFailure, handlers.getStudentLocationMembersFailure],

    [actions.createEnrollmentAssociation, handlers.createEnrollmentAssociation],
    [actions.createEnrollmentAssociationSuccess, handlers.createEnrollmentAssociationSuccess],
    [actions.createEnrollmentAssociationFailure, handlers.createEnrollmentAssociationFailure],

    [actions.refreshStudentEnrollments, handlers.refreshStudentEnrollments],
    [actions.refreshEnrollmentAssociations, handlers.refreshEnrollmentAssociations],
  ]),
  initialState,
);

export default reducer;
