/* eslint-disable no-bitwise */

const bitAnalysis = (n) => {
  const binPowers = [];

  for (let bit = 0; bit < 10; bit += 1) {
    const binPower = 1 << bit;

    if (binPower & n) binPowers[bit] = binPower;
  }
  return binPowers;
};

export default bitAnalysis;
