/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-find-dom-node */
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  fullWidth: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
});

const SelectInput = (props) => {
  const {
    input,
    label,
    meta: { touched, error },
    helperText,
    variant,
    fullWidth,
    type,
    autoFocus,
    autoComplete,
    margin,
    classes,
    customClasses,
    required,
    adornmentText,
    id,
    options,
    InputLabelProps,
    InputProps,
    ...custom
  } = props;

  const { name, value } = input;

  const [inputRef, setInputRef] = useState(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    if (ReactDOM.findDOMNode(inputRef)) {
      setLabelWidth(ReactDOM.findDOMNode(inputRef).offsetWidth);
    }
  });

  if (variant === 'outlined') {
    return (
      <FormControl className={classes.fullWidth} variant="outlined" error={!!(touched && error)}>
        <InputLabel
          htmlFor={id}
          ref={(ref) => {
            setInputRef(ref);
          }}
          {...InputLabelProps}
        >
          {`${label} ${required ? '*' : ''}`}
        </InputLabel>
        <Select
          inputProps={{
            name,
            id,
          }}
          input={<OutlinedInput labelWidth={labelWidth} name={name} {...InputProps} />}
          variant={variant}
          value={value}
          classes={customClasses}
          {...input}
          {...custom}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
      </FormControl>
    );
  }

  return (
    <FormControl className={classes.fullWidth} error={!!(touched && error)}>
      <InputLabel htmlFor={id} {...InputLabelProps}>{`${label} ${required ? '*' : ''}`}</InputLabel>
      <Select
        classes={classes}
        inputProps={{
          name,
          id,
        }}
        input={<OutlinedInput labelWidth={labelWidth} name={name} {...InputProps} />}
        variant={variant}
        value={value}
        {...input}
        {...custom}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );

  // value={this.state.age}
  // onChange={this.handleChange}

  // return (
  //     <TextField
  //         fullWidth={fullWidth}
  //         variant={variant}
  //         error={!!(touched && error)}
  //         helperText={touched && error ? error : null}
  //         style={touched && error ? {} : { marginBottom: '1.75rem' }}
  //         margin={margin}
  //         autoFocus={autoFocus}
  //         autoComplete={autoComplete}
  //         label={label}
  //         type={type}
  //         {...input}
  //         {...custom}
  //     />
  // );
};

SelectInput.defaultProps = {
  meta: {
    error: '',
  },
  helperText: '',
  variant: 'outlined',
  type: 'text',
  fullWidth: true,
  margin: 'normal',
  autoFocus: false,
  autoComplete: '',
  required: false,
  adornmentText: '',
  customClasses: {},
  InputLabelProps: {},
  InputProps: {},
};

SelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  customClasses: PropTypes.object,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  id: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  classes: PropTypes.object.isRequired,
  adornmentText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(SelectInput);
