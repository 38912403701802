// Note: This Route will re-render the entire page. Need to check the appropriateness of use.
import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Router, Location } from '@reach/router';
import PropTypes from 'react-proptypes';

const FadeTransitionRouter = (props) => (
  <Location>
    {({ location }) => (
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={250}>
          <Router location={location}>{props.children}</Router>
        </CSSTransition>
      </TransitionGroup>
    )}
  </Location>
);

FadeTransitionRouter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FadeTransitionRouter;
