import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

import downloadBase64 from 'helpers/downloadBase64';
import format from './formatter';

export const getSets = (id) => http.get(
  cas3ApiRoute(`/data-collections/${id}/sets`),
).then((res) => res.data.dataCollectionsSets);

export const getStudents = (id) => http.get(
  cas3ApiRoute(`/data-collections/${id}/completed/students`),
);

export const generateIsr = (data) => http.post(
  cas3ApiRoute('/reports/individual-student'),
  data,
).then((res) => res.data);

export const getIsrInfo = ({ outputId }) => http.get(
  cas3ApiRoute(`/data-collections-students-outputs/${outputId}`),
).then((res) => res.data.dataCollectionsStudentsOutput);

export const generateStudentScoresheet = ({ collectionId, studentEnrollmentId, generateRandomScores }) => http.post(
  cas3ApiRoute('/score-sheet/template'),
  {
    dataCollectionId: collectionId,
    studentEnrollmentId,
    generateRandomScores,
  },
).then((res) => {
  downloadBase64(res.data.pdf, `Rating_Sheet_${studentEnrollmentId}.pdf`);
});

export const getSetsTotals = (id) => http.get(
  cas3ApiRoute(`/data-collections/${id}/completed/sets`),
);

export const getHierarchy = (id) => http.get(
  cas3ApiRoute(`/data-collections/${id}/completed/hierarchy`),
).then((res) => format(res.data));

export const getResultByItems = (id) => http.get(
  cas3ApiRoute(`/data-collections/${id}/completed`),
).then((res) => res.data);

export const getStudentByItems = (id) => http.get(
  cas3ApiRoute(`/data-collections/${id}/completed/sets/students`),
).then((res) => res.data);
