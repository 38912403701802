export const teachersFormat = (resData = []) => {
  const result = resData.data.filter((item) => !Array.isArray(item));

  if (result[0].demographics) {
    return result.map((student) => ({
      ...student,
      teachers: Object.keys(student.teachers).reduce((acc, teacherId) => ({
        ...acc,
        [teacherId]: {
          first_name: student.teachers[teacherId].teacher_first_name,
          last_name: student.teachers[teacherId].teacher_last_name,
          id: student.teachers[teacherId].teacher_id,
        },
      }), {}),
    }));
  }
  return result;
};

export default teachersFormat;
