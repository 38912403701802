import { DATA_DISPLAY_TYPES } from 'commonConstants';

const assessmentTypeDefinition = (itemData, isAdminMode) => {
  let type;
  if (isAdminMode) {
    type = itemData.isKra ? DATA_DISPLAY_TYPES.KRA : DATA_DISPLAY_TYPES.ELA;

    return type;
  }
  // type = (itemData.assessments && itemData.assessments.taxonomy === 'SUMMATIVE') ? DATA_DISPLAY_TYPES.KRA : DATA_DISPLAY_TYPES.ELA;
  return type;
};

const mapAssessmentApiToDisplay = ({ dc, isAdminMode }) => ({
  ...dc,
  type: dc.type || assessmentTypeDefinition(dc, isAdminMode),
  student_count: dc.studentAssignments,
});

const initialState = {
  collections: [],
  data: [],
  isLoading: false,

  collectionDetails: {},
  detailsIsLoading: false,
  detailsIsUpdating: false,

  error: null,
};

export const fetchCollections = () => ({
  ...initialState,
  isLoading: true,
});

export const fetchCollectionFail = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const fetchCollectionsSuccess = (state, { payload }) => ({
  ...state,
  collections: payload.map((dc) => mapAssessmentApiToDisplay({ dc, isAdminMode: true })),
  isLoading: false,
});

export const getCollectionsList = (state) => ({
  ...state,
  isLoading: true,
});

export const getCollectionsListFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const getCollectionsListSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const getCollectionsByRegion = () => ({
  ...initialState,
  isLoading: true,
});

export const getCollectionsByRegionFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const getCollectionsByRegionSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const getCollectionsByDistrict = () => ({
  ...initialState,
  isLoading: true,
});

export const getCollectionsByDistrictFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const getCollectionsByDistrictSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const getCollectionsByLocation = () => ({
  ...initialState,
  isLoading: true,
});

export const getCollectionsByLocationFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const getCollectionsByLocationSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const getCollectionDetails = (state) => ({
  ...state,
  detailsIsLoading: true,
});

export const getCollectionDetailsFailure = (state, { payload }) => ({
  ...state,
  detailsIsLoading: false,
  collectionDetails: initialState.userDetails,
  error: payload,
});

export const getCollectionDetailsSuccess = (state, { payload }) => ({
  ...state,
  detailsIsLoading: false,
  collectionDetails: payload,
  error: null,
});

export const createCollection = (state) => ({
  ...state,
  isLoading: true,
});

export const createCollectionFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const createCollectionSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const updateCollection = (state) => ({
  ...state,
  detailsIsUpdating: true,
});

export const updateCollectionSuccess = (state, { payload }) => ({
  ...state,
  detailsIsUpdating: false,
  collectionDetails: payload,
});

export const updateCollectionFailure = (state, { payload }) => ({
  ...state,
  detailsIsUpdating: false,
  error: payload,
});

export const refreshCollectionDetails = (state) => ({
  ...state,
  collectionDetails: initialState.collectionDetails,
});

export const refreshCollectionsList = (state) => ({
  ...state,
  data: initialState.data,
});

export default initialState;
