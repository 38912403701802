import React, { useEffect } from 'react';
import PropTypes from 'react-proptypes';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  link: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    fontFamily: 'LatoWebSemibold, sans',
    position: 'absolute',
    top: 0,
    left: '-9999px',
    zIndex: 99999,
    '&:focus': {
      left: 0,
      border: `2px dashed ${theme.palette.primary.contrastText}`,
    },
  },
});

const SkipLinks = ({ dispatch, links, classes }) => {
  useEffect(() => {
    dispatch({ type: 'POLL_SKIPLINKS' });
  }, []);

  return (
    <>
      {links
        .filter((link) => link.visible)
        .map((visibleLink) => (
          <a className={classes.link} key={visibleLink.target} href={`#${visibleLink.target}`}>
            {visibleLink.description}
          </a>
        ))}
    </>
  );
};

SkipLinks.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ skiplinks }) => ({
  links: skiplinks.links,
});

export default connect(
  mapStateToProps,
  (dispatch) => ({ dispatch }),
)(withStyles(styles)(SkipLinks));
