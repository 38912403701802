/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

import DashboardIcon from '@material-ui/icons/Dashboard';
import SchoolIcon from '@material-ui/icons/School';
import SettingsIcon from '@material-ui/icons/Settings';
import LogoutIcon from 'mdi-react/LogoutIcon';
import CogsIcon from 'mdi-react/CogsIcon';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AccIcon from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';

import menuIcon from 'assets/icons/menu_icon.png';
import { actions } from 'redux/auth';
import * as CasInterop from 'helpers/cas4/interop';
import Notifications from '../Notifications';

const styles = (theme) => ({
  menu: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.9)',
    zIndex: 99,
    color: 'white',
    paddingLeft: '5em',
  },
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: `${theme.palette.primary.main} url(/assets/header.png) 0 0 no-repeat`,
  },
  button: {
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    background: 'inherit',
  },
  btnIcon: {
    width: 27,
    height: 27,
    flexShrink: '0',
  },
  appLogo: {
    height: '3.5rem',
    marginBottom: '-0.4rem',
    marginLeft: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      height: 'auto',
    },
  },
  appLogoIcon: {
    height: '3.25rem',
    marginBottom: '-0.25rem',
  },
  grow: {
    flexGrow: 1,
  },
  headerLink: {
    color: `${theme.palette.primary.contrastText}`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing()}px 0`,
      minWidth: '40px',
    },
    [theme.breakpoints.down(768)]: {
      fontSize: 32,
      marginBottom: 15,
    },
  },
  headerLinkIcon: {
    flexShrink: '0',
    marginRight: '.5rem',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
    },
    [theme.breakpoints.down(768)]: {
      height: 44,
      width: 44,
      marginRight: 15,
    },
  },
  headerLinkLabel: {
    textAlign: 'left',
  },
  sidebarButton: {
    color: `${theme.palette.primary.contrastText}`,
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing()}px 0`,
      minWidth: '40px',
    },
    [theme.breakpoints.down(768)]: {
      fontSize: 32,
      marginBottom: 15,
    },
    '& > span': {
      display: 'flex',
    },
  },
  sidebarButtonIcon: {
    [theme.breakpoints.down(768)]: {
      height: 44,
      width: 44,
      marginRight: 15,
    },
    flexShrink: '0',
  },
  navContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
    flexGrow: 1,
  },
});

const renderRoleSwitcher = ({
  firstName,
  lastName,
  activeRole,
  isMobile,
  dispatch,
  classes,
  roles,
  t,
  impersonateMode,
}) => {
  const isAdmin = roles.length && roles.indexOf('ROLE_ADMIN') > -1;
  const isTeacher = roles.length && roles.indexOf('ROLE_TEACHER') > -1;
  const isSystemAdmin = roles.length && roles.indexOf('ROLE_SYSTEM_ADMIN') > -1;

  const getUserName = () => {
    let modifiedFN = firstName;
    let modifiedLN = lastName;

    if (firstName.length > 15) {
      modifiedFN = `${firstName.substring(0, 14)}...`;
    }

    if (lastName.length > 15) {
      modifiedLN = `${lastName.substring(0, 14)}...`;
    }

    return `${modifiedFN} ${modifiedLN}`;
  };

  const supportLink = activeRole === 'ROLE_ADMIN'
    ? 'https://drive.google.com/file/d/1NcAv4X65genZJcmAU33jlfC8c7Lzp_aO/view?usp=sharing'
    : 'https://www.hopkinslearning.org/learn/article/kready-technical-support';

  const handleProfileClick = () => CasInterop.navigate('/profile', { reason: 'new-profile-route' });
  return (
    <>
      {activeRole === 'ROLE_TEACHER' && (
        <Button className={classes.headerLink} component={Link} to="/teacher/dashboard" id="header-teacher-dashboard-link">
          <DashboardIcon className={classes.headerLinkIcon} />
          <Hidden
            className={classes.headerLinkLabel}
            implementation="css"
            smDown={!isMobile}
          >
            {t('Dashboard')}
          </Hidden>
        </Button>
      )}
      {activeRole !== 'ROLE_ADMIN' && isAdmin && (
        <Button
          className={classes.headerLink}
          component={Link}
          to="/administrator/dashboard"
          onClick={() => dispatch(actions.setActiveRole({ data: 'ROLE_ADMIN' }))}
        >
          <SettingsIcon className={classes.headerLinkIcon} />
          <Hidden
            className={classes.headerLinkLabel}
            implementation="css"
            smDown={!isMobile}
          >
            {t('Administration')}
          </Hidden>
        </Button>
      )}
      {activeRole !== 'ROLE_TEACHER' && isTeacher && (
        <Button
          className={classes.headerLink}
          component={Link}
          to="/teacher/dashboard"
          onClick={() => dispatch(actions.setActiveRole({ data: 'ROLE_TEACHER' }))}
        >
          <SchoolIcon className={classes.headerLinkIcon} />
          <Hidden
            implementation="css"
            smDown={!isMobile}
            className={classes.headerLinkLabel}
          >
            {t('Teacher')}
          </Hidden>
        </Button>
      )}
      {activeRole !== 'ROLE_SYSTEM_ADMIN' && isSystemAdmin && (
        <Button
          className={classes.headerLink}
          component={Link}
          to="/system/dashboard"
          onClick={() => dispatch(actions.setActiveRole({ data: 'ROLE_SYSTEM_ADMIN' }))}
        >
          <CogsIcon className={classes.headerLinkIcon} />
          <Hidden
            implementation="css"
            smDown={!isMobile}
            className={classes.headerLinkLabel}
          >
            {t('System Settings')}
          </Hidden>
        </Button>
      )}
      <Button className={classes.headerLink} component={Link} to="/downloads" id="header-downloads-link">
        <CloudDownloadIcon className={classes.headerLinkIcon} />
        <Hidden
          className={classes.headerLinkLabel}
          implementation="css"
          smDown={!isMobile}
        >
          {t('Downloads')}
        </Hidden>
      </Button>
      <Button className={classes.headerLink} component="a" href={supportLink} id="header-help-link">
        <HelpIcon className={classes.headerLinkIcon} />
        {t('Support')}
      </Button>
      <Notifications className={classes.headerLinkIcon} />
      <Button className={classes.headerLink} onClick={handleProfileClick} id="header-teacher-dashboard-link">
        <AccIcon className={classes.headerLinkIcon} />
        <Hidden implementation="css" smDown={!isMobile}>
          {getUserName()}
        </Hidden>
      </Button>
      <Button className={classes.headerLink} onClick={() => dispatch(actions.logout())} id="header-logout-link">
        <LogoutIcon className={classes.headerLinkIcon} />
        {isMobile && t('Logout')}
        {!isMobile && impersonateMode && t('End Impersonation')}
      </Button>
    </>
  );
};

const Header = (props) => {
  const [open, setOpen] = useState(false);
  const { classes, resolutionMode } = props;
  const isMobile = resolutionMode !== 'mobile';

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [resolutionMode]);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Link to="/" id="logo-link">
            <Hidden implementation="css" xsDown>
              <img className={classes.appLogo} src="/assets/logo.png" alt="Ready for Kindergarten Logo" />
            </Hidden>
            <Hidden implementation="css" smUp>
              <img className={classes.appLogoIcon} src="/assets/logoIcon.png" alt="Ready for Kindergarten Logo" />
            </Hidden>
          </Link>
          <nav id="application-navigation" className={classes.navContainer}>
            {
              isMobile ? (
                renderRoleSwitcher({ isMobile: open, ...props })
              ) : (
                <button
                  type="button"
                  className={classes.button}
                  onClick={() => setOpen(!open)}
                >
                  <img src={menuIcon} className={classes.btnIcon} alt="PDF" />
                </button>
              )
            }
          </nav>
        </Toolbar>
      </AppBar>
      {
        open && (
          <div className={classes.menu} onClick={() => setOpen(false)}>
            {renderRoleSwitcher({ isMobile: open, ...props })}
          </div>
        )
      }
    </div>
  );
};

renderRoleSwitcher.propTypes = {
  lastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  activeRole: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMobile: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  impersonateMode: PropTypes.bool.isRequired,
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  resolutionMode: PropTypes.string.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  roles: auth.roles,
  firstName: auth.firstName,
  lastName: auth.lastName,
  activeRole: auth.activeRole,
  resolutionMode: auth.resolutionMode,
  impersonateMode: auth.impersonateMode,
});

export default connect(
  mapStateToProps,
  (dispatch) => ({ dispatch }),
)(withStyles(styles)(withTranslation()(Header)));
