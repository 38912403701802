const initialState = {
  acknowledgements: [],
  loading: false,
  error: '',
  acknowledgement: {
    id: null,
    text: null,
    dataCollection: false,
    tenant: null,
  },
};

export const systemNewAcknowledgement = (state) => ({
  ...state,
  acknowledgement: { ...initialState.acknowledgement },
});

export const systemGetAcknowledgement = (state) => ({
  ...state,
  loading: true,
  error: '',
  acknowledgement: { ...initialState.acknowledgement },
});

export const systemGetAcknowledgementError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.data,
});

export const systemGetAcknowledgementSuccess = (state, { payload }) => ({
  ...state,
  acknowledgement: payload,
  loading: false,
  error: '',
});

export const systemGetAcknowledgements = (state) => ({
  ...state,
  loading: true,
  error: '',
});

export const systemGetAcknowledgementsError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.data,
});

export const systemGetAcknowledgementsSuccess = (state, { payload }) => ({
  ...state,
  acknowledgements: payload,
  loading: false,
  error: '',
});

export const systemSaveAcknowledgement = (state) => ({
  ...state,
  loading: true,
});

export const systemSaveAcknowledgementSuccess = (state) => ({
  ...state,
  loading: true,
});

export const systemSaveAcknowledgementError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.data,
});

export const systemUpdateAcknowledgement = (state) => ({
  ...state,
  loading: true,
});

export const systemUpdateAcknowledgementSuccess = (state) => ({
  ...state,
  loading: true,
});

export const systemUpdateAcknowledgementError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.data,
});

export default initialState;
