/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import CameraIcon from 'mdi-react/CameraIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  buttonIconMarginRight: {
    marginRight: '0.25rem',
  },
  imgPreviewContainer: {
    display: 'flex',
    marginBottom: '.5rem',
  },
  imgBlock: {
    paddingTop: '175px',
    width: '300px',
    position: 'relative',
    overflow: 'hidden',
    marginRight: '.5rem',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '&:hover > button': {
      opacity: 1,
    },
  },
  img: {
    width: 'auto',
    height: '100px',
  },
  imgOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: theme.palette.primary.lighter,
    opacity: 0,
    transition: 'all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover, &:focused': {
      opacity: 1,
    },
  },
  fileTouchIcon: {},
});

const FileInput = ({
  label, theme, classes, multiple, input: { onChange },
}) => {
  const [files, setFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);

  const onDrop = (acceptedFiles) => {
    onChange(acceptedFiles);
    setFiles(acceptedFiles);

    const newFilePreviews = [];
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        newFilePreviews.push({
          ...file,
          preview: event.target.result,
        });
        setFilePreviews([...newFilePreviews]);
      };
      reader.readAsDataURL(file);
    });
  };

  const removeImage = (img) => {
    setFiles(files.filter((file) => file.path !== img.path));
    setFilePreviews(filePreviews.filter((file) => file.path !== img.path));
    onChange(files);
  };

  const renderImageBlock = (img) => (
    <div key={img.path} className={classes.imgBlock} style={{ backgroundImage: `url(${img.preview})` }}>
      <button type="button" className={classes.imgOverlay} onClick={() => removeImage(img)}>
        <Typography variant="srOnly">Remove image</Typography>
        <DeleteIcon color={theme.palette.primary.contrastText} className={classes.fileTouchIcon} />
      </button>
    </div>
  );

  return (
    <Dropzone onDrop={onDrop} multiple={multiple}>
      {({ getRootProps, getInputProps }) => (
        <section>
          {filePreviews.length ? (
            <div className={classes.imgPreviewContainer}>
              {filePreviews.map((img) => {
                if (!img.preview) return null;
                return renderImageBlock(img);
              })}
            </div>
          ) : (
            <div className={classes.imgPreviewContainer} />
          )}
          <div {...getRootProps()}>
            <Typography component="label" variant="srOnly" htmlFor={`file-input-${label}`}>
              {label}
            </Typography>
            <input id={`file-input-${label}`} {...getInputProps()} />
            <Button type="button" variant="contained" size="medium" color="primary">
              <CameraIcon color={theme.palette.primary.contrastText} className={classes.buttonIconMarginRight} />
              {label}
            </Button>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

FileInput.defaultProps = {
  multiple: false,
};

FileInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  multiple: PropTypes.bool,
};

export default withStyles(styles)(withTheme(FileInput));
