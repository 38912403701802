export const formatJobStatus = ({
  count_isr_queued: countIsrQueued,
  count_isr_in_progress_30_mins: countIsrInLongProgress,
  count_bulk_load_queued: countBulkLoadQueued,
  count_bulk_load_in_progress_30_mins: countBulkLoadInLongProgress,
  count_reports_queued: countReportsQueued,
  count_reports_in_progress_30_mins: countReportsInLongProgress,
}) => ({
  countIsrQueued: Number.parseInt(countIsrQueued, 10),
  countIsrInLongProgress: Number.parseInt(countIsrInLongProgress, 10),
  countBulkLoadQueued: Number.parseInt(countBulkLoadQueued, 10),
  countBulkLoadInLongProgress: Number.parseInt(countBulkLoadInLongProgress, 10),
  countReportsQueued: Number.parseInt(countReportsQueued, 10),
  countReportsInLongProgress: Number.parseInt(countReportsInLongProgress, 10),
});

export default formatJobStatus;
