import moment from 'moment';

const dateFormatter = (date) => moment.unix(date).format('ll');
const archiveDateFormatter = (date) => (date > 0 ? moment.unix(date).format('MM/DD/YYYY h:mm:ss a') : '');

export const schoolYearsFormatter = (schoolYears) => schoolYears.map((item) => ({
  ...item,
  startDate: dateFormatter(item.startDate),
  endDate: dateFormatter(item.endDate),
  dataCollections: item.dataCollections,
}));

export const archivesFormatter = (archives) => archives.map(({
  id,
  kind,
  status,
  createdAt,
  metadata,
  s3ResultsFileName,
  errors,
}) => ({
  id,
  kind,
  status,
  createdAt: archiveDateFormatter(createdAt),
  metadata: JSON.parse(metadata),
  s3ResultsFileName,
  errors,
}));
