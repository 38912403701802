import { combineReducers } from 'redux';

import { reducer as form } from 'redux-form';

import auth from './auth';
import admin from './admin';
import system from './system';
import teacher from './teacher';
import snackbar from './snackbar';
import skiplinks from './skiplinks';
import commonData from './common-data';
import dataDisplay from './data-display';
import dataDisplayFilters from './data-display-filters';
import notifications from './notifications';

import filters from './filters';
import options from './options';
import viewKRA from './view-kra';
import students from './students';
import groups from './groups';

const rootReducer = combineReducers({
  auth,
  form,
  admin,
  system,
  teacher,
  snackbar,
  skiplinks,
  commonData,
  dataDisplay,
  dataDisplayFilters,
  notifications,

  filters,
  options,
  viewKRA,
  students,
  groups,
});

export default rootReducer;
