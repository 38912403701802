import { navigate } from '@reach/router';

import { takeLatest, call, put } from 'redux-saga/effects';

import { actions as snackbarActions } from 'redux/snackbar';
import * as api from 'http/admin/districts';

import { actions } from './index';

export function* getDistrictsByRegion({ payload }) {
  try {
    const { regionId } = payload;

    const result = yield call(api.getDistricts, { parentId: regionId, active: 1 });

    yield put(actions.getDistrictsByRegionSuccess(result));
  } catch (error) {
    yield put(actions.getDistrictsByRegionFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getDistrictDetails({ payload }) {
  try {
    const { districtId } = payload;

    const result = yield call(api.getDistrictDetails, { districtId });

    yield put(actions.getDistrictDetailsSuccess(result));
  } catch (error) {
    yield put(actions.getDistrictDetailsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* createDistrict({ payload }) {
  try {
    const {
      tenantId,
      regionId,
      data,
      successHandle,
      isOpenAfter,
    } = payload;

    const result = yield call(api.createDistrict, {
      ...data,
      parentRegionId: +regionId,
      nameLabel: data.name,
      isCensus: !!data.is_census,
    });

    yield put(actions.createDistrictSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'District successfully created' } }));
    yield put(actions.getDistrictsByRegion({ tenantId, regionId }));

    if (isOpenAfter) navigate(`/administrator/organizations/regions/${regionId}/district/${result.data.id}/profile/details`);
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.createDistrictFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* updateDistrict({ payload }) {
  try {
    const { districtId, data } = payload;

    const district = {
      id: +districtId,
      nameLabel: data.name,
      uniqueId: data.uniqueId,
      isCensus: !!data.is_census,
    };

    const result = yield call(api.updateDistrict, district);

    yield put(actions.updateDistrictSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'District successfully updated' } }));
  } catch (error) {
    yield put(actions.updateDistrictFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getIntegrationByDistrict({ payload }) {
  try {
    const {
      tenantId,
      regionId,
      districtId,
      successHandle,
    } = payload;

    const result = yield call(api.getIntegrationByDistrict, { tenantId, regionId, districtId });

    yield put(actions.getIntegrationByDistrictSuccess(result));

    if (successHandle) successHandle(result);
  } catch (error) {
    yield put(actions.getIntegrationByDistrictFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* saveIntegrationByDistrict({ payload }) {
  try {
    const {
      tenantId,
      regionId,
      districtId,
      data,
      successHandle,
    } = payload;

    yield call(api.saveIntegrationByDistrict, {
      data, tenantId, regionId, districtId,
    });

    yield put(actions.saveIntegrationByDistrictSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Integration Saved' } }));

    if (successHandle) successHandle();
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.saveIntegrationByDistrictFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getIntegrationFtpByDistrict({ payload }) {
  try {
    const {
      tenantId,
      regionId,
      districtId,
      successHandle,
    } = payload;

    const result = yield call(api.getIntegrationFtpByDistrict, { tenantId, regionId, districtId });

    yield put(actions.getIntegrationFtpByDistrictSuccess(result));

    if (successHandle) successHandle(result);
  } catch (error) {
    yield put(actions.getIntegrationFtpByDistrictFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getIntegrationUsersByDistrict({ payload }) {
  try {
    const {
      tenantId,
      regionId,
      districtId,
    } = payload;

    const result = yield call(api.getIntegrationUsersByDistrict, { tenantId, regionId, districtId });

    yield put(actions.getIntegrationUsersByDistrictSuccess(result));
  } catch (error) {
    yield put(actions.getIntegrationUsersByDistrictFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* removeIntegrationByDistrict({ payload }) {
  try {
    const { districtId, successHandle } = payload;

    yield call(api.removeIntegrationByDistrict, { districtId });

    if (successHandle) successHandle();
  } catch (error) {
    yield put(actions.removeIntegrationByDistrictFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.getDistrictsByRegion, getDistrictsByRegion);
  yield takeLatest(actions.createDistrict, createDistrict);
  yield takeLatest(actions.getDistrictDetails, getDistrictDetails);
  yield takeLatest(actions.updateDistrict, updateDistrict);
  yield takeLatest(actions.getIntegrationByDistrict, getIntegrationByDistrict);
  yield takeLatest(actions.saveIntegrationByDistrict, saveIntegrationByDistrict);
  yield takeLatest(actions.getIntegrationFtpByDistrict, getIntegrationFtpByDistrict);
  yield takeLatest(actions.getIntegrationUsersByDistrict, getIntegrationUsersByDistrict);
  yield takeLatest(actions.removeIntegrationByDistrict, removeIntegrationByDistrict);
}
