const initialState = {
  data: [],
  isLoading: false,
  error: null,

  districtDetails: {},
  detailsIsLoading: false,
  detailsIsUpdating: false,
  detailsError: null,

  isIntegrationsLoading: false,
  isFtpIntegrationLoading: false,

  usersIntegration: [],
  isUsersIntegrationsLoading: false,
};

export const getDistrictsByRegion = (state) => ({
  ...state,
  isLoading: true,
});

export const getDistrictsByRegionSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload,
});

export const getDistrictsByRegionFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const createDistrict = (state) => ({
  ...state,
  isLoading: true,
});

export const createDistrictSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const createDistrictFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const getDistrictDetails = (state) => ({
  ...state,
  detailsIsLoading: true,
});

export const getDistrictDetailsSuccess = (state, { payload }) => ({
  ...state,
  detailsIsLoading: false,
  districtDetails: payload,
});

export const getDistrictDetailsFailure = (state, { payload }) => ({
  ...state,
  detailsIsLoading: false,
  detailsError: payload,
});

export const updateDistrict = (state) => ({
  ...state,
  detailsIsUpdating: true,
});

export const updateDistrictSuccess = (state, { payload }) => ({
  ...state,
  detailsIsUpdating: false,
  districtDetails: payload,
});

export const updateDistrictFailure = (state, { payload }) => ({
  ...state,
  detailsIsUpdating: false,
  detailsError: payload,
});

export const refreshDistrictDetails = (state) => ({
  ...state,
  districtDetails: initialState.districtDetails,
});

export const getIntegrationByDistrict = (state) => ({
  ...state,
  isIntegrationsLoading: true,
});

export const getIntegrationByDistrictSuccess = (state) => ({
  ...state,
  isIntegrationsLoading: false,
});

export const getIntegrationByDistrictFailure = (state, { payload }) => ({
  ...state,
  isIntegrationsLoading: false,
  error: payload,
});

export const saveIntegrationByDistrict = (state) => state;

export const saveIntegrationByDistrictSuccess = (state) => state;

export const saveIntegrationByDistrictFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const getIntegrationFtpByDistrict = (state) => ({
  ...state,
  isFtpIntegrationLoading: true,
});

export const getIntegrationFtpByDistrictSuccess = (state) => ({
  ...state,
  isFtpIntegrationLoading: false,
});

export const getIntegrationFtpByDistrictFailure = (state, { payload }) => ({
  ...state,
  isFtpIntegrationLoading: false,
  error: payload,
});

export const getIntegrationUsersByDistrict = (state) => ({
  ...state,
  usersIntegration: initialState.usersIntegration,
  isUsersIntegrationsLoading: true,
});

export const getIntegrationUsersByDistrictSuccess = (state, { payload }) => ({
  ...state,
  usersIntegration: payload,
  isUsersIntegrationsLoading: false,
});

export const getIntegrationUsersByDistrictFailure = (state, { payload }) => ({
  ...state,
  isUsersIntegrationsLoading: false,
  error: payload,
});

export const removeIntegrationByDistrict = (state) => state;

export const removeIntegrationByDistrictSuccess = (state) => state;

export const removeIntegrationByDistrictFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export default initialState;
