import styled from 'styled-components';
import theme from 'theme';

import { withStyles } from '@material-ui/core/styles';
import MUIInputLabel from '@material-ui/core/InputLabel';
import MUIFormHelperText from '@material-ui/core/FormHelperText';

const {
  defaultColor,
  focusedColor,
  linedColor,
  selectedColor,
  activeColor,
  outlineColor,
  mainColor,
} = theme.overrides.SelectOptions;

const getOptionColor = ({ isSelected, isFocused }) => {
  if (isSelected) return selectedColor;
  if (isFocused) return focusedColor;

  return defaultColor;
};

export const SelectContainer = styled.div`
    margin-top: -0.25em;
    width: 100%;

    .react-select__control {
        min-height: ${({ selectHeight }) => (selectHeight ? `${selectHeight}px` : 'initial')};
        margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '1.5rem')};
        z-index: 1;
        background: none;
        border-color: ${({ error }) => (error ? theme.palette.error.main : outlineColor)};

        :hover {
            border-color: ${({ error }) => (error ? theme.palette.error.main : mainColor)};
        }
    }

    .react-select__control--is-focused {
        box-shadow: ${({ error }) => (error ? `0 0 0 1px ${theme.palette.error.main}` : `0 0 0 1px ${linedColor}`)};
        border-color: ${({ error }) => (error ? theme.palette.error.main : linedColor)};

        :hover {
            border-color: ${({ error }) => (error ? theme.palette.error.main : linedColor)};
        }
    }

    .react-select__value-container--has-value {
        padding-left: 0.25rem;
    }

    .react-select__option {
        font-size: 0.9rem;
        cursor: pointer;
    }

    .react-select__value-container {
        cursor: text;
        font-size: 0.9rem;
        padding: 0.25em 0 0.25em 12px;

        .react-select__multi-value{
            border-radius: 10px;
            align-items: center;
        }

        .react-select__multi-value__remove {
            cursor: pointer;
            border-radius: 10px;
            height: 22px;
            width: 22px;
        }
    }

    .react-select__indicators {
        .react-select__indicator {
            width: 30px;
            display: flex;
            justify-content: center;
            padding: 0;
            color: ${mainColor};
            cursor: pointer;

            :hover {
                color: ${linedColor}
            }
        }
    }

    .react-select__menu-list {
        z-index: 500;
        padding: 0;
        margin: 0;
        border-radius: 0;
    }

    .react-select__menu {
        z-index: 500;
        margin-top: 0;
        padding: 0;
        border-radius: 0;
    }
`;

export const StyledLabel = styled.label`
    font-size: 1.0rem;
    font-weight: bold;
    margin-bottom: 0.3rem;
    display: block;
`;

export const optionsStyles = {
  option: (styles, { isFocused, isSelected }) => {
    const optionColor = getOptionColor({ isSelected, isFocused });

    return ({
      ...styles,
      backgroundColor: optionColor,
      color: 'inherit',

      ':active': {
        ...styles[':active'],
        backgroundColor: activeColor,
      },
    });
  },
};

export const groupStyles = {
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
  fontSize: '120%',
  marginLeft: '-4px',
};

export const InputLabel = withStyles(() => ({
  outlined: {
    zIndex: 2,
    position: 'absolute',
    color: '#808080',
  },

  shrink: {
    background: 'white',
    padding: '0 6px',
    marginLeft: '-4px',
    color: mainColor,
  },
  focused: {
    color: focusedColor,
  },
}))(MUIInputLabel);

export const FormHelperText = withStyles(() => ({
  root: {
    margin: '8px 14px 0',
  },
}))(MUIFormHelperText);
