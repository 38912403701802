import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_USERS: undefined,
  GET_USERS_FAILURE: undefined,
  GET_USERS_SUCCESS: undefined,

  GET_USER_DETAILS: undefined,
  GET_USER_DETAILS_FAILURE: undefined,
  GET_USER_DETAILS_SUCCESS: undefined,

  CREATE_USER: undefined,
  CREATE_USER_FAILURE: undefined,
  CREATE_USER_SUCCESS: undefined,

  UPDATE_USER: undefined,
  UPDATE_USER_FAILURE: undefined,
  UPDATE_USER_SUCCESS: undefined,

  SET_USER_DETAILS: undefined,

  ACTIVATE_USER: undefined,
  ACTIVATE_USER_FAILURE: undefined,
  ACTIVATE_USER_SUCCESS: undefined,

  DEACTIVATE_USER: undefined,
  DEACTIVATE_USER_FAILURE: undefined,
  DEACTIVATE_USER_SUCCESS: undefined,

  GET_USER_ORGS: undefined,
  GET_USER_ORGS_FAILURE: undefined,
  GET_USER_ORGS_SUCCESS: undefined,

  GET_USER_REGIONS: undefined,
  GET_USER_REGIONS_FAILURE: undefined,
  GET_USER_REGIONS_SUCCESS: undefined,

  GET_USER_DISTRICTS: undefined,
  GET_USER_DISTRICTS_FAILURE: undefined,
  GET_USER_DISTRICTS_SUCCESS: undefined,

  GET_USER_LOCATIONS: undefined,
  GET_USER_LOCATIONS_FAILURE: undefined,
  GET_USER_LOCATIONS_SUCCESS: undefined,

  ASSOCIATE_USER_WITH_REGION: undefined,
  ASSOCIATE_USER_WITH_REGION_FAILURE: undefined,
  ASSOCIATE_USER_WITH_REGION_SUCCESS: undefined,

  DISASSOCIATE_USER_WITH_REGION: undefined,
  DISASSOCIATE_USER_WITH_REGION_FAILURE: undefined,
  DISASSOCIATE_USER_WITH_REGION_SUCCESS: undefined,

  DEMOTE_FROM_REGION_ADMIN: undefined,
  DEMOTE_FROM_REGION_ADMIN_FAILURE: undefined,
  DEMOTE_FROM_REGION_ADMIN_SUCCESS: undefined,

  PROMOTE_TO_REGION_ADMIN: undefined,
  PROMOTE_TO_REGION_ADMIN_FAILURE: undefined,
  PROMOTE_TO_REGION_ADMIN_SUCCESS: undefined,

  ASSOCIATE_USER_WITH_DISTRICT: undefined,
  ASSOCIATE_USER_WITH_DISTRICT_FAILURE: undefined,
  ASSOCIATE_USER_WITH_DISTRICT_SUCCESS: undefined,

  DISASSOCIATE_USER_WITH_DISTRICT: undefined,
  DISASSOCIATE_USER_WITH_DISTRICT_FAILURE: undefined,
  DISASSOCIATE_USER_WITH_DISTRICT_SUCCESS: undefined,

  DEMOTE_FROM_DISTRICT_ADMIN: undefined,
  DEMOTE_FROM_DISTRICT_ADMIN_FAILURE: undefined,
  DEMOTE_FROM_DISTRICT_ADMIN_SUCCESS: undefined,

  PROMOTE_TO_DISTRICT_ADMIN: undefined,
  PROMOTE_TO_DISTRICT_ADMIN_FAILURE: undefined,
  PROMOTE_TO_DISTRICT_ADMIN_SUCCESS: undefined,

  UPDATE_USER_DISTRICT: undefined,
  UPDATE_USER_DISTRICT_FAILURE: undefined,
  UPDATE_USER_DISTRICT_SUCCESS: undefined,

  ASSOCIATE_USER_WITH_LOCATION: undefined,
  ASSOCIATE_USER_WITH_LOCATION_FAILURE: undefined,
  ASSOCIATE_USER_WITH_LOCATION_SUCCESS: undefined,

  DISASSOCIATE_USER_WITH_LOCATION: undefined,
  DISASSOCIATE_USER_WITH_LOCATION_FAILURE: undefined,
  DISASSOCIATE_USER_WITH_LOCATION_SUCCESS: undefined,

  DEMOTE_FROM_LOCATION_ADMIN: undefined,
  DEMOTE_FROM_LOCATION_ADMIN_FAILURE: undefined,
  DEMOTE_FROM_LOCATION_ADMIN_SUCCESS: undefined,

  PROMOTE_TO_LOCATION_ADMIN: undefined,
  PROMOTE_TO_LOCATION_ADMIN_FAILURE: undefined,
  PROMOTE_TO_LOCATION_ADMIN_SUCCESS: undefined,

  REFRESH_USER_REGIONS: undefined,
  REFRESH_USER_DISTRICTS: undefined,
  REFRESH_USER_LOCATIONS: undefined,

  GET_USER_REGIONS_MEMBER: undefined,
  GET_USER_REGIONS_MEMBER_FAILURE: undefined,
  GET_USER_REGIONS_MEMBER_SUCCESS: undefined,

  GET_USER_DISTRICTS_MEMBER: undefined,
  GET_USER_DISTRICTS_MEMBER_FAILURE: undefined,
  GET_USER_DISTRICTS_MEMBER_SUCCESS: undefined,

  GET_USER_LOCATIONS_MEMBER: undefined,
  GET_USER_LOCATIONS_MEMBER_FAILURE: undefined,
  GET_USER_LOCATIONS_MEMBER_SUCCESS: undefined,

  REFRESH_USER_DETAILS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getUsers, handlers.getUsers],
    [actions.getUsersFailure, handlers.getUsersFailure],
    [actions.getUsersSuccess, handlers.getUsersSuccess],

    [actions.getUserDetails, handlers.getUserDetails],
    [actions.getUserDetailsFailure, handlers.getUserDetailsFailure],
    [actions.getUserDetailsSuccess, handlers.getUserDetailsSuccess],

    [actions.createUser, handlers.createUser],
    [actions.createUserFailure, handlers.createUserFailure],
    [actions.createUserSuccess, handlers.createUserSuccess],

    [actions.updateUser, handlers.updateUser],
    [actions.updateUserFailure, handlers.updateUserFailure],
    [actions.updateUserSuccess, handlers.updateUserSuccess],

    [actions.setUserDetails, handlers.setUserDetails],

    [actions.activateUser, handlers.activateUser],
    [actions.activateUserFailure, handlers.activateUserFailure],
    [actions.activateUserSuccess, handlers.activateUserSuccess],

    [actions.deactivateUser, handlers.deactivateUser],
    [actions.deactivateUserFailure, handlers.deactivateUserFailure],
    [actions.deactivateUserSuccess, handlers.deactivateUserSuccess],

    [actions.getUserOrgs, handlers.getUserOrgs],
    [actions.getUserOrgsFailure, handlers.getUserOrgsFailure],
    [actions.getUserOrgsSuccess, handlers.getUserOrgsSuccess],

    [actions.getUserRegions, handlers.getUserRegions],
    [actions.getUserRegionsFailure, handlers.getUserRegionsFailure],
    [actions.getUserRegionsSuccess, handlers.getUserRegionsSuccess],

    [actions.getUserDistricts, handlers.getUserDistricts],
    [actions.getUserDistrictsFailure, handlers.getUserDistrictsFailure],
    [actions.getUserDistrictsSuccess, handlers.getUserDistrictsSuccess],

    [actions.getUserLocations, handlers.getUserLocations],
    [actions.getUserLocationsFailure, handlers.getUserLocationsFailure],
    [actions.getUserLocationsSuccess, handlers.getUserLocationsSuccess],

    [actions.associateUserWithRegion, handlers.associateUserWithRegion],
    [actions.associateUserWithRegionFailure, handlers.associateUserWithRegionFailure],
    [actions.associateUserWithRegionSuccess, handlers.associateUserWithRegionSuccess],

    [actions.disassociateUserWithRegion, handlers.disassociateUserWithRegion],
    [actions.disassociateUserWithRegionFailure, handlers.disassociateUserWithRegionFailure],
    [actions.disassociateUserWithRegionSuccess, handlers.disassociateUserWithRegionSuccess],

    [actions.demoteFromRegionAdmin, handlers.demoteFromRegionAdmin],
    [actions.demoteFromRegionAdminFailure, handlers.demoteFromRegionAdminFailure],
    [actions.demoteFromRegionAdminSuccess, handlers.demoteFromRegionAdminSuccess],

    [actions.promoteToRegionAdmin, handlers.promoteToRegionAdmin],
    [actions.promoteToRegionAdminFailure, handlers.promoteToRegionAdminFailure],
    [actions.promoteToRegionAdminSuccess, handlers.promoteToRegionAdminSuccess],

    [actions.associateUserWithDistrict, handlers.associateUserWithDistrict],
    [actions.associateUserWithDistrictFailure, handlers.associateUserWithDistrictFailure],
    [actions.associateUserWithDistrictSuccess, handlers.associateUserWithDistrictSuccess],

    [actions.disassociateUserWithDistrict, handlers.disassociateUserWithDistrict],
    [actions.disassociateUserWithDistrictFailure, handlers.disassociateUserWithDistrictFailure],
    [actions.disassociateUserWithDistrictSuccess, handlers.disassociateUserWithDistrictSuccess],

    [actions.demoteFromDistrictAdmin, handlers.demoteFromDistrictAdmin],
    [actions.demoteFromDistrictAdminFailure, handlers.demoteFromDistrictAdminFailure],
    [actions.demoteFromDistrictAdminSuccess, handlers.demoteFromDistrictAdminSuccess],

    [actions.promoteToDistrictAdmin, handlers.promoteToDistrictAdmin],
    [actions.promoteToDistrictAdminFailure, handlers.promoteToDistrictAdminFailure],
    [actions.promoteToDistrictAdminSuccess, handlers.promoteToDistrictAdminSuccess],

    [actions.updateUserDistrict, handlers.updateUserDistrict],
    [actions.updateUserDistrictFailure, handlers.updateUserDistrictFailure],
    [actions.updateUserDistrictSuccess, handlers.updateUserDistrictSuccess],

    [actions.associateUserWithLocation, handlers.associateUserWithLocation],
    [actions.associateUserWithLocationFailure, handlers.associateUserWithLocationFailure],
    [actions.associateUserWithLocationSuccess, handlers.associateUserWithLocationSuccess],

    [actions.disassociateUserWithLocation, handlers.disassociateUserWithLocation],
    [actions.disassociateUserWithLocationFailure, handlers.disassociateUserWithLocationFailure],
    [actions.disassociateUserWithLocationSuccess, handlers.disassociateUserWithLocationSuccess],

    [actions.demoteFromLocationAdmin, handlers.demoteFromLocationAdmin],
    [actions.demoteFromLocationAdminFailure, handlers.demoteFromLocationAdminFailure],
    [actions.demoteFromLocationAdminSuccess, handlers.demoteFromLocationAdminSuccess],

    [actions.promoteToLocationAdmin, handlers.promoteToLocationAdmin],
    [actions.promoteToLocationAdminFailure, handlers.promoteToLocationAdminFailure],
    [actions.promoteToLocationAdminSuccess, handlers.promoteToLocationAdminSuccess],

    [actions.refreshUserRegions, handlers.refreshUserRegions],
    [actions.refreshUserDistricts, handlers.refreshUserDistricts],
    [actions.refreshUserLocations, handlers.refreshUserLocations],
    [actions.getUserRegionsMember, handlers.getUserRegionsMember],
    [actions.getUserRegionsMemberFailure, handlers.getUserRegionsMemberFailure],
    [actions.getUserRegionsMemberSuccess, handlers.getUserRegionsMemberSuccess],

    [actions.getUserDistrictsMember, handlers.getUserDistrictsMember],
    [actions.getUserDistrictsMemberFailure, handlers.getUserDistrictsMemberFailure],
    [actions.getUserDistrictsMemberSuccess, handlers.getUserDistrictsMemberSuccess],

    [actions.getUserLocationsMember, handlers.getUserLocationsMember],
    [actions.getUserLocationsMemberFailure, handlers.getUserLocationsMemberFailure],
    [actions.getUserLocationsMemberSuccess, handlers.getUserLocationsMemberSuccess],

    [actions.refreshUserDetails, handlers.refreshUserDetails],
  ]),
  initialState,
);

export default reducer;
