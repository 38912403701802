import moment from 'moment';

export const checkDateStatus = (startDate, endDate) => {
  const currentDate = moment();

  if (currentDate.isBefore(startDate) && currentDate.isBefore(endDate)) {
    return ({
      status: 'upcoming',
      statusColor: '#229c26',
    });
  }

  if (currentDate.isAfter(startDate) && currentDate.isBefore(endDate)) {
    return ({
      status: 'active',
      statusColor: '#0f4fd1',
    });
  }

  if (currentDate.isAfter(startDate) && currentDate.isAfter(endDate)) {
    return ({
      status: 'past',
      statusColor: '#e07f10',
    });
  }

  return ({
    status: 'undefined',
    statusColor: '#1c1c1b',
  });
};

export default checkDateStatus;
