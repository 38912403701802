import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_CUSTOM_FIELDS: undefined,
  GET_CUSTOM_FIELDS_SUCCESS: undefined,
  GET_CUSTOM_FIELDS_FAILURE: undefined,

  REFRESH_CUSTOM_FIELDS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getCustomFields, handlers.getCustomFields],
    [actions.getCustomFieldsSuccess, handlers.getCustomFieldsSuccess],
    [actions.getCustomFieldsFailure, handlers.getCustomFieldsFailure],

    [actions.refreshCustomFields, handlers.refreshCustomFields],
  ]),
  initialState,
);

export default reducer;
