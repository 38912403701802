import { takeLatest, call, put } from 'redux-saga/effects';
import moment from 'moment';

import * as api from 'http/data-displays-filters';
import { actions as snackbarActions } from 'redux/snackbar';
import { actions } from './index';

const filterNotificationMessage = 'Filters successfully updated';

function* getFiltersByCollection({ payload }) {
  try {
    const data = yield call(api.getFiltersByCollection, payload);

    yield put(actions.getFiltersByCollectionSuccess(data));

    if (payload.callback) { payload.callback(); }
  } catch (error) {
    yield put(actions.getFiltersByCollectionFailure(error));
  }
}

function* createFilterByCollection({ payload }) {
  try {
    const { data: payloadData, successHandle, addInfo = {} } = payload;

    const data = yield call(
      api.createFiltersByCollection,
      {
        ...payloadData,
        fromDate: typeof payloadData.fromDate !== 'number' && moment(payloadData.fromDate).unix(),
        toDate: typeof payloadData.toDate !== 'number' && payloadData.fromDate && moment(payloadData.toDate).unix(),
      },
    );

    yield put(actions.createFilterByCollectionSuccess(data));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: data.message } }));
    yield put(actions.getFiltersByCollection({
      collectionId: payloadData.collectionId,
      addInfo,
    }));

    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.createFilterByCollectionFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getFilterById({ payload }) {
  try {
    const data = yield call(api.getFilter, payload);

    yield put(actions.getFilterByIdSuccess(data));
  } catch (error) {
    yield put(actions.getFilterByIdFailure(error));
  }
}

function* updateFilterById({ payload }) {
  try {
    const { data: payloadData, successHandle } = payload;

    const data = yield call(
      api.updateFilter,
      {
        ...payloadData,
        fromDate: typeof payloadData.fromDate !== 'number' && moment(payloadData.fromDate).unix(),
        toDate: typeof payloadData.toDate !== 'number' && moment(payloadData.toDate).unix(),
      },
    );

    yield put(actions.updateFilterByIdSuccess(data));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: filterNotificationMessage } }));

    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.updateFilterByIdFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* deleteFilterById({ payload }) {
  try {
    const { data: payloadData, successHandle, addInfo = {} } = payload;

    const data = yield call(api.deleteFilter, payloadData);

    yield put(actions.deleteFilterByIdSuccess(data));
    yield put(actions.getFiltersByCollection({
      collectionId: payloadData.collectionId,
      addInfo,
    }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: filterNotificationMessage } }));

    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.deleteFilterByIdFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.getFiltersByCollection, getFiltersByCollection);
  yield takeLatest(actions.createFilterByCollection, createFilterByCollection);
  yield takeLatest(actions.getFilterById, getFilterById);
  yield takeLatest(actions.updateFilterById, updateFilterById);
  yield takeLatest(actions.deleteFilterById, deleteFilterById);
}
