import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  MERGE_STUDENTS: undefined,
  MERGE_STUDENTS_SUCCESS: undefined,
  MERGE_STUDENTS_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.mergeStudents, handlers.mergeStudents],
    [actions.mergeStudentsSuccess, handlers.mergeStudentsSuccess],
    [actions.mergeStudentsFailure, handlers.mergeStudentsFailure],
  ]),
  initialState,
);

export default reducer;
