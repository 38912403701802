import {
  takeLatest,
  call,
  put,
  select,
} from 'redux-saga/effects';

import * as api from 'http/admin/announcements';

import { actions as snackbarActions } from 'redux/snackbar';
import { actions } from './index';

export function* getAnnouncements() {
  try {
    const { tenantId } = yield select((state) => state.auth);
    const result = yield call(api.getAnnouncements, { tenantsId: tenantId });

    yield put(actions.getAnnouncementsSuccess(result));
  } catch (error) {
    yield put(actions.getAnnouncementsFailure(error));
  }
}

export function* createAnnouncement({ payload }) {
  try {
    const { tenantId } = yield select((state) => state.auth);
    const { data: payloadData, successHandle } = payload;

    const result = yield call(api.createAnnouncement, { tenantsId: tenantId, ...payloadData });

    yield put(actions.createAnnouncementSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Announcement successfully created' } }));
    yield put(actions.getAnnouncements());

    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.createAnnouncementFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* updateAnnouncement({ payload }) {
  try {
    const { data: payloadData, successHandle } = payload;

    const result = yield call(api.updateAnnouncement, payloadData);

    yield put(actions.updateAnnouncementSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Announcement successfully updated' } }));
    yield put(actions.getAnnouncements());

    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.updateAnnouncementFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* deleteAnnouncement({ payload }) {
  try {
    const { data: payloadData, successHandle } = payload;

    const result = yield call(api.deleteAnnouncement, payloadData);

    yield put(actions.deleteAnnouncementSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Announcement successfully deleted' } }));
    yield put(actions.getAnnouncements());

    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.deleteAnnouncementFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* watchSagas() {
  yield takeLatest(actions.getAnnouncements, getAnnouncements);
  yield takeLatest(actions.createAnnouncement, createAnnouncement);
  yield takeLatest(actions.updateAnnouncement, updateAnnouncement);
  yield takeLatest(actions.deleteAnnouncement, deleteAnnouncement);
}
