const initialState = {
  data: [],
  isLoading: false,
  error: null,

  isRequestSent: false,
  isUpdateNeeded: false,
};

export const getReportsByTenant = (state) => ({
  ...state,
  isLoading: true,
  isUpdateNeeded: false,
});

export const getReportsByTenantSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload,
});

export const getReportsByTenantFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const requestReport = (state) => ({
  ...state,
  isRequestSent: true,
});

export const requestReportSuccess = (state) => ({
  ...state,
  isRequestSent: false,
  isUpdateNeeded: true,
});

export const requestReportFailure = (state, { payload }) => ({
  ...state,
  isRequestSent: false,
  error: payload,
});

export default initialState;
