import { FILTERS_TYPES } from 'commonConstants';
import { formatDate } from 'http/data-displays-filters/formatter';

const {
  DATA_FILTERS,
  VIEW_SETTINGS_FILTERS,
  VISIBLE_ITEMS_FILTERS,
  CUSTOM_LISTS_FILTERS,
  OTHER_FILTERS,
} = FILTERS_TYPES;

const initialState = {
  filters: {
    [DATA_FILTERS]: [],
    [VIEW_SETTINGS_FILTERS]: [],
    [VISIBLE_ITEMS_FILTERS]: [],
    [CUSTOM_LISTS_FILTERS]: [],
    [OTHER_FILTERS]: [],
  },
  selectedFilter: {},
  isLoading: false,
  error: null,
};

const updatedFilterFormatter = (filters, { id, metadata, ...data }) => {
  const result = { ...filters };
  if (metadata.filterType !== FILTERS_TYPES.VIEW_SETTINGS_FILTERS) {
    const indexFilter = result[metadata.filterType].findIndex((filter) => filter.id === id);

    result[FILTERS_TYPES[metadata.filterType]][indexFilter] = {
      id,
      ...data,
      ...metadata,
      fromDate: formatDate(metadata.fromDate),
      toDate: formatDate(metadata.toDate),
    };
  } else {
    result[FILTERS_TYPES[metadata.filterType]] = {
      id,
      ...data,
      ...metadata,
      fromDate: formatDate(metadata.fromDate),
      toDate: formatDate(metadata.toDate),
    };
  }

  return result;
};

export const getFiltersByCollection = (state) => ({
  ...state,
  isLoading: true,
});

export const getFiltersByCollectionSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  filters: payload,
});

export const getFiltersByCollectionFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const createFilterByCollection = (state) => ({
  ...state,
  isLoading: true,
});

export const createFilterByCollectionSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const createFilterByCollectionFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const getFilterById = (state) => ({
  ...state,
  isLoading: true,
});

export const getFilterByIdSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  filters: payload,
});

export const getFilterByIdFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const updateFilterById = (state) => ({
  ...state,
  isLoading: true,
});

export const updateFilterByIdSuccess = (state, { payload }) => {
  const {
    id,
    dataCollectionsId: collectionId,
    name,
    color,
    metadata,
  } = payload;

  return ({
    ...state,
    isLoading: false,
    filters: updatedFilterFormatter(state.filters, {
      id,
      collectionId,
      name,
      color,
      metadata: JSON.parse(metadata),
    }),
  });
};

export const updateFilterByIdFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const deleteFilterById = (state) => ({
  ...state,
  isLoading: true,
});

export const deleteFilterByIdSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const deleteFilterByIdFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const setSelectedFilter = (state, { payload }) => ({
  ...state,
  selectedFilter: payload,
});

export const updateSelectedFilter = (state, { payload }) => ({
  ...state,
  selectedFilter: {
    ...state.selectedFilter,
    ...payload,
  },
});

export const refreshDataDisplaysFilters = () => ({
  ...initialState,
});

export default initialState;
