import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_OTHER_TRANSFERS_BY_TENANT: undefined,
  GET_OTHER_TRANSFERS_BY_TENANT_SUCCESS: undefined,
  GET_OTHER_TRANSFERS_BY_TENANT_FAILURE: undefined,

  GET_USER_TRANSFERS_BY_TENANT: undefined,
  GET_USER_TRANSFERS_BY_TENANT_SUCCESS: undefined,
  GET_USER_TRANSFERS_BY_TENANT_FAILURE: undefined,

  GET_HISTORY_TRANSFERS_BY_TENANT: undefined,
  GET_HISTORY_TRANSFERS_BY_TENANT_SUCCESS: undefined,
  GET_HISTORY_TRANSFERS_BY_TENANT_FAILURE: undefined,

  CREATE_TRANSFER: undefined,
  CREATE_TRANSFER_SUCCESS: undefined,
  CREATE_TRANSFER_FAILURE: undefined,

  APPROVE_TRANSFER: undefined,
  APPROVE_TRANSFER_SUCCESS: undefined,
  APPROVE_TRANSFER_FAILURE: undefined,

  DECLINE_TRANSFER: undefined,
  DECLINE_TRANSFER_SUCCESS: undefined,
  DECLINE_TRANSFER_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getOtherTransfersByTenant, handlers.getTransfersByTenant],
    [actions.getOtherTransfersByTenantSuccess, handlers.getTransfersByTenantSuccess],
    [actions.getOtherTransfersByTenantFailure, handlers.getTransfersByTenantFailure],

    [actions.getUserTransfersByTenant, handlers.getTransfersByTenant],
    [actions.getUserTransfersByTenantSuccess, handlers.getTransfersByTenantSuccess],
    [actions.getUserTransfersByTenantFailure, handlers.getTransfersByTenantFailure],

    [actions.getHistoryTransfersByTenant, handlers.getTransfersByTenant],
    [actions.getHistoryTransfersByTenantSuccess, handlers.getTransfersByTenantSuccess],
    [actions.getHistoryTransfersByTenantFailure, handlers.getTransfersByTenantFailure],

    [actions.createTransfer, handlers.createTransfer],
    [actions.createTransferSuccess, handlers.createTransferSuccess],
    [actions.createTransferFailure, handlers.createTransferFailure],

    [actions.approveTransfer, handlers.approveTransfer],
    [actions.approveTransferSuccess, handlers.approveTransferSuccess],
    [actions.approveTransferFailure, handlers.approveTransferFailure],

    [actions.declineTransfer, handlers.declineTransfer],
    [actions.declineTransferSuccess, handlers.declineTransferSuccess],
    [actions.declineTransferFailure, handlers.declineTransferFailure],
  ]),
  initialState,
);

export default reducer;
