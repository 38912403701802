import {
  takeLatest, select, take, call, put,
} from 'redux-saga/effects';

import * as api from 'http/teacher';

import { actions as snackbarActions } from 'redux/snackbar';

import { actions } from './index';

const getItems = ({ teacher }) => teacher.assessment.items;

function* fetchAssessment({ payload: { data } }) {
  try {
    const result = yield call(api.getAssessment, data);

    yield put(actions.getAssessmentSuccess({ data: result }));
  } catch (error) {
    yield put(actions.getAssessmentError({ data: error }));
  }
}

function* fetchCollectionItems({ payload: { data } }) {
  try {
    const result = yield call(api.getCollectionItems, data);

    yield put(actions.getCollectionItemsSuccess({
      data: result.map((item) => ({
        ...item,
        assessmentItemHierarchyId: item.assessment_item_heirarchy_id,
        parentAssessmentItemsScalesDirectoryId: item.assessment_items_scales_directory_parent_id,
        parentDataCollectionSetsId: item.data_collection_sets_parent_id,
      })),
      collectionId: data,
    }));
  } catch (error) {
    yield put(actions.getCollectionItemsError({ data: error }));
  }
}

function* fetchItemMaterials({ payload: { collectionId } }) {
  try {
    let items = yield select(getItems);

    if (!items.length) {
      yield take(actions.getCollectionItemsSuccess);
      items = yield select(getItems);
    }

    const result = yield call(api.getItemMaterials, { collectionId });

    result?.forEach((r) => {
      const index = items.findIndex((i) => i.id === r.itemId);

      if (index === undefined) return;

      if (!items[index].materials) items[index].materials = [];

      if (items[index].materials.find((item) => item.id === r.id)) return;

      items[index].materials.push(r);
    });

    yield put(actions.getItemMaterialsSuccess({ data: items }));
  } catch (error) {
    yield put(actions.getItemMaterialsError({ data: error }));
  }
}

function* fetchCollectionItemsMaterials({ payload: collectionId }) {
  try {
    let items = yield select(getItems);

    if (!items.length) {
      yield take(actions.getCollectionItemsSuccess);
      items = yield select(getItems);
    }

    const resources = yield call(api.getItemMaterials, { collectionId });
    resources.forEach((r) => {
      const index = items.findIndex((item) => item.id === r.assessmentItemId);

      if (index + 1) {
        if (!items[index].materials) {
          items[index].materials = [];
        }
        items[index].materials.push(r);
      }
    });

    yield put(actions.getCollectionItemsMaterialsSuccess({ data: items }));
  } catch (error) {
    yield put(actions.getCollectionItemsMaterialsError({ data: error }));
  }
}

function* fetchIsrsReport({ payload: { collectionId, params } }) {
  try {
    yield call(api.getIsrsReport, { collectionId, params });

    yield put(actions.getIsrsReportResponse());
  } catch (error) {
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message || 'Something went wrong' } }));
    yield put(actions.getIsrsReportResponse({ data: error }));
  }
}

function* fetchRatingSheetsReport({ payload: collectionId }) {
  try {
    yield call(api.getRatingSheetsReport, collectionId);

    yield put(actions.getRatingSheetsReportResponse());
  } catch (error) {
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message || 'Something went wrong' } }));
    yield put(actions.getRatingSheetsReportResponse({ data: error }));
  }
}

function* fetchCollectionScales({ payload: { data } }) {
  try {
    const result = yield call(api.getCollectionScales, data);

    yield put(actions.getCollectionScalesSuccess(result));
  } catch (error) {
    yield put(actions.getCollectionScalesError(error));
  }
}

function* fetchCollectionSets({ payload: { data } }) {
  try {
    const result = yield call(api.getCollectionSets, data);

    yield put(actions.getCollectionSetsSuccess(result));
  } catch (error) {
    yield put(actions.getCollectionSetsError({ data: error }));
  }
}

function* fetchCollectionHierarchy({ payload: { data } }) {
  try {
    const result = yield call(api.getCollectionHierarchy, data);

    yield put(actions.getCollectionHierarchySuccess(result));
  } catch (error) {
    yield put(actions.getCollectionHierarchyError({ data: error }));
  }
}

function* fetchCollectionStudents({ payload: { data } }) {
  try {
    const result = yield call(api.getCollectionStudents, { data });

    yield put(actions.getCollectionStudentsSuccess(result));
  } catch (error) {
    yield put(actions.getCollectionStudentsError({ data: error }));
  }
}

function* fetchCollectionGrades({ payload: { data } }) {
  try {
    const result = yield call(api.getCollectionGrades, data);

    yield put(actions.getCollectionGradesSuccess({ data: result }));
  } catch (error) {
    yield put(actions.getCollectionGradesError({ data: error }));
  }
}

function* saveStudentAssignmentType({ payload }) {
  try {
    const { id, collectionId, assignmentType } = payload;
    yield call(api.putStudentAssessmentType, { id, assignmentType });

    yield put(actions.saveStudentAssignmentTypeSuccess());
    yield put(actions.teacherGetCollectionStudents({ data: collectionId }));
  } catch (error) {
    yield put(actions.saveStudentAssignmentTypeFailure(payload));
  }
}

export default function* watchSagas() {
  yield takeLatest(actions.teacherGetAssessment, fetchAssessment);
  yield takeLatest(actions.teacherGetCollectionItems, fetchCollectionItems);
  yield takeLatest(actions.getItemMaterialsRequest, fetchItemMaterials);
  yield takeLatest(actions.getCollectionItemsMaterialsRequest, fetchCollectionItemsMaterials);
  yield takeLatest(actions.getIsrsReportRequest, fetchIsrsReport);
  yield takeLatest(actions.getRatingSheetsReportRequest, fetchRatingSheetsReport);
  yield takeLatest(actions.teacherGetCollectionScales, fetchCollectionScales);
  yield takeLatest(actions.teacherGetCollectionSets, fetchCollectionSets);
  yield takeLatest(actions.teacherGetCollectionHierarchy, fetchCollectionHierarchy);
  yield takeLatest(actions.teacherGetCollectionStudents, fetchCollectionStudents);
  yield takeLatest(actions.teacherGetCollectionGrades, fetchCollectionGrades);
  yield takeLatest(actions.saveStudentAssignmentType, saveStudentAssignmentType);
}
