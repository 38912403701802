import { takeLatest, call, put } from 'redux-saga/effects';

import { actions as snackbarActions } from 'redux/snackbar';
import * as api from 'http/admin/bulkloads';

import { actions } from './index';

function* getBulkloadsByTenant({ payload }) {
  try {
    const { tenantId, params } = payload;
    const result = yield call(api.getBulkloadsByTenant, { tenantId, params });

    yield put(actions.getBulkloadsByTenantSuccess(result));
  } catch (error) {
    yield put(actions.getBulkloadsByTenantFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getBulkloadsAdminByTenant({ payload }) {
  try {
    const { tenantId } = payload;
    const result = yield call(api.getBulkloadsAdminByTenant, { tenantId });

    yield put(actions.getBulkloadsAdminByTenantSuccess(result));
  } catch (error) {
    yield put(actions.getBulkloadsAdminByTenantFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* uploadBulkload({ payload }) {
  try {
    const { tenantId, data } = payload;
    yield call(api.uploadBulkload, { tenantId, data });

    yield put(actions.uploadBulkloadSuccess());
  } catch (error) {
    yield put(actions.uploadBulkloadFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getBulkloadTemplate({ payload }) {
  try {
    yield call(api.getBulkloadTemplate, payload);

    yield put(actions.getBulkloadTemplateSuccess());
  } catch (error) {
    yield put(actions.getBulkloadTemplateFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* downloadBulkload({ payload }) {
  try {
    yield call(api.getBulkloadFile, payload);

    yield put(actions.downloadBulkloadSuccess());
  } catch (error) {
    yield put(actions.downloadBulkloadFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.getBulkloadsByTenant, getBulkloadsByTenant);
  yield takeLatest(actions.getBulkloadsAdminByTenant, getBulkloadsAdminByTenant);
  yield takeLatest(actions.uploadBulkload, uploadBulkload);
  yield takeLatest(actions.getBulkloadTemplate, getBulkloadTemplate);
  yield takeLatest(actions.downloadBulkload, downloadBulkload);
}
