import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const GET_DISTRICTS_BY_REGION = 'GET_DISTRICTS_BY_REGION';
export const GET_DISTRICTS_BY_REGION_SUCCESS = 'GET_DISTRICTS_BY_REGION_SUCCESS';
export const GET_DISTRICTS_BY_REGION_FAILURE = 'GET_DISTRICTS_BY_REGION_FAILURE';

export const CREATE_DISTRICT = 'CREATE_DISTRICT';
export const CREATE_DISTRICT_SUCCESS = 'CREATE_DISTRICT_SUCCESS';
export const CREATE_DISTRICT_FAILURE = 'CREATE_DISTRICT_FAILURE';

export const GET_DISTRICT_DETAILS = 'GET_DISTRICT_DETAILS';
export const GET_DISTRICT_DETAILS_SUCCESS = 'GET_DISTRICT_DETAILS_SUCCESS';
export const GET_DISTRICT_DETAILS_FAILURE = 'GET_DISTRICT_DETAILS_FAILURE';

export const UPDATE_DISTRICT = 'UPDATE_DISTRICT';
export const UPDATE_DISTRICT_SUCCESS = 'UPDATE_DISTRICT_SUCCESS';
export const UPDATE_DISTRICT_FAILURE = 'UPDATE_DISTRICT_FAILURE';

export const REFRESH_DISTRICT_DETAILS = 'REFRESH_DISTRICT_DETAILS';

export const GET_INTEGRATION_BY_DISTRICT = 'GET_INTEGRATION_BY_DISTRICT';
export const GET_INTEGRATION_BY_DISTRICT_SUCCESS = 'GET_INTEGRATION_BY_DISTRICT_SUCCESS';
export const GET_INTEGRATION_BY_DISTRICT_FAILURE = 'GET_INTEGRATION_BY_DISTRICT_FAILURE';

export const SAVE_INTEGRATION_BY_DISTRICT = 'SAVE_INTEGRATION_BY_DISTRICT';
export const SAVE_INTEGRATION_BY_DISTRICT_SUCCESS = 'SAVE_INTEGRATION_BY_DISTRICT_SUCCESS';
export const SAVE_INTEGRATION_BY_DISTRICT_FAILURE = 'SAVE_INTEGRATION_BY_DISTRICT_FAILURE';

export const GET_INTEGRATION_FTP_BY_DISTRICT = 'GET_INTEGRATION_FTP_BY_DISTRICT';
export const GET_INTEGRATION_FTP_BY_DISTRICT_SUCCESS = 'GET_INTEGRATION_FTP_BY_DISTRICT_SUCCESS';
export const GET_INTEGRATION_FTP_BY_DISTRICT_FAILURE = 'GET_INTEGRATION_FTP_BY_DISTRICT_FAILURE';

export const GET_INTEGRATION_USERS_BY_DISTRICT = 'GET_INTEGRATION_USERS_BY_DISTRICT';
export const GET_INTEGRATION_USERS_BY_DISTRICT_SUCCESS = 'GET_INTEGRATION_USERS_BY_DISTRICT_SUCCESS';
export const GET_INTEGRATION_USERS_BY_DISTRICT_FAILURE = 'GET_INTEGRATION_USERS_BY_DISTRICT_FAILURE';

export const REMOVE_INTEGRATION_BY_DISTRICT = 'REMOVE_INTEGRATION_BY_DISTRICT';
export const REMOVE_INTEGRATION_BY_DISTRICT_SUCCESS = 'REMOVE_INTEGRATION_BY_DISTRICT_SUCCESS';
export const REMOVE_INTEGRATION_BY_DISTRICT_FAILURE = 'REMOVE_INTEGRATION_BY_DISTRICT_FAILURE';

export const actions = createActions({
  [GET_DISTRICTS_BY_REGION]: undefined,
  [GET_DISTRICTS_BY_REGION_SUCCESS]: undefined,
  [GET_DISTRICTS_BY_REGION_FAILURE]: undefined,

  [CREATE_DISTRICT]: undefined,
  [CREATE_DISTRICT_SUCCESS]: undefined,
  [CREATE_DISTRICT_FAILURE]: undefined,

  [GET_DISTRICT_DETAILS]: undefined,
  [GET_DISTRICT_DETAILS_SUCCESS]: undefined,
  [GET_DISTRICT_DETAILS_FAILURE]: undefined,

  [UPDATE_DISTRICT]: undefined,
  [UPDATE_DISTRICT_SUCCESS]: undefined,
  [UPDATE_DISTRICT_FAILURE]: undefined,

  [REFRESH_DISTRICT_DETAILS]: undefined,

  [GET_INTEGRATION_BY_DISTRICT]: undefined,
  [GET_INTEGRATION_BY_DISTRICT_SUCCESS]: undefined,
  [GET_INTEGRATION_BY_DISTRICT_FAILURE]: undefined,

  [SAVE_INTEGRATION_BY_DISTRICT]: undefined,
  [SAVE_INTEGRATION_BY_DISTRICT_SUCCESS]: undefined,
  [SAVE_INTEGRATION_BY_DISTRICT_FAILURE]: undefined,

  [GET_INTEGRATION_FTP_BY_DISTRICT]: undefined,
  [GET_INTEGRATION_FTP_BY_DISTRICT_SUCCESS]: undefined,
  [GET_INTEGRATION_FTP_BY_DISTRICT_FAILURE]: undefined,

  [GET_INTEGRATION_USERS_BY_DISTRICT]: undefined,
  [GET_INTEGRATION_USERS_BY_DISTRICT_SUCCESS]: undefined,
  [GET_INTEGRATION_USERS_BY_DISTRICT_FAILURE]: undefined,

  [REMOVE_INTEGRATION_BY_DISTRICT]: undefined,
  [REMOVE_INTEGRATION_BY_DISTRICT_SUCCESS]: undefined,
  [REMOVE_INTEGRATION_BY_DISTRICT_FAILURE]: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getDistrictsByRegion, handlers.getDistrictsByRegion],
    [actions.getDistrictsByRegionSuccess, handlers.getDistrictsByRegionSuccess],
    [actions.getDistrictsByRegionFailure, handlers.getDistrictsByRegionFailure],

    [actions.createDistrict, handlers.createDistrict],
    [actions.createDistrictSuccess, handlers.createDistrictSuccess],
    [actions.createDistrictFailure, handlers.createDistrictFailure],

    [actions.getDistrictDetails, handlers.getDistrictDetails],
    [actions.getDistrictDetailsSuccess, handlers.getDistrictDetailsSuccess],
    [actions.getDistrictDetailsFailure, handlers.getDistrictDetailsFailure],

    [actions.updateDistrict, handlers.updateDistrict],
    [actions.updateDistrictSuccess, handlers.updateDistrictSuccess],
    [actions.updateDistrictFailure, handlers.updateDistrictFailure],

    [actions.refreshDistrictDetails, handlers.refreshDistrictDetails],

    [actions.getIntegrationByDistrict, handlers.getIntegrationByDistrict],
    [actions.getIntegrationByDistrictSuccess, handlers.getIntegrationByDistrictSuccess],
    [actions.getIntegrationByDistrictFailure, handlers.getIntegrationByDistrictFailure],

    [actions.saveIntegrationByDistrict, handlers.saveIntegrationByDistrict],
    [actions.saveIntegrationByDistrictSuccess, handlers.saveIntegrationByDistrictSuccess],
    [actions.saveIntegrationByDistrictFailure, handlers.saveIntegrationByDistrictFailure],

    [actions.getIntegrationFtpByDistrict, handlers.getIntegrationFtpByDistrict],
    [actions.getIntegrationFtpByDistrictSuccess, handlers.getIntegrationFtpByDistrictSuccess],
    [actions.getIntegrationFtpByDistrictFailure, handlers.getIntegrationFtpByDistrictFailure],

    [actions.getIntegrationUsersByDistrict, handlers.getIntegrationUsersByDistrict],
    [actions.getIntegrationUsersByDistrictSuccess, handlers.getIntegrationUsersByDistrictSuccess],
    [actions.getIntegrationUsersByDistrictFailure, handlers.getIntegrationUsersByDistrictFailure],

    [actions.removeIntegrationByDistrict, handlers.removeIntegrationByDistrict],
    [actions.removeIntegrationByDistrictSuccess, handlers.removeIntegrationByDistrictSuccess],
    [actions.removeIntegrationByDistrictFailure, handlers.removeIntegrationByDistrictFailure],
  ]),
  initialState,
);

export default reducer;
