import moment from 'moment';

const dateFormatter = (date) => (date ? moment.unix(date).format('MM/DD/YYYY h:mm:ss a z') : null);

export const bulkloadsFormatter = (bulkloads) => bulkloads.map((item) => ({
  ...item,
  dateRequested: dateFormatter(item.createdAt),
  dateCompleted: dateFormatter(item.dateCompleted),
  fileName: item.originalFileName,
}));

export const bulkloadsAdminFormatter = (bulkloads) => bulkloads.map((item) => ({
  ...item,
  dateRequested: dateFormatter(item.createdAt),
  dateCompleted: dateFormatter(item.dateCompleted),
  fileName: item.originalFileName,
  userName: item.username,
}));
