import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import apiGetData from 'http/common-data';

import { actions } from './index';

function* getData({ payload: { data, isAdminMode, type } }) {
  try {
    const result = yield call(apiGetData, { data, isAdminMode, type });

    yield put(actions.getDataSuccess(result));
  } catch (error) {
    yield put(actions.getDataError({ data: error }));
  }
}

export default function* watchSagas() {
  yield takeLatest(actions.getData, getData);
}
