import { combineReducers } from 'redux';

import announcements from './announcements';
import collections from './collections';
import jobStatus from './job-status';
import regions from './regions';
import districts from './districts';
import locations from './locations';
import students from './students';
import users from './users';
import reports from './reports';
import bulkloads from './bulkloads';
import archives from './archives';
import customFields from './custom-fields';
import merge from './merge';
import transfers from './transfers';

export default combineReducers({
  announcements,
  collections,
  jobStatus,
  regions,
  districts,
  locations,
  students,
  users,
  reports,
  bulkloads,
  archives,
  customFields,
  merge,
  transfers,
});
