import http from 'http/index';
import axios from 'axios';
import fileDownload from 'js-file-download';

import { cas3ApiRoute } from 'http/utility';
import { getEnv } from 'helpers/env';
import { reportsFormatter } from './formatter';

export const getReport = ({ type, priorityOnly, collectionId }) => http.post(`/user/collections/${collectionId}/reports.json?download=false`, {
  request_data: {
    type,
    priorityOnly,
    output: 'csv',
  },
});

export const getInfoSheet = ({ tenant }) => {
  const env = getEnv();
  return axios.get(
    `https://cas-tenant-resources-${env}.s3.amazonaws.com/${tenant.toLowerCase()}-info-sheet.pdf`,
    { responseType: 'arraybuffer' },
  ).then((res) => fileDownload(res.data, 'info-sheet.pdf', 'application/pdf'));
};

export const checkReportFile = ({ collectionId, reportId }) => (
  http.get(`/user/collections/${collectionId}/reports/${reportId}.json?download=false`)
);

export const getReports = (id) => http.get(
  cas3ApiRoute('/data-collections-outputs'),
  {
    params: { dataCollectionId: id },
  },
).then((res) => reportsFormatter(res.data.dataCollectionsOutputs));
