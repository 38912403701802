import downloadFile from 'helpers/downloadFile';
import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

import { bulkloadsFormatter, bulkloadsAdminFormatter } from './formatter';

export const getBulkloadsByTenant = ({ params }) => http.get(
  cas3ApiRoute('/bulk-load'),
  { params },
).then((res) => bulkloadsFormatter(res.data.bulkLoads || []));

export const getBulkloadsAdminByTenant = () => (
  http.get(cas3ApiRoute('/bulk-load'), {
    params: { administration: true },
  }).then((res) => bulkloadsAdminFormatter(res.data.bulkLoads || []))
);

export const uploadBulkload = ({ data }) => http.post(cas3ApiRoute('/bulk-load'), data);

export const getBulkloadTemplate = ({ type, getRequiredOnly }) => downloadFile(
  cas3ApiRoute(`/bulk-load/template?type=${type}&getRequiredOnly=${getRequiredOnly}`),
  `${type[0]}${type.slice(1).toLowerCase()}Template.csv`,
);

export const getBulkloadFile = ({ id, type, fileName }) => downloadFile(
  cas3ApiRoute(`/bulk-load/${id}/download/${type}`),
  fileName,
);
