import {
  takeLatest,
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import * as collectionsApi from 'http/admin/collections';

import { actions as snackbarActions } from 'redux/snackbar';
import { actions } from './index';

function* getCollectionFieldTests({ payload }) {
  try {
    const { tenantId, collectionId } = payload;

    const result = yield call(collectionsApi.getCollectionFieldTests, { tenantId, collectionId });

    yield put(actions.getCollectionFieldTestsSuccess(result));
  } catch (error) {
    yield put(actions.getCollectionFieldTestsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getCollectionAvailableFieldTests({ payload }) {
  try {
    const { tenantId, collectionId } = payload;

    const result = yield call(collectionsApi.getCollectionFieldTests, { tenantId, collectionId, params: { available: true } });

    yield put(actions.getCollectionAvailableFieldTestsSuccess(result));
  } catch (error) {
    yield put(actions.getCollectionAvailableFieldTestsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* assignLocationFieldTests({ payload }) {
  try {
    const {
      tenantId,
      collectionId,
      locationId,
      data,
      successHandle,
    } = payload;

    yield call(collectionsApi.assignLocationFieldTests, {
      tenantId,
      collectionId,
      locationId,
      data,
    });

    yield put(actions.assignLocationFieldTestsSuccess({ locationId }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Field Test Assigned for Location' } }));

    if (successHandle) successHandle();
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.assignLocationFieldTestsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'error' } }));
  }
}

function* unassignLocationFieldTests({ payload }) {
  try {
    const {
      tenantId,
      collectionId,
      locationId,
      successHandle,
    } = payload;

    yield call(collectionsApi.unassignLocationFieldTests, { tenantId, collectionId, locationId });

    yield put(actions.unassignLocationFieldTestsSuccess({ locationId }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Data Collection Assignments removed for Location' } }));

    if (successHandle) successHandle();
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.unassignLocationFieldTestsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* watchSagas() {
  yield takeLatest(actions.getCollectionFieldTests, getCollectionFieldTests);
  yield takeLatest(actions.getCollectionAvailableFieldTests, getCollectionAvailableFieldTests);
  yield takeEvery(actions.assignLocationFieldTests, assignLocationFieldTests);
  yield takeEvery(actions.unassignLocationFieldTests, unassignLocationFieldTests);
}
