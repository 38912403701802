import announcementsSaga from './announcements/sagas';
import collectionsSaga from './collections/sagas';
import jobStatusSaga from './job-status/sagas';
import regionsSaga from './regions/sagas';
import districtsSaga from './districts/sagas';
import locationsSaga from './locations/sagas';
import studentsSaga from './students/sagas';
import usersSaga from './users/sagas';
import reportsSaga from './reports/sagas';
import bulkloadsSaga from './bulkloads/sagas';
import archivesSaga from './archives/sagas';
import customFields from './custom-fields/sagas';
import mergeSaga from './merge/sagas';
import transfersSaga from './transfers/sagas';

export default function* watchSagas() {
  yield* announcementsSaga();
  yield* collectionsSaga();
  yield* jobStatusSaga();
  yield* regionsSaga();
  yield* districtsSaga();
  yield* locationsSaga();
  yield* studentsSaga();
  yield* usersSaga();
  yield* reportsSaga();
  yield* bulkloadsSaga();
  yield* archivesSaga();
  yield* customFields();
  yield* mergeSaga();
  yield* transfersSaga();
}
