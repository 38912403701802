/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import Select, { components, Creatable } from 'react-select';

import PropTypes from 'prop-types';

import * as S from './styled';

const getOption = (option) => ({
  value: option?.value || option,
  label: (option?.label || option).toString(),
});

const convertToOptionType = (options) => options.map((item) => {
  if (item?.options) {
    item.options = item.options.map(getOption);
    return item;
  }
  return getOption(item);
});

const getSelectOptions = (options) => options.reduce((items, item) => {
  if (item?.options?.map) return [...items, ...item.options.map((option) => getOption(option))];
  return [...items, getOption(item)];
}, []);

const getSelectValue = (value, options = [], multi = false) => {
  let result;

  if (value && multi && Array.isArray(value)) {
    result = value.map((valueItem) => {
      if (valueItem.value && valueItem.label) {
        return ({
          value: valueItem.value,
          label: valueItem.label,
        });
      }

      const currentOption = options.find((option) => option.value === valueItem);

      return ({
        value: valueItem,
        label: currentOption ? currentOption.label : valueItem,
      });
    });
  } else if (value) {
    const currentOption = options.find((option) => option.value === value);

    result = {
      value,
      label: currentOption ? currentOption.label : value,
    };
  } else {
    result = null;
  }
  return result;
};

const OutlinedControl = (controlProps) => (
  <>
    <S.InputLabel
      shrink={controlProps.hasValue || controlProps.isFocused}
      focused={controlProps.isFocused}
      disabled={controlProps.isDisabled}
      variant="outlined"
      required={controlProps.selectProps.required}
      error={controlProps.selectProps.error}
    >
      {controlProps.selectProps.label}

    </S.InputLabel>
    <components.Control {...controlProps} />
  </>
);

const formatGroupLabel = (data) => (
  <span style={S.groupStyles}>{data.label}</span>
);

const ReactSelect = ({
  isDisabled,
  onChanged,
  options,
  value,
  multi,
  label,
  placeholder,
  outlined,
  maxMenuHeight,
  isSearchable,
  name,
  error,
  helperText,
  isCreatableSelect,
  isClearable,
  required,
  ...props
}) => {
  const convertedSelectOptions = useMemo(() => convertToOptionType(options), [options]);
  const selectOptions = useMemo(() => getSelectOptions(options), [options]);

  const selectValue = getSelectValue(value, selectOptions, multi);

  return (
    <S.SelectContainer error={error} {...props}>
      {!outlined && <S.StyledLabel htmlFor="">{label}</S.StyledLabel>}
      {!isCreatableSelect ? (
        <Select
          components={{ Control: outlined ? OutlinedControl : components.Control }}
          classNamePrefix="react-select"
          isMulti={multi}
          isDisabled={isDisabled}
          value={selectValue}
          onChange={onChanged}
          options={convertedSelectOptions}
          isSearchable={isSearchable}
          styles={S.optionsStyles}
          formatGroupLabel={formatGroupLabel}
          placeholder={outlined ? '' : placeholder}
          label={label}
          name={name}
          maxMenuHeight={maxMenuHeight}
          required={required}
          error={error}
          isClearable={isClearable}
        />
      ) : (
        <Creatable
          components={{ Control: outlined ? OutlinedControl : components.Control }}
          classNamePrefix="react-select"
          isMulti={multi}
          isDisabled={isDisabled}
          value={selectValue}
          onChange={onChanged}
          options={convertedSelectOptions}
          isSearchable={isSearchable}
          styles={S.optionsStyles}
          formatGroupLabel={formatGroupLabel}
          placeholder={outlined ? '' : placeholder}
          label={label}
          name={name}
          maxMenuHeight={maxMenuHeight}
          required={required}
          error={error}
          isClearable={isClearable}
        />
      )}
      {error && <S.FormHelperText error>{helperText}</S.FormHelperText>}
    </S.SelectContainer>
  );
};

ReactSelect.defaultProps = {
  multi: false,
  isDisabled: false,
  outlined: false,
  label: '',
  name: '',
  placeholder: 'Select...',
  maxMenuHeight: 500,
  isSearchable: true,
  error: false,
  helperText: '',
  isCreatableSelect: false,
  isClearable: false,
  required: false,
};

ReactSelect.propTypes = {
  label: PropTypes.node,
  multi: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  name: PropTypes.string,
  outlined: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.any, // eslint-disable-line
  onChanged: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isCreatableSelect: PropTypes.bool,
  isClearable: PropTypes.bool,
  required: PropTypes.bool,
};

export default ReactSelect;
