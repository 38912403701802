const initialState = {
  isLoading: false,
  groups: [],
  error: '',
  selectedItemIndex: null,
  selectedItemForPanel: null,
  selectedStudentIndex: null,
  selectedItemToggle: null,
  itemSlideOut: null,
  selectedEventSource: null,
  filter: {
    groups: [],
    priority: false,
    selectedHierarchyIndex: [],
    sets: [],
  },
  selectedHierarchyIndex: [],
  isArtifactsModalOpen: false,
  loadingArtifacts: false,
  savingArtifacts: false,
  selectedGrade: null,
  artifacts: [],
};

export const getGroupsRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getGroupsError = (state) => ({
  ...state,
  isLoading: false,
});

export const getGroupsSuccess = (state, { payload }) => ({
  ...state,
  groups: payload,
  isLoading: false,
});

export const postStudentGrade = (state) => ({
  ...state,
  isLoading: true,
});

export const postStudentGradeError = (state) => ({
  ...state,
  isLoading: false,
});

export const postStudentGradeSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const deleteStudentGradeRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const deleteStudentGradeError = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deleteStudentGradeSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const spreadsheetSelectItem = (state) => ({
  ...state,
});

export const spreadsheetItemSelected = (state, { payload }) => ({
  ...state,
  selectedItemIndex: payload.itemId,
  selectedItemForPanel: payload.forExpansionPanel ? payload.itemId : state.selectedItemForPanel,
  selectedItemToggle: !state.selectedItemToggle,
  selectedEventSource: payload.selectedEventSource,
});

export const spreadsheetItemSelectedToggle = (state, { payload }) => ({
  ...state,
  selectedItemToggle: payload.selectedItemToggle,
});

export const spreadsheetItemSlideOut = (state, { payload }) => ({
  ...state,
  itemSlideOut: payload.itemSlideOut,
});

export const spreadsheetSelectStudent = (state) => ({
  ...state,
});

export const spreadsheetStudentSelected = (state, { payload }) => ({
  ...state,
  selectedStudentIndex: payload.data,
});

export const setPriority = (state, { payload }) => ({
  ...state,
  filter: {
    ...state.filter,
    priority: payload,
  },
});

export const selectGroup = (state, { payload }) => ({
  ...state,
  filter: {
    ...state.filter,
    groups: payload,
  },
});
export const openArtifactsModal = (state, { payload }) => ({
  ...state,
  isArtifactsModalOpen: true,
  loadingArtifacts: true,
  selectedGrade: payload,
});

export const openArtifactsModalSuccess = (state, { payload }) => ({
  ...state,
  loadingArtifacts: false,
  artifacts: payload.data,
});

export const openArtifactsModalError = (state, { payload }) => ({
  ...state,
  error: payload.error,
});

export const saveArtifactsModal = (state) => ({
  ...state,
  savingArtifacts: true,
});

export const saveArtifactsModalSuccess = (state) => ({
  ...state,
  savingArtifacts: false,
});

export const saveArtifactsModalError = (state) => ({
  ...state,
  savingArtifacts: false,
});

export const closeArtifactsModal = (state) => ({
  ...state,
  isArtifactsModalOpen: false,
  loadingArtifacts: false,
  selectedGrade: null,
  artifacts: [],
});

export const spreadsheetSelectHierarchy = (state, { payload }) => ({
  ...state,
  filter: {
    ...state.filter,
    selectedHierarchyIndex: payload,
  },
});

export const spreadsheetSelectSet = (state, { payload }) => ({
  ...state,
  filter: {
    ...state.filter,
    sets: payload,
  },
});

export const setInitialState = () => ({
  ...initialState,
});

export const spreadsheetHierarchySelected = (state, { payload }) => ({
  ...state,
  selectedHierarchyIndex: payload.data,
});

export default initialState;
