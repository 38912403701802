const initialState = {
  authenticated: false,
  jwt: null,
  checkingAuth: false,
  loading: false,
  loginError: '',
  roles: [],
  features: [],
  username: null,
  id: null,
  activeRole: null,
  tenantId: null,
  tenantLogo: '',
  tenantName: '',
  csrfToken: null,
  savingProfile: false,
  resolutionMode: '',
  resetPasswordError: '',
  forgotPasswordError: '',
  forgotSucces: false,
  welcomeMessage: '',
  ruleset: '',
  configuration: '',
  roleHierarchy: [],
  impersonateMode: false,
};

export const logout = (state) => ({
  ...state,
  loading: true,
  authenticated: false,
  loginError: '',
});

export const logoutSuccess = () => ({
  ...initialState,
});

export const clearSuccessFlag = (state) => ({
  ...state,
  forgotSucces: false,
});

export const setActiveRole = (state, { payload }) => ({ ...state, activeRole: payload.data });

export const getCurrentUser = () => ({ ...initialState, checkingAuth: true });

export const getCurrentUserError = (state) => ({
  ...state,
  loading: false,
  authenticated: false,
  checkingAuth: false,
});

export const login = (state) => ({
  ...state,
  loading: true,
  authenticated: false,
});

export const loginError = (state) => ({
  ...state,
  loading: false,
  authenticated: false,
  loginError: 'Invalid username or password',
});

export const loginSuccess = (state, { payload }) => ({
  ...state,
  checkingAuth: false,
  loading: false,
  authenticated: true,
  loginError: '',
  ...payload,
});

export const getCsrfToken = (state) => ({
  ...state,
  csrfToken: null,
});

export const getCsrfTokenFail = (state) => ({
  ...state,
  csrfToken: null,
});

export const getCsrfTokenSuccess = (state, { payload }) => ({
  ...state,
  csrfToken: payload.data,
});

export const tentantConfigurationFail = (state) => ({
  ...state,
  tenantLogo: initialState.tenantLogo,
  tenantName: initialState.tenantName,
});

export const tentantConfigurationSuccess = (state, { payload }) => ({
  ...state,
  tenantLogo: payload.data.logoUrl,
  tenantName: payload.data.nameLabel,
});

export const saveProfileRequest = (state) => ({
  ...state,
  savingProfile: true,
});

export const saveProfileSuccess = (state) => ({
  ...state,
  savingProfile: false,
});

export const saveProfileFailure = (state) => ({
  ...state,
  savingProfile: false,
});

export const setResolutionMode = (state, { payload }) => ({
  ...state,
  resolutionMode: payload,
});

export const resetPasswordRequest = () => ({
  ...initialState,
  loading: true,
});

export const resetPasswordSuccess = () => ({
  ...initialState,
  loading: false,
});

export const resetPasswordFailure = (state, { payload }) => ({
  ...initialState,
  loading: false,
  resetPasswordError: payload,
});

export const forgotPasswordRequest = (state, { payload: { isAuthorized } }) => ({
  ...(isAuthorized ? state : initialState),
  authenticated: isAuthorized,
  loading: true,
  forgotPasswordError: '',
  forgotSucces: false,
});

export const forgotPasswordSuccess = (state, { payload: { isAuthorized } }) => ({
  ...(isAuthorized ? state : initialState),
  authenticated: isAuthorized,
  loading: false,
  forgotSucces: true,
});

export const forgotPasswordFailure = (state, { payload: { error, isAuthorized } }) => ({
  ...(isAuthorized ? state : initialState),
  authenticated: isAuthorized,
  loading: false,
  forgotPasswordError: error,
  forgotSucces: false,
});

export const impersonateRequest = () => ({
  ...initialState,
  loading: true,
});

export const impersonateRequestSuccess = (state, { payload }) => ({
  ...state,
  checkingAuth: false,
  loading: false,
  authenticated: true,
  loginError: '',
  ...payload,
});

export const impersonateRequestFailure = (state, { payload }) => ({
  ...initialState,
  loading: false,
  loginError: payload,
});

export default initialState;
