import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_COLLECTION_ASSESSMENT_ITEM_SETS: undefined,
  GET_COLLECTION_ASSESSMENT_ITEM_SETS_FAILURE: undefined,
  GET_COLLECTION_ASSESSMENT_ITEM_SETS_SUCCESS: undefined,

  POST_COLLECTION_ASSESSMENT_ITEM_SET: undefined,
  POST_COLLECTION_ASSESSMENT_ITEM_SET_FAILURE: undefined,
  POST_COLLECTION_ASSESSMENT_ITEM_SET_SUCCESS: undefined,

  PUT_COLLECTION_ASSESSMENT_ITEM_SET: undefined,
  PUT_COLLECTION_ASSESSMENT_ITEM_SET_FAILURE: undefined,
  PUT_COLLECTION_ASSESSMENT_ITEM_SET_SUCCESS: undefined,

  DELETE_COLLECTION_ASSESSMENT_ITEM_SET: undefined,
  DELETE_COLLECTION_ASSESSMENT_ITEM_SET_FAILURE: undefined,
  DELETE_COLLECTION_ASSESSMENT_ITEM_SET_SUCCESS: undefined,

  GET_COLLECTION_ASSESSMENT_SET_ITEMS: undefined,
  GET_COLLECTION_ASSESSMENT_SET_ITEMS_FAILURE: undefined,
  GET_COLLECTION_ASSESSMENT_SET_ITEMS_SUCCESS: undefined,

  POST_COLLECTION_ASSESSMENT_SET_ITEM: undefined,
  POST_COLLECTION_ASSESSMENT_SET_ITEM_FAILURE: undefined,
  POST_COLLECTION_ASSESSMENT_SET_ITEM_SUCCESS: undefined,

  PUT_COLLECTION_ASSESSMENT_SET_ITEM: undefined,
  PUT_COLLECTION_ASSESSMENT_SET_ITEM_FAILURE: undefined,
  PUT_COLLECTION_ASSESSMENT_SET_ITEM_SUCCESS: undefined,

  DELETE_COLLECTION_ASSESSMENT_SET_ITEM: undefined,
  DELETE_COLLECTION_ASSESSMENT_SET_ITEM_FAILURE: undefined,
  DELETE_COLLECTION_ASSESSMENT_SET_ITEM_SUCCESS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getCollectionAssessmentItemSets, handlers.getCollectionAssessmentItemSets],
    [actions.getCollectionAssessmentItemSetsFailure, handlers.getCollectionAssessmentItemSetsFailure],
    [actions.getCollectionAssessmentItemSetsSuccess, handlers.getCollectionAssessmentItemSetsSuccess],

    [actions.postCollectionAssessmentItemSet, handlers.postCollectionAssessmentItemSet],
    [actions.postCollectionAssessmentItemSetFailure, handlers.postCollectionAssessmentItemSetFailure],
    [actions.postCollectionAssessmentItemSetSuccess, handlers.postCollectionAssessmentItemSetSuccess],

    [actions.putCollectionAssessmentItemSet, handlers.putCollectionAssessmentItemSet],
    [actions.putCollectionAssessmentItemSetFailure, handlers.putCollectionAssessmentItemSetFailure],
    [actions.putCollectionAssessmentItemSetSuccess, handlers.putCollectionAssessmentItemSetSuccess],

    [actions.deleteCollectionAssessmentItemSet, handlers.deleteCollectionAssessmentItemSet],
    [actions.deleteCollectionAssessmentItemSetFailure, handlers.deleteCollectionAssessmentItemSetFailure],
    [actions.deleteCollectionAssessmentItemSetSuccess, handlers.deleteCollectionAssessmentItemSetSuccess],

    [actions.getCollectionAssessmentSetItems, handlers.getCollectionAssessmentSetItems],
    [actions.getCollectionAssessmentSetItemsFailure, handlers.getCollectionAssessmentSetItemsFailure],
    [actions.getCollectionAssessmentSetItemsSuccess, handlers.getCollectionAssessmentSetItemsSuccess],

    [actions.postCollectionAssessmentSetItem, handlers.postCollectionAssessmentSetItem],
    [actions.postCollectionAssessmentSetItemFailure, handlers.postCollectionAssessmentSetItemFailure],
    [actions.postCollectionAssessmentSetItemSuccess, handlers.postCollectionAssessmentSetItemSuccess],

    [actions.putCollectionAssessmentSetItem, handlers.putCollectionAssessmentSetItem],
    [actions.putCollectionAssessmentSetItemFailure, handlers.putCollectionAssessmentSetItemFailure],
    [actions.putCollectionAssessmentSetItemSuccess, handlers.putCollectionAssessmentSetItemSuccess],

    [actions.deleteCollectionAssessmentSetItem, handlers.deleteCollectionAssessmentSetItem],
    [actions.deleteCollectionAssessmentSetItemFailure, handlers.deleteCollectionAssessmentSetItemFailure],
    [actions.deleteCollectionAssessmentSetItemSuccess, handlers.deleteCollectionAssessmentSetItemSuccess],
  ]),
  initialState,
);

export default reducer;
