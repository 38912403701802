import React, { useEffect, useState, Suspense } from 'react';
import { PropTypes } from 'react-proptypes';
import { withTranslation } from 'react-i18next';
import { lazy } from '@loadable/component';
import { connect, useDispatch } from 'react-redux';
import { wasRedirectedFromCAS4 } from 'helpers/cas4/persistence';

import { Location, Redirect } from '@reach/router';
import i18n from 'i18next';

import CssBaseline from '@material-ui/core/CssBaseline';

import { actions } from 'src/redux/auth';
import { actions as snackbarActions } from 'redux/snackbar';

// Page elements
import {
  FadeTransitionRouter, Header, Skiplinks, Snackbar, Downloads,
} from 'src/components';
import Wrapper from 'src/modules/Teacher/components/Wrapper';
import Loading from 'src/components/Loading';

import experimentalFeatures from 'src/helpers/expermentalFeatures';

import { NoRoleWarning } from './styled';
// Pages
import { ForgotRouter } from '../Authentication';

const NoRole = () => (
  <NoRoleWarning data-testid="no-role-warning">
    {i18n.t('Should you be here?')}
    <br />
    {i18n.t('Please enter a valid address')}
  </NoRoleWarning>
);

// On demand load the Admin app when it is needed, just to reduce package size a bit.
const AsyncSystemApp = lazy(() => import('src/modules/System'));

// On demand load the Admin app when it is needed, just to reduce package size a bit.
const AsyncAdministratorApp = lazy(() => import('src/modules/Administrator/'));

// On demand load the Teacher app when it is needed, just to reduce package size a bit.
const AsyncTeacherApp = lazy(() => import('src/modules/Teacher'));

// On demand load the Profile app when it is needed, just to reduce package size a bit.
const AsyncProfileApp = lazy(() => import('src/modules/Profile'));

const isForgot = /^(\/forgot)$/;
const isDownloads = /^(\/downloads)|^(\/)$/;
const isBeta = /^(\/beta)|^(\/)$/;
const isLegacy = /^(\/legacy)|^(\/)$/;
const isLegacyStop = /^(\/legacy-stop)|^(\/)$/;
const isProfile = /^(\/profile)|^(\/)$/;
const isTeacher = /^(\/teacher)|^(\/)$/;
const isSystem = /^(\/system)|^(\/)$/;
const isAdmin = /^(\/administrator)|^(\/)$/;

const redirectUserToDefaultApp = (pathname) => {
  const dispatch = useDispatch();

  dispatch(snackbarActions.showSnackbar({
    data: { type: 'error', message: i18n.t(`You don't have enough permissions to view '${pathname}' page`) },
  }));

  return <Redirect noThrow to="/" />;
};
/**
 * A helper so that lazy loaded routes can easily be wrapped by Suspense
 *
 * @param {string[]} roles - list of logged in user roles
 * @param {string[]} features - list of feature flags the user has
 * @param {boolean} menuOpen - determines if the menu is open or not
 * @param {boolean} toggleSidebar - determines if the sidebar is open or not
 * @param {object} location - location object from Reach Router to do path inspection
 * @param {object} dispatch - redux dispach function for updating application state
 * @returns {JSX.Element|null} - Lazy loaded routes if the path is for one of those routes
 * @constructor
 */
const LazyLoadedRoutes = ({
  roles, features, menuOpen, toggleSidebar, location, dispatch,
}) => {
  if (isTeacher.test(location.pathname) && roles.includes('ROLE_TEACHER')) {
    dispatch(actions.setActiveRole({ data: 'ROLE_TEACHER' }));
    return <AsyncTeacherApp />;
  }

  if (isAdmin.test(location.pathname) && roles.includes('ROLE_ADMIN')) {
    dispatch(actions.setActiveRole({ data: 'ROLE_ADMIN' }));
    return <AsyncAdministratorApp features={features} roles={roles} menuOpen={menuOpen} toggleSidebar={toggleSidebar} />;
  }

  if (isSystem.test(location.pathname) && roles.includes('ROLE_SYSTEM_ADMIN')) {
    dispatch(actions.setActiveRole({ data: 'ROLE_SYSTEM_ADMIN' }));
    return <AsyncSystemApp roles={roles} menuOpen={menuOpen} toggleSidebar={toggleSidebar} />;
  }

  // if it doesn't match the last route, return null
  return isProfile.test(location.pathname) ? (
    <Wrapper path="profile">
      <AsyncProfileApp />
    </Wrapper>
  ) : redirectUserToDefaultApp(location.pathname);
};

/**
 * A helper for rendering different route paths
 *
 * @param {boolean} authenticated - whether or not the current user is authenticated
 * @param {boolean} checkingAuth - whether or not the app is checking logged in state
 * @param {string[]} roles - list of logged in user roles
 * @param {string[]} features - list of feature flags the user has
 * @param {boolean} menuOpen - determines if the menu is open or not
 * @param {boolean} toggleSidebar - determines if the sidebar is open or not
 * @param {object} location - location object from Reach Router to do path inspection
 * @param {object} dispatch - redux dispach function for updating application state
 * @returns {JSX.Element|null} - the react element for the current route
 * @constructor
 */
const renderRoutes = ({
  authenticated, checkingAuth, roles, features, menuOpen, toggleSidebar, location, dispatch,
}) => {
  if (checkingAuth) {
    return <Loading title="Verifying your session, please wait..." />;
  }

  if (!authenticated) {
    return <Loading title="Creating new session..." />;
  }

  if (isForgot.test(location.pathname)) return <ForgotRouter />;

  // for lazy loaded routes, the main portions of the app
  if (isTeacher.test(location.pathname)
      || isAdmin.test(location.pathname)
      || isSystem.test(location.pathname)
      || isProfile.test(location.pathname)
  ) {
    return (
      <Suspense fallback={<Loading />}>
        <LazyLoadedRoutes
          roles={roles}
          features={features}
          menuOpen={menuOpen}
          toggleSidebar={toggleSidebar}
          location={location}
          dispatch={dispatch}
        />
      </Suspense>
    );
  }

  /*
   * Handles putting the site in beta mode, legacy mode, letting it fallback to environmental settings
   */
  if (isBeta.test(location.pathname)) {
    experimentalFeatures.putSiteInBetaMode();
    window.location = '/';
  }

  if (isLegacy.test(location.pathname)) {
    experimentalFeatures.putSiteInLegacyMode();
    window.location = '/';
  }

  if (isLegacyStop.test(location.pathname)) {
    experimentalFeatures.removeLegacyFlag();
    window.location = '/';
  }

  if (isDownloads.test(location.pathname)) {
    return (
      <Wrapper path="downloads">
        <Downloads default />
      </Wrapper>
    );
  }

  return (
    <FadeTransitionRouter>
      <NoRole style={{ paddingTop: '5rem' }} path="*" />
    </FadeTransitionRouter>
  );
};

const App = (props) => {
  const { authenticated, dispatch, resolutionMode } = props;

  // For controlling the administrator sidebar in small screens.
  // We need to communicate the prop to a few places, which is why it's at this level.
  const [menuOpen, setMenuOpen] = useState(true);

  const toggleSidebar = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    // Try authorizing the current user if they have a valid session still...
    if (!wasRedirectedFromCAS4()) {
      console.debug('Fetching current user...'); // eslint-disable-line
      dispatch(actions.getCurrentUser());
    }
  }, []);

  useEffect(() => {
    // Close administrator sidebar when resolution less than desktop
    if (resolutionMode && resolutionMode !== 'desktop') setMenuOpen(false);
  }, [resolutionMode]);

  return (
    <>
      <Skiplinks />
      <CssBaseline />
      <Snackbar />
      {authenticated ? <Header toggleSidebar={toggleSidebar} /> : null}
      <Location>
        {
          ({ location }) => renderRoutes({
            ...props,
            menuOpen,
            location,
            toggleSidebar,
          })
        }
      </Location>
    </>
  );
};

FadeTransitionRouter.propTypes = {
  children: PropTypes.node.isRequired,
};

LazyLoadedRoutes.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  features: PropTypes.array.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  menuOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

renderRoutes.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  checkingAuth: PropTypes.bool.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  features: PropTypes.array.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  menuOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

App.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  resolutionMode: PropTypes.string.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  roles: auth.roles,
  features: auth.features,
  authenticated: auth.authenticated,
  checkingAuth: auth.checkingAuth,
  activeRole: auth.activeRole,
  resolutionMode: auth.resolutionMode,
});

export default connect(
  mapStateToProps,
  (dispatch) => ({ dispatch }),
)(withTranslation()(App));
