const initialState = {
  data: [],
  currentAnnouncement: {},
  isLoading: false,
  error: null,
};

export const getAnnouncements = (state) => ({
  ...state,
  isLoading: true,
});

export const getAnnouncementsFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: [],
  error: payload,
});

export const getAnnouncementsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload,
  error: null,
});

export const setAnnouncementDetails = (state, { payload }) => ({
  ...state,
  currentAnnouncement: payload,
});

export const createAnnouncement = (state) => ({
  ...state,
  isLoading: true,
});

export const createAnnouncementSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const createAnnouncementFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const updateAnnouncement = (state) => ({
  ...state,
  isLoading: true,
});

export const updateAnnouncementSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const updateAnnouncementFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const deleteAnnouncement = (state) => ({
  ...state,
  isLoading: true,
});

export const deleteAnnouncementSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const deleteAnnouncementFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export default initialState;
