import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const FETCH_COLLECTIONS = 'FETCH_COLLECTIONS';
export const FETCH_COLLECTIONS_FAIL = 'FETCH_COLLECTIONS_FAIL';
export const FETCH_COLLECTIONS_SUCCESS = 'FETCH_COLLECTIONS_SUCCESS';

export const GET_COLLECTIONS_LIST = 'GET_COLLECTIONS_LIST';
export const GET_COLLECTIONS_LIST_FAILURE = 'GET_COLLECTIONS_LIST_FAILURE';
export const GET_COLLECTIONS_LIST_SUCCESS = 'GET_COLLECTIONS_LIST_SUCCESS';

export const GET_COLLECTIONS_BY_REGION = 'GET_COLLECTIONS_BY_REGION';
export const GET_COLLECTIONS_BY_REGION_FAILURE = 'GET_COLLECTIONS_BY_REGION_FAILURE';
export const GET_COLLECTIONS_BY_REGION_SUCCESS = 'GET_COLLECTIONS_BY_REGION_SUCCESS';

export const GET_COLLECTIONS_BY_DISTRICT = 'GET_COLLECTIONS_BY_DISTRICT';
export const GET_COLLECTIONS_BY_DISTRICT_FAILURE = 'GET_COLLECTIONS_BY_DISTRICT_FAILURE';
export const GET_COLLECTIONS_BY_DISTRICT_SUCCESS = 'GET_COLLECTIONS_BY_DISTRICT_SUCCESS';

export const GET_COLLECTIONS_BY_LOCATION = 'GET_COLLECTIONS_BY_LOCATION';
export const GET_COLLECTIONS_BY_LOCATION_FAILURE = 'GET_COLLECTIONS_BY_LOCATION_FAILURE';
export const GET_COLLECTIONS_BY_LOCATION_SUCCESS = 'GET_COLLECTIONS_BY_LOCATION_SUCCESS';

export const GET_COLLECTION_DETAILS = 'GET_COLLECTION_DETAILS';
export const GET_COLLECTION_DETAILS_FAILURE = 'GET_COLLECTION_DETAILS_FAILURE';
export const GET_COLLECTION_DETAILS_SUCCESS = 'GET_COLLECTION_DETAILS_SUCCESS';

export const CREATE_COLLECTION = 'CREATE_COLLECTION';
export const CREATE_COLLECTION_FAILURE = 'CREATE_COLLECTION_FAILURE';
export const CREATE_COLLECTION_SUCCESS = 'CREATE_COLLECTION_SUCCESS';

export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const UPDATE_COLLECTION_FAILURE = 'UPDATE_COLLECTION_FAILURE';
export const UPDATE_COLLECTION_SUCCESS = 'UPDATE_COLLECTION_SUCCESS';

export const REFRESH_COLLECTION_DETAILS = 'REFRESH_COLLECTION_DETAILS';
export const REFRESH_COLLECTIONS_LIST = 'REFRESH_COLLECTIONS_LIST';

export const actions = createActions({
  [FETCH_COLLECTIONS]: undefined,
  [FETCH_COLLECTIONS_FAIL]: undefined,
  [FETCH_COLLECTIONS_SUCCESS]: undefined,

  [GET_COLLECTIONS_LIST]: undefined,
  [GET_COLLECTIONS_LIST_FAILURE]: undefined,
  [GET_COLLECTIONS_LIST_SUCCESS]: undefined,

  [GET_COLLECTIONS_BY_REGION]: undefined,
  [GET_COLLECTIONS_BY_REGION_FAILURE]: undefined,
  [GET_COLLECTIONS_BY_REGION_SUCCESS]: undefined,

  [GET_COLLECTIONS_BY_DISTRICT]: undefined,
  [GET_COLLECTIONS_BY_DISTRICT_FAILURE]: undefined,
  [GET_COLLECTIONS_BY_DISTRICT_SUCCESS]: undefined,

  [GET_COLLECTIONS_BY_LOCATION]: undefined,
  [GET_COLLECTIONS_BY_LOCATION_FAILURE]: undefined,
  [GET_COLLECTIONS_BY_LOCATION_SUCCESS]: undefined,

  [GET_COLLECTION_DETAILS]: undefined,
  [GET_COLLECTION_DETAILS_FAILURE]: undefined,
  [GET_COLLECTION_DETAILS_SUCCESS]: undefined,

  [CREATE_COLLECTION]: undefined,
  [CREATE_COLLECTION_FAILURE]: undefined,
  [CREATE_COLLECTION_SUCCESS]: undefined,

  [UPDATE_COLLECTION]: undefined,
  [UPDATE_COLLECTION_FAILURE]: undefined,
  [UPDATE_COLLECTION_SUCCESS]: undefined,

  [REFRESH_COLLECTION_DETAILS]: undefined,
  [REFRESH_COLLECTIONS_LIST]: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.fetchCollections, handlers.fetchCollections],
    [actions.fetchCollectionsFail, handlers.fetchCollections],
    [actions.fetchCollectionsSuccess, handlers.fetchCollectionsSuccess],

    [actions.getCollectionsList, handlers.getCollectionsList],
    [actions.getCollectionsListFailure, handlers.getCollectionsListFailure],
    [actions.getCollectionsListSuccess, handlers.getCollectionsListSuccess],

    [actions.getCollectionsByRegion, handlers.getCollectionsByRegion],
    [actions.getCollectionsByRegionFailure, handlers.getCollectionsByRegionFailure],
    [actions.getCollectionsByRegionSuccess, handlers.getCollectionsByRegionSuccess],

    [actions.getCollectionsByDistrict, handlers.getCollectionsByDistrict],
    [actions.getCollectionsByDistrictFailure, handlers.getCollectionsByDistrictFailure],
    [actions.getCollectionsByDistrictSuccess, handlers.getCollectionsByDistrictSuccess],

    [actions.getCollectionsByLocation, handlers.getCollectionsByLocation],
    [actions.getCollectionsByLocationFailure, handlers.getCollectionsByLocationFailure],
    [actions.getCollectionsByLocationSuccess, handlers.getCollectionsByLocationSuccess],

    [actions.getCollectionDetails, handlers.getCollectionDetails],
    [actions.getCollectionDetailsFailure, handlers.getCollectionDetailsFailure],
    [actions.getCollectionDetailsSuccess, handlers.getCollectionDetailsSuccess],

    [actions.createCollection, handlers.createCollection],
    [actions.createCollectionFailure, handlers.createCollectionFailure],
    [actions.createCollectionSuccess, handlers.createCollectionSuccess],

    [actions.updateCollection, handlers.updateCollection],
    [actions.updateCollectionFailure, handlers.updateCollectionFailure],
    [actions.updateCollectionSuccess, handlers.updateCollectionSuccess],

    [actions.refreshCollectionDetails, handlers.refreshCollectionDetails],
    [actions.refreshCollectionsList, handlers.refreshCollectionsList],
  ]),
  initialState,
);

export default reducer;
