import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { cancelStudents } from 'http/data-displays/endpoints';
import apiGetData from 'http/common-data';
import { actions as commonDataActions } from 'redux/common-data';

import { actions } from './index';

function* refreshStudents({ payload: { isAdminMode = false, data } }) {
  yield put(commonDataActions.refreshCommonData());
  yield call(cancelStudents);
  sessionStorage.setItem('studentprogress', '0');

  try {
    const result = yield call(apiGetData, { data, isAdminMode });

    yield put(commonDataActions.getDataSuccess(result));
    yield put(actions.getStudents({ data: result }));
  } catch (error) {
    yield put(commonDataActions.getDataError({ data: error }));
    yield put(actions.getStudents({ error }));
  }
}

export default function* watchStudentsFetch() {
  yield takeLatest(actions.refreshStudents, refreshStudents);
}
