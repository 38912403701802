import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GENERATE_OUTPUT_BY_COLLECTION: undefined,
  GENERATE_OUTPUT_BY_COLLECTION_FAILURE: undefined,
  GENERATE_OUTPUT_BY_COLLECTION_SUCCESS: undefined,

  CLEAR_OUTPUT_BY_COLLECTION: undefined,
  CLEAR_OUTPUT_BY_COLLECTION_FAILURE: undefined,
  CLEAR_OUTPUT_BY_COLLECTION_SUCCESS: undefined,

  GET_OUTPUT_BY_COLLECTION: undefined,
  GET_OUTPUT_BY_COLLECTION_FAILURE: undefined,
  GET_OUTPUT_BY_COLLECTION_SUCCESS: undefined,

  CLEAR_OUTPUT_INFO: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.generateOutputByCollection, handlers.generateOutputByCollection],
    [actions.generateOutputByCollectionFailure, handlers.generateOutputByCollectionFailure],
    [actions.generateOutputByCollectionSuccess, handlers.generateOutputByCollectionSuccess],

    [actions.clearOutputByCollection, handlers.clearOutputByCollection],
    [actions.clearOutputByCollectionFailure, handlers.clearOutputByCollectionFailure],
    [actions.clearOutputByCollectionSuccess, handlers.clearOutputByCollectionSuccess],

    [actions.getOutputByCollection, handlers.getOutputByCollection],
    [actions.getOutputByCollectionFailure, handlers.getOutputByCollectionFailure],
    [actions.getOutputByCollectionSuccess, handlers.getOutputByCollectionSuccess],

    [actions.clearOutputInfo, handlers.clearOutputInfo],
  ]),
  initialState,
);

export default reducer;
