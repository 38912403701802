const initialState = {
  customFields: [],
  supports: [],
  scoresheetHistory: [],
  itemHistory: [],
  studentItemsHistory: [],
  evidence: [],
  isLoading: false,
  filter: null,
  loadingSupports: false,
  evidenceLoading: false,
  evidenceSaving: false,
  savingSupports: false,
  savingComment: false,
  isCommentsPopupOpen: false,
  isSupportsPopupOpen: false,
  teachers: [],
  error: '',
};

export const setStudentDetails = (state, { payload }) => ({
  ...state,
  isLoading: false,
  teachers: payload.teachers,
});

export const setFilter = (state, { payload }) => {
  const { filter } = state;

  let value = payload;
  if (payload === filter) {
    value = null;
  }
  return {
    ...state,
    filter: value,
  };
};

export const getSupportsRequest = (state) => ({
  ...state,
  loadingSupports: true,
});

export const getSupportsSuccess = (state, { payload }) => ({
  ...state,
  supports: payload,
  loadingSupports: false,
});

export const getSupportsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loadingSupports: false,
});

export const saveCommentRequest = (state) => ({
  ...state,
  savingComment: true,
});

export const saveCommentSuccess = (state) => ({
  ...state,
  savingComment: false,
});

export const saveCommentFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  savingComment: false,
});

export const saveSupportsRequest = (state) => ({
  ...state,
  savingSupports: true,
});

export const saveSupportsSuccess = (state) => ({
  ...state,
  savingSupports: false,
});

export const saveSupportsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  savingSupports: false,
});

export const getScoresheetHistoryRequest = (state) => ({
  ...state,
  isLoading: true,
  savingSupports: true,
});

export const getScoresheetHistorySuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  scoresheetHistory: payload,
  savingSupports: false,
});

export const getScoresheetHistoryFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getItemHistoryRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getItemHistorySuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  itemHistory: payload,
});

export const getItemHistoryFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getStudentItemsHistoryRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getStudentItemsHistorySuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  studentItemsHistory: payload,
});

export const getStudentItemsHistoryFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getEvidenceRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getEvidenceSuccess = (state, { payload }) => ({
  ...state,
  evidence: payload,
  isLoading: false,
});

export const getEvidenceFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const saveEvidenceRequest = (state) => ({
  ...state,
  evidenceSaving: true,
});

export const saveEvidenceSuccess = (state) => ({
  ...state,
  evidenceSaving: false,
});

export const saveEvidenceFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  evidenceSaving: false,
});

export const deleteEvidenceRequest = (state) => ({
  ...state,
});

export const deleteEvidenceSuccess = (state) => ({
  ...state,
});

export const deleteEvidenceFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const setEvidenceLoading = (state) => ({
  ...state,
  evidenceLoading: true,
});

export const unsetEvidenceLoading = (state) => ({
  ...state,
  evidenceLoading: false,
});

export const openSupportsPopup = (state) => ({
  ...state,
  isSupportsPopupOpen: true,
});

export const closeSupportsPopup = (state) => ({
  ...state,
  isSupportsPopupOpen: false,
});

export const openCommentsPopup = (state) => ({
  ...state,
  isCommentsPopupOpen: true,
});

export const closeCommentsPopup = (state) => ({
  ...state,
  isCommentsPopupOpen: false,
});

export const getCustomFieldsRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getCustomFieldsSuccess = (state, { payload }) => ({
  ...state,
  customFields: payload,
  isLoading: false,
});

export const getCustomFieldsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const updateStudentOptionsAndInputs = (state) => ({
  ...state,
  isLoading: true,
});

export const updateStudentOptionsAndInputsSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const updateStudentOptionsAndInputsFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export default initialState;
