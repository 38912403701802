import { combineReducers } from 'redux';

import collectionAssessmentItemSets from './collectionAssessmentItemSets';
import collectionAssignments from './collectionAssignments';
import collectionSupports from './collectionSupports';
import collectionsData from './collectionsData';
import collectionISRManagement from './collectionISRManagement';
import collectionFieldTests from './collectionFieldTests';

export default combineReducers({
  collectionAssessmentItemSets,
  collectionSupports,
  collectionAssignments,
  collectionsData,
  collectionISRManagement,
  collectionFieldTests,
});
