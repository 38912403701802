export const formatForRequest = ({
  color,
  label,
  data_collections_student_groups_student_enrollments: enrollments,
  id,
}) => ({
  id,
  color,
  label,
  enrollmentIds: enrollments.map((d) => d.student_enrollment_id),
});

export default formatForRequest;
