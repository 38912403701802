/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import ReactTable from 'react-table-6';

import TextField from '@material-ui/core/TextField';

// To make the table searchable, send in a prop called "searchable" whose value is an array of
// accessors to the data array. For instance, searching on the field "name" means each element in data has "name" and
// searchable={['name']}

const styles = (theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    '& .rt-thead, & .rt-th.-cursor-pointer': {
      outline: 'none',
      color: theme.typography.body1Next.color,
    },
    '& .rt-td': {
      display: 'flex',
      fontSize: theme.typography.body1Next.fontSize,
    },
    '& .rt-th': {
      fontFamily: theme.typography.fontFamilyBold,
      fontSize: theme.typography.table.headers.fontSize,
      color: theme.typography.table.headers.textColor,
    },
    '& .rt-th, & .rt-td': {
      whiteSpace: 'normal',
      textAlign: 'left',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing()}px !important`,
      '&.numeric, &.actions': {
        padding: `${theme.spacing(2)}px ${theme.spacing()}px ${theme.spacing(2)}px ${theme.spacing(4)}px !important`,
      },
    },
    '& .numeric, & .actions': {
      textAlign: 'right',
      justifyContent: 'flex-end',
      '& > :not(:last-child)': {
        marginRight: theme.spacing(),
      },
    },
    '& .pagination-bottom': {
      fontSize: theme.typography.table.pagination.fontSize,
    },
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
});
const StyledReactTable = (props) => {
  const {
    classes, data, searchable, searchLabel, t, ...rest
  } = props;

  const [search, setSearch] = useState('');

  const filteredData = [];

  if (searchable.length) {
    searchable.forEach((s) => {
      data.forEach((v) => {
        if (v[s].toLowerCase().indexOf(search.toLowerCase()) > -1) filteredData.push(v);
      });
    });
  } else {
    data.forEach((i) => filteredData.push(i));
  }

  return (
    <div className={searchable.length ? null : classes.marginTop}>
      {searchable.length ? (
        <TextField
          className={classes.marginTop}
          fullWidth
          variant="outlined"
          label={t(searchLabel)}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      ) : null}
      <ReactTable data={filteredData} className={classes.root} {...rest} />
    </div>
  );
};

StyledReactTable.defaultProps = {
  searchable: [],
  searchLabel: 'Search table',
};

StyledReactTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchable: PropTypes.arrayOf(PropTypes.string),
  searchLabel: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(withTranslation()(StyledReactTable));
