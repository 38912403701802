import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import * as collectionsApi from 'http/admin/collections';

import { actions as snackbarActions } from 'redux/snackbar';
import { actions } from './index';

function* getCollectionSupports({ payload }) {
  try {
    const { collectionId } = payload;

    const result = yield call(collectionsApi.getCollectionSupports, { collectionId });

    yield put(actions.getCollectionSupportsSuccess(result));
  } catch (error) {
    yield put(actions.getCollectionSupportsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* createCollectionSupport({ payload }) {
  const {
    collectionId,
    data: payloadData,
    successHandle,
  } = payload;

  try {
    yield call(collectionsApi.createCollectionSupport, { data: payloadData, collectionId });

    yield put(actions.createCollectionSupportSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: `Support ${payloadData.label} created` } }));

    yield put(actions.getCollectionSupports({ collectionId }));
    if (successHandle) successHandle();
  } catch (error) {
    yield put(actions.createCollectionSupportFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* updateCollectionSupport({ payload }) {
  const {
    tenantId,
    id,
    data,
    collectionId,
    successHandle,
  } = payload;

  try {
    const response = yield call(collectionsApi.updateCollectionSupport, {
      tenantId,
      id,
      collectionId,
      data,
    });

    yield put(actions.updateCollectionSupportSuccess(response));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: `Support ${response.label} updated` } }));

    if (successHandle) successHandle();
  } catch (error) {
    yield put(actions.updateCollectionSupportFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* deleteCollectionSupport({ payload }) {
  const {
    collectionId,
    id,
    successHandle,
    label,
  } = payload;

  try {
    yield call(collectionsApi.deleteCollectionSupport, { id, collectionId });

    yield put(actions.deleteCollectionSupportSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: `Support ${label} removed` } }));

    yield put(actions.getCollectionSupports({ collectionId }));
    if (successHandle) successHandle();
  } catch (error) {
    yield put(actions.deleteCollectionSupportFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* watchSagas() {
  yield takeLatest(actions.getCollectionSupports, getCollectionSupports);
  yield takeLatest(actions.createCollectionSupport, createCollectionSupport);
  yield takeLatest(actions.updateCollectionSupport, updateCollectionSupport);
  yield takeLatest(actions.deleteCollectionSupport, deleteCollectionSupport);
}
