import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  POLL_SKIPLINKS: undefined,
  POLL_SKIPLINKS_FAIL: undefined,
  POLL_SKIPLINKS_SUCCESS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.pollSkiplinksSuccess, handlers.pollSkiplinksSuccess],
  ]),
  initialState,
);

export default reducer;
