import { combineReducers } from 'redux';

import reports from './reports';
import dashboard from './dashboard';
import assessment from './assessment';
import spreadsheet from './spreadsheet';
import completions from './completions';
import studentProfile from './studentProfile';

export default combineReducers({
  reports,
  dashboard,
  assessment,
  spreadsheet,
  completions,
  studentProfile,
});
