import { navigate } from '@reach/router';

import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import * as api from 'http/admin/users';

import { actions as snackbarActions } from 'redux/snackbar';
import { actions } from './index';

function* getUsers({ payload }) {
  try {
    const { tenantId } = payload;

    const result = yield call(api.getUsers, { tenantId });

    yield put(actions.getUsersSuccess(result));
  } catch (error) {
    yield put(actions.getUsersFailure(error));
  }
}

function* getUserDetails({ payload }) {
  try {
    const {
      tenantId, userId,
    } = payload;

    const result = yield call(api.getUserDetails, {
      tenantId, userId,
    });

    yield put(actions.getUserDetailsSuccess(result));
  } catch (error) {
    yield put(actions.getUserDetailsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* createUser({ payload }) {
  try {
    const {
      data, tenantId, successHandle, isOpenAfter,
    } = payload;

    const createPayload = {
      ...data,
      tenantMemberId: tenantId,
    };
    const result = yield call(api.createUser, { tenantId, data: createPayload });

    yield put(actions.createUserSuccess(result));
    yield put(actions.getUsers({ tenantId }));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User successfully created' } }));
    if (isOpenAfter) navigate(`/administrator/users/${result.data.user.id}/profile/details`);
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.createUserFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* updateUser({ payload }) {
  try {
    const { data, userId } = payload;

    const updateData = {
      id: +userId,
      firstName: data.firstName ? data.firstName : undefined,
      lastName: data.lastName ? data.lastName : undefined,
      enabled: data.enabled,
      expired: data.expired,
      roles: data.roles,
      email: data.email,
      password: data.password ? data.password : undefined,
    };

    const result = yield call(api.updateUser, updateData);

    yield put(actions.updateUserSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User successfully updated' } }));
  } catch (error) {
    yield put(actions.updateUserFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* activateUser({ payload }) {
  try {
    const { data: payloadData, successHandle } = payload;

    const result = yield call(api.activateUser, payloadData);

    yield put(actions.activateUserSuccess(result));
    yield put(actions.getUsers(payloadData));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User successfully activated' } }));
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.activateUserFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* deactivateUser({ payload }) {
  try {
    const { data: payloadData, successHandle } = payload;

    const result = yield call(api.deactivateUser, payloadData);

    yield put(actions.deactivateUserSuccess(result));
    yield put(actions.getUsers(payloadData));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User successfully deactivated' } }));
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.deactivateUserFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getUserOrgs({ payload }) {
  try {
    const userOrgs = yield call(api.getUserOrgs, payload);
    userOrgs.regions = userOrgs.regions.map((r) => ({
      ...r,
      name: r.nameLabel,
    }));
    userOrgs.districts = userOrgs.districts.map((d) => ({
      ...d,
      name: d.nameLabel,
    }));
    userOrgs.locations = userOrgs.locations.map((l) => ({
      ...l,
      name: l.nameLabel,
    }));
    yield put(actions.getUserOrgsSuccess(userOrgs));
  } catch (error) {
    yield put(actions.getUserRegionsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getUserRegions({ payload }) {
  try {
    const { userId } = payload;

    const userOrgs = yield call(api.getUserOrgs, { userId, admin: true });

    const result = userOrgs.regions.map((r) => ({
      ...r,
      name: r.nameLabel,
    }));

    yield put(actions.getUserRegionsSuccess(result));
  } catch (error) {
    yield put(actions.getUserRegionsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getUserDistricts({ payload }) {
  try {
    const { userId } = payload;

    const userOrgs = yield call(api.getUserOrgs, { userId, admin: true });

    const result = userOrgs.districts.map((d) => ({
      ...d,
      name: d.nameLabel,
    }));

    yield put(actions.getUserDistrictsSuccess(result));
  } catch (error) {
    yield put(actions.getUserDistrictsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getUserLocations({ payload }) {
  try {
    const { userId } = payload;

    const userOrgs = yield call(api.getUserOrgs, { userId, admin: true });

    const result = userOrgs.locations.map((l) => ({
      ...l,
      name: l.nameLabel,
    }));
    yield put(actions.getUserLocationsSuccess(result));
  } catch (error) {
    yield put(actions.getUserLocationsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* associateUserWithRegion({ payload }) {
  try {
    const {
      tenantId, userId, regionId,
    } = payload;

    const result = yield call(api.associateUserWithRegion, {
      tenantId, userId, regionId,
    });

    yield put(actions.associateUserWithRegionSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User Associated With Region' } }));
    yield put(actions.getUserRegions({ tenantId, userId }));
  } catch (error) {
    yield put(actions.associateUserWithRegionFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* disassociateUserWithRegion({ payload }) {
  try {
    const {
      data, successHandle,
    } = payload;

    const result = yield call(api.disassociateUserWithRegion, data);

    yield put(actions.disassociateUserWithRegionSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User Disassociated With Region' } }));

    if (successHandle) {
      successHandle();
    }
    yield put(actions.getUserRegions(data));
  } catch (error) {
    yield put(actions.disassociateUserWithRegionFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* demoteFromRegionAdmin({ payload }) {
  try {
    const {
      data, successHandle,
    } = payload;

    const result = yield call(api.demoteFromRegionAdmin, data);

    yield put(actions.demoteFromRegionAdminSuccess(result));
    yield put(actions.getUserRegions(data));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User Demoted from Admin' } }));
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.demoteFromRegionAdminFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* promoteToRegionAdmin({ payload }) {
  try {
    const {
      data, successHandle,
    } = payload;

    const result = yield call(api.promoteToRegionAdmin, data);

    yield put(actions.promoteToRegionAdminSuccess(result));
    yield put(actions.getUserRegions(data));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User Promoted to Admin' } }));
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.promoteToRegionAdminFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* associateUserWithDistrict({ payload }) {
  try {
    const {
      data, successHandle,
    } = payload;

    const result = yield call(api.associateUserWithDistrict, data);
    yield put(actions.associateUserWithDistrictSuccess(result));
    yield put(actions.getUserDistricts(data));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User Associated With District' } }));
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.associateUserWithDistrictFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* disassociateUserWithDistrict({ payload }) {
  try {
    const {
      data: { tenantId, userId, districtId }, callback,
    } = payload;

    const result = yield call(api.disassociateUserWithDistrict, {
      tenantId, userId, districtId,
    });
    yield put(actions.disassociateUserWithDistrictSuccess(result));
    yield put(actions.getUserDistricts({ tenantId, userId }));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User Disassociated With District' } }));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(actions.disassociateUserWithDistrictFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));

    const {
      data: { tenantId, userId }, callback,
    } = payload;
    if (callback) {
      callback();
    }
    yield put(actions.getUserDistricts({ tenantId, userId }));
  }
}

function* demoteFromDistrictAdmin({ payload }) {
  try {
    const {
      data, successHandle,
    } = payload;

    const result = yield call(api.demoteFromDistrictAdmin, data);
    yield put(actions.demoteFromDistrictAdminSuccess(result));
    yield put(actions.getUserDistricts(data));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User Demoted from Admin' } }));
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.demoteFromDistrictAdminFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* promoteToDistrictAdmin({ payload }) {
  try {
    const {
      data, successHandle,
    } = payload;

    if (data.memberId) {
      yield call(api.promoteToDistrictAdmin, data);
    } else {
      yield call(api.associateUserWithDistrict, data);
    }

    yield put(actions.promoteToDistrictAdminSuccess());
    yield put(actions.getUserDistricts(data));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User Promoted to Admin' } }));
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.promoteToDistrictAdminFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* updateUserDistrict({ payload }) {
  try {
    const {
      data, successHandle,
    } = payload;

    const result = yield call(api.updateDistrict, data);
    yield put(actions.updateUserDistrictSuccess(result));
    yield put(actions.getUserDistricts(data));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User District Updated' } }));
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.updateUserDistrictFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* associateUserWithLocation({ payload }) {
  try {
    const {
      data,
      successHandle,
    } = payload;

    const result = yield call(api.associateUserWithLocation, data);

    yield put(actions.associateUserWithLocationSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User Associated With Location' } }));

    if (successHandle) {
      successHandle();
    }

    yield put(actions.getUserLocations(data));
  } catch (error) {
    yield put(actions.associateUserWithLocationFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* disassociateUserWithLocation({ payload }) {
  try {
    const {
      data,
      successHandle,
    } = payload;

    const result = yield call(api.disassociateUserWithLocation, data);

    yield put(actions.disassociateUserWithLocationSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User Disassociated With Location' } }));

    if (successHandle) {
      successHandle();
    }

    yield put(actions.getUserLocations(data));
  } catch (error) {
    yield put(actions.disassociateUserWithLocationFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* demoteFromLocationAdmin({ payload }) {
  try {
    const {
      data, successHandle,
    } = payload;

    const result = yield call(api.demoteFromLocationAdmin, data);

    yield put(actions.demoteFromLocationAdminSuccess(result));
    yield put(actions.getUserLocations(data));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User Demoted from Admin' } }));
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.demoteFromLocationAdminFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* promoteToLocationAdmin({ payload }) {
  try {
    const {
      data, successHandle,
    } = payload;

    if (data.memberId) {
      yield call(api.promoteToLocationAdmin, data);
    } else {
      yield call(api.associateUserWithLocation, data);
    }

    yield put(actions.promoteToLocationAdminSuccess());
    yield put(actions.getUserLocations(data));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'User Promoted to Admin' } }));
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.promoteToLocationAdminFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getUserRegionsMember({ payload }) {
  try {
    const { tenantId, userId } = payload;

    const result = yield call(api.getUserRegionsMember, { tenantId, userId });

    yield put(actions.getUserRegionsMemberSuccess(result));
  } catch (error) {
    yield put(actions.getUserRegionsMemberFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getUserDistrictsMember({ payload }) {
  try {
    const { tenantId, userId } = payload;

    const result = yield call(api.getUserDistrictsMember, { tenantId, userId });

    yield put(actions.getUserDistrictsMemberSuccess(result));
  } catch (error) {
    yield put(actions.getUserDistrictsMemberFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getUserLocationsMember({ payload }) {
  try {
    const { tenantId, userId } = payload;

    const result = yield call(api.getUserLocationsMember, { tenantId, userId });

    yield put(actions.getUserLocationsMemberSuccess(result));
  } catch (error) {
    yield put(actions.getUserLocationsMemberFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* watchSagas() {
  yield takeLatest(actions.getUsers, getUsers);
  yield takeLatest(actions.getUserDetails, getUserDetails);
  yield takeLatest(actions.createUser, createUser);
  yield takeLatest(actions.updateUser, updateUser);
  yield takeLatest(actions.activateUser, activateUser);
  yield takeLatest(actions.deactivateUser, deactivateUser);
  yield takeLatest(actions.getUserOrgs, getUserOrgs);
  yield takeLatest(actions.getUserRegions, getUserRegions);
  yield takeLatest(actions.getUserDistricts, getUserDistricts);
  yield takeLatest(actions.getUserLocations, getUserLocations);
  yield takeLatest(actions.associateUserWithRegion, associateUserWithRegion);
  yield takeLatest(actions.disassociateUserWithRegion, disassociateUserWithRegion);
  yield takeLatest(actions.demoteFromRegionAdmin, demoteFromRegionAdmin);
  yield takeLatest(actions.promoteToRegionAdmin, promoteToRegionAdmin);
  yield takeLatest(actions.associateUserWithDistrict, associateUserWithDistrict);
  yield takeLatest(actions.disassociateUserWithDistrict, disassociateUserWithDistrict);
  yield takeLatest(actions.demoteFromDistrictAdmin, demoteFromDistrictAdmin);
  yield takeLatest(actions.promoteToDistrictAdmin, promoteToDistrictAdmin);
  yield takeLatest(actions.updateUserDistrict, updateUserDistrict);
  yield takeLatest(actions.associateUserWithLocation, associateUserWithLocation);
  yield takeLatest(actions.disassociateUserWithLocation, disassociateUserWithLocation);
  yield takeLatest(actions.demoteFromLocationAdmin, demoteFromLocationAdmin);
  yield takeLatest(actions.promoteToLocationAdmin, promoteToLocationAdmin);
  yield takeLatest(actions.getUserRegionsMember, getUserRegionsMember);
  yield takeLatest(actions.getUserDistrictsMember, getUserDistrictsMember);
  yield takeLatest(actions.getUserLocationsMember, getUserLocationsMember);
}
