import { takeLatest, call, put } from 'redux-saga/effects';
import { navigate } from '@reach/router';

import {
  getDataCollections,
  getAssessments,
  postAssessment,
  putAssessment,
  getAssessmentItems,
  postAssessmentHierarchyItem,
  postAssessmentItem,
  putAssessmentItem,
  putAssessmentHierarchyItem,
  deleteAssessmentItem,
  deleteAssessmentHierarchyItem,
  postAssessmentImport,
  postAssessmentItemResource,
  deleteAssessmentItemResource,
} from 'http/system';

import { actions as snackbarActions } from 'redux/snackbar';
import { actions } from './index';

function* fetchAssessment({ payload }) {
  try {
    const result = yield call(getAssessments, payload);

    yield put(actions.systemGetAssessmentSuccess(result.data.assessments[0]));
  } catch (error) {
    yield put(actions.systemGetAssessmentError({ data: error }));
  }
}

function* fetchAssessments({ payload }) {
  try {
    const result = yield call(getAssessments, payload);

    yield put(actions.systemGetAssessmentsSuccess(result.data.assessments));
  } catch (error) {
    yield put(actions.systemGetAssessmentsError({ data: error }));
  }
}

function* saveAssessment({ payload }) {
  try {
    yield call(postAssessment, payload.newAssessment);

    yield put(actions.systemSaveAssessmentSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Assessment saved' } }));

    navigate('/system/assessments');
  } catch (error) {
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Error saving assessment' } }));
    yield put(actions.systemSaveAssessmentError({ data: error }));
  }
}

function* updateAssessment({ payload }) {
  try {
    yield call(putAssessment, payload.updatedAssessment);

    yield put(actions.systemUpdateAssessmentSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Assessment updated' } }));

    navigate('/system/assessments');
  } catch (error) {
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Error updating assessment' } }));
    yield put(actions.systemUpdateAssessmentError({ data: error }));
  }
}

function* fetchAssessmentItems({ payload }) {
  try {
    const result = yield call(getAssessmentItems, { assessmentId: payload.assessmentId, params: payload.params });

    yield put(actions.systemGetAssessmentItemsSuccess(result));
  } catch (error) {
    yield put(actions.systemGetAssessmentItemsError({ data: error }));
  }
}

function* saveAssessmentItem({ payload }) {
  try {
    const result = yield call(postAssessmentItem, payload);

    for (let i = 0; i < payload.data.filesToUpload.length; i += 1) {
      yield call(postAssessmentItemResource, payload.assessmentId, result.id, payload.data.filesToUpload[i]);
    }
    yield put(actions.systemGetAssessmentItems({ assessmentId: payload.assessmentId }));
    yield put(actions.systemSaveAssessmentItemSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Assessment item saved' } }));
  } catch (error) {
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Error saving assessment item' } }));
    yield put(actions.systemSaveAssessmentItemError());
  }
}

function* updateAssessmentItem({ payload }) {
  try {
    yield call(putAssessmentItem, payload);

    for (let i = 0; i < payload.data.filesToUpload.length; i += 1) {
      yield call(postAssessmentItemResource, payload.assessmentId, payload.data.id, payload.data.filesToUpload[i]);
    }

    for (let i = 0; i < payload.data.filesToDelete.length; i += 1) {
      yield call(deleteAssessmentItemResource, payload.assessmentId, payload.data.id, payload.data.filesToDelete[i]);
    }

    yield put(actions.systemGetAssessmentItems({ assessmentId: payload.assessmentId }));
    yield put(actions.systemUpdateAssessmentItemSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Assessment item updated' } }));
  } catch (error) {
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Error updating assessment item' } }));
    yield put(actions.systemUpdateAssessmentItemError());
  }
}

function* deleteAssessmentItemFunction({ payload }) {
  try {
    yield call(deleteAssessmentItem, payload);
    yield put(actions.systemGetAssessmentItems({ assessmentId: payload.assessmentId }));
    yield put(actions.systemDeleteAssessmentItemSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Assessment item deleted' } }));
  } catch (error) {
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Error deleting assessment item' } }));
    yield put(actions.systemDeleteAssessmentItemError());
  }
}

function* saveAssessmentHierarchyItem({ payload }) {
  try {
    yield call(postAssessmentHierarchyItem, payload);
    yield put(actions.systemGetAssessmentItems({ assessmentId: payload.assessmentId }));
    yield put(actions.systemSaveAssessmentHierarchyItemSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Assessment hierarchy item saved' } }));
  } catch (error) {
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Error saving assessment hierarchy item' } }));
    yield put(actions.systemSaveAssessmentHierarchyItemError());
  }
}

function* updateAssessmentHierarchyItem({ payload }) {
  try {
    yield call(putAssessmentHierarchyItem, payload);
    yield put(actions.systemGetAssessmentItems({ assessmentId: payload.assessmentId }));
    yield put(actions.systemUpdateAssessmentHierarchyItemSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Assessment hierarchy item updated' } }));
  } catch (error) {
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Error updating assessment hierarchy item' } }));
    yield put(actions.systemUpdateAssessmentHierarchyItemError());
  }
}

function* deleteAssessmentHierarchyItemFunction({ payload }) {
  try {
    yield call(deleteAssessmentHierarchyItem, payload);
    yield put(actions.systemGetAssessmentItems({ assessmentId: payload.assessmentId }));
    yield put(actions.systemDeleteAssessmentHierarchyItemSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Assessment hierarchy item deleted' } }));
  } catch (error) {
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Error deleting assessment hierarchy item' } }));
    yield put(actions.systemDeleteAssessmentHierarchyItemError(error));
  }
}

function* importAssessment({ payload }) {
  try {
    yield call(postAssessmentImport, payload);
    yield put(actions.systemGetAssessments());
    yield put(actions.systemImportAssessmentSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: `Imported Assessment ${payload.data.name}` } }));
  } catch (error) {
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Error importing assessment' } }));
    yield put(actions.systemImportAssessmentError(error));
    window.location.reload();
  }
}

function* fetchDataCollections({ payload }) {
  try {
    const result = yield call(getDataCollections, payload);

    yield put(actions.systemGetDatacollectionsSuccess(result));
  } catch (error) {
    yield put(actions.systemGetDatacollectionsError({ data: error }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.systemGetAssessment, fetchAssessment);
  yield takeLatest(actions.systemGetAssessments, fetchAssessments);
  yield takeLatest(actions.systemSaveAssessment, saveAssessment);
  yield takeLatest(actions.systemUpdateAssessment, updateAssessment);
  yield takeLatest(actions.systemGetAssessmentItems, fetchAssessmentItems);
  yield takeLatest(actions.systemSaveAssessmentItem, saveAssessmentItem);
  yield takeLatest(actions.systemUpdateAssessmentItem, updateAssessmentItem);
  yield takeLatest(actions.systemDeleteAssessmentItem, deleteAssessmentItemFunction);
  yield takeLatest(actions.systemSaveAssessmentHierarchyItem, saveAssessmentHierarchyItem);
  yield takeLatest(actions.systemUpdateAssessmentHierarchyItem, updateAssessmentHierarchyItem);
  yield takeLatest(actions.systemDeleteAssessmentHierarchyItem, deleteAssessmentHierarchyItemFunction);
  yield takeLatest(actions.systemImportAssessment, importAssessment);
  yield takeLatest(actions.systemGetDatacollections, fetchDataCollections);
}
