/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Tooltip from '../Tooltip';

const styles = (theme) => ({
  passwordField: {
    paddingRight: '0',
    width: 'calc(100%)',
  },
  required: {
    color: theme.palette.error.main,
  },
});

const TextInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const {
    input,
    label,
    meta: { touched, error },
    helperText,
    variant,
    fullWidth,
    type,
    autoFocus,
    autoComplete,
    margin,
    classes,
    required,
    adornmentText,
    ...custom
  } = props;

  if (type === 'password') {
    return (
      <TextField
        fullWidth={fullWidth}
        className={classes.passwordField}
        error={!!(touched && error)}
        helperText={touched && error ? error : null}
        margin={margin}
        variant={variant}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        type={showPassword ? 'text' : 'password'}
        label={label}
        required={required}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={adornmentText}>
                <IconButton aria-label={adornmentText} onClick={handleClickShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        {...input}
        {...custom}
      />
    );
  }

  return (
    <TextField
      fullWidth={fullWidth}
      variant={variant}
      error={!!(touched && error)}
      helperText={touched && error ? error : null}
      margin={margin}
      required={required}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      label={label}
      type={type}
      {...input}
      {...custom}
    />
  );
};

TextInput.defaultProps = {
  meta: {
    error: '',
  },
  helperText: '',
  variant: 'outlined',
  type: 'text',
  fullWidth: true,
  margin: 'normal',
  autoFocus: false,
  autoComplete: '',
  required: false,
  adornmentText: '',
};

TextInput.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  id: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
  classes: PropTypes.object.isRequired,
  adornmentText: PropTypes.string,
};

export default withStyles(styles)(TextInput);
