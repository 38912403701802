export const format = (data) => {
  const result = {};
  data.forEach((item) => {
    const key = item.id;
    result[key] = item;
    return item;
  });
  return result;
};

export const blank = () => {};
