import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

export default function CloseButton({ onClose, size }) {
  const classes = useStyles();

  return (
    <IconButton size={size} className={classes.button} aria-label="close" onClick={onClose}>
      <ClearIcon />
    </IconButton>
  );
}

CloseButton.defaultProps = {
  size: 'medium',
};

CloseButton.propTypes = {
  size: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};
