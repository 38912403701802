import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  data: [],
  isLoading: false,
  currentStudent: {},

  existingStudentData: null,
  isStudentChecking: false,

  availableRosters: [],
  isAvailableRostersLoading: false,
  availableRostersError: null,
  isRostersUpdating: false,

  studentDetails: {},
  detailsIsLoading: false,
  detailsIsUpdating: false,
  isDeleting: false,
  detailsError: null,

  outputs: [],
  isOutputsLoading: false,

  assignments: [],
  isAssignmentsLoading: false,
  isNeedAssignmentsReloading: false,

  studentEnrollments: [],
  isEnrollmentsLoading: false,
  isEnrollmentUpdating: false,
  currentEnrollment: {},
  enrollmentAssociations: [],
  isEnrollmentAssociationsLoading: false,
  isEnrollmentAssociationsDeleting: false,

  studentLocationMembers: [],
  isStudentLocationMembersLoading: false,

  error: null,
};

export const getAvailableRosters = (state) => ({
  ...state,
  isAvailableRostersLoading: true,
});

export const getAvailableRostersSuccess = (state, { payload }) => ({
  ...state,
  isAvailableRostersLoading: false,
  availableRosters: payload,
});

export const getAvailableRostersFailure = (state, { payload }) => ({
  ...state,
  isAvailableRostersLoading: false,
  availableRostersError: payload,
});

export const getStudentsRosters = (state) => ({
  ...state,
  isLoading: true,
});

export const getStudentsRostersSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload,
});

export const getStudentsRostersFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const updateStudentsRosters = (state) => ({
  ...state,
  isRostersUpdating: true,
});

export const updateStudentsRostersSuccess = (state) => ({
  ...state,
  isRostersUpdating: false,
});

export const updateStudentsRostersFailure = (state, { payload }) => ({
  ...state,
  isRostersUpdating: false,
  error: payload,
});

export const getStudentsByTenant = (state) => ({
  ...state,
  isLoading: true,
});

export const getStudentsByTenantSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload,
});

export const getStudentsByTenantFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const getStudentsByLocation = (state) => ({
  ...state,
  isLoading: true,
});

export const getStudentsByLocationSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload,
});

export const getStudentsByLocationFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const getStudentDetails = (state) => ({
  ...state,
  detailsIsLoading: true,
});

export const getStudentDetailsSuccess = (state, { payload }) => ({
  ...state,
  detailsIsLoading: false,
  studentDetails: payload,
});

export const getStudentDetailsFailure = (state, { payload }) => ({
  ...state,
  detailsIsLoading: false,
  detailsError: payload,
});

export const createStudent = (state) => ({
  ...state,
  isLoading: true,
});

export const createStudentSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const createStudentFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const updateStudent = (state) => ({
  ...state,
  detailsIsUpdating: true,
});

export const updateStudentSuccess = (state) => ({
  ...state,
  detailsIsUpdating: false,
});

export const updateStudentFailure = (state, { payload }) => ({
  ...state,
  detailsIsUpdating: false,
  detailsError: payload,
});

export const removeStudent = (state) => ({
  ...state,
  isLoading: true,
});

export const removeStudentSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const removeStudentFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const checkStudentExiting = (state) => ({
  ...state,
  isStudentChecking: true,
});

export const checkStudentExitingSuccess = (state, { payload }) => ({
  ...state,
  isStudentChecking: false,
  existingStudentData: payload,
});

export const checkStudentExitingFailure = (state, { payload }) => ({
  ...state,
  isStudentChecking: false,
  error: payload,
});

export const setCurrentStudent = (state, { payload }) => ({
  ...state,
  currentStudent: payload,
});

export const refreshStudentsData = (state) => ({
  ...state,
  data: initialState.data,
});

export const refreshExitingStudentData = (state) => ({
  ...state,
  existingStudentData: initialState.existingStudentData,
});

export const refreshStudentDetails = (state) => ({
  ...state,
  studentDetails: initialState.studentDetails,
});

export const getEnrollmentsByStudent = (state, { payload }) => {
  const { isNeedReload } = payload;

  return ({
    ...state,
    isPoaEnrollmentsLoading: isNeedReload,
  });
};

export const getEnrollmentsByStudentSuccess = (state) => state;

export const getEnrollmentsByStudentFailure = (state, { payload }) => ({
  ...state,
  isPoaEnrollmentsLoading: false,
  error: payload,
});

const updateEnrollments = ({ enrollments, poas }) => (
  enrollments.map((enrollment) => {
    const poaFound = poas.find((poa) => enrollment.locationName === _.get(poa, 'tenants_locations.name'));

    if (!poaFound) return enrollment;

    return {
      ...enrollment,
      isPoa: true,
    };
  }));

export const getPoasByStudent = (state) => state;

export const getPoasByStudentSuccess = (state, { payload }) => {
  const newPoaEnrollments = updateEnrollments(payload);

  return ({
    ...state,
    isPoaEnrollmentsLoading: false,
    poaEnrollments: newPoaEnrollments,
  });
};

export const getPoasByStudentFailure = (state, { payload }) => ({
  ...state,
  isPoaEnrollmentsLoading: false,
  error: payload,
});

export const makePoaByStudent = (state) => state;

export const makePoaByStudentSuccess = (state) => state;

export const makePoaByStudentFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const validatePoa = (state) => state;

export const validatePoaSuccess = (state, { payload }) => {
  const { result, locationName } = payload;

  const newPoaEnrollments = state.poaEnrollments.map((enrollment) => {
    if (enrollment.locationName !== locationName) return enrollment;

    return {
      ...enrollment,
      isValid: result?.valid,
      message: result?.message,
    };
  });

  return ({
    ...state,
    poaEnrollments: newPoaEnrollments,
    error: payload,
  });
};

export const validatePoaFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const getStudentOutputs = (state) => ({
  ...state,
  outputs: [],
  isOutputsLoading: true,
});

export const getStudentOutputsSuccess = (state, { payload = [] }) => {
  const newData = payload.map((output) => {
    output.id = uuidv4();

    if (!output.outputStatus) output.outputStatus = 'No ISR';

    return output;
  });

  return ({
    ...state,
    outputs: newData,
    isOutputsLoading: false,
  });
};

export const getStudentOutputsFailure = (state, { payload }) => ({
  ...state,
  isOutputsLoading: false,
  error: payload,
});

export const getStudentOutputsForUpdate = (state) => state;

export const getStudentOutputsForUpdateSuccess = (state, { payload }) => {
  const { collectionId, outputsForUpdate = [] } = payload;
  const newOutput = outputsForUpdate.find((output) => output.collectionId === collectionId);

  if (!newOutput) return state;

  const newData = state.outputs.map((output) => {
    if (output.collectionId === collectionId) {
      return {
        ...output,
        outputId: newOutput.outputId,
        outputStatus: newOutput.outputStatus ? newOutput.outputStatus : 'No ISR',
      };
    }
    return output;
  });

  return ({
    ...state,
    outputs: newData,
  });
};

export const getStudentOutputsForUpdateFailure = (state, { payload }) => ({
  ...state,
  isOutputsLoading: false,
  error: payload,
});

export const generateStudentOutput = (state) => state;

export const generateStudentOutputSuccess = (state) => state;

export const generateStudentOutputFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const clearStudentOutput = (state) => state;

export const clearStudentOutputSuccess = (state, { payload }) => {
  const { outputId } = payload;
  const newData = state.outputs.map((output) => {
    if (output.outputId === outputId) {
      return {
        ...output,
        outputStatus: 'Deleted',
      };
    }
    return output;
  });

  return ({
    ...state,
    outputs: newData,
  });
};

export const clearStudentOutputFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const getStudentAssignmentsByEnrollment = (state) => ({
  ...state,
  isAssignmentsLoading: true,
  isNeedAssignmentsReloading: false,
});

export const getStudentAssignmentsByEnrollmentSuccess = (state, { payload }) => {
  const { assignments, isTenantAdmin } = payload;
  const newData = assignments.map((assignment) => {
    if (!isTenantAdmin && assignment.hideToken) assignment.token = '';
    return assignment;
  });

  return ({
    ...state,
    assignments: newData,
    isAssignmentsLoading: false,
  });
};

export const getStudentAssignmentsByEnrollmentFailure = (state, { payload }) => ({
  ...state,
  isAssignmentsLoading: false,
  error: payload,
});

export const assignDataCollectionByAssignment = (state) => state;

export const assignDataCollectionByAssignmentSuccess = (state) => ({
  ...state,
  isNeedAssignmentsReloading: true,
});

export const assignDataCollectionByAssignmentFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const unassignDataCollectionByAssignment = (state) => state;

export const unassignDataCollectionByAssignmentSuccess = (state, { payload }) => {
  const { assignmentId } = payload;
  const newData = state.assignments.filter((assignment) => assignment.id !== assignmentId);

  return ({
    ...state,
    assignments: newData,
  });
};

export const unassignDataCollectionByAssignmentFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const getStudentEnrollments = (state) => ({
  ...state,
  isEnrollmentsLoading: true,
});

export const getStudentEnrollmentsSuccess = (state, { payload }) => ({
  ...state,
  isEnrollmentsLoading: false,
  studentEnrollments: payload,
});

export const getStudentEnrollmentsFailure = (state, { payload }) => ({
  ...state,
  isEnrollmentsLoading: false,
  error: payload,
});

export const getEnrollmentAssociations = (state) => ({
  ...state,
  isEnrollmentAssociationsLoading: true,
});

export const getEnrollmentAssociationsSuccess = (state, { payload }) => ({
  ...state,
  isEnrollmentAssociationsLoading: false,
  enrollmentAssociations: payload,
});

export const getEnrollmentAssociationsFailure = (state, { payload }) => ({
  ...state,
  isEnrollmentAssociationsLoading: false,
  error: payload,
});

export const updateEnrollment = (state) => ({
  ...state,
  isEnrollmentUpdating: true,
});

export const updateEnrollmentSuccess = (state, { payload }) => {
  const newData = state.studentEnrollments.map((enrollment) => {
    if (enrollment?.id === payload?.id) return payload;

    return enrollment;
  });

  return ({
    ...state,
    isEnrollmentUpdating: false,
    studentEnrollments: newData,
  });
};

export const updateEnrollmentFailure = (state, { payload }) => ({
  ...state,
  isEnrollmentUpdating: false,
  error: payload,
});

export const removeEnrollmentAssociation = (state) => ({
  ...state,
  isEnrollmentAssociationsDeleting: true,
});

export const removeEnrollmentAssociationSuccess = (state, { payload }) => ({
  ...state,
  isEnrollmentAssociationsDeleting: false,
  enrollmentAssociations: state.enrollmentAssociations.filter((association) => association?.id !== payload?.id),

});

export const removeEnrollmentAssociationFailure = (state, { payload }) => ({
  ...state,
  isEnrollmentAssociationsDeleting: false,
  error: payload,
});

export const setCurrentEnrollment = (state, { payload }) => ({
  ...state,
  currentEnrollment: payload,
});

export const getStudentLocationMembers = (state) => ({
  ...state,
  isStudentLocationMembersLoading: true,
});

export const getStudentLocationMembersSuccess = (state, { payload }) => ({
  ...state,
  isStudentLocationMembersLoading: false,
  studentLocationMembers: payload,
});

export const getStudentLocationMembersFailure = (state, { payload }) => ({
  ...state,
  isStudentLocationMembersLoading: false,
  error: payload,
});

export const createEnrollmentAssociation = (state) => (state);

export const createEnrollmentAssociationSuccess = (state, { payload }) => ({
  ...state,
  enrollmentAssociations: [payload, ...state.enrollmentAssociations],
});

export const createEnrollmentAssociationFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const refreshStudentEnrollments = (state) => ({
  ...state,
  studentEnrollments: initialState.studentEnrollments,
  error: initialState.error,
  currentEnrollment: initialState.currentEnrollment,
});

export const refreshEnrollmentAssociations = (state) => ({
  ...state,
  enrollmentAssociations: initialState.enrollmentAssociations,
  error: initialState.error,
});

export default initialState;
