import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';
import { formatRegion, formatRegions } from './formatter';

export const getRegions = (params) => http.get(
  cas3ApiRoute('/regions'),
  { params },
).then((res) => formatRegions(res.data.regions));

export const getRegionDetails = ({ regionId }) => http.get(
  cas3ApiRoute(`/regions/${regionId}`),
).then((res) => formatRegion(res.data.region));

export const createRegion = (data) => http.post(cas3ApiRoute('/regions'), data);

export const updateRegion = (data) => http.put(
  cas3ApiRoute('/regions'),
  data,
).then((res) => formatRegion(res.data.region));
