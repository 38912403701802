import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

import { schoolYearsFormatter, archivesFormatter } from './formatter';

export const getSchoolYearsByTenant = () => (
  http.get(cas3ApiRoute('/reports/archive'), {

  }).then((res) => schoolYearsFormatter(res.data.schoolYearsAndDcs || []))
);

export const getArchivesByYearId = ({ yearId }) => (
  http.get(cas3ApiRoute('/reports'), {
    params: { schoolYearId: yearId },
  }).then((res) => archivesFormatter(res.data.reports || []))
);
