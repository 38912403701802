import moment from 'moment';
import { cas3ApiRoute } from 'http/utility';

export const itemsFormat = (res) => {
  res.data = res.data.map((item) => ({ ...item, materials: [] }));

  return res;
};

export const format = (data) => {
  const result = {};
  data.map((item) => {
    const key = item.id;
    result[key] = item;
    return item;
  });

  return result;
};

export const scalesFormat = (scales) => {
  const result = {};
  scales.map((scale) => {
    const key = scale.parentAssessmentItemsScalesDirectoryId;
    if (result[key] === undefined) {
      result[key] = [];
    }
    result[key].push(scale);
    return scale;
  });
  return result;
};

export const materialsFormat = (collectionId, { data }) => data.resources.map((material) => ({
  id: material.id,
  itemId: material.assessmentItemId,
  label: material.originalFileName,
  url: cas3ApiRoute(`/data-collections/${collectionId}/items/${material.assessmentItemId}/resources/${material.id}`),
}));

export const assessmentDateFormat = (data) => (
  {
    ...data,
    cycle_start_date: moment.unix(data.cycleStartDate),
    cycle_end_date: moment.unix(data.cycleEndDate),
    enrollment_end_date: moment.unix(data.enrollmentEndDate),
    // if the field is not defined or null then moment will make it the current datetime so we need to check
    // first since these are used for hiding an showing things.
    isr_access_date: data.isrAccessDate ? moment.unix(data.isrAccessDate) : undefined,
    isr_teacher_date: data.isrTeacherDate ? moment.unix(data.isrTeacherDate) : undefined,
    omr_access_date: data.omrAccessDate ? moment.unix(data.omrAccessDate) : undefined,
    data_displays_date: data.dataDisplaysDate ? moment.unix(data.dataDisplaysDate) : undefined,
  }
);

export const assessmentsDateFormat = (data) => (
  data.map((item) => assessmentDateFormat(item))
);

export const announcementDateFormat = (data) => (
  data.map(({ start, end, ...rest }) => ({
    start: moment.unix(start),
    end: moment.unix(end),
    ...rest,
  })));

export async function studentsFormat(students) {
  const locations = [];
  const districts = [];
  const teachers = [];
  const regions = [];

  students.forEach((student) => {
    if (!locations.includes(student.locationName)) {
      locations.push(student.locationName);
    }

    if (!districts.includes(student.districtName)) {
      districts.push(student.districtName);
    }

    if (!regions.includes(student.regionName)) {
      regions.push(student.regionName);
    }
  });

  return {
    students,
    locations,
    districts,
    teachers,
    regions,
  };
}

export default format;
