import {
  takeEvery,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import { actions as snackbarActions } from 'redux/snackbar';
import * as api from 'http/admin/transfers';

import { actions } from './index';
import { actions as studentActions } from '../students/index';

function* getOtherTransfersByTenant({ payload }) {
  try {
    const { tenantId } = payload;
    const result = yield call(api.getOtherTransfersByTenant, { tenantId });

    yield put(actions.getOtherTransfersByTenantSuccess(result));
  } catch (error) {
    yield put(actions.getOtherTransfersByTenantFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getUserTransfersByTenant({ payload }) {
  try {
    const { tenantId } = payload;
    const result = yield call(api.getUserTransfersByTenant, { tenantId });

    yield put(actions.getUserTransfersByTenantSuccess(result));
  } catch (error) {
    yield put(actions.getUserTransfersByTenantFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getHistoryTransfersByTenant({ payload }) {
  try {
    const { tenantId } = payload;
    const result = yield call(api.getHistoryTransfersByTenant, { tenantId });

    yield put(actions.getHistoryTransfersByTenantSuccess(result));
  } catch (error) {
    yield put(actions.getHistoryTransfersByTenantFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* createTransfer({ payload }) {
  try {
    const { data, successHandle } = payload;

    const requestData = {
      studentId: data.id,
      enrollmentId: data.enrollmentId,
      locationId: data.locationId,
    };

    const result = yield call(api.createTransfer, requestData);
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: result.message } }));

    yield put(studentActions.getStudentsByTenant({ tenantId: data.tenantId }));

    if (successHandle) { successHandle(); }
  } catch (error) {
    yield put(actions.createTransferFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* approveTransfer({ payload }) {
  try {
    const { tenantId, transferId, actionHandle } = payload;

    yield call(api.approveTransfer, { tenantId, transferId });

    yield put(actions.approveTransferSuccess({ transferId }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Transfer Request Approved' } }));

    if (actionHandle) actionHandle();
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.approveTransferFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* declineTransfer({ payload }) {
  try {
    const { tenantId, transferId, actionHandle } = payload;

    yield call(api.declineTransfer, { tenantId, transferId });

    yield put(actions.declineTransferSuccess({ transferId }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Transfer Request Declined' } }));

    if (actionHandle) actionHandle();
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.declineTransferFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}
export default function* sagas() {
  yield takeLatest(actions.getOtherTransfersByTenant, getOtherTransfersByTenant);
  yield takeLatest(actions.getUserTransfersByTenant, getUserTransfersByTenant);
  yield takeLatest(actions.getHistoryTransfersByTenant, getHistoryTransfersByTenant);
  yield takeLatest(actions.createTransfer, createTransfer);
  yield takeEvery(actions.approveTransfer, approveTransfer);
  yield takeEvery(actions.declineTransfer, declineTransfer);
}
