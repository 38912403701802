/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { PropTypes } from 'react-proptypes';
import { withTranslation } from 'react-i18next';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';

const offlineBarStyles = (theme) => ({
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const {
    classes, message, variant, ...other
  } = props;

  return (
    <SnackbarContent
      className={classes.warning}
      aria-describedby="offline-warning"
      message={(
        <span id="offline-warning" className={classes.message}>
          <InfoIcon className={classes.icon} />
          {message}
        </span>
      )}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  variant: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

const MySnackbarContentWrapper = withStyles(offlineBarStyles)(MySnackbarContent);

const OfflineMessage = ({ t }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    open
  >
    <MySnackbarContentWrapper
      variant="warning"
      message={t('Your device currently offline. You will not be able to login, save data, or see fresh data.')}
    />
  </Snackbar>
);

OfflineMessage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(OfflineMessage);
