import http from 'http/index';

export default (url, fileName, params) => {
  http.get(url, { params }).then((res) => {
    // eslint-disable-next-line no-console
    console.log({ headers: res.headers });
    // if (res.headers['content-disposition'].split('filename=') && res.headers['content-disposition'].split('filename=')[1]) {
    //   // eslint-disable-next-line prefer-destructuring
    //   fileName = res.headers['content-disposition'].split('filename=')[1].replace('"', '').replace('"', '');
    // }
    if (res.data.url) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = res.data.url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      return;
    }

    let objectUrl;
    if (typeof res.data !== 'string') {
      objectUrl = URL.createObjectURL(res.data);
    } else {
      const buffer = Buffer.from(res.data, 'base64');
      const byteArray = new Uint8Array(buffer);
      const blob = new Blob([byteArray]);
      objectUrl = URL.createObjectURL(blob);
    }

    const link = document.createElement('a');
    link.target = '_blank';
    link.href = objectUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  });
};
