import { takeLatest, put } from 'redux-saga/effects';

function* fetchFiltersData() {
  try {
    yield put({ type: 'GET_FILTERS_SUCCESS' });
  } catch (error) {
    yield put({ type: 'GET_FILTERS_ERROR', error });
  }
}

function* addFilterLayer({ data }) {
  yield put({ type: 'ADD_FILTER_LAYER_SUCCESS', data });
}

function* removeFilterLayer({ data }) {
  yield put({ type: 'REMOVE_FILTER_SUCCESS', data });
}

function* editFilter({ data }) {
  yield put({ type: 'EDIT_FILTER_SUCCESS', data });
}

function* showFilterEditPanel({ data }) {
  yield put({ type: 'EDIT_FILTER_SUCCESS', data });
}

function* showFilterTableModal({ data }) {
  yield put({ type: 'EDIT_FILTER_SUCCESS', data });
}

function* loadFiltersFromLocalDB() {
  yield put({ type: 'LOAD_FILTERS_SUCCESS' });
}

export default function* watchFiltersFetch() {
  yield takeLatest('GET_FILTERS', fetchFiltersData);
  yield takeLatest('ADD_FILTER_LAYER', addFilterLayer);
  yield takeLatest('REMOVE_FILTER', removeFilterLayer);
  yield takeLatest('EDIT_FILTER', editFilter);
  yield takeLatest('SHOW_FILTER_EDIT_PANEL', showFilterEditPanel);
  yield takeLatest('SHOW_TABLE_MODAL', showFilterTableModal);
  yield takeLatest('LOAD_FILTERS', loadFiltersFromLocalDB);
}
