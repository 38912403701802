import bitAnalyses from 'helpers/bitAnalyses';

export const filterStudents = (filters, students, allRaces) => {
  const {
    iep,
    ell,
    ses,
    cares,
    races,
    locations,
    genders,
    districts,
    regions,
  } = filters;

  const filtered = students
    .filter((record) => {
      let result = true;
      const studentRace = bitAnalyses(record.race);

      if (iep === 'true') {
        result = result && (record.iep === true || record.iep === 'Yes');
      }

      if (iep === 'false') {
        result = result && (record.iep === false || record.iep === 'No');
      }

      if (ell === 'true') {
        result = result && (record.ell === true || record.ell === 'Yes');
      }

      if (ell === 'false') {
        result = result && (record.ell === false || record.ell === 'No');
      }

      if (ses === 'true') {
        result = result && (record.ses === true || record.ses === 'Yes');
      }

      if (ses === 'false') {
        result = result && (record.ses === false || record.ses === 'No');
      }

      if (races.length && allRaces.length !== races.length) {
        result = result && studentRace.some((value) => races.map((raceOption) => raceOption.value).includes(value));
      }

      if (cares.length) {
        const isSuitable = cares.find(({ value }) => !!record[value]);

        result = result && isSuitable;
      }

      if (genders.length) {
        if (record.gender) {
          const isSuitable = genders.find((gender) => gender.value.toLowerCase() === record.gender.toLowerCase());

          result = result && (isSuitable);
        } else {
          return false;
        }
      }

      if (locations.length) {
        const isSuitable = locations.find((location) => location.value === record.school_id);

        result = result && (isSuitable);
      }

      if (districts.length) {
        const isSuitable = districts.find((district) => district.value === record.district_id);

        result = result && (isSuitable);
      }

      if (regions.length) {
        const isSuitable = regions.find((region) => region.value === record.region_id);

        result = result && (isSuitable);
      }

      return result;
    });
  return filtered;
};

export default filterStudents;
