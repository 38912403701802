import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

import {
  teachersFormat,
} from './formatter';

const getData = ({ data: id, isAdminMode = false, type }) => {
  if (isAdminMode) {
    return http.get(
      cas3ApiRoute(`/data-displays/admin/${type}`),
      { params: { dataCollectionId: id } },
    )
      .then(async (res) => {
        const output = await fetch(res.data.url);
        const data = await output.json();
        return { data };
      })
      .then((res) => teachersFormat(res.data));
  }

  return http.get(
    cas3ApiRoute(`/data-displays/teacher/${type}`),
    { params: { dataCollectionId: id } },
  )
    .then(async (res) => {
      const output = await fetch(res.data.url);
      const data = await output.json();
      return { data };
    })
    .then((res) => teachersFormat(res.data));
};

export default getData;
