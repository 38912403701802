export const formatDistrict = (district) => ({
  id: district.id,
  name: district.nameLabel,
  tenantParentId: district.parentTenantId,
  parentId: district.parentId,
  uniqueId: district.uniqueId,
  is_census: district.isCensus,
});

export const formatDistricts = (districts) => districts.map((district) => formatDistrict(district));

export const formatIntegration = (integrations) => (integrations[0] || null);

export const formatIntegrationUsers = (users) => users.map(({
  id,
  firstName,
  lastName,
  email,
}) => ({
  id,
  firstName,
  lastName,
  email,
}));
