import { navigate } from '@reach/router';

import {
  takeLatest,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import { actions as snackbarActions } from 'redux/snackbar';
import i18n from 'i18n';

import * as api from 'http/admin/students';
import { formatStudentsWithEnrollments, formatStudentWithEnrollments } from 'http/admin/students/formatter';
import { raceFormatter } from 'http/data-displays/formatter';

import { actions as optionsActions } from '../../options';

import { actions } from './index';

export function* getAvailableRosters({ payload }) {
  try {
    const { tenantId, userId } = payload;

    const result = yield call(api.getAvailableRosters, { tenantId, userId });

    yield put(actions.getAvailableRostersSuccess(result));
  } catch (error) {
    yield put(actions.getAvailableRostersFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getStudentsRosters({ payload }) {
  try {
    const { tenantId, userId } = payload;

    const result = yield call(api.getStudentsRosters, { tenantId, userId });

    yield put(actions.getStudentsRostersSuccess(result));
  } catch (error) {
    yield put(actions.getStudentsRostersFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* updateStudentsRosters({ payload }) {
  try {
    const { tenantId, userId, enrollmentId } = payload;

    yield call(api.updateStudentsRosters, { userId, enrollmentId });

    yield put(actions.updateStudentsRostersSuccess());
    yield put(actions.getAvailableRosters({ tenantId, userId }));
    yield put(actions.getStudentsRosters({ tenantId, userId }));
  } catch (error) {
    yield put(actions.updateStudentsRostersFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getStudentsByTenant({ payload }) {
  try {
    const { tenantId } = payload;

    const result = yield call(api.getStudentsByTenant, { tenantId });

    const formattedStudents = formatStudentsWithEnrollments(result.students);

    yield put(actions.getStudentsByTenantSuccess(formattedStudents));
    yield put(optionsActions.getRacesSuccess({ data: raceFormatter(result.races) }));
  } catch (error) {
    yield put(actions.getStudentsByTenantFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getStudentsByLocation({ payload }) {
  try {
    const { locationId } = payload;

    const result = yield call(api.getStudentsByLocation, { locationId });

    const formattedStudents = formatStudentsWithEnrollments(result.students);

    yield put(actions.getStudentsByLocationSuccess(formattedStudents));
    yield put(optionsActions.getRacesSuccess({ data: raceFormatter(result.races) }));
  } catch (error) {
    yield put(actions.getStudentsByLocationFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* createStudent({ payload }) {
  const {
    tenantId,
    regionId,
    districtId,
    locationId,
    data,
    successHandle,
    transfer = false,
    formType,
    isOpenAfter,
  } = payload;
  const successMessage = transfer ? 'Student Transfer Requested' : 'Student successfully created';

  try {
    const student = {
      tenantUniqueId: data.studentStateId,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      gender: data.gender,
      dob: data.dob.unix(),
      englishLanguageLearner: data.ell === '1',
      race: data.race.reduce((accum, value) => accum + value, 0),
      parentLocationId: +locationId,
      lowSes: data.lowSes === '1',
      specialEducationDisabilityCode: +data.disabilityCode,
      specialEducation: data.specialEducation === '1',
    };

    const result = yield call(api.createStudent, student);

    yield put(actions.createStudentSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: successMessage } }));
    if (formType === 'location') {
      yield put(actions.getStudentsByLocation({
        tenantId, regionId, districtId, locationId,
      }));
    } else if (formType === 'studentList') {
      yield put(actions.getStudentsByTenant({ tenantId }));
    }

    if (isOpenAfter) {
      // eslint-disable-next-line max-len
      navigate(`/administrator/organizations/regions/${regionId}/district/${districtId}/location/${locationId}/students/${result.id}/profile/details`);
    }
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.createStudentFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getStudentDetails({ payload }) {
  try {
    const { studentId, locationId } = payload;
    const result = yield call(api.getStudentDetails, { studentId, locationId });

    const formattedStudent = formatStudentWithEnrollments(result.student);

    yield put(actions.getStudentDetailsSuccess(formattedStudent));
    yield put(optionsActions.getRacesSuccess({ data: raceFormatter(result.races) }));
  } catch (error) {
    yield put(actions.getStudentDetailsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* updateStudent({ payload }) {
  try {
    const {
      tenantId,
      regionId,
      districtId,
      locationId,
      studentId,
      data,
      getDetailsByLocation,
    } = payload;

    const student = {
      id: +studentId,
      tenantUniqueId: data.studentStateId,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      gender: data.gender,
      dob: data.dob.unix(),
      englishLanguageLearner: data.ell === '1',
      race: data.race.reduce((accum, value) => accum + value, 0),
      lowSes: data.lowSes === '1',
    };

    const enrollment = {
      specialEducationDisabilityCode: data.disabilityCode,
      specialEducation: data.specialEducation === '1',
    };

    yield call(api.updateStudent, { student, enrollment });

    yield put(actions.updateStudentSuccess());
    yield put(actions.getStudentDetails({
      getDetailsByLocation, tenantId, regionId, districtId, locationId, studentId,
    }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Student successfully updated' } }));
  } catch (error) {
    yield put(actions.updateStudentFailure(error));
    yield put(snackbarActions.showSnackbar({
      data: {
        type: 'error',
        message: Array.isArray(error.errors) ? error.errors.join('\n') : error.message,
      },
    }));
  }
}

export function* removeStudent({ payload }) {
  try {
    const {
      data: { tenantId, userId, associationId },
      successHandle,
    } = payload;

    const result = yield call(api.removeStudent, { userId, associationId });

    yield put(actions.removeStudentSuccess(result));
    yield put(actions.getAvailableRosters({ tenantId, userId }));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Student successfully removed' } }));
    yield put(actions.getStudentsRosters({ tenantId, userId }));

    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.removeStudentFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* checkStudentExiting({ payload }) {
  const {
    tenantId,
    studentStateId,
    studentIdMustBeUnique,
    successHandle,
  } = payload;

  try {
    const result = yield call(api.checkStudentExiting, {
      tenantId,
      studentStateId,
    });

    yield put(actions.checkStudentExitingSuccess(result));
    if (studentIdMustBeUnique && result) {
      yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Student State ID must be unique' } }));
    }

    if (successHandle) successHandle(result);
  } catch (error) {
    if (payload?.errorHandle) payload.errorHandle(error);

    yield put(actions.checkStudentExitingFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getStudentOutputs({ payload }) {
  try {
    const { studentId } = payload;

    const result = yield call(api.getStudentOutputs, { studentId });

    yield put(actions.getStudentOutputsSuccess(result));
  } catch (error) {
    yield put(actions.getStudentOutputsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getStudentOutputsForUpdate({ payload }) {
  try {
    const {
      studentId,
      collectionId,
      successHandle,
    } = payload;

    const result = yield call(api.getStudentOutputs, { studentId });

    yield put(actions.getStudentOutputsForUpdateSuccess({ outputsForUpdate: result, collectionId }));

    if (successHandle) successHandle();
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.getStudentOutputsForUpdateFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* generateStudentOutput({ payload }) {
  try {
    const {
      collectionId,
      studentId,
      successHandle,
    } = payload;

    yield call(api.generateStudentOutput, { collectionId, studentId });

    yield put(actions.generateStudentOutputSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'ISR Generated' } }));

    if (successHandle) successHandle();
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.generateStudentOutputFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* clearStudentOutput({ payload }) {
  try {
    const {
      outputId,
      successHandle,
    } = payload;

    yield call(api.clearStudentOutput, { outputId });

    yield put(actions.clearStudentOutputSuccess({ outputId }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'ISR Cleared' } }));

    if (successHandle) successHandle();
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.clearStudentOutputFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getStudentAssignmentsByEnrollment({ payload }) {
  try {
    const {
      tenantId,
      studentId,
      studentEnrollmentId,
      isTenantAdmin,
    } = payload;

    const result = yield call(api.getStudentAssignmentsByEnrollment, { tenantId, studentId, studentEnrollmentId });

    yield put(actions.getStudentAssignmentsByEnrollmentSuccess({ assignments: result, isTenantAdmin }));
  } catch (error) {
    yield put(actions.getStudentAssignmentsByEnrollmentFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* unassignDataCollectionByAssignment({ payload }) {
  try {
    const {
      assignmentId,
      successHandle,
    } = payload;

    yield call(api.unassignDataCollectionByAssignment, { assignmentId });

    yield put(actions.unassignDataCollectionByAssignmentSuccess({ assignmentId }));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Data Collection Unassigned' } }));

    if (successHandle) successHandle();
  } catch (error) {
    yield put(actions.unassignDataCollectionByAssignmentFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* assignDataCollectionByAssignment({ payload }) {
  try {
    const {
      studentEnrollmentId,
      data,
      successHandle,
    } = payload;

    const formattedData = {
      studentEnrollmentId,
      parentDataCollectionId: data.data_collection_id,
      teacherType: 'Primary',
    };

    yield call(api.assignDataCollectionByAssignment, { studentEnrollmentId, data: formattedData });

    yield put(actions.assignDataCollectionByAssignmentSuccess());

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Data Collection Assigned' } }));

    if (successHandle) successHandle();
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.assignDataCollectionByAssignmentFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Failed to Assign Data Collection' } }));
  }
}

export function* getEnrollmentsByStudent({ payload }) {
  try {
    const {
      tenantId,
      studentId,
      isNeedReload,
      successHandle,
    } = payload;

    const result = yield call(api.getEnrollmentsByStudent, { tenantId, studentId });

    yield put(actions.getEnrollmentsByStudentSuccess({ isNeedReload }));

    if (successHandle) successHandle(result);
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.getEnrollmentsByStudentFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getPoasByStudent({ payload }) {
  try {
    const {
      tenantId,
      studentId,
      enrollments,
      successHandle,
    } = payload;

    const result = yield call(api.getPoasByStudent, { tenantId, studentId });

    yield put(actions.getPoasByStudentSuccess({ enrollments, poas: result }));

    if (successHandle) successHandle(result);
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.getPoasByStudentFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* makePoaByStudent({ payload }) {
  try {
    const {
      enrollmentId,
      locationId,
      studentId,
      successHandle,
    } = payload;

    yield call(
      api.makePoaByStudent,
      {
        studentId: +studentId,
        locationId,
        enrollmentId,
        isPointOfAuthority: true,
      },
    );

    yield put(actions.makePoaByStudentSuccess());

    if (successHandle) successHandle();
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'POA changed successfully' } }));
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.makePoaByStudentFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* validatePoa({ payload }) {
  try {
    const {
      tenantId,
      studentId,
      poaId,
      locationName,
    } = payload;

    const result = yield call(api.validatePoa, { tenantId, studentId, poaId });

    yield put(actions.validatePoaSuccess({ result, locationName }));
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.validatePoaFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getStudentEnrollments({ payload }) {
  try {
    const {
      tenantId,
      studentId,
      actionHandle,
    } = payload;

    const result = yield call(api.getStudentEnrollments, { tenantId, studentId });

    yield put(actions.getStudentEnrollmentsSuccess(result));
    yield put(actions.setCurrentEnrollment(result[0] || {}));
    if (actionHandle) actionHandle();
  } catch (error) {
    yield put(actions.getStudentEnrollmentsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getEnrollmentAssociations({ payload }) {
  try {
    const { enrollmentId, successHandler } = payload;

    const result = yield call(api.getEnrollmentAssociations, enrollmentId);

    yield put(actions.getEnrollmentAssociationsSuccess(result));

    if (successHandler) {
      successHandler();
    }
  } catch (error) {
    yield put(actions.getEnrollmentAssociationsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* updateEnrollment({ payload }) {
  try {
    const {
      tenantId,
      studentId,
      customFieldsData,
      data,
      successHandler,
    } = payload;
    const body = { ...data };
    Object.keys(body).forEach((key) => {
      const test = /((input)|(option))_\d*/g.test(key);
      if (test && body[key] === '') { body[key] = null; }
    });

    customFieldsData.forEach((cf) => {
      if (cf.type === 'numeric' && body[cf.studentField] !== null && Number.isInteger(parseInt(body[cf.studentField], 10))) {
        body[cf.studentField] = +body[cf.studentField];
      }
    });
    body.enrollmentId = body.id;
    body.input1 = body.input_1;
    body.input2 = body.input_2;
    body.input3 = body.input_3;
    body.input4 = body.input_4;
    body.input5 = body.input_5;
    body.input6 = body.input_6;
    body.input7 = body.input_7;
    body.input8 = body.input_8;
    body.input9 = body.input_9;
    body.input10 = body.input_10;
    body.option1 = body.option_1;
    body.option2 = body.option_2;
    body.option3 = body.option_3;
    body.option4 = body.option_4;
    body.option5 = body.option_5;
    body.option6 = body.option_6;
    body.option7 = body.option_7;
    body.option8 = body.option_8;
    body.option9 = body.option_9;
    body.option10 = body.option_10;

    Object.keys(body).forEach((key) => {
      const test = /((input)|(option))_\d*/g.test(key);
      if (test) { delete body[key]; }
    });

    yield call(api.updateEnrollment, body);

    yield put(actions.updateEnrollmentSuccess(body));
    yield put(actions.getStudentEnrollments({
      studentId,
      tenantId,
      actionHandle: successHandler,
    }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: i18n.t('Enrollment info successfully updated') } }));
  } catch (error) {
    yield put(actions.updateEnrollmentFailure(error.errors));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* removeEnrollmentAssociation({ payload }) {
  try {
    const {
      data: { associationId },
      successHandler,
    } = payload;

    yield call(api.removeEnrollmentAssociation, { associationId });

    yield put(actions.removeEnrollmentAssociationSuccess({ id: associationId }));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: i18n.t('Teacher assignment removed') } }));

    if (successHandler) {
      successHandler();
    }
  } catch (error) {
    yield put(actions.removeEnrollmentAssociationFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getStudentLocationMembers({ payload }) {
  try {
    const { locationId } = payload;

    const params = { roles: 'ROLE_TEACHER' };

    const result = yield call(api.getStudentLocationMembers, { locationId, params });

    yield put(actions.getStudentLocationMembersSuccess(result));
  } catch (error) {
    yield put(actions.getStudentLocationMembersFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* createEnrollmentAssociation({ payload }) {
  const {
    data: {
      locationId,
      enrollmentId,
      teacher,
    },
    successHandler,
  } = payload;

  try {
    const enrollmentAssociation = yield call(api.createEnrollmentAssociation, {
      locationId,
      enrollmentId,
      userId: teacher.id,
    });

    yield put(actions.createEnrollmentAssociationSuccess({
      ...enrollmentAssociation,
      teacherFullName: `${teacher.firstName} ${teacher.lastName}`,
      associationDate: enrollmentAssociation.createdAt,
    }));

    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: i18n.t('Teacher successfully assigned') } }));
    yield put(actions.getEnrollmentAssociations({ enrollmentId, successHandler }));
  } catch (error) {
    yield put(actions.createEnrollmentAssociationFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
    // Update enrollment associations when user get error
    yield put(actions.getEnrollmentAssociations({ enrollmentId, successHandler }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.getAvailableRosters, getAvailableRosters);
  yield takeLatest(actions.getStudentsRosters, getStudentsRosters);
  yield takeLatest(actions.updateStudentsRosters, updateStudentsRosters);
  yield takeLatest(actions.getStudentsByTenant, getStudentsByTenant);
  yield takeLatest(actions.getStudentsByLocation, getStudentsByLocation);
  yield takeLatest(actions.createStudent, createStudent);
  yield takeLatest(actions.getStudentDetails, getStudentDetails);
  yield takeLatest(actions.updateStudent, updateStudent);
  yield takeLatest(actions.removeStudent, removeStudent);
  yield takeLatest(actions.checkStudentExiting, checkStudentExiting);
  yield takeLatest(actions.getEnrollmentsByStudent, getEnrollmentsByStudent);
  yield takeLatest(actions.getPoasByStudent, getPoasByStudent);
  yield takeLatest(actions.makePoaByStudent, makePoaByStudent);
  yield takeEvery(actions.validatePoa, validatePoa);
  yield takeLatest(actions.getStudentOutputs, getStudentOutputs);
  yield takeEvery(actions.getStudentOutputsForUpdate, getStudentOutputsForUpdate);
  yield takeEvery(actions.generateStudentOutput, generateStudentOutput);
  yield takeEvery(actions.clearStudentOutput, clearStudentOutput);
  yield takeLatest(actions.getStudentAssignmentsByEnrollment, getStudentAssignmentsByEnrollment);
  yield takeLatest(actions.unassignDataCollectionByAssignment, unassignDataCollectionByAssignment);
  yield takeLatest(actions.getStudentEnrollments, getStudentEnrollments);
  yield takeLatest(actions.getEnrollmentAssociations, getEnrollmentAssociations);
  yield takeLatest(actions.updateEnrollment, updateEnrollment);
  yield takeLatest(actions.removeEnrollmentAssociation, removeEnrollmentAssociation);
  yield takeEvery(actions.assignDataCollectionByAssignment, assignDataCollectionByAssignment);
  yield takeLatest(actions.getStudentLocationMembers, getStudentLocationMembers);
  yield takeEvery(actions.createEnrollmentAssociation, createEnrollmentAssociation);
}
