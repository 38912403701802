const initialState = {
  message: 'Message',
  type: 'info',
  show: false,
};

export const showSnackbar = (state, { payload }) => ({
  ...state,
  message: payload.data.message,
  type: payload.data.type,
  show: true,
});

export const hideSnackbar = (state) => ({
  ...state,
  message: '',
  type: initialState.type,
  show: false,
});

export default initialState;
