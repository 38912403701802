import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const GET_LOCATIONS_BY_DISTRICT = 'GET_LOCATIONS_BY_DISTRICT';
export const GET_LOCATIONS_BY_DISTRICT_SUCCESS = 'GET_LOCATIONS_BY_DISTRICT_SUCCESS';
export const GET_LOCATIONS_BY_DISTRICT_FAILURE = 'GET_LOCATIONS_BY_DISTRICT_FAILURE';

export const CREATE_LOCATION = 'CREATE_LOCATION';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAILURE = 'CREATE_LOCATION_FAILURE';

export const GET_LOCATION_DETAILS = 'GET_LOCATION_DETAILS';
export const GET_LOCATION_DETAILS_SUCCESS = 'GET_LOCATION_DETAILS_SUCCESS';
export const GET_LOCATION_DETAILS_FAILURE = 'GET_LOCATION_DETAILS_FAILURE';

export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE';

export const DELETE_LOCATION = 'DELETE_LOCATION';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE';

export const REFRESH_LOCATION_DETAILS = 'REFRESH_LOCATION_DETAILS';

export const actions = createActions({
  [GET_LOCATIONS_BY_DISTRICT]: undefined,
  [GET_LOCATIONS_BY_DISTRICT_SUCCESS]: undefined,
  [GET_LOCATIONS_BY_DISTRICT_FAILURE]: undefined,

  [CREATE_LOCATION]: undefined,
  [CREATE_LOCATION_SUCCESS]: undefined,
  [CREATE_LOCATION_FAILURE]: undefined,

  [GET_LOCATION_DETAILS]: undefined,
  [GET_LOCATION_DETAILS_SUCCESS]: undefined,
  [GET_LOCATION_DETAILS_FAILURE]: undefined,

  [UPDATE_LOCATION]: undefined,
  [UPDATE_LOCATION_SUCCESS]: undefined,
  [UPDATE_LOCATION_FAILURE]: undefined,

  [DELETE_LOCATION]: undefined,
  [DELETE_LOCATION_SUCCESS]: undefined,
  [DELETE_LOCATION_FAILURE]: undefined,

  [REFRESH_LOCATION_DETAILS]: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getLocationsByDistrict, handlers.getLocationsByDistrict],
    [actions.getLocationsByDistrictSuccess, handlers.getLocationsByDistrictSuccess],
    [actions.getLocationsByDistrictFailure, handlers.getLocationsByDistrictFailure],

    [actions.createLocation, handlers.createLocation],
    [actions.createLocationSuccess, handlers.createLocationSuccess],
    [actions.createLocationFailure, handlers.createLocationFailure],

    [actions.getLocationDetails, handlers.getLocationDetails],
    [actions.getLocationDetailsSuccess, handlers.getLocationDetailsSuccess],
    [actions.getLocationDetailsFailure, handlers.getLocationDetailsFailure],

    [actions.updateLocation, handlers.updateLocation],
    [actions.updateLocationSuccess, handlers.updateLocationSuccess],
    [actions.updateLocationFailure, handlers.updateLocationFailure],

    [actions.deleteLocation, handlers.deleteLocation],
    [actions.deleteLocationSuccess, handlers.deleteLocationSuccess],
    [actions.deleteLocationFailure, handlers.deleteLocationFailure],

    [actions.refreshLocationDetails, handlers.refreshLocationDetails],
  ]),
  initialState,
);

export default reducer;
