import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_DATA: undefined,
  GET_DATA_ERROR: undefined,
  GET_DATA_SUCCESS: undefined,
  REFRESH_COMMON_DATA: undefined,
  SET_STUDENTS_TEACHERS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getData, handlers.getData],
    [actions.getDataError, handlers.getDataError],
    [actions.getDataSuccess, handlers.getDataSuccess],
    [actions.refreshCommonData, handlers.refreshCommonData],
    [actions.setStudentsTeachers, handlers.setStudentsTeachers],
  ]),
  initialState,
);

export default reducer;
