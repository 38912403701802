/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    backgroundColor: 'rgba(128, 128, 128, 0.5)',
    zIndex: 1099,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  sessionVerifying: {
    fontSize: '40px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

const Loading = ({ title, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.paper} {...rest}>
      <div className={classes.sessionVerifying}>{title}</div>
      <CircularProgress size={100} />
    </div>
  );
};

Loading.defaultProps = {
  title: '',
};

Loading.propTypes = {
  title: PropTypes.string,
};

export default Loading;
