import axios from 'axios';
import errorHandler from 'helpers/http/errorHandler';
import * as CasInterop from 'helpers/cas4/interop';

// set runtime env vars temporary, it should be removed after .env vars are configured in AWS build
// emulate global process.env
window.runtime_process_env = process.env;

// the deployment cases:
// 1) CAS2 + Bundle with CAS3 FE: no .env vars, use `//${window.location.hostname}`
// 2) CAS3 Teacher FE + CAS2 Admin/System FE: clear .env.REACT_APP_BASE_API and .env.REACT_APP_ADMIN_VERSION,
//    use `//${window.location.hostname}` (proxy or rewrite rules in AWS to real API host)
// 3) CAS3 Teacher+Admin FE: set .env.REACT_APP_BASE_API and .env.REACT_APP_ADMIN_VERSION = CAS3
// 4) local dev for CAS3 Teacher+Admin FE: set .env.REACT_APP_BASE_API and .env.REACT_APP_ADMIN_VERSION = CAS3
window.runtime_process_env.REACT_APP_BASE_API = process.env.REACT_APP_BASE_API || `//${window.location.hostname}`;

// dev API proxy
const useProxy = process.env.REACT_APP_SHOULD_USE_API_PROXY && JSON.parse(process.env.REACT_APP_SHOULD_USE_API_PROXY);
if (useProxy) {
  window.runtime_process_env.REACT_APP_BASE_API = '';
}

window.runtime_process_env.REACT_APP_PATH = process.env.REACT_APP_PATH || '/en/api/v1';

const { REACT_APP_BASE_API, REACT_APP_PATH } = window.runtime_process_env;

const http = axios.create({
  baseURL: `${REACT_APP_BASE_API}${REACT_APP_PATH}`,
  withCredentials: true,
});

http.interceptors.response.use((response) => response, (error) => {
  let errorMessage = error;

  if (error.response.status === 401) {
    CasInterop.eraseAuth();
    CasInterop.navigate('/sign-in', { reason: 'logout' });
  }

  if (error.response.data.message) {
    throw error.response.data;
  }

  // Integrate errorHandler function only for admin requests
  if (error.config && error.config.url.includes('/admin')) {
    errorMessage = errorHandler(error);
  }

  return Promise.reject(errorMessage);
});

export default http;
