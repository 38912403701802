import { takeLatest, call, put } from 'redux-saga/effects';

import { getAssessments, getAnnouncements } from 'http/teacher';
import { actions } from './index';

export function* fetchAssessments() {
  try {
    const result = yield call(getAssessments);

    yield put(actions.teacherGetAssessmentsSuccess({ data: result }));
  } catch (error) {
    yield put(actions.teacherGetAssessmentsFail({ data: error }));
  }
}

export function* fetchAnnoucements() {
  try {
    const result = yield call(getAnnouncements);

    yield put(actions.teacherGetAnnouncementsSuccess({ data: result }));
  } catch (error) {
    yield put(actions.teacherGetAnnouncementsFail({ data: error }));
  }
}

export default function* watchDashboardCalls() {
  yield takeLatest(actions.teacherGetAssessments, fetchAssessments);
  yield takeLatest(actions.teacherGetAnnouncements, fetchAnnoucements);
}
