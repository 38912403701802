import { takeLatest, put, delay } from 'redux-saga/effects';

import initialState from './handlers';
import { actions } from './index';

const pollDelay = 30000; // In milliseconds.

function* pollSkipLinks() {
  while (true) {
    const testBatch = [...initialState.links];
    testBatch.forEach((link) => {
      if (document.getElementById(link.target) !== null) link.visible = true;
    });
    yield put(actions.pollSkiplinksSuccess({ data: testBatch }));
    yield delay(pollDelay);
  }
}

export default function* watchUserFetch() {
  yield takeLatest(actions.pollSkiplinks, pollSkipLinks);
}
