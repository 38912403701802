import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const SET_GENERATING_FLAG = 'SET_GENERATING_FLAG';
export const TEACHER_GET_ASSESSMENT = 'TEACHER_GET_ASSESSMENT';
export const GET_ASSESSMENT_ERROR = 'GET_ASSESSMENT_ERROR';
export const GET_ASSESSMENT_SUCCESS = 'GET_ASSESSMENT_SUCCESS';
export const TEACHER_GET_COLLECTION_ITEMS = 'TEACHER_GET_COLLECTION_ITEMS';
export const GET_COLLECTION_ITEMS_ERROR = 'GET_COLLECTION_ITEMS_ERROR';
export const GET_COLLECTION_ITEMS_SUCCESS = 'GET_COLLECTION_ITEMS_SUCCESS';
export const GET_ITEM_MATERIALS_REQUEST = 'GET_ITEM_MATERIALS_REQUEST';
export const GET_ITEM_MATERIALS_SUCCESS = 'GET_ITEM_MATERIALS_SUCCESS';
export const GET_ITEM_MATERIALS_ERROR = 'GET_ITEM_MATERIALS_ERROR';
export const GET_COLLECTION_ITEMS_MATERIALS_REQUEST = 'GET_COLLECTION_ITEMS_MATERIALS_REQUEST';
export const GET_COLLECTION_ITEMS_MATERIALS_SUCCESS = 'GET_COLLECTION_ITEMS_MATERIALS_SUCCESS';
export const GET_COLLECTION_ITEMS_MATERIALS_ERROR = 'GET_COLLECTION_ITEMS_MATERIALS_ERROR';
export const GET_ISRS_REPORT_REQUEST = 'GET_ISRS_REPORT_REQUEST';
export const GET_ISRS_REPORT_RESPONSE = 'GET_ISRS_REPORT_RESPONSE';
export const GET_RATING_SHEETS_REPORT_REQUEST = 'GET_RATING_SHEETS_REPORT_REQUEST';
export const GET_RATING_SHEETS_REPORT_RESPONSE = 'GET_RATING_SHEETS_REPORT_RESPONSE';
export const SET_COLLECTION_STUDENTS = 'SET_COLLECTION_STUDENTS';
export const TEACHER_GET_COLLECTION_SCALES = 'TEACHER_GET_COLLECTION_SCALES';
export const GET_COLLECTION_SCALES_ERROR = 'GET_COLLECTION_SCALES_ERROR';
export const GET_COLLECTION_SCALES_SUCCESS = 'GET_COLLECTION_SCALES_SUCCESS';
export const SET_COLLECTION_GRADES = 'SET_COLLECTION_GRADES';
export const TEACHER_GET_COLLECTION_SETS = 'TEACHER_GET_COLLECTION_SETS';
export const GET_COLLECTION_SETS_ERROR = 'GET_COLLECTION_SETS_ERROR';
export const GET_COLLECTION_SETS_SUCCESS = 'GET_COLLECTION_SETS_SUCCESS';
export const TEACHER_GET_COLLECTION_HIERARCHY = 'TEACHER_GET_COLLECTION_HIERARCHY';
export const GET_COLLECTION_HIERARCHY_ERROR = 'GET_COLLECTION_HIERARCHY_ERROR';
export const GET_COLLECTION_HIERARCHY_SUCCESS = 'GET_COLLECTION_HIERARCHY_SUCCESS';
export const SET_STUDENTS_ALL_TEACHERS = 'SET_STUDENTS_ALL_TEACHERS';
export const REFRESH_ASSESSMENT_STATE = 'REFRESH_ASSESSMENT_STATE';
export const TEACHER_GET_COLLECTION_STUDENTS = 'TEACHER_GET_COLLECTION_STUDENTS';
export const GET_COLLECTION_STUDENTS_ERROR = 'GET_COLLECTION_STUDENTS_ERROR';
export const GET_COLLECTION_STUDENTS_SUCCESS = 'GET_COLLECTION_STUDENTS_SUCCESS';
export const TEACHER_GET_COLLECTION_GRADES = 'TEACHER_GET_COLLECTION_GRADES';
export const GET_COLLECTION_GRADES_ERROR = 'GET_COLLECTION_GRADES_ERROR';
export const GET_COLLECTION_GRADES_SUCCESS = 'GET_COLLECTION_GRADES_SUCCESS';
export const SAVE_STUDENT_ASSIGNMENT_TYPE = 'SAVE_STUDENT_ASSIGNMENT_TYPE';
export const SAVE_STUDENT_ASSIGNMENT_TYPE_SUCCESS = 'SAVE_STUDENT_ASSIGNMENT_TYPE_SUCCESS';
export const SAVE_STUDENT_ASSIGNMENT_TYPE_FAILURE = 'SAVE_STUDENT_ASSIGNMENT_TYPE_FAILURE';
export const UPDATE_STUDENT_OUTPUT = 'UPDATE_STUDENT_OUTPUT';

export const actions = createActions({
  [SET_GENERATING_FLAG]: undefined,

  [TEACHER_GET_ASSESSMENT]: undefined,
  [GET_ASSESSMENT_ERROR]: undefined,
  [GET_ASSESSMENT_SUCCESS]: undefined,

  [TEACHER_GET_COLLECTION_ITEMS]: undefined,
  [GET_COLLECTION_ITEMS_ERROR]: undefined,
  [GET_COLLECTION_ITEMS_SUCCESS]: undefined,

  [GET_ITEM_MATERIALS_REQUEST]: undefined,
  [GET_ITEM_MATERIALS_SUCCESS]: undefined,
  [GET_ITEM_MATERIALS_ERROR]: undefined,

  [GET_COLLECTION_ITEMS_MATERIALS_REQUEST]: undefined,
  [GET_COLLECTION_ITEMS_MATERIALS_SUCCESS]: undefined,
  [GET_COLLECTION_ITEMS_MATERIALS_ERROR]: undefined,

  [GET_ISRS_REPORT_REQUEST]: undefined,
  [GET_ISRS_REPORT_RESPONSE]: undefined,

  [GET_RATING_SHEETS_REPORT_REQUEST]: undefined,
  [GET_RATING_SHEETS_REPORT_RESPONSE]: undefined,

  [SET_COLLECTION_STUDENTS]: undefined,

  [TEACHER_GET_COLLECTION_SCALES]: undefined,
  [GET_COLLECTION_SCALES_ERROR]: undefined,
  [GET_COLLECTION_SCALES_SUCCESS]: undefined,

  [SET_COLLECTION_GRADES]: undefined,

  [TEACHER_GET_COLLECTION_SETS]: undefined,
  [GET_COLLECTION_SETS_ERROR]: undefined,
  [GET_COLLECTION_SETS_SUCCESS]: undefined,

  [TEACHER_GET_COLLECTION_HIERARCHY]: undefined,
  [GET_COLLECTION_HIERARCHY_ERROR]: undefined,
  [GET_COLLECTION_HIERARCHY_SUCCESS]: undefined,

  [SET_STUDENTS_ALL_TEACHERS]: undefined,

  [REFRESH_ASSESSMENT_STATE]: undefined,

  [TEACHER_GET_COLLECTION_STUDENTS]: undefined,
  [GET_COLLECTION_STUDENTS_ERROR]: undefined,
  [GET_COLLECTION_STUDENTS_SUCCESS]: undefined,

  [TEACHER_GET_COLLECTION_GRADES]: undefined,
  [GET_COLLECTION_GRADES_ERROR]: undefined,
  [GET_COLLECTION_GRADES_SUCCESS]: undefined,

  [SAVE_STUDENT_ASSIGNMENT_TYPE]: undefined,
  [SAVE_STUDENT_ASSIGNMENT_TYPE_SUCCESS]: undefined,
  [SAVE_STUDENT_ASSIGNMENT_TYPE_FAILURE]: undefined,

  [UPDATE_STUDENT_OUTPUT]: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.setGeneratingFlag, handlers.setGeneratingFlag],

    [actions.teacherGetAssessment, handlers.teacherGetAssessment],
    [actions.getAssessmentError, handlers.getAssessmentError],
    [actions.getAssessmentSuccess, handlers.getAssessmentSuccess],

    [actions.teacherGetCollectionItems, handlers.teacherGetCollectionItems],
    [actions.getCollectionItemsError, handlers.getCollectionItemsError],
    [actions.getCollectionItemsSuccess, handlers.getCollectionItemsSuccess],

    [actions.getItemMaterialsRequest, handlers.getItemMaterialsRequest],
    [actions.getItemMaterialsSuccess, handlers.getItemMaterialsSuccess],
    [actions.getItemMaterialsError, handlers.getItemMaterialsError],

    [actions.getCollectionItemsMaterialsRequest, handlers.getCollectionItemsMaterialsRequest],
    [actions.getCollectionItemsMaterialsSuccess, handlers.getCollectionItemsMaterialsSuccess],
    [actions.getCollectionItemsMaterialsError, handlers.getCollectionItemsMaterialsError],

    [actions.getIsrsReportRequest, handlers.getIsrsReportRequest],
    [actions.getIsrsReportResponse, handlers.getIsrsReportResponse],

    [actions.getRatingSheetsReportRequest, handlers.getRatingSheetsReportRequest],
    [actions.getRatingSheetsReportResponse, handlers.getRatingSheetsReportResponse],

    [actions.setCollectionStudents, handlers.setCollectionStudents],

    [actions.teacherGetCollectionScales, handlers.teacherGetCollectionScales],
    [actions.getCollectionScalesError, handlers.getCollectionScalesError],
    [actions.getCollectionScalesSuccess, handlers.getCollectionScalesSuccess],

    [actions.setCollectionGrades, handlers.setCollectionGrades],

    [actions.teacherGetCollectionSets, handlers.teacherGetCollectionSets],
    [actions.getCollectionSetsError, handlers.getCollectionSetsError],
    [actions.getCollectionSetsSuccess, handlers.getCollectionSetsSuccess],

    [actions.teacherGetCollectionHierarchy, handlers.teacherGetCollectionHierarchy],
    [actions.getCollectionHierarchyError, handlers.getCollectionHierarchyError],
    [actions.getCollectionHierarchySuccess, handlers.getCollectionHierarchySuccess],

    [actions.setStudentsAllTeachers, handlers.setStudentsAllTeachers],

    [actions.refreshAssessmentState, handlers.refreshAssessmentState],

    [actions.teacherGetCollectionStudents, handlers.teacherGetCollectionStudents],
    [actions.getCollectionStudentsError, handlers.getCollectionStudentsError],
    [actions.getCollectionStudentsSuccess, handlers.getCollectionStudentsSuccess],

    [actions.teacherGetCollectionGrades, handlers.teacherGetCollectionGrades],
    [actions.getCollectionGradesError, handlers.getCollectionGradesError],
    [actions.getCollectionGradesSuccess, handlers.getCollectionGradesSuccess],

    [actions.saveStudentAssignmentType, handlers.saveStudentAssignmentType],
    [actions.saveStudentAssignmentTypeSuccess, handlers.saveStudentAssignmentTypeSuccess],
    [actions.saveStudentAssignmentTypeFailure, handlers.saveStudentAssignmentTypeFailure],

    [actions.updateStudentOutput, handlers.updateStudentOutput],
  ]),
  initialState,
);

export default reducer;
