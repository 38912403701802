import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const GET_ANNOUNCEMENTS_FAILURE = 'GET_ANNOUNCEMENTS_FAILURE';
export const GET_ANNOUNCEMENTS_SUCCESS = 'GET_ANNOUNCEMENTS_SUCCESS';
export const SET_ANNOUNCEMENT_DETAILS = 'SET_ANNOUNCEMENT_DETAILS';
export const CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT';
export const CREATE_ANNOUNCEMENT_FAILURE = 'CREATE_ANNOUNCEMENT_FAILURE';
export const CREATE_ANNOUNCEMENT_SUCCESS = 'CREATE_ANNOUNCEMENT_SUCCESS';
export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';
export const UPDATE_ANNOUNCEMENT_FAILURE = 'UPDATE_ANNOUNCEMENT_FAILURE';
export const UPDATE_ANNOUNCEMENT_SUCCESS = 'UPDATE_ANNOUNCEMENT_SUCCESS';
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT_FAILURE = 'DELETE_ANNOUNCEMENT_FAILURE';
export const DELETE_ANNOUNCEMENT_SUCCESS = 'DELETE_ANNOUNCEMENT_SUCCESS';

export const actions = createActions({
  [GET_ANNOUNCEMENTS]: undefined,
  [GET_ANNOUNCEMENTS_FAILURE]: undefined,
  [GET_ANNOUNCEMENTS_SUCCESS]: undefined,

  [SET_ANNOUNCEMENT_DETAILS]: undefined,

  [CREATE_ANNOUNCEMENT]: undefined,
  [CREATE_ANNOUNCEMENT_FAILURE]: undefined,
  [CREATE_ANNOUNCEMENT_SUCCESS]: undefined,

  [UPDATE_ANNOUNCEMENT]: undefined,
  [UPDATE_ANNOUNCEMENT_FAILURE]: undefined,
  [UPDATE_ANNOUNCEMENT_SUCCESS]: undefined,

  [DELETE_ANNOUNCEMENT]: undefined,
  [DELETE_ANNOUNCEMENT_FAILURE]: undefined,
  [DELETE_ANNOUNCEMENT_SUCCESS]: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getAnnouncements, handlers.getAnnouncements],
    [actions.getAnnouncementsFailure, handlers.getAnnouncementsFailure],
    [actions.getAnnouncementsSuccess, handlers.getAnnouncementsSuccess],

    [actions.setAnnouncementDetails, handlers.setAnnouncementDetails],

    [actions.createAnnouncement, handlers.createAnnouncement],
    [actions.createAnnouncementFailure, handlers.createAnnouncementFailure],
    [actions.createAnnouncementSuccess, handlers.createAnnouncementSuccess],

    [actions.updateAnnouncement, handlers.updateAnnouncement],
    [actions.updateAnnouncementFailure, handlers.updateAnnouncementFailure],
    [actions.updateAnnouncementSuccess, handlers.updateAnnouncementSuccess],

    [actions.deleteAnnouncement, handlers.deleteAnnouncement],
    [actions.deleteAnnouncementFailure, handlers.deleteAnnouncementFailure],
    [actions.deleteAnnouncementSuccess, handlers.deleteAnnouncementSuccess],
  ]),
  initialState,
);

export default reducer;
