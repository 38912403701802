import React from 'react';
import { Router } from '@reach/router';

// Pages
import Forgot from './components/Forgot';
import Reset from './components/Reset';
import Login from './components/Login';

const LoginRouter = () => (
  <Router primary={false}>
    <Login path="/sign-in" />
    <Forgot path="/forgot" />
    <Reset path="/reset/:token" />
    <Reset path="/user-registration/:token" />
  </Router>
);

export const ForgotRouter = () => (
  <Router primary={false}>
    <Forgot path="/forgot" isAuthorized />
  </Router>
);

export default LoginRouter;
