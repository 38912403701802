import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const TEACHER_GET_ASSESSMENTS = 'TEACHER_GET_ASSESSMENTS';
export const TEACHER_GET_ASSESSMENTS_FAIL = 'TEACHER_GET_ASSESSMENTS_FAIL';
export const TEACHER_GET_ASSESSMENTS_SUCCESS = 'TEACHER_GET_ASSESSMENTS_SUCCESS';
export const TEACHER_GET_ANNOUNCEMENTS = 'TEACHER_GET_ANNOUNCEMENTS';
export const TEACHER_GET_ANNOUNCEMENTS_FAIL = 'TEACHER_GET_ANNOUNCEMENTS_FAIL';
export const TEACHER_GET_ANNOUNCEMENTS_SUCCESS = 'TEACHER_GET_ANNOUNCEMENTS_SUCCESS';

export const actions = createActions({
  [TEACHER_GET_ASSESSMENTS]: undefined,
  [TEACHER_GET_ASSESSMENTS_FAIL]: undefined,
  [TEACHER_GET_ASSESSMENTS_SUCCESS]: undefined,

  [TEACHER_GET_ANNOUNCEMENTS]: undefined,
  [TEACHER_GET_ANNOUNCEMENTS_FAIL]: undefined,
  [TEACHER_GET_ANNOUNCEMENTS_SUCCESS]: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.teacherGetAssessments, handlers.teacherGetAssessments],
    [actions.teacherGetAssessmentsFail, handlers.teacherGetAssessmentsFail],
    [actions.teacherGetAssessmentsSuccess, handlers.teacherGetAssessmentsSuccess],

    [actions.teacherGetAnnouncements, handlers.teacherGetAnnouncements],
    [actions.teacherGetAnnouncementsFail, handlers.teacherGetAnnouncementsFail],
    [actions.teacherGetAnnouncementsSuccess, handlers.teacherGetAnnouncementsSuccess],
  ]),
  initialState,
);

export default reducer;
