import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_GROUPS_LIST: undefined,
  GET_GROUPS_LIST_SUCCESS: undefined,
  GET_GROUPS_LIST_FAILURE: undefined,

  UPDATE_GROUP: undefined,
  UPDATE_GROUP_SUCCESS: undefined,
  UPDATE_GROUP_FAILURE: undefined,

  CREATE_GROUP: undefined,
  CREATE_GROUP_SUCCESS: undefined,
  CREATE_GROUP_FAILURE: undefined,

  DELETE_GROUP: undefined,
  DELETE_GROUP_SUCCESS: undefined,
  DELETE_GROUP_FAILURE: undefined,

  REFRESH_GROUPS_DATA: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getGroupsList, handlers.getGroupsList],
    [actions.getGroupsListSuccess, handlers.getGroupsListSuccess],
    [actions.getGroupsListFailure, handlers.getGroupsListFailure],

    [actions.updateGroup, handlers.updateGroup],
    [actions.updateGroupSuccess, handlers.updateGroupSuccess],
    [actions.updateGroupFailure, handlers.updateGroupFailure],

    [actions.createGroup, handlers.createGroup],
    [actions.createGroupSuccess, handlers.createGroupSuccess],
    [actions.createGroupFailure, handlers.createGroupFailure],

    [actions.deleteGroup, handlers.deleteGroup],
    [actions.deleteGroupSuccess, handlers.deleteGroupSuccess],
    [actions.deleteGroupFailure, handlers.deleteGroupFailure],

    [actions.refreshGroupsData, handlers.refreshGroupsData],
  ]),
  initialState,
);

export default reducer;
