import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_STUDENTS_PROGRESS: undefined,
  REFRESH_STUDENTS: undefined,
  REFRESH_STUDENTS_PROGRESS: undefined,
  GET_STUDENTS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getStudentsProgress, handlers.getStudentsProgress],
    [actions.refreshStudents, handlers.refreshStudents],
    [actions.refreshStudentsProgress, handlers.refreshStudentsProgress],
    [actions.getStudents, handlers.getStudents],
  ]),
  initialState,
);

export default reducer;
