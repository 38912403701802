import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  main: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: '5rem',
  },
  sessionVerifying: {
    fontSize: '40px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

export const NoRoleWarning = styled.main`
  padding: 80px 20px;
`;

export default useStyles;
