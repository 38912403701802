import { takeLatest, call, put } from 'redux-saga/effects';

import { actions as snackbarActions } from 'redux/snackbar';
import * as api from 'http/admin/reports';

import { actions } from './index';

function* getReportsByTenant({ payload }) {
  try {
    const { tenantId } = payload;

    const result = yield call(api.getReportsByTenant, { tenantId });

    yield put(actions.getReportsByTenantSuccess(result));
  } catch (error) {
    yield put(actions.getReportsByTenantFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* requestReport({ payload }) {
  try {
    const { tenantId, data, successMessage = 'Your report has been successfully requested' } = payload;

    let route;
    let body;
    switch (data.type.toLowerCase()) {
    case 'ela.setcompletion':
      route = 'learning-progressions';
      body = {
        dataCollectionId: +data.dataCollectionsId,
        doFilterByPoa: data.poaOnly,
      };

      if (data.tenantsRegionsId) {
        body.regionId = +data.tenantsRegionsId;
      }

      if (data.tenantsDistrictsId) {
        body.districtId = +data.tenantsDistrictsId;
      }
      break;
    case 'ela.skbratings':
      route = 'ela-skb-ratings';
      body = {
        dataCollectionId: +data.dataCollectionsId,
        filterByPoa: !!data.poaOnly,
        showObservationDates: !!data.showObservationDate,
        includeInactiveStudents: !!data.includeInactive,
        showComments: !!+data.showComments,
        showArtifacts: data.showArtifacts,
        includeScaleScoreData: data.includeScaleScoreData,
      };

      if (data.startDate) {
        body.startDate = +data.startDate;
        body.endDate = +data.endDate;
      }

      if (data.tenantsLocationsId) {
        body.locationId = +data.tenantsLocationsId;
      }

      if (data.tenantsDistrictsId) {
        body.districtId = +data.tenantsDistrictsId;
      }

      if (data.tenantsRegionsId) {
        body.regionId = +data.tenantsRegionsId;
      }
      break;
    case 'kra.ssr':
      route = 'kra-scaled-score';
      body = {
        dataCollectionId: +data.dataCollectionsId,
        outputType: data.output.toLowerCase(),
        showAsterisksForAppItems: !!+data.includeAsterisk,
        showItemsBy: +data.showItemNames ? 'name' : 'code',
      };

      if (data.tenantsDistrictsId) {
        body.districtId = +data.tenantsDistrictsId;
      }

      if (data.tenantsRegionsId) {
        body.regionId = +data.tenantsRegionsId;
      }
      break;
    case 'ela.ssr':
      route = 'ela-scaled-score';
      body = {
        dataCollectionId: +data.dataCollectionsId,
        outputType: data.output.toLowerCase(),
      };

      if (data.tenantsDistrictsId) {
        body.districtId = +data.tenantsDistrictsId;
      }

      if (data.tenantsRegionsId) {
        body.regionId = +data.tenantsRegionsId;
      }
      break;
    case 'kra.enhancedcompletion':
      route = 'completion-by-item';
      body = {
        dataCollectionId: +data.dataCollectionsId,
        isDistrictCensus: data.censusOnly,
        isRandomSample: data.randomSampleOnly,
      };

      if (data.tenantsLocationsId) {
        body.locationId = +data.tenantsLocationsId;
      }

      if (data.tenantsDistrictsId) {
        body.districtId = +data.tenantsDistrictsId;
      }

      if (data.tenantsRegionsId) {
        body.regionId = +data.tenantsRegionsId;
      }
      break;
    case 'kra.languageliteracy':
      route = 'language-literacy';
      body = {
        dataCollectionId: +data.dataCollectionsId,
      };

      if (data.tenantsLocationsId) {
        body.locationId = +data.tenantsLocationsId;
      }

      if (data.tenantsDistrictsId) {
        body.districtId = +data.tenantsDistrictsId;
      }

      if (data.tenantsRegionsId) {
        body.regionId = +data.tenantsRegionsId;
      }
      break;
    case 'kra.teachercompletion':
    case 'kra.locationcompletion':
    case 'kra.districtcompletion':
      route = 'kra-percentage-completion';
      body = {
        dataCollectionId: +data.dataCollectionsId,
      };

      if (data.tenantsLocationsId) {
        body.locationId = +data.tenantsLocationsId;
      }

      if (data.tenantsDistrictsId) {
        body.districtId = +data.tenantsDistrictsId;
      }

      if (data.tenantsRegionsId) {
        body.regionId = +data.tenantsRegionsId;
      }
      break;
    case 'kra.studentsenrollments':
      route = 'students-enrollments';
      body = {
        dataCollectionId: +data.dataCollectionsId,
      };

      if (data.tenantsLocationsId) {
        body.locationId = +data.tenantsLocationsId;
      }

      if (data.tenantsDistrictsId) {
        body.districtId = +data.tenantsDistrictsId;
      }

      if (data.tenantsRegionsId) {
        body.regionId = +data.tenantsRegionsId;
      }
      break;
    case 'kra.domaindata':
      route = 'domain-data';
      body = {
        dataCollectionId: +data.dataCollectionsId,
        doShowEll: data.showEll,
        doShowIep: data.showIep,
      };

      if (data.tenantsLocationsId) {
        body.locationId = +data.tenantsLocationsId;
      }

      if (data.tenantsDistrictsId) {
        body.districtId = +data.tenantsDistrictsId;
      }

      if (data.tenantsRegionsId) {
        body.regionId = +data.tenantsRegionsId;
      }
      break;
    case 'exportstudents':
      route = 'export-student';
      body = {};

      if (data.dataCollectionsId) {
        body.dataCollectionsId = +data.dataCollectionsId;
      }

      if (data.tenantsRegionsId) {
        body.regionId = +data.tenantsRegionsId;
      }

      if (data.tenantsDistrictsId) {
        body.districtId = +data.tenantsDistrictsId;
      }

      if (data.tenantsLocationsId) {
        body.locationId = +data.tenantsLocationsId;
      }
      break;
    case 'exportenrollments':
      route = 'export-enrollment';
      body = {};

      if (data.dataCollectionsId) {
        body.dataCollectionsId = +data.dataCollectionsId;
      }

      if (data.tenantsRegionsId) {
        body.regionId = +data.tenantsRegionsId;
      }

      if (data.tenantsDistrictsId) {
        body.districtId = +data.tenantsDistrictsId;
      }

      if (data.tenantsLocationsId) {
        body.locationId = +data.tenantsLocationsId;
      }
      break;
    case 'exportteachers':
      route = 'export-teacher';
      body = {
        teacherRole: data.teacherRole,
        teacherStatus: data.teacherStatus.toLowerCase(),
      };

      if (data.dataCollectionsId) {
        body.dataCollectionsId = +data.dataCollectionsId;
      }

      if (data.tenantsRegionsId) {
        body.regionId = +data.tenantsRegionsId;
      }

      if (data.tenantsDistrictsId) {
        body.districtId = +data.tenantsDistrictsId;
      }

      if (data.tenantsLocationsId) {
        body.locationId = +data.tenantsLocationsId;
      }
      break;
    case 'kra.isrr':
      route = 'kra-scaled-score';
      body = {
        dataCollectionId: +data.dataCollectionsId,
        outputType: 'csv',
        showAsterisksForAppItems: false,
        showItemsBy: 'code',
        showDownloadLink: data.showDownloadLink,
      };

      if (data.tenantsRegionsId) {
        body.regionId = +data.tenantsRegionsId;
      }

      if (data.tenantsDistrictsId) {
        body.districtId = +data.tenantsDistrictsId;
      }

      if (data.tenantsLocationsId) {
        body.locationId = +data.tenantsLocationsId;
      }
      break;
    case 'teacheroutputs':
      route = 'kra-isr-generation';
      body = {
        dataCollectionId: +data.dataCollectionsId,
      };

      if (data.tenantsRegionsId) {
        body.regionId = +data.tenantsRegionsId;
      }

      if (data.tenantsDistrictsId) {
        body.districtId = +data.tenantsDistrictsId;
      }

      if (data.tenantsLocationsId) {
        body.locationId = +data.tenantsLocationsId;
      }
      break;
    default:
    }

    if (route) {
      yield call(api.requestCas3ApiReport, route, body);
    } else {
      yield call(api.requestCas2ApiReport, { data, tenantId });
    }

    yield put(actions.requestReportSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: successMessage } }));
  } catch (error) {
    yield put(actions.requestReportFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.getReportsByTenant, getReportsByTenant);
  yield takeLatest(actions.requestReport, requestReport);
}
