import React, { Fragment } from 'react';
import PropTypes from 'react-proptypes';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  content: {
    padding: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(15),
    },
  },
  page: {
    marginTop: '4rem',
  },
});

const Wrapper = ({ children, classes }) => (
  <div className={classes.page}>
    <div className={classes.content}>{children}</div>
  </div>
);

Wrapper.defaultProps = {
  children: <></>,
};

Wrapper.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Wrapper);
