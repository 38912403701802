import React from 'react';
import { PropTypes } from 'react-proptypes';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import WindowsIcon from 'mdi-react/WindowsIcon';
import LaptopIcon from 'mdi-react/LaptopIcon';
import LinuxIcon from 'mdi-react/LinuxIcon';

const styles = (theme) => ({
  divider: {
    margin: `${theme.spacing(4)}px 0`,
  },
  pageTitle: {
    marginBottom: theme.spacing(4),
  },
  logo: {
    height: 'auto',
    width: `calc(100% - ${theme.spacing(2)}px)`,
    maxWidth: '200px',
  },
  downloadButton: {
    display: 'inline-block',
  },
  downloadLogo: {
    width: 50,
    height: 50,
  },
  downloadLink: {
    color: theme.palette.primary.contrastText,
    textTransform: 'none',
  },
  downloadIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: 18,
    height: 18,
  },
});

const Downloads = (props) => {
  const { classes, t } = props;

  return (
    <Grid container direction="column" spacing={10}>
      <Grid item>
        <Typography component="h2" variant="h5">
          {t('Download our mobile and desktop apps')}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={10} alignContent="center">
          <Grid item>
            <img className={classes.downloadLogo} src="/assets/kra-app.png" alt="" />
          </Grid>
          <Grid item>
            <Typography component="h3" variant="body1" className={classes.noPaddingMargin}>
              {t('KRA App')}
            </Typography>
            <Grid container direction="row" spacing={8}>
              <Grid item>
                <Button size="small" variant="contained" color="primary" component="a" href="https://kra.kready.org">
                  <LaptopIcon className={classes.downloadIcon} />
                  <Typography className={classes.downloadLink}>{t('Web')}</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={10}>
          <Grid item>
            <img className={classes.downloadLogo} src="/assets/ela-app.png" alt="" />
          </Grid>
          <Grid item>
            <Typography component="h3" variant="body1">
              {t('ELA App')}
            </Typography>
            <Grid container direction="row" spacing={8}>
              <Grid item>
                <Button size="small" variant="contained" color="primary" component="a" href="https://ela.kready.org">
                  <LaptopIcon className={classes.downloadIcon} />
                  <Typography className={classes.downloadLink}>{t('Web')}</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={10}>
          <Grid item>
            <img className={classes.downloadLogo} src="/assets/kready-file-watcher.png" alt="" />
          </Grid>
          <Grid item>
            <Typography component="h3" variant="body1">
              {t('KReady File Watcher')}
            </Typography>
            <Grid container direction="row" spacing={8}>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component="a"
                  href="https://s3.amazonaws.com/cas2-downloads/KReady_File_Watcher_Installer.exe"
                >
                  <WindowsIcon className={classes.downloadIcon} />
                  <Typography className={classes.downloadLink}>{t('For PC')}</Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component="a"
                  href="https://s3.amazonaws.com/cas2-downloads/KReady_File_Watcher_Linux.deb"
                >
                  <LinuxIcon className={classes.downloadIcon} />
                  <Typography className={classes.downloadLink}>{t('Linux - Debian')}</Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component="a"
                  href="https://s3.amazonaws.com/cas2-downloads/KReady_File_Watcher_Linux.rpm"
                >
                  <LinuxIcon className={classes.downloadIcon} />
                  <Typography className={classes.downloadLink}>{t('Linux - RPM')}</Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component="a"
                  href="https://s3.amazonaws.com/cas2-downloads/KReady_File_Watcher_Linux.zip"
                >
                  <LinuxIcon className={classes.downloadIcon} />
                  <Typography className={classes.downloadLink}>{t('Linux - ZIP')}</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Downloads.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(Downloads));
