import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  SPREADSHEET_SELECT_ITEM: undefined,
  SPREADSHEET_SELECT_SET: undefined,
  SPREADSHEET_ITEM_SELECTED: undefined,

  SPREADSHEET_SELECT_STUDENT: undefined,
  SPREADSHEET_STUDENT_SELECTED: undefined,
  SPREADSHEET_ITEM_SELECTED_TOGGLE: undefined,
  SPREADSHEET_ITEM_SLIDE_OUT: undefined,

  SET_PRIORITY: undefined,
  SELECT_GROUP: undefined,
  SPREADSHEET_SELECT_HIERARCHY: undefined,

  GET_GROUPS_REQUEST: undefined,
  GET_GROUPS_ERROR: undefined,
  GET_GROUPS_SUCCESS: undefined,

  POST_STUDENT_GRADE: undefined,
  POST_STUDENT_GRADE_ERROR: undefined,
  POST_STUDENT_GRADE_SUCCESS: undefined,

  DELETE_STUDENT_GRADE_REQUEST: undefined,
  DELETE_STUDENT_GRADE_ERROR: undefined,
  DELETE_STUDENT_GRADE_SUCCESS: undefined,

  SET_INITIAL_STATE: undefined,

  OPEN_ARTIFACTS_MODAL: undefined,
  OPEN_ARTIFACTS_MODAL_SUCCESS: undefined,
  OPEN_ARTIFACTS_MODAL_ERROR: undefined,

  CLOSE_ARTIFACTS_MODAL: undefined,

  SAVE_ARTIFACTS_MODAL: undefined,
  SAVE_ARTIFACTS_MODAL_SUCCESS: undefined,
  SAVE_ARTIFACTS_MODAL_ERROR: undefined,

});

const reducer = handleActions(
  new Map([
    [actions.getGroupsRequest, handlers.getGroupsRequest],
    [actions.getGroupsError, handlers.getGroupsError],
    [actions.getGroupsSuccess, handlers.getGroupsSuccess],

    [actions.postStudentGrade, handlers.postStudentGrade],
    [actions.postStudentGradeError, handlers.postStudentGradeError],
    [actions.postStudentGradeSuccess, handlers.postStudentGradeSuccess],

    [actions.deleteStudentGradeRequest, handlers.deleteStudentGradeRequest],
    [actions.deleteStudentGradeError, handlers.deleteStudentGradeError],
    [actions.deleteStudentGradeSuccess, handlers.deleteStudentGradeSuccess],

    [actions.spreadsheetSelectItem, handlers.spreadsheetSelectItem],
    [actions.spreadsheetSelectSet, handlers.spreadsheetSelectSet],
    [actions.spreadsheetItemSelected, handlers.spreadsheetItemSelected],
    [actions.spreadsheetItemSelectedToggle, handlers.spreadsheetItemSelectedToggle],
    [actions.spreadsheetItemSlideOut, handlers.spreadsheetItemSlideOut],

    [actions.spreadsheetSelectStudent, handlers.spreadsheetSelectStudent],
    [actions.spreadsheetStudentSelected, handlers.spreadsheetStudentSelected],

    [actions.setPriority, handlers.setPriority],
    [actions.selectGroup, handlers.selectGroup],
    [actions.spreadsheetSelectHierarchy, handlers.spreadsheetSelectHierarchy],

    [actions.setInitialState, handlers.setInitialState],

    [actions.spreadsheetHierarchySelected, handlers.spreadsheetHierarchySelected],

    [actions.openArtifactsModal, handlers.openArtifactsModal],
    [actions.openArtifactsModalSuccess, handlers.openArtifactsModalSuccess],
    [actions.openArtifactsModalError, handlers.openArtifactsModalError],
    [actions.closeArtifactsModal, handlers.closeArtifactsModal],

    [actions.saveArtifactsModal, handlers.saveArtifactsModal],
    [actions.saveArtifactsModalSuccess, handlers.saveArtifactsModalSuccess],
    [actions.saveArtifactsModalError, handlers.saveArtifactsModalError],

  ]),
  initialState,
);

export default reducer;
