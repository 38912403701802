const format = (data) => {
  const sets = {};
  data.forEach((hierarchy) => {
    if (!sets[hierarchy.hierarchy_grandparent_id] && hierarchy.hierarchy_grandparent_id) {
      sets[hierarchy.hierarchy_grandparent_id] = hierarchy.hierarchy_grandparent_label;
    }
  });
  return {
    data,
    sets,
  };
};

export default format;
