import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const GENERATE_ISR_REQUEST = 'GENERATE_ISR_REQUEST';
export const GENERATE_ISR_FAILURE = 'GENERATE_ISR_FAILURE';
export const GENERATE_ISR_SUCCESS = 'GENERATE_ISR_SUCCESS';
export const GENERATE_ALL_ISRS = 'GENERATE_ALL_ISRS';
export const GENERATE_ALL_ISRS_FAILURE = 'GENERATE_ALL_ISRS_FAILURE';
export const GENERATE_ALL_ISRS_SUCCESS = 'GENERATE_ALL_ISRS_SUCCESS';
export const UPDATE_ISR_OUTPUT_REQUEST = 'UPDATE_ISR_OUTPUT_REQUEST';
export const UPDATE_ISR_OUTPUT_FAILURE = 'UPDATE_ISR_OUTPUT_FAILURE';
export const UPDATE_ISR_OUTPUT_SUCCESS = 'UPDATE_ISR_OUTPUT_SUCCESS';
export const GENERATE_SCORESHEET_REQUEST = 'GENERATE_SCORESHEET_REQUEST';
export const GENERATE_SCORESHEET_FAILURE = 'GENERATE_SCORESHEET_FAILURE';
export const GENERATE_SCORESHEET_SUCCESS = 'GENERATE_SCORESHEET_SUCCESS';
export const GET_SETS_TOTALS_REQUEST = 'GET_SETS_TOTALS_REQUEST';
export const GET_SETS_TOTALS_FAILURE = 'GET_SETS_TOTALS_FAILURE';
export const GET_SETS_TOTALS_SUCCESS = 'GET_SETS_TOTALS_SUCCESS';
export const GET_COMPLETION_ITEMS_REQUEST = 'GET_COMPLETION_ITEMS_REQUEST';
export const GET_COMPLETION_ITEMS_FAILURE = 'GET_COMPLETION_ITEMS_FAILURE';
export const GET_COMPLETION_ITEMS_SUCCESS = 'GET_COMPLETION_ITEMS_SUCCESS';
export const GET_COMPLETION_STUDENTS_REQUEST = 'GET_COMPLETION_STUDENTS_REQUEST';
export const GET_COMPLETION_STUDENTS_FAILURE = 'GET_COMPLETION_STUDENTS_FAILURE';
export const GET_COMPLETION_STUDENTS_SUCCESS = 'GET_COMPLETION_STUDENTS_SUCCESS';
export const GET_COMPLETION_HIERARCHY_REQUEST = 'GET_COMPLETION_HIERARCHY_REQUEST';
export const GET_COMPLETION_HIERARCHY_FAILURE = 'GET_COMPLETION_HIERARCHY_FAILURE';
export const GET_COMPLETION_HIERARCHY_SUCCESS = 'GET_COMPLETION_HIERARCHY_SUCCESS';
export const GET_COMPLETION_RESULT_BY_ITEMS_REQUEST = 'GET_COMPLETION_RESULT_BY_ITEMS_REQUEST';
export const GET_COMPLETION_RESULT_BY_ITEMS_FAILURE = 'GET_COMPLETION_RESULT_BY_ITEMS_FAILURE';
export const GET_COMPLETION_RESULT_BY_ITEMS_SUCCESS = 'GET_COMPLETION_RESULT_BY_ITEMS_SUCCESS';
export const GET_COMPLETION_STUDENTS_BY_ITEMS_REQUEST = 'GET_COMPLETION_STUDENTS_BY_ITEMS_REQUEST';
export const GET_COMPLETION_STUDENTS_BY_ITEMS_FAILURE = 'GET_COMPLETION_STUDENTS_BY_ITEMS_FAILURE';
export const GET_COMPLETION_STUDENTS_BY_ITEMS_SUCCESS = 'GET_COMPLETION_STUDENTS_BY_ITEMS_SUCCESS';
export const REFRESH_COMPLETION_STATE = 'REFRESH_COMPLETION_STATE';

export const actions = createActions({
  [GENERATE_ISR_REQUEST]: undefined,
  [GENERATE_ISR_FAILURE]: undefined,
  [GENERATE_ISR_SUCCESS]: undefined,

  [GENERATE_ALL_ISRS]: undefined,
  [GENERATE_ALL_ISRS_FAILURE]: undefined,
  [GENERATE_ALL_ISRS_SUCCESS]: undefined,

  [UPDATE_ISR_OUTPUT_REQUEST]: undefined,
  [UPDATE_ISR_OUTPUT_FAILURE]: undefined,
  [UPDATE_ISR_OUTPUT_SUCCESS]: undefined,

  [GENERATE_SCORESHEET_REQUEST]: undefined,
  [GENERATE_SCORESHEET_FAILURE]: undefined,
  [GENERATE_SCORESHEET_SUCCESS]: undefined,

  [GET_SETS_TOTALS_REQUEST]: undefined,
  [GET_SETS_TOTALS_FAILURE]: undefined,
  [GET_SETS_TOTALS_SUCCESS]: undefined,

  [GET_COMPLETION_ITEMS_REQUEST]: undefined,
  [GET_COMPLETION_ITEMS_FAILURE]: undefined,
  [GET_COMPLETION_ITEMS_SUCCESS]: undefined,

  [GET_COMPLETION_STUDENTS_REQUEST]: undefined,
  [GET_COMPLETION_STUDENTS_FAILURE]: undefined,
  [GET_COMPLETION_STUDENTS_SUCCESS]: undefined,

  [GET_COMPLETION_HIERARCHY_REQUEST]: undefined,
  [GET_COMPLETION_HIERARCHY_FAILURE]: undefined,
  [GET_COMPLETION_HIERARCHY_SUCCESS]: undefined,

  [GET_COMPLETION_RESULT_BY_ITEMS_REQUEST]: undefined,
  [GET_COMPLETION_RESULT_BY_ITEMS_FAILURE]: undefined,
  [GET_COMPLETION_RESULT_BY_ITEMS_SUCCESS]: undefined,

  [GET_COMPLETION_STUDENTS_BY_ITEMS_REQUEST]: undefined,
  [GET_COMPLETION_STUDENTS_BY_ITEMS_FAILURE]: undefined,
  [GET_COMPLETION_STUDENTS_BY_ITEMS_SUCCESS]: undefined,

  [REFRESH_COMPLETION_STATE]: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.generateIsrRequest, handlers.generateIsrRequest],
    [actions.generateIsrFailure, handlers.generateIsrFailure],
    [actions.generateIsrSuccess, handlers.generateIsrSuccess],

    [actions.generateAllIsrs, handlers.generateAllIsrs],
    [actions.generateAllIsrsFailure, handlers.generateAllIsrsFailure],
    [actions.generateAllIsrsSuccess, handlers.generateAllIsrsSuccess],

    [actions.updateIsrOutputRequest, handlers.updateIsrOutputRequest],
    [actions.updateIsrOutputFailure, handlers.updateIsrOutputFailure],
    [actions.updateIsrOutputSuccess, handlers.updateIsrOutputSuccess],

    [actions.generateScoresheetRequest, handlers.generateScoresheetRequest],
    [actions.generateScoresheetFailure, handlers.generateScoresheetFailure],
    [actions.generateScoresheetSuccess, handlers.generateScoresheetSuccess],

    [actions.getSetsTotalsRequest, handlers.getSetsTotalsRequest],
    [actions.getSetsTotalsFailure, handlers.getSetsTotalsFailure],
    [actions.getSetsTotalsSuccess, handlers.getSetsTotalsSuccess],

    [actions.getCompletionItemsRequest, handlers.getCompletionItemsRequest],
    [actions.getCompletionItemsFailure, handlers.getCompletionItemsFailure],
    [actions.getCompletionItemsSuccess, handlers.getCompletionItemsSuccess],

    [actions.getCompletionStudentsRequest, handlers.getCompletionStudentsRequest],
    [actions.getCompletionStudentsFailure, handlers.getCompletionStudentsFailure],
    [actions.getCompletionStudentsSuccess, handlers.getCompletionStudentsSuccess],

    [actions.getCompletionHierarchyRequest, handlers.getCompletionHierarchyRequest],
    [actions.getCompletionHierarchyFailure, handlers.getCompletionHierarchyFailure],
    [actions.getCompletionHierarchySuccess, handlers.getCompletionHierarchySuccess],

    [actions.getCompletionResultByItemsRequest, handlers.getCompletionResultByItemsRequest],
    [actions.getCompletionResultByItemsFailure, handlers.getCompletionResultByItemsFailure],
    [actions.getCompletionResultByItemsSuccess, handlers.getCompletionResultByItemsSuccess],

    [actions.getCompletionStudentsByItemsRequest, handlers.getCompletionStudentsByItemsRequest],
    [actions.getCompletionStudentsByItemsFailure, handlers.getCompletionStudentsByItemsFailure],
    [actions.getCompletionStudentsByItemsSuccess, handlers.getCompletionStudentsByItemsSuccess],

    [actions.refreshCompletionState, handlers.refreshCompletionState],
  ]),
  initialState,
);

export default reducer;
