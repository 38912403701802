import downloadFile from 'helpers/downloadFile';
import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

export const saveTenant = (data) => http.post(cas3ApiRoute('/tenants'), data);

export const putTenant = (data) => http.put(cas3ApiRoute('/tenants'), data);

export const getTenants = (data) => http.get(cas3ApiRoute('/tenants'), { params: data });

export const getAssessments = (data) => http.get(cas3ApiRoute('/assessments'), { params: data });

export const postAssessment = (data) => http.post(cas3ApiRoute('/assessments'), data);

export const putAssessment = (data) => http.put(cas3ApiRoute('/assessments'), data);

export const postAssessmentItem = (
  { assessmentId, data },
) => http.post(
  cas3ApiRoute(`/assessments/${assessmentId}/items`),
  {
    code: data.code,
    description: data.description,
    label: data.label,
    assessmentItemHierarchyId: data.assessmentItemHierarchyId,
  },
).then((res) => res.data.assessmentItem);

export const putAssessmentItem = (
  { assessmentId, data },
) => http.put(
  cas3ApiRoute(`/assessments/${assessmentId}/items`),
  {
    id: data.id,
    code: data.code,
    description: data.description,
    label: data.label,
  },
);

export const deleteAssessmentItem = ({ assessmentId, data }) => http.delete(
  cas3ApiRoute(`/assessments/${assessmentId}/items`),
  { params: data },
);

export const postAssessmentHierarchyItem = (
  { assessmentId, data },
) => http.post(cas3ApiRoute(`/assessments/${assessmentId}/hierarchy-items`), data);

export const putAssessmentHierarchyItem = (
  { assessmentId, data },
) => http.put(cas3ApiRoute(`/assessments/${assessmentId}/hierarchy-items`), data);

export const deleteAssessmentHierarchyItem = ({ assessmentId, data }) => http.delete(
  cas3ApiRoute(`/assessments/${assessmentId}/hierarchy-items`),
  { params: data },
);

export const getAssessmentItems = ({ assessmentId, params }) => http.get(
  cas3ApiRoute(`/assessments/${assessmentId}/items`),
  { params },
);

export const getAssessmentExport = ({ assessmentId }) => http.get(
  cas3ApiRoute(`/assessments/${assessmentId}/export`),
);

export const postAssessmentImport = ({ data }) => http.post(cas3ApiRoute('/assessments/import'), data);

export const getAcknowledgements = (data) => http.get(cas3ApiRoute('/acknowledgements'), {
  params: data,
});

export const getAcknowledgement = (acknowledgementId) => http.get(
  cas3ApiRoute(`/acknowledgements/${acknowledgementId}`),
).then((res) => res.data.acknowledgement);

export const postAcknowledgement = (data) => http.post(cas3ApiRoute('/acknowledgements'), data);

export const putAcknowledgement = (data) => http.put(cas3ApiRoute('/acknowledgements'), data);

export const getAnnouncements = () => http.get(cas3ApiRoute('/announcements'));

export const getDataCollections = (data) => http.get(cas3ApiRoute('/data-collections'), { params: data });

export const getTenantAdmin = (id) => http.get(cas3ApiRoute(`/users/${id}`));

export const getTenantAdmins = (data) => http.get(cas3ApiRoute('/users'), { params: data });

export const postTenantAdmin = (data) => http.post(cas3ApiRoute('/users'), data);

export const putTenantAdmin = (data) => http.put(cas3ApiRoute('/users'), data);

export const getSchoolYears = (data) => http.get(cas3ApiRoute('/school-years'), { params: data });

export const postSchoolYear = (data) => http.post(cas3ApiRoute('/school-years'), data);

export const getAssessmentItemResources = (assessmentId, itemId) => http.get(
  cas3ApiRoute(`/assessments/${assessmentId}/items/${itemId}/resources`),
);

export const downloadAssessmentItemResource = (assessmentId, itemId, resourceId) => downloadFile(
  cas3ApiRoute(`/assessments/${assessmentId}/items/${itemId}/resources/${resourceId}/download`),
);

export const postAssessmentItemResource = (assessmentId, itemId, data) => http.post(
  cas3ApiRoute(`/assessments/${assessmentId}/items/${itemId}/resources`),
  { file: data },
);

export const deleteAssessmentItemResource = (assessmentId, itemId, data) => http.delete(
  cas3ApiRoute(`/assessments/${assessmentId}/items/${itemId}/resources`),
  {
    params: { id: data.id },
  },
);

export const getAssessmentItemScaleEntries = (assessmentId, assessmentItemId) => http.get(
  cas3ApiRoute(`/assessments/${assessmentId}/items/${assessmentItemId}/entries`),
);

export const postAssessmentItemScaleEntry = (assessmentId, assessmentItemId, entry) => http.post(
  cas3ApiRoute(`/assessments/${assessmentId}/items/${assessmentItemId}/entries`),
  entry,
);

export const putAssessmentItemScaleEntry = (assessmentId, assessmentItemId, entry) => http.put(
  cas3ApiRoute(`/assessments/${assessmentId}/items/${assessmentItemId}/entries`),
  entry,
);

export const deleteAssessmentItemScaleEntry = (assessmentId, assessmentItemId, entryId) => http.delete(
  cas3ApiRoute(`/assessments/${assessmentId}/items/${assessmentItemId}/entries`),
  {
    params: { id: entryId },
  },
);

export const getTenantIntegrations = (tenantId) => http.get(
  cas3ApiRoute(`/tenant-integrations?tenantId=${tenantId}`),
).then((res) => res.data.tenantIntegrations);

export const postTenantIntegration = (tenantId, token, description, clientId) => http.post(
  cas3ApiRoute('/tenant-integrations'),
  {
    tenantId,
    token,
    description,
    clientId,
  },
).then((res) => res.data.id);

const postTenantIntegrationLicense = (tenantId, tenantIntegrationId, licenseId, role) => http.post(
  cas3ApiRoute('/tenant-integration-licenses'),
  {
    tenantId,
    tenantIntegrationId,
    licenseId,
    role,
  },
);

export const postTenantIntegrationLicenses = (tenantId, tenantIntegrationId, licenses) => {
  if (!licenses.length) return [];

  const promises = [];
  for (let i = 0; i < licenses.length; i += 1) {
    const license = licenses[i];
    promises.push(postTenantIntegrationLicense(tenantId, tenantIntegrationId, license.licenseId, license.role));
  }

  return Promise.allSettled(promises);
};

export const putTenantIntegration = (id, tenantId, token, description, clientId) => http.put(
  cas3ApiRoute(`/tenant-integrations/${id}`),
  {
    tenantId,
    token,
    description,
    clientId,
  },
);

export const deleteTenantIntegrations = (id) => http.delete(
  cas3ApiRoute(`/tenant-integrations/${id}`),
);

const deleteTenantIntegrationLicense = (id) => http.delete(
  cas3ApiRoute(`/tenant-integration-licenses/${id}`),
);

export const deleteTenantIntegrationLicenses = (ids) => {
  if (!ids.length) return [];

  const promises = [];
  for (let i = 0; i < ids.length; i += 1) {
    const id = ids[i];
    promises.push(deleteTenantIntegrationLicense(id));
  }

  return Promise.allSettled(promises);
};
