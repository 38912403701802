import React, { useCallback, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import * as CasInterop from 'helpers/cas4/interop';

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import i18n from 'i18n';

import { actions } from 'redux/auth';
import { TextInput } from 'components';
import useStyles from './styled';
import Container from '../Container';

const Forgot = ({
  successFlag,
  dispatch,
  invalid,
  loading,
  t,
  handleSubmit,
  isAuthorized,
}) => {
  const classes = useStyles();

  useEffect(() => {
    dispatch(actions.clearSuccessFlag());
  }, []);

  const handleCancel = () => {
    if (isAuthorized) {
      return navigate('/');
    }

    return CasInterop.navigate('/sign-in', { reason: 'forgot-password-cancel' });
  };

  const submit = useCallback(
    (value) => {
      dispatch(actions.forgotPasswordRequest({ email: value.email, isAuthorized }));
    },
    [isAuthorized],
  );

  if (successFlag) {
    return (
      <Container>
        <div className={classes.forgotSuccess}>
          An email has been sent and contains a link to reset your password.
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className={classes.button}
            onClick={handleCancel}
            data-testid="forgot-cancel"
          >
            {t('Cancel')}
          </Button>
        </div>
      </Container>
    );
  }

  // An email has been sent and contains a link to reset your password.
  return (
    <Container>
      {loading && <Box width="100%" display="flex" justifyContent="center"><CircularProgress /></Box>}
      {!loading && (
        <>
          <Typography component="h2" variant="h5">
            {t('Enter the email address you signed up with to send a password reset')}
          </Typography>
          <Form onSubmit={handleSubmit(submit)} className={classes.form}>
            <Field
              name="email"
              id="email"
              autoFocus
              required
              component={TextInput}
              label={t('Email')}
              data-testid="forgot-email"
            />
            <Grid>
              <Button
                type="submit"
                disabled={invalid || loading}
                variant="contained"
                size="medium"
                color="primary"
                className={classes.marginTop}
                data-testid="forgot-submit"
              >
                {t('Send')}
              </Button>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className={classes.marginTop}
                onClick={handleCancel}
                data-testid="forgot-cancel"
              >
                {t('Cancel')}
              </Button>
            </Grid>
          </Form>
        </>
      )}
    </Container>
  );
};

Forgot.defaultProps = {
  isAuthorized: false,
};

Forgot.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  successFlag: PropTypes.bool.isRequired,
  isAuthorized: PropTypes.bool,
};

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = i18n.t('Email is required');
  }
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
    errors.email = i18n.t('Invalid email format');
  }

  return errors;
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  forgotPasswordError: auth.forgotPasswordError,
  successFlag: auth.forgotSucces,
});

export default connect(
  mapStateToProps,
  (dispatch) => ({ dispatch }),
)(
  withTranslation()(
    reduxForm({
      form: 'forgot',
      validate,
      enableReinitialize: true,
    })(Forgot),
  ),
);
