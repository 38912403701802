import { takeLatest, call, put } from 'redux-saga/effects';

import { actions as snackbarActions } from 'redux/snackbar';
import * as api from 'http/admin/customFields';

import { actions } from './index';

function* getCustomFields({ payload }) {
  try {
    const { tenantId } = payload;
    const result = yield call(api.getCustomFields, { tenantId });

    yield put(actions.getCustomFieldsSuccess(result));
  } catch (error) {
    yield put(actions.getCustomFieldsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.getCustomFields, getCustomFields);
}
