export const formatLocation = (location) => ({
  id: location.id,
  name: location.nameLabel,
  tenantParentId: location.parentTenantId,
  parentId: location.parentId,
  uniqueId: location.uniqueId,
});

export const formatLocations = (locations) => locations.map((location) => formatLocation(location));

export default formatLocations;
