import { CancelToken } from 'axios';
import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

import { raceFormatter, customFieldFormatter } from './formatter';

const source = CancelToken.source();
sessionStorage.setItem('studentprogress', '0');

export const getUser = () => http.get('/user/profile.json');

export const getRaces = () => http.get(cas3ApiRoute('/race-codes')).then((res) => raceFormatter(res.data.raceCodes));

export const getPriorCares = () => http.get(
  cas3ApiRoute('/tenants-fields'),
  { params: { entityField: '%CUSTOM%' } },
).then((res) => customFieldFormatter(res.data.tenantsFields));

export const getRegions = ({ tenant, user }) => http.get(`/admin/tenants/${tenant}/users/${user}/region/administrative.json`);

export const getDistricts = ({ tenant, user }) => http.get(`/admin/tenants/${tenant}/users/${user}/district/administrative.json`);

export const getDistrict = ({ districtId }) => http.get(
  cas3ApiRoute(`/districts/${districtId}`),
);

export const getOrgs = ({ user }) => http.get(
  cas3ApiRoute(`/users/${user}/organizations`),
  {
    params: { admin: true },
  },
);

export const getGenders = () => ['MALE', 'FEMALE', 'UNKNOWN'];

export const cancelStudents = () => {
  source.cancel('Operation canceled by the user.');
};

// Student data should be loaded from IndexDB if it is available, and if not, stream the API response.
// It's a huge dataset, so we want to show a progress indicator too.
