import { takeLatest, call, put } from 'redux-saga/effects';
import * as api from 'http/data-displays/endpoints';
import * as adminAssessmentsApi from 'http/admin/assessments';
import * as userApi from 'http/admin/users';

import { actions } from './index';

function* fetchAssessments({ payload: { tenantId } }) {
  try {
    const data = yield call(adminAssessmentsApi.getAssessments, { tenantId });

    yield put(actions.getAssessmentsSuccess({ data }));
  } catch (error) {
    yield put(actions.getAssessmentsError({ error }));
  }
}

function* fetchRaces() {
  try {
    const data = yield call(api.getRaces);

    yield put(actions.getRacesSuccess({ data }));
  } catch (error) {
    yield put(actions.getRacesError({ error }));
  }
}

function* fetchDistrict({ payload: { data, successHandle } }) {
  try {
    const { districtId, userId } = data;
    const districtsData = yield call(api.getDistrict, { districtId });
    const userOrgs = yield call(userApi.getUserOrgs, { userId });

    const district = userOrgs.districts.find((d) => d.id === +data.districtId);

    yield put(actions.getDistrictSuccess());

    if (successHandle) {
      successHandle({
        ...districtsData.data.district,
        administrative: district.administrative,
      });
    }
  } catch (error) {
    yield put(actions.getDistrictsError({ error }));
  }
}

function* fetchOrgs({ payload: { data } }) {
  try {
    const result = yield call(api.getOrgs, data);

    yield put(actions.getOrgsSuccess({ data: result.data }));
  } catch (error) {
    yield put(actions.getOrgsError({ error }));
  }
}

function* fetchGenders() {
  try {
    const data = yield call(api.getGenders);

    yield put(actions.getGendersSuccess({ data }));
  } catch (error) {
    yield put(actions.getGendersError({ error }));
  }
}

function* fetchPriorCares() {
  try {
    const data = yield call(api.getPriorCares);

    yield put(actions.getPriorCaresSuccess({ data }));
  } catch (error) {
    yield put(actions.getPriorCaresError({ error }));
  }
}

export default function* watchOptionsFetch() {
  yield takeLatest(actions.getAssessmentsRequest, fetchAssessments);
  yield takeLatest(actions.getPriorCaresRequest, fetchPriorCares);
  yield takeLatest(actions.getRacesRequest, fetchRaces);
  yield takeLatest(actions.getDistrictRequest, fetchDistrict);
  yield takeLatest(actions.getOrgsRequest, fetchOrgs);
  yield takeLatest(actions.getGendersRequest, fetchGenders);
}
