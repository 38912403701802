import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';
import { announcementsFormat, formatForRequest } from './formatter';

export const getAnnouncements = ({ tenantsId }) => http.get(
  cas3ApiRoute('/announcements'),
  {
    params: { tenantsId },
  },
).then((res) => announcementsFormat(res.data.announcements));

export const getAnnouncementDetails = ({ id }) => http.get(cas3ApiRoute(`/announcements/${id}`));

export const updateAnnouncement = (data) => http.put(
  cas3ApiRoute('/announcements'),
  formatForRequest(data),
);

export const createAnnouncement = (data) => http.post(
  cas3ApiRoute('/announcements'),
  formatForRequest(data),
);

export const deleteAnnouncement = ({ id }) => http.delete(
  cas3ApiRoute('/announcements'),
  {
    params: { id },
  },
);
