import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_STUDENT_DETAILS: undefined,
  SET_STUDENT: undefined,
  SET_DATA_DISPLAY_FILTER: undefined,

  ADD_LIST: undefined,
  EDIT_LIST: undefined,
  DELETE_LIST: undefined,

  ADD_CHECKPOINT_PERIOD: undefined,
  EDIT_CHECKPOINT_PERIOD: undefined,
  DELETE_CHECKPOINT_PERIOD: undefined,

  SET_CLASSROOM_REPORT_FILTER: undefined,
  ADD_CLASSROOM_REPORT_FILTER: undefined,
  EDIT_CLASSROOM_REPORT_FILTER: undefined,
  DELETE_CLASSROOM_REPORT_FILTER: undefined,

  REFRESH_DATA_DISPLAY_STATE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.setStudent, handlers.setStudent],
    [actions.setDataDisplayFilter, handlers.setFilter],

    [actions.addList, handlers.addList],
    [actions.editList, handlers.editList],
    [actions.deleteList, handlers.deleteList],

    [actions.addCheckpointPeriod, handlers.addCheckpointPeriod],
    [actions.editCheckpointPeriod, handlers.editCheckpointPeriod],
    [actions.deleteCheckpointPeriod, handlers.deleteCheckpointPeriod],

    [actions.setClassroomReportFilter, handlers.setClassroomReportFilter],
    [actions.addClassroomReportFilter, handlers.addClassroomReportFilter],
    [actions.editClassroomReportFilter, handlers.editClassroomReportFilter],
    [actions.deleteClassroomReportFilter, handlers.deleteClassroomReportFilter],

    [actions.refreshDataDisplayState, handlers.refreshDataDisplayState],
  ]),
  initialState,
);

export default reducer;
