const downloadBase64 = (base64, fileName) => {
  const buffer = Buffer.from(base64, 'base64');
  const byteArray = new Uint8Array(buffer);
  const blob = new Blob([byteArray]);
  const objectUrl = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.target = '_blank';
  link.href = objectUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(objectUrl);
};

export default downloadBase64;
