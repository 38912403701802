import moment from 'moment';

import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';
import {
  collectionsFormat,
  formatForUpdate,
  locationsFormat,
  fieldTestsFormat,
  formatDataCollections,
  formatDataCollection,
  formatForRequest,
} from './formatter';

export const getCollections = (tenantId) => http.get(
  cas3ApiRoute('/data-collections'),
  {
    params: { tenantId },
  },
).then((res) => formatDataCollections(res.data.dataCollections));

export const getCollectionsByRegion = ({ tenantId, regionId }) => http.get(`/admin/tenants/${tenantId}/regions/${regionId}/collections.json`, {
  params: {
    time: moment().unix(),
  },
}).then((res) => collectionsFormat(res.data));

export const getCollectionsByDistrict = ({
  tenantId, regionId, districtId,
}) => http.get(`/admin/tenants/${tenantId}/regions/${regionId}/districts/${districtId}/collections.json`, {
  params: {
    time: moment().unix(),
  },
}).then((res) => collectionsFormat(res.data));

export const getCollectionsByLocation = ({
  tenantId,
  regionId,
  districtId,
  locationId,
}) => http.get(`/admin/tenants/${tenantId}/regions/${regionId}/districts/${districtId}/locations/${locationId}/collections.json`, {
  params: {
    time: moment().unix(),
  },
}).then((res) => collectionsFormat(res.data));

export const getCollectionDetails = ({ collectionId }) => http.get(
  cas3ApiRoute(`/data-collections/${collectionId}`),
).then((res) => formatDataCollection(res.data.dataCollection));

export const getCollectionAssignments = ({
  tenantId,
  collectionId,
}) => http.get(`/admin/tenants/${tenantId}/collections/${collectionId}/assignments.json`)
  .then((res) => locationsFormat(res.data));

export const getCollectionAssessmentItemSets = ({ collectionId }) => http.get(
  cas3ApiRoute('/data-collections-sets'),
  {
    params: { parentDataCollectionId: collectionId },
  },
).then((res) => res.data.dataCollectionsSets);

export const postCollectionAssessmentItemSet = ({ data, collectionId }) => http.post(
  cas3ApiRoute('/data-collections-sets'),
  { ...data, parentDataCollectionId: +collectionId },
);

export const putCollectionAssessmentItemSet = ({ setId, data }) => http.put(
  cas3ApiRoute('/data-collections-sets'),
  { id: setId, ...data },
).then((res) => res.data);

export const deleteCollectionAssessmentItemSet = ({ setId }) => http.delete(
  cas3ApiRoute('/data-collections-sets'),
  { params: { id: setId } },
);

export const getCollectionAssessmentSetItems = ({ collectionId, setId }) => http.get(
  cas3ApiRoute(`/data-collections-sets/${setId}/items`),
  {
    params: {
      parentDataCollectionId: collectionId,
    },
  },
).then((res) => res.data.dataCollectionsSetsItems);

export const postCollectionAssessmentSetItem = ({ setId, data }) => http.post(
  cas3ApiRoute(`/data-collections-sets/${setId}/items`),
  data,
).then((res) => res.data);

export const putCollectionAssessmentSetItem = ({ setId, data }) => http.put(
  cas3ApiRoute(`/data-collections-sets/${setId}/items`),
  data,
).then((res) => res.data.dataCollectionsSetsItem);

export const deleteCollectionAssessmentSetItem = ({ setId, itemId }) => http.delete(
  cas3ApiRoute(`/data-collections-sets/${setId}/items`),
  {
    params: { id: +itemId },
  },
);

export const createCollection = ({ tenantId, payloadData }) => http.post(
  cas3ApiRoute('/data-collections'),
  formatForRequest({ ...payloadData, parentTenantId: tenantId }),
);

export const updateCollection = ({ collectionId, payloadData }) => http.put(
  cas3ApiRoute('/data-collections'),
  formatForUpdate({ id: +collectionId, ...payloadData }),
).then((res) => formatDataCollection(res.data.dataCollection));

export const getCollectionLocations = ({
  tenantId,
  collectionId,
}) => http.get(`/admin/tenants/${tenantId}/collections/${collectionId}/locations.json`)
  .then((res) => locationsFormat(res.data));

export const getDistrictsByTenant = ({
  tenantId,
}) => http.get(`/admin/tenants/${tenantId}/districts.json`)
  .then((res) => res.data);

export const getRegionsByTenant = ({
  tenantId,
}) => http.get(`/admin/tenants/${tenantId}/regions.json`)
  .then((res) => res.data);

export const unassignLocation = ({
  tenantId,
  collectionId,
  locationId,
}) => http.delete(`/admin/tenants/${tenantId}/collections/${collectionId}/locations/${locationId}.json`);

export const assignLocation = ({
  tenantId,
  collectionId,
  locationId,
}) => http.post(`/admin/tenants/${tenantId}/collections/${collectionId}/locations/${locationId}.json`, {
  cid: collectionId,
  lid: locationId,
});

export const assignLocationsByDistrict = ({
  tenantId,
  collectionId,
  districtId,
}) => http.post(`/admin/tenants/${tenantId}/collections/${collectionId}/districts/${districtId}.json`, {
  id: collectionId,
});

export const assignLocationsByRegion = ({
  tenantId,
  collectionId,
  regionId,
}) => http.post(`/admin/tenants/${tenantId}/collections/${collectionId}/regions/${regionId}.json`, {
  id: collectionId,
});

export const generateOutputByCollection = (data) => http.post(
  cas3ApiRoute('/reports/individual-student'),
  data,
);

export const clearOutputByCollection = ({
  collectionId,
  data,
}) => http.delete(cas3ApiRoute('/data-collections-students-outputs'), {
  params: {
    dataCollectionId: collectionId, ...data,
  },
});

export const getOutputByCollection = ({
  collectionId,
  params,
}) => http.get(`/admin/collections/${collectionId}/outputs.json`, {
  params: {
    ...params,
    time: moment().unix(),
  },
}).then((res) => res.data);

export const getCollectionSupports = ({ collectionId }) => http.get(
  cas3ApiRoute(`/data-collections/${collectionId}/supports`),
).then((res) => res.data.dataCollectionsStudentSupports);

export const createCollectionSupport = ({
  collectionId,
  data,
}) => http.post(
  cas3ApiRoute(`/data-collections/${collectionId}/supports`),
  data,
).then((res) => res.data);

export const updateCollectionSupport = ({ id, data, collectionId }) => http.put(
  cas3ApiRoute(`/data-collections/${collectionId}/supports`),
  {
    ...data,
    id,
  },
).then((res) => res.data.dataCollectionsStudentSupport);

export const deleteCollectionSupport = ({ id, collectionId }) => http.delete(
  cas3ApiRoute(`/data-collections/${collectionId}/supports`),
  {
    params: { id },
  },
);

export const getCollectionFieldTests = ({
  tenantId,
  collectionId,
  params = {},
}) => http.get(`/admin/tenants/${tenantId}/collections/${collectionId}/fieldtests.json`, {
  params: {
    ...params,
    time: moment().unix(),
  },
}).then((res) => fieldTestsFormat(res?.data?.data || []));

export const assignLocationFieldTests = ({
  tenantId,
  collectionId,
  locationId,
  data,
}) => http.post(`/admin/tenants/${tenantId}/collections/${collectionId}/locations/${locationId}/fieldtests.json`, data);

export const unassignLocationFieldTests = ({
  tenantId,
  collectionId,
  locationId,
}) => http.delete(`/admin/tenants/${tenantId}/collections/${collectionId}/locations/${locationId}/fieldtests.json`);
