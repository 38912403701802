export const REACT_APP_PATH = `${window.runtime_process_env.REACT_APP_BASE_API}${window.runtime_process_env.REACT_APP_PATH}`;

export const DATE_FORMAT = 'MMMM D, YYYY';

export const Roles = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_SYSTEM_ADMIN: 'ROLE_SYSTEM_ADMIN',
  ROLE_TEACHER: 'ROLE_TEACHER',
  ROLE_TRAINER: 'ROLE_TRAINER',
  ROLE_TENANT_ADMIN: 'ROLE_TENANT_ADMIN',
  ROLE_DATA_MANAGER: 'ROLE_DATA_MANAGER',
  ROLE_HELPDESK: 'ROLE_HELPDESK',
  ROLE_HELPDESK_ADMIN: 'ROLE_HELPDESK_ADMIN',
};

export const FILTERS_TYPES = {
  DATA_FILTERS: 'DATA_FILTERS',
  VIEW_SETTINGS_FILTERS: 'VIEW_SETTINGS_FILTERS',
  VISIBLE_ITEMS_FILTERS: 'VISIBLE_ITEMS_FILTERS',
  CUSTOM_LISTS_FILTERS: 'CUSTOM_LISTS_FILTERS',
  OTHER_FILTERS: 'OTHER_FILTERS',
};

export const FeatureFlags = {
  CAS4_ACCESS: 'CAS4_ACCESS',
  CAS4_DATA_DISPLAYS: 'CAS4_DATA_DISPLAYS',
  CAS4_ADMIN_DOWNLOADS: 'CAS4_ADMIN_DOWNLOADS',
  ELA_SKB_RATINGS_SCALE_SCORE_OPTION: 'ELA_SKB_RATINGS_SCALE_SCORE_OPTION',
};

export const DATA_DISPLAY_TYPES = {
  ELA: 'ELA',
  KRA: 'KRA',
};

export const tabsConfigGrades = {
  FILTER: 'Filter',
  GRADE_R: 'R',
  GRADE_N: 'N',
  GRADE_NE: 'NE',
  GRADE_A: 'A',
  GRADE_B: 'B',
  GRADE_C: 'C',
  GRADE_D: 'D',
  GRADE_1: '1',
  GRADE_2: '2',
  GRADE_3: '3',
  GRADE_4: '4',
  GRADE_5: '5',
  PrecedingDevScale: 4,
  ProgressTowardScale: 2,
};

export const scaleColors = {
  [tabsConfigGrades.GRADE_R]: '#474747',
  [tabsConfigGrades.GRADE_N]: '#c86aa8',
  [tabsConfigGrades.GRADE_NE]: '#5e6063',
  [tabsConfigGrades.GRADE_A]: '#7a7b7f',
  [tabsConfigGrades.GRADE_B]: '#909090',
  [tabsConfigGrades.GRADE_C]: '#ccc',
  [tabsConfigGrades.GRADE_D]: '#eee',
  [tabsConfigGrades.GRADE_1]: '#fc8318',
  [tabsConfigGrades.GRADE_2]: '#9a33cc',
  [tabsConfigGrades.GRADE_3]: '#09AFFD',
  [tabsConfigGrades.GRADE_4]: '#046A9B',
  [tabsConfigGrades.GRADE_5]: '#96da39',
  default: '#ccc',
};

export const MD_GENDERS = [
  { value: 'MALE', label: '1' },
  { value: 'FEMALE', label: '2' },
  { value: 'NON-BINARY', label: 'X' },
  { value: 'UNKNOWN', label: 'Unknown' },
];
