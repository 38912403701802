import moment from 'moment';
import { DATA_DISPLAY_TYPES } from 'commonConstants';

const initialState = {
  assessments: [],
  assessmentsLoading: false,
  assessmentsError: null,
  announcements: [],
  announcementsLoading: false,
  announcementsError: null,
};

const mapAssessmentApiToDisplay = (item) => ({
  ...item,
  id: item.id,
  name: item.collectionName,
  type: item.assessmentTaxonomy === 'SUMMATIVE' ? DATA_DISPLAY_TYPES.KRA : DATA_DISPLAY_TYPES.ELA,
  cycleStart: item.cycle_start_date,
  cycleEnd: item.cycle_end_date,
  enrollmentEnd: item.enrollment_end_date,
});

const mapAnnouncementApiToDisplay = (item) => ({
  id: item.id,
  title: item.title,
  description: item.text,
  showStart: moment.unix(item.startDate),
  showEnd: moment.unix(item.endDate),
});

export const teacherGetAssessments = (state) => ({
  ...state,
  assessmentsLoading: true,
  announcementsError: null,
});

export const teacherGetAssessmentsFail = (state, { payload }) => ({
  ...state,
  assessmentsLoading: false,
  announcementsError: payload.data,
});

export const teacherGetAssessmentsSuccess = (state, { payload }) => ({
  ...state,
  assessments: payload.data.map(mapAssessmentApiToDisplay),
  assessmentsLoading: false,
  announcementsError: null,
});

export const teacherGetAnnouncements = (state) => ({
  ...state,
  announcementsLoading: true,
  announcementsError: null,
});

export const teacherGetAnnouncementsFail = (state, { payload }) => ({
  ...state,
  announcementsLoading: false,
  announcementsError: payload.data,
});

export const teacherGetAnnouncementsSuccess = (state, { payload }) => ({
  ...state,
  announcements: payload.data.map(mapAnnouncementApiToDisplay),
  announcementsLoading: false,
  announcementsError: null,
});

export default initialState;
