import { END, eventChannel } from 'redux-saga';
import {
  takeLatest, call, put, select, take,
} from 'redux-saga/effects';

import {
  acknowledgeNotification,
  getNotifications,
  removeNotification,
  subscribeToNotifications,
} from 'http/notifications';
import { actions } from './index';

const createSocketChannel = (ws) => eventChannel((emit) => {
  ws.onmessage = (event) => {
    emit(JSON.parse(event.data));
  };

  ws.onclose = () => {
    emit(END);
  };

  const unsubscribe = () => {
    ws.onmessage = null;
  };

  return unsubscribe;
});

function* getNotificationsSaga() {
  try {
    const notifications = yield call(getNotifications);
    yield put(actions.getNotificationsSuccess(notifications));
  } catch (error) {
    yield put(actions.getNotificationsFail({ error }));
  }
}

function* subscribeToNotificationsSaga() {
  try {
    const { id, tenantId } = yield select((state) => state.auth);
    const ws = yield call(subscribeToNotifications, id, tenantId);
    const socketChannel = yield call(createSocketChannel, ws);

    while (true) {
      const { payload, type } = yield take(socketChannel);
      if (type === 'notifications') {
        yield put(actions.receiveNotificationSuccess(payload));
      }
    }
  } catch (error) {
    yield put(actions.subscribeToNotificationsFail({ error }));
  }
}

function* removeNotificationSaga({ payload }) {
  const { notificationId } = payload;
  try {
    yield call(removeNotification, notificationId);
    yield put(actions.removeNotificationSuccess({ notificationId }));
  } catch (error) {
    yield put(actions.removeNotificationFail({ error }));
  }
}

function* acknowledgeNotificationSaga({ payload }) {
  const { notificationId } = payload;
  try {
    yield call(acknowledgeNotification, notificationId);
    yield put(actions.acknowledgeNotificationSuccess({ notificationId }));
  } catch (error) {
    yield put(actions.acknowledgeNotificationFail({ error }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.getNotifications, getNotificationsSaga);
  yield takeLatest(actions.removeNotification, removeNotificationSaga);
  yield takeLatest(actions.subscribeToNotifications, subscribeToNotificationsSaga);
  yield takeLatest(actions.acknowledgeNotification, acknowledgeNotificationSaga);
}
