import { FILTERS_TYPES } from 'commonConstants';
import { filterStudents } from 'helpers/filterStudentsForKra';
import moment from 'moment';

const {
  DATA_FILTERS,
  VIEW_SETTINGS_FILTERS,
  VISIBLE_ITEMS_FILTERS,
  CUSTOM_LISTS_FILTERS,
  OTHER_FILTERS,
} = FILTERS_TYPES;

export const formatDate = (date) => {
  if (typeof date === 'number') {
    return moment.unix(date);
  }

  return moment(date);
};

export const filterByTypeFormatter = (filters, addInfo) => {
  const {
    isKRA = false,
    allStudents = [],
    races = [],
  } = addInfo;

  const result = {
    [DATA_FILTERS]: [],
    [VIEW_SETTINGS_FILTERS]: {
      isDefaultSettings: true,
      name: VIEW_SETTINGS_FILTERS,
      filterType: VIEW_SETTINGS_FILTERS,
      color: '#ffffff', // color field for BE validation
      levels: true,
      stages: true,
      parentSignature: false,
      comments: false,
      scores: false,
    },
    [CUSTOM_LISTS_FILTERS]: [],
    [VISIBLE_ITEMS_FILTERS]: {
      name: VISIBLE_ITEMS_FILTERS,
      filterType: VISIBLE_ITEMS_FILTERS,
      color: '#ffffff', // color field for BE validation
      isDefaultSettings: true,
      visibleItem: 'all',
    },
    CUSTOM_LISTS_FILTERS: [],
    [OTHER_FILTERS]: [],
  };

  filters.forEach(({
    id,
    dataCollectionsId: collectionId,
    name,
    color,
    metadata,
  }) => {
    const { filterType } = metadata;

    if (filterType !== VIEW_SETTINGS_FILTERS && filterType !== VISIBLE_ITEMS_FILTERS) {
      result[FILTERS_TYPES[filterType] || OTHER_FILTERS].push({
        ...metadata,
        fromDate: formatDate(metadata.fromDate),
        toDate: formatDate(metadata.toDate),
        id,
        name,
        color,
        collectionId,
        students: (isKRA && allStudents.length) ? filterStudents(metadata, allStudents, races) : [],
      });
    } else {
      result[FILTERS_TYPES[filterType]] = {
        ...metadata,
        fromDate: formatDate(metadata.fromDate),
        toDate: formatDate(metadata.toDate),
        id,
        name,
        color,
        collectionId,
        isDefaultSettings: false,
        students: (isKRA && allStudents.length) ? filterStudents(metadata, allStudents, races) : [],
      };
    }
  });

  return result;
};

export const formatForRequest = ({ name, color, ...metadata }) => ({
  name,
  dataCollectionsId: +metadata.collectionId,
  color,
  metadata,
});
