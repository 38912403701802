const initialState = {
  list: [],
  isLoading: false,
  error: null,
};

export const getGroupsList = (state) => ({
  ...state,
  isLoading: true,
});

export const getGroupsListSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  list: payload,
});

export const getGroupsListFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload || 'Error fetching groups',
});

export const updateGroup = (state) => ({
  ...state,
  isLoading: true,
});

export const updateGroupSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const updateGroupFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload.response.data || 'Error updating group',
});

export const createGroup = (state) => ({
  ...state,
  isLoading: true,
});

export const createGroupSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const createGroupFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload.response.data || 'Error creating group',
});

export const deleteGroup = (state) => ({
  ...state,
  isLoading: true,
});

export const deleteGroupSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const deleteGroupFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload.response.data || 'Error deletion group',
});

export const refreshGroupsData = () => ({
  ...initialState,
});

export default initialState;
