import { DATA_DISPLAY_TYPES } from 'commonConstants';

const initialState = {
  loading: false,
  id: null,
  name: '',
  collection: null,
  isGenerating: false,
  assessmentLoading: false,
  items: [],
  itemsLoading: false,
  itemsMaterialsLoading: false,
  isrReportLoading: false,
  ratingReportLoading: false,
  students: [],
  districts: [],
  locations: [],
  regions: [],
  studentsLoading: false,
  scales: [],
  scalesLoading: false,
  grades: [],
  gradesLoading: false,
  sets: [],
  setsLoading: false,
  hierarchy: [],
  allStudentsTeachers: [],
  hierarchyLoading: true,
};

export const assessmentTypeDefinition = (itemData, isAdminMode) => {
  let type;
  if (isAdminMode) {
    type = itemData.is_kra ? DATA_DISPLAY_TYPES.KRA : DATA_DISPLAY_TYPES.ELA;

    return type;
  }
  type = (itemData.assessmentTaxonomy === 'SUMMATIVE') ? DATA_DISPLAY_TYPES.KRA : DATA_DISPLAY_TYPES.ELA;
  return type;
};

export const mapAssessmentApiToDisplay = ({ item, isAdminMode }) => ({
  id: item.id,
  name: item.collectionName,
  type: item.type || assessmentTypeDefinition(item, isAdminMode),
  cycleStart: item.cycle_start_date,
  cycleEnd: item.cycle_end_date,
  enrollmentEnd: item.enrollment_end_date,
  isrAccessDate: item.isr_access_date,
  isrTeacherDate: item.isr_teacher_date,
  omrAccessDate: item.omr_access_date,
  dataDisplaysDate: item.data_displays_date,
  student_count: item.student_count,
  dataDisplayDate: item.data_displays_date,
  isKra: item.isKra,
});

const mapGradesApiToDisplay = (grades) => {
  const result = {
    items: [],
    students: [],
  };

  grades.forEach((grade) => {
    const studentId = grade.student_id;
    const itemId = grade.assessment_items_id;
    grade.state = 'none';

    if (result.students[studentId] === undefined) {
      result.students[studentId] = {};
    }
    if (result.students[studentId][itemId] === undefined) {
      result.students[studentId][itemId] = [];
    }
    result.students[studentId][itemId].push(grade);

    if (result.items[itemId] === undefined) {
      result.items[itemId] = {};
    }
    if (result.items[itemId][studentId] === undefined) {
      result.items[itemId][studentId] = [];
    }
    result.items[itemId][studentId].push(grade);
    return grade;
  });

  return result;
};

export const sortStudents = (a, b) => {
  if (a.last_name < b.last_name) {
    return -1;
  }
  if (a.last_name > b.last_name) {
    return 1;
  }
  if (a.first_name < b.first_name) {
    return -1;
  }
  if (a.first_name > b.first_name) {
    return 1;
  }
  return 0;
};

export const setGeneratingFlag = (state, { payload }) => ({
  ...state,
  assessmentLoading: payload,
});

export const teacherGetAssessment = (state) => ({
  ...state,
  assessmentLoading: true,
});

export const getAssessmentError = (state) => ({
  ...state,
  collection: initialState.collection,
  assessmentLoading: false,
});

export const getAssessmentSuccess = (state, { payload }) => ({
  ...state,
  id: payload.data.assessmentId,
  name: payload.data.assessmentName,
  collection: mapAssessmentApiToDisplay({ item: payload.data, isAdminMode: payload.isAdminMode }),
  assessmentLoading: false,
});

export const teacherGetCollectionItems = (state) => ({
  ...state,
  itemsLoading: true,
});

export const getCollectionItemsError = (state) => ({
  ...state,
  items: [],
  itemsLoading: false,
});

export const getCollectionItemsSuccess = (state, { payload }) => ({
  ...state,
  items: payload.data,
  itemsLoading: false,
});

export const getItemMaterialsRequest = (state) => ({
  ...state,
  itemsMaterialsLoading: true,
});

export const getItemMaterialsError = (state) => ({
  ...state,
  itemsMaterialsLoading: false,
});

export const getItemMaterialsSuccess = (state, { payload }) => ({
  ...state,
  items: payload.data,
  itemsMaterialsLoading: false,
});

export const getCollectionItemsMaterialsRequest = (state) => ({
  ...state,
  itemsMaterialsLoading: true,
});

export const getIsrsReportRequest = (state) => ({
  ...state,
  isrReportLoading: true,
});

export const getRatingSheetsReportRequest = (state) => ({
  ...state,
  ratingReportLoading: true,
});

export const getCollectionItemsMaterialsError = (state) => ({
  ...state,
  itemsMaterialsLoading: false,
});

export const getIsrsReportResponse = (state) => ({
  ...state,
  isrReportLoading: false,
});

export const getRatingSheetsReportResponse = (state) => ({
  ...state,
  ratingReportLoading: false,
});

export const getCollectionItemsMaterialsSuccess = (state, { payload }) => ({
  ...state,
  items: payload.data,
  itemsMaterialsLoading: false,
});

export const setStudentsAllTeachers = (state, { payload }) => ({
  ...state,
  allStudentsTeachers: payload,
  studentsLoading: false,
});

export const teacherGetCollectionScales = (state) => ({
  ...state,
  scalesLoading: true,
});

export const getCollectionScalesError = (state) => ({
  ...state,
  scales: [],
  scalesLoading: false,
});

export const getCollectionScalesSuccess = (state, { payload }) => ({
  ...state,
  scales: payload,
  scalesLoading: false,
});

export const setCollectionStudents = (state, { payload }) => ({
  ...state,
  regions: payload.regions,
  districts: payload.districts,
  locations: payload.locations,
  students: payload.students.sort(sortStudents),
});

export const setCollectionGrades = (state, { payload }) => ({
  ...state,
  grades: payload,
});

export const teacherGetCollectionSets = (state) => ({
  ...state,
  setsLoading: true,
});

export const getCollectionSetsError = (state) => ({
  ...state,
  sets: [],
  setsLoading: false,
});

export const getCollectionSetsSuccess = (state, { payload }) => ({
  ...state,
  sets: payload,
  setsLoading: false,
});

export const teacherGetCollectionHierarchy = (state) => ({
  ...state,
  hierarchyLoading: true,
});

export const getCollectionHierarchyError = (state) => ({
  ...state,
  hierarchy: [],
  hierarchyLoading: false,
});

export const getCollectionHierarchySuccess = (state, { payload }) => ({
  ...state,
  hierarchy: payload,
  hierarchyLoading: false,
});

export const refreshAssessmentState = () => ({
  ...initialState,
});

export const teacherGetCollectionStudents = (state) => ({
  ...state,
  studentsLoading: true,
});

export const getCollectionStudentsError = (state) => ({
  ...state,
  students: [],
  studentsLoading: false,
});

export const getCollectionStudentsSuccess = (state, { payload }) => ({
  ...state,
  regions: payload.regions,
  districts: payload.districts,
  locations: payload.locations,
  students: payload.students.sort(sortStudents),
  studentsLoading: false,
});

export const teacherGetCollectionGrades = (state) => ({
  ...state,
  gradesLoading: true,
});

export const getCollectionGradesError = (state) => ({
  ...state,
  grades: [],
  gradesLoading: false,
});

export const getCollectionGradesSuccess = (state, { payload: { data } }) => ({
  ...state,
  grades: mapGradesApiToDisplay(data),
  gradesLoading: false,
});

export const updateStudentOutput = (state, { payload }) => {
  const studentsArray = [...state.students];
  const updateStudentIndex = state.students.findIndex((item) => item.id === payload.id);

  studentsArray[updateStudentIndex] = { ...studentsArray[updateStudentIndex], ...payload };

  return ({
    ...state,
    students: studentsArray,
  });
};

export const saveStudentAssignmentType = (state) => ({
  ...state,
  studentsLoading: true,
});

export const saveStudentAssignmentTypeSuccess = (state) => ({
  ...state,
  studentsLoading: false,
});

export const saveStudentAssignmentTypeFailure = (state, { payload }) => ({
  ...state,
  studentsLoading: false,
  error: payload,
});

export default initialState;
