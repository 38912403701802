import {
  takeLatest, call, put,
} from 'redux-saga/effects';
import { navigate } from '@reach/router';

import { actions as snackbarActions } from 'redux/snackbar';
import {
  getTenants,
  getTenantAdmins,
  saveTenant,
  putTenant,
  postTenantAdmin,
  putTenantAdmin,
  postSchoolYear,
} from 'http/system';
import { actions } from './index';

function* fetchTenants(data) {
  try {
    const result = yield call(getTenants, data.params);
    yield put(actions.systemGetTenantsSuccess(result.data.tenants));
  } catch (error) {
    yield put(actions.systemGetTenantsError({ data: error }));
  }
}

function* saveTenantData({ data }) {
  try {
    const result = yield call(saveTenant, data.newTenant);
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Tenant saved' } }));
    yield put({ type: 'SYSTEM_SAVE_TENANT_SUCCESS', data: result.data });

    if (data.newUser) {
      yield put(snackbarActions.hideSnackbar());
      // New tenant flag to prevent navigation to tenant admins page
      yield put({ type: 'SYSTEM_CREATE_TENANT_ADMIN', data: { ...data.newUser, tenantMemberId: result.data.tenantId, newTenant: true } });
    }
  } catch (error) {
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Error saving tenant' } }));
    yield put({ type: 'SYSTEM_SAVE_TENANT_ERROR', data: error });
  }
  navigate('/system/tenants');
}

function* updateTenantData({ payload }) {
  try {
    payload.id = +payload.id;
    const result = yield call(putTenant, payload);
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Tenant saved' } }));
    yield put({ type: 'SYSTEM_UPDATE_TENANT_SUCCESS', data: result.data });

    navigate('/system/tenants');
  } catch (error) {
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Error saving tenant' } }));
    yield put({ type: 'SYSTEM_UPDATE_TENANT_ERROR', data: error });
  }
}

function* createTenantAdmin({ data }) {
  try {
    const result = yield call(postTenantAdmin, data);
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Tenant admin saved' } }));
    yield put({ type: 'SYSTEM_CREATE_TENANT_ADMIN_SUCCESS', data: result.data });
  } catch (error) {
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Error saving tenant admin' } }));
    yield put(actions.systemCreateTenantAdminError({ data: error.message }));
  }
  // This action is also called when creating a tenant so we don't want to navigate if that's the case
  if (!data.newTenant) navigate('/system/tenant-admins');
}

function* fetchTenantAdmins() {
  const data = {
    roles: ['ROLE_TENANT_ADMIN'],
  };

  try {
    const result = yield call(getTenantAdmins, data);
    yield put(actions.systemGetTenantAdminsSuccess(result.data.users));
  } catch (error) {
    yield put(actions.systemGetTenantAdminsError({ data: error }));
  }
}

function* updateTenantAdmin({ data }) {
  try {
    yield call(putTenantAdmin, data);
    yield put(actions.systemUpdateTenantAdminSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Tenant admin updated' } }));

    navigate('/system/tenant-admins');
  } catch (error) {
    yield put(actions.systemUpdateTenantAdminError({ data: error }));
  }
}

function* saveSchoolYear({ payload }) {
  try {
    yield call(postSchoolYear, payload);
    yield put(actions.systemCreateSchoolYearSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'School year updated' } }));
  } catch (error) {
    yield put(actions.systemCreateSchoolYearError({ data: error }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: 'Error creating school year' } }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.systemGetTenants, fetchTenants);
  yield takeLatest(actions.systemGetTenantAdmins, fetchTenantAdmins);
  yield takeLatest(actions.systemCreateTenantAdmin, createTenantAdmin);
  yield takeLatest(actions.systemUpdateTenantAdmin, updateTenantAdmin);
  yield takeLatest('SYSTEM_SAVE_TENANT', saveTenantData);
  yield takeLatest('SYSTEM_UPDATE_TENANT', updateTenantData);
  yield takeLatest(actions.systemCreateSchoolYear, saveSchoolYear);
}
