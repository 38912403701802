import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const SET_ACTIVE_ROLE = 'SET_ACTIVE_ROLE';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR';

export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const GET_CSRF_TOKEN = 'GET_CSRF_TOKEN';
export const GET_CSRF_TOKEN_FAIL = 'GET_CSRF_TOKEN_FAIL';
export const GET_CSRF_TOKEN_SUCCESS = 'GET_CSRF_TOKEN_SUCCESS';

export const CLEAR_SUCCESS_FLAG = 'CLEAR_SUCCESS_FLAG';

export const GET_TENTANT_CONFIG = 'GET_TENTANT_CONFIG';
export const TENTANT_CONFIGURATION_FAIL = 'TENTANT_CONFIGURATION_FAIL';
export const TENTANT_CONFIGURATION_SUCCESS = 'TENTANT_CONFIGURATION_SUCCESS';

export const SAVE_PROFILE_REQUEST = 'SAVE_PROFILE_REQUEST';
export const SAVE_PROFILE_SUCCESS = 'SAVE_PROFILE_SUCCESS';
export const SAVE_PROFILE_FAILURE = 'SAVE_PROFILE_FAILURE';

export const SET_RESOLUTION_MODE = 'SET_RESOLUTION_MODE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const IMPERSONATE_REQUEST = 'IMPERSONATE_REQUEST';
export const IMPERSONATE_REQUEST_SUCCESS = 'IMPERSONATE_REQUEST_SUCCESS';
export const IMPERSONATE_REQUEST_FAILURE = 'IMPERSONATE_REQUEST_FAILURE';

export const actions = createActions({
  [LOGOUT]: undefined,
  [LOGOUT_ERROR]: undefined,
  [LOGOUT_SUCCESS]: undefined,

  [SET_ACTIVE_ROLE]: undefined,

  [GET_CURRENT_USER]: undefined,
  [GET_CURRENT_USER_ERROR]: undefined,

  [LOGIN]: undefined,
  [LOGIN_ERROR]: undefined,
  [LOGIN_SUCCESS]: undefined,

  [GET_CSRF_TOKEN]: undefined,
  [GET_CSRF_TOKEN_FAIL]: undefined,
  [GET_CSRF_TOKEN_SUCCESS]: undefined,

  [CLEAR_SUCCESS_FLAG]: undefined,

  [GET_TENTANT_CONFIG]: undefined,
  [TENTANT_CONFIGURATION_FAIL]: undefined,
  [TENTANT_CONFIGURATION_SUCCESS]: undefined,

  [SAVE_PROFILE_REQUEST]: undefined,
  [SAVE_PROFILE_SUCCESS]: undefined,
  [SAVE_PROFILE_FAILURE]: undefined,

  [SET_RESOLUTION_MODE]: undefined,

  [RESET_PASSWORD_REQUEST]: undefined,
  [RESET_PASSWORD_SUCCESS]: undefined,
  [RESET_PASSWORD_FAILURE]: undefined,

  [FORGOT_PASSWORD_REQUEST]: undefined,
  [FORGOT_PASSWORD_SUCCESS]: undefined,
  [FORGOT_PASSWORD_FAILURE]: undefined,

  [IMPERSONATE_REQUEST]: undefined,
  [IMPERSONATE_REQUEST_SUCCESS]: undefined,
  [IMPERSONATE_REQUEST_FAILURE]: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.logout, handlers.logout],
    [actions.logoutSuccess, handlers.logoutSuccess],

    [actions.setActiveRole, handlers.setActiveRole],

    [actions.getCurrentUser, handlers.getCurrentUser],
    [actions.getCurrentUserError, handlers.getCurrentUserError],

    [actions.login, handlers.login],
    [actions.loginError, handlers.loginError],
    [actions.loginSuccess, handlers.loginSuccess],

    [actions.getCsrfToken, handlers.getCsrfToken],
    [actions.getCsrfTokenFail, handlers.getCsrfTokenFail],
    [actions.getCsrfTokenSuccess, handlers.getCsrfTokenSuccess],

    [actions.tentantConfigurationFail, handlers.tentantConfigurationFail],
    [actions.tentantConfigurationSuccess, handlers.tentantConfigurationSuccess],

    [actions.clearSuccessFlag, handlers.clearSuccessFlag],

    [actions.saveProfileRequest, handlers.saveProfileRequest],
    [actions.saveProfileSuccess, handlers.saveProfileSuccess],
    [actions.saveProfileFailure, handlers.saveProfileFailure],

    [actions.setResolutionMode, handlers.setResolutionMode],

    [actions.resetPasswordRequest, handlers.resetPasswordRequest],
    [actions.resetPasswordFailure, handlers.resetPasswordFailure],
    [actions.resetPasswordSuccess, handlers.resetPasswordSuccess],

    [actions.forgotPasswordRequest, handlers.forgotPasswordRequest],
    [actions.forgotPasswordFailure, handlers.forgotPasswordFailure],
    [actions.forgotPasswordSuccess, handlers.forgotPasswordSuccess],

    [actions.impersonateRequest, handlers.impersonateRequest],
    [actions.impersonateRequestSuccess, handlers.impersonateRequestSuccess],
    [actions.impersonateRequestFailure, handlers.impersonateRequestFailure],
  ]),
  initialState,
);

export default reducer;
