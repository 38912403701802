import {
  takeLatest,
  select,
  delay,
  call,
  put,
} from 'redux-saga/effects';

import * as api from 'http/reports';
import { requestCas3ApiReport } from 'http/admin/reports';

import { actions as snackbarActions } from 'redux/snackbar';
import { actions } from './index';

function* getReportsRequest({ payload }) {
  try {
    const result = yield call(api.getReports, payload);

    yield put(actions.getReportsSuccess(result));
  } catch (error) {
    yield put(actions.getReportsFailure(error));
  }
}

function* getInfoSheetRequest({ payload }) {
  try {
    const result = yield call(api.getInfoSheet, payload);

    yield put(actions.getInfoSheetSuccess(result));
  } catch (error) {
    yield put(actions.getInfoSheetFailure(error));
  }
}

function* onGetReportRequest({ payload: { data } }) {
  try {
    const { collection } = yield select(({ teacher }) => teacher.assessment);

    let route;
    let body;
    switch (data.type.toLowerCase()) {
    case 'ela.studentscores':
      route = 'ela-class-skb';
      body = {
        dataCollectionId: data.collectionId,
        showOnlyPriorityItems: data.priorityOnly,
        isTeacher: true,
      };
      break;
    case 'kra.domaindata':
      route = 'domain-data';
      body = {
        dataCollectionId: data.collectionId,
        doShowEll: data.showEll,
        doShowIep: data.showIep,
        isTeacher: true,
      };
      break;
    case 'kra.languageliteracy':
      route = 'language-literacy';
      body = {
        dataCollectionId: data.collectionId,
        isTeacher: true,
      };
      break;
    case 'kra.dataresults':
      route = 'kra-data-results';
      body = {
        dataCollectionId: data.collectionId,
        isTeacher: true,
      };
      break;
    default:
    }

    if (route) {
      yield call(requestCas3ApiReport, route, body);
    } else {
      const result = yield call(api.getReport, data);
      let check;
      let counter = 0;

      if (data.type !== 'ELA.StudentScores') {
        while ((!check || !check.data || check.data.status.toLowerCase() !== 'complete') && counter < 100) {
          check = yield call(api.checkReportFile, { ...data, reportId: result.data.id });
          yield delay(3000);
          counter += 1;
        }
      }

      yield put(actions.getReportSuccess(result.data.id));
    }

    yield put(actions.getReportsRequest(collection.id));
  } catch (error) {
    yield put(actions.getReportFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}
export default function* watchSagas() {
  yield takeLatest(actions.getInfoSheetRequest, getInfoSheetRequest);
  yield takeLatest(actions.getReportRequest, onGetReportRequest);
  yield takeLatest(actions.getReportsRequest, getReportsRequest);
}
