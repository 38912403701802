import {
  takeLatest,
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import * as collectionsApi from 'http/admin/collections';

import { actions as snackbarActions } from 'redux/snackbar';
import { actions } from './index';

function* getCollectionAssignments({ payload }) {
  try {
    const {
      tenantId, collectionId,
    } = payload;

    const result = yield call(collectionsApi.getCollectionAssignments, {
      tenantId, collectionId,
    });

    yield put(actions.getCollectionAssignmentsSuccess(result));
  } catch (error) {
    yield put(actions.getCollectionAssignmentsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getLocations({ payload }) {
  try {
    const {
      tenantId, collectionId,
    } = payload;

    const result = yield call(collectionsApi.getCollectionLocations, {
      tenantId, collectionId,
    });

    yield put(actions.getCollectionLocationsSuccess(result));
  } catch (error) {
    yield put(actions.getCollectionLocationsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getDistricts({ payload }) {
  try {
    const {
      tenantId,
    } = payload;

    const result = yield call(collectionsApi.getDistrictsByTenant, {
      tenantId,
    });

    yield put(actions.getCollectionDistrictsSuccess(result.data));
  } catch (error) {
    yield put(actions.getCollectionDistrictsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getRegions({ payload }) {
  try {
    const {
      tenantId,
    } = payload;

    const result = yield call(collectionsApi.getRegionsByTenant, {
      tenantId,
    });

    yield put(actions.getCollectionRegionsSuccess(result.data));
  } catch (error) {
    yield put(actions.getCollectionRegionsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* assignLocation({ payload }) {
  try {
    const {
      tenantId,
      collectionId,
      locationId,
    } = payload;

    const result = yield call(collectionsApi.assignLocation, {
      tenantId,
      collectionId,
      locationId,
    });

    yield put(actions.getCollectionLocations({ tenantId, collectionId }));
    yield put(actions.getCollectionAssignments({ tenantId, collectionId }));
    yield put(actions.assignLocationSuccess({ locationId, result }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Data Collection Assignments added for Location' } }));
  } catch (error) {
    yield put(actions.assignLocationFailure({ error, locationId: payload.locationId }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* assignLocationsByDistrict({ payload }) {
  try {
    const {
      tenantId,
      collectionId,
      districtId,
    } = payload;

    const result = yield call(collectionsApi.assignLocationsByDistrict, {
      tenantId,
      collectionId,
      districtId,
    });

    yield put(actions.getCollectionAssignments({ tenantId, collectionId }));
    yield put(actions.assignLocationsByDistrictSuccess({ result, districtId }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Successfully assigned to all locations in the district' } }));
  } catch (error) {
    yield put(actions.assignLocationsByDistrictFailure({ error, districtId: payload.districtId }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* assignLocationsByRegion({ payload }) {
  try {
    const {
      tenantId,
      collectionId,
      regionId,
    } = payload;

    const result = yield call(collectionsApi.assignLocationsByRegion, {
      tenantId,
      collectionId,
      regionId,
    });

    yield put(actions.getCollectionAssignments({ tenantId, collectionId }));
    yield put(actions.assignLocationsByRegionSuccess({ result, regionId }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Successfully added to all assigned locations in the region' } }));
  } catch (error) {
    yield put(actions.assignLocationsByRegionFailure({ error, regionId: payload.regionId }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* unassignLocation({ payload }) {
  try {
    const {
      tenantId,
      collectionId,
      locationId,
    } = payload;

    const result = yield call(collectionsApi.unassignLocation, {
      tenantId,
      collectionId,
      locationId,
    });

    yield put(actions.getCollectionAssignments({ tenantId, collectionId }));
    yield put(actions.getCollectionLocations({ tenantId, collectionId }));
    yield put(actions.unassignLocationSuccess({ result, locationId }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Data Collection Assignments removed for Location' } }));
  } catch (error) {
    yield put(actions.unassignLocationFailure({ error, locationId: payload.locationId }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* watchSagas() {
  yield takeLatest(actions.getCollectionAssignments, getCollectionAssignments);
  yield takeLatest(actions.getCollectionLocations, getLocations);
  yield takeLatest(actions.getCollectionDistricts, getDistricts);
  yield takeLatest(actions.getCollectionRegions, getRegions);
  yield takeEvery(actions.assignLocation, assignLocation);
  yield takeEvery(actions.assignLocationsByDistrict, assignLocationsByDistrict);
  yield takeEvery(actions.assignLocationsByRegion, assignLocationsByRegion);
  yield takeEvery(actions.unassignLocation, unassignLocation);
}
