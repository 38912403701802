import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_REPORTS_BY_TENANT: undefined,
  GET_REPORTS_BY_TENANT_SUCCESS: undefined,
  GET_REPORTS_BY_TENANT_FAILURE: undefined,

  REQUEST_REPORT: undefined,
  REQUEST_REPORT_SUCCESS: undefined,
  REQUEST_REPORT_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getReportsByTenant, handlers.getReportsByTenant],
    [actions.getReportsByTenantSuccess, handlers.getReportsByTenantSuccess],
    [actions.getReportsByTenantFailure, handlers.getReportsByTenantFailure],

    [actions.requestReport, handlers.requestReport],
    [actions.requestReportSuccess, handlers.requestReportSuccess],
    [actions.requestReportFailure, handlers.requestReportFailure],
  ]),
  initialState,
);

export default reducer;
