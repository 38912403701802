/* eslint-disable indent,quotes,object-curly-newline,keyword-spacing,no-console,consistent-return,no-use-before-define */
/**
 * This file is in charge of navigating between CAS3 and CAS4 as seamlessly as possible. There is a counterpart file in
 * CAS4, also called interop, which looks identical. The consuming UI should handle the parts specific to them, this was
 * designed to be generalized so that identical copies can be used between both UIs, hence the eslint-disable directive.
 * I opted to default to the CAS4 style, since that UI will eventually supercede the entirety of CAS3.
 *
 * We use session storage for signaling and auth persistence between the UIs, because:
 * - It persists across reloads
 * - It persists across redirects
 * - It does not persist across tabs, or closed-then-reopened tabs
 *
 * This makes it an ideal candidate for session-based signaling compared to something like localStorage or query params.
 * We have the added benefit of storing any arbitrary data, compared to query params, which also pollute the URL.
 */

// These two lines are the only things that should change per-UI in this file. Anything else should be updated abroad.
// The URL function has slight implementation differences per-UI, so it's not included here.
// The source MUST be unique, and helps determine where session data came from when navigating somewhere.
import url from "helpers/http/url";

const SOURCE = "cas3";

const SIGNALING_KEY = "cas-interop";
const PERSISTENCE_KEY = "cas-auth";

let debug = !!localStorage.getItem("interop:debug");
log("debug enabled");

export function toggleDebug() {
    debug = true;
    log("debug enabled");
}

export function log(...messages) {
    if(debug) {
        // We have to serialize everything to strings, otherwise after a redirect
        const serialized = messages.map((line) => {
            if(Array.isArray(line) || typeof line === "object") {
                return JSON.stringify(line, null, 4);
            }

            return line;
        }).join("\n");

        console.debug(`[CasInterop:${SOURCE}]`, serialized);
    }
}

export function navigate(path, metadata = {}) {
    const from = window.location.pathname;
    const to = url(path, true);
    const payload = { source: SOURCE, to, from, metadata };

    // Just a helpful utility to track redirects
    if(metadata && metadata.reason) {
        log(`Navigating away from ${SOURCE} because ${metadata.reason}`, payload);
    }

    sessionStorage.setItem(SIGNALING_KEY, JSON.stringify(payload));

    // This is used for things like redirects from one UI to the other, where there is an alias
    // An example would be CAS4-UI's /administrator route going straight to /administrator/dashboard
    if(metadata && metadata.replace) {
        return window.location.replace(to);
    }

    window.location.href = to;
}

export function persistAuth(data, jwt) {
    sessionStorage.setItem(PERSISTENCE_KEY, JSON.stringify({ data, jwt }));
}

export function getPersistedAuth() {
    const raw = sessionStorage.getItem(PERSISTENCE_KEY);
    if(!raw) return { error: "not-present" };

    try {
        const parsed = JSON.parse(raw);
        if(!parsed || !parsed.data || !parsed.jwt) return { error: "not-valid" };

        return parsed;
    } catch(error) {
        return { error };
    }
}

export function processSignal() {
    const raw = sessionStorage.getItem(SIGNALING_KEY);
    if(!raw) return { error: "not-present" };

    try {
        const parsed = JSON.parse(raw);

        if(!parsed || !parsed.source) return { error: "not-valid" };

        return parsed;
    } catch(error) {
        return { error };
    }
}

export function eraseSignal() {
    sessionStorage.removeItem(SIGNALING_KEY);
}

export function eraseAuth() {
    sessionStorage.removeItem(PERSISTENCE_KEY);
}

// Re-export so consuming JS only needs one import
export { url };
