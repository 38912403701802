import React, { useState, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'react-proptypes';

import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import CloseButton from 'modules/Teacher/components/ScoreModal/CloseButton';
import { actions } from 'redux/notifications';

const styles = (theme) => ({
  headerLink: {
    color: `${theme.palette.primary.contrastText}`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing()}px 0`,
      minWidth: '40px',
    },
    [theme.breakpoints.down(768)]: {
      fontSize: 32,
      marginBottom: 15,
    },
  },
  notification: {
    margin: 0,
  },
  unreadNotification: {
    fontWeight: '700',
    margin: 0,
  },
});

const Notifications = (props) => {
  const {
    classes, dispatch, notifications, t, className, resolutionMode,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const unreadNotifications = useMemo(() => notifications.filter((n) => !!n.acknowledged === false), [notifications]);

  useEffect(() => {
    dispatch(actions.getNotifications());
    dispatch(actions.subscribeToNotifications());
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (notificationId) => {
    if (notificationId) {
      dispatch(actions.acknowledgeNotification({ notificationId }));
    }
    setAnchorEl(null);
  };

  const clearNotification = (notificationId) => {
    dispatch(actions.removeNotification({ notificationId }));
  };

  return (
    <>
      <Button
        aria-owns={anchorEl ? 'notifications-menu' : undefined}
        className={classes.headerLink}
        aria-haspopup="true"
        onClick={handleClick}
        id="header-notifications-icon"
      >
        {unreadNotifications.length > 0 ? (
          <Badge badgeContent={unreadNotifications.length} color="error">
            <NotificationsIcon className={className} />
            {resolutionMode === 'mobile' && 'Notifications'}
          </Badge>
        ) : (
          <>
            <NotificationsIcon className={className} />
            {resolutionMode === 'mobile' && 'Notifications'}
          </>
        )}
      </Button>
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {notifications.length ? (
          <div>
            <ListItem>
              <span style={{ borderBottom: '1px solid lightgray', width: '100%' }}>
                <b>{t('Notifications')}</b>
                <br />
                {t('Click a notification to acknowledge it and the X to clear it')}
                <br />
                (
                {t('acknowledged')}
                ,&nbsp;
                <span style={{ fontWeight: 700 }}>{t('not acknowledged')}</span>
                )
              </span>
            </ListItem>
            {
              notifications.map((notification) => (
                <MenuItem
                  dense
                  key={notification.id}
                  onClick={() => handleClose(notification.id)}
                  className={notification.acknowledged ? classes.notification : classes.unreadNotification}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    {notification.description || notification.message}
                    &emsp;
                    <CloseButton
                      size="small"
                      onClose={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        clearNotification(notification.id);
                      }}
                    />
                  </Box>
                </MenuItem>
              ))
            }
          </div>
        ) : (
          <MenuItem onClick={() => handleClose()}>{t('No notifications')}</MenuItem>
        )}
      </Menu>
    </>
  );
};

Notifications.defaultProps = {
  className: '',
};
Notifications.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  resolutionMode: PropTypes.string.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      userId: PropTypes.number,
      message: PropTypes.string,
      acknowledged: PropTypes.number,
      classification: PropTypes.string,
      createdAt: PropTypes.number,
    }),
  ).isRequired,
};

const mapStateToProps = ({ auth, notifications }) => ({
  notifications: notifications.list,
  resolutionMode: auth.resolutionMode,
});

export default connect(
  mapStateToProps,
  (dispatch) => ({ dispatch }),
)(withStyles(styles)(withTranslation()(Notifications)));
