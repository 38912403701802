import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

import { filterByTypeFormatter, formatForRequest } from './formatter';

export const getFiltersByCollection = ({ collectionId, addInfo }) => http.get(
  cas3ApiRoute('/data-collections-filters'),
  { params: { dataCollectionId: collectionId } },
).then((res) => filterByTypeFormatter(res.data.filters, addInfo));

export const createFiltersByCollection = ({ collectionId, ...data }) => http.post(
  cas3ApiRoute('/data-collections-filters'),
  {
    ...formatForRequest(data),
    dataCollectionsId: +collectionId,
  },
).then((res) => res.data);

export const getFilter = (collectionId, filterId) => http.get(`/user/collections/${collectionId}/filters/${filterId}.json`);

export const updateFilter = ({ id, ...data }) => http.put(
  cas3ApiRoute('/data-collections-filters'),
  { id, ...formatForRequest(data) },
).then((res) => res.data.filter);

export const deleteFilter = ({ id }) => http.delete(
  cas3ApiRoute('/data-collections-filters'),
  { params: { id } },
);
