import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

import { transfersFormatter } from './formatter';

export const getOtherTransfersByTenant = () => http.get(
  cas3ApiRoute('/transfers/incoming'),
).then((res) => transfersFormatter(res.data.transfers));

export const getUserTransfersByTenant = () => http.get(
  cas3ApiRoute('/transfers/outgoing'),
).then((res) => transfersFormatter(res.data.transfers));

export const getHistoryTransfersByTenant = () => http.get(
  cas3ApiRoute('/transfers/history'),
).then((res) => transfersFormatter(res.data.transfers));

export const createTransfer = (data) => http.post(
  cas3ApiRoute('/enrollments'),
  data,
).then((res) => res.data);

export const approveTransfer = ({ transferId }) => http.put(
  cas3ApiRoute(`/transfers/${transferId}`),
  { isApproved: true },
);

export const declineTransfer = ({ transferId }) => http.put(
  cas3ApiRoute(`/transfers/${transferId}`),
  { isApproved: false },
);
