import { navigate } from '@reach/router';

import { takeLatest, call, put } from 'redux-saga/effects';

import { actions as snackbarActions } from 'redux/snackbar';
import * as api from 'http/admin/locations';

import { actions } from './index';

export function* getLocationsByDistrict({ payload }) {
  try {
    const { districtId } = payload;

    const result = yield call(api.getLocations, { parentId: districtId, active: 1 });

    yield put(actions.getLocationsByDistrictSuccess(result));
  } catch (error) {
    yield put(actions.getLocationsByDistrictFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getLocationDetails({ payload }) {
  try {
    const { locationId } = payload;

    const result = yield call(api.getLocationDetails, { locationId });

    yield put(actions.getLocationDetailsSuccess(result));
  } catch (error) {
    yield put(actions.getLocationDetailsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* createLocation({ payload }) {
  try {
    const {
      tenantId,
      regionId,
      districtId,
      data,
      successHandle,
      isOpenAfter,
    } = payload;

    const result = yield call(api.createLocation, {
      ...data,
      nameLabel: data.name,
      parentDistrictId: +districtId,
    });

    yield put(actions.createLocationSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Location successfully created' } }));
    yield put(actions.getLocationsByDistrict({ tenantId, regionId, districtId }));

    if (isOpenAfter) navigate(`/administrator/organizations/regions/${regionId}/district/${districtId}/location/${result.data.id}/profile/details`);
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.createLocationFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* updateLocation({ payload }) {
  try {
    const { locationId, data } = payload;

    const location = {
      id: +locationId,
      nameLabel: data.name,
      uniqueId: data.uniqueId,
    };

    const result = yield call(api.updateLocation, location);

    yield put(actions.updateLocationSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Location successfully updated' } }));
  } catch (error) {
    yield put(actions.updateLocationFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* deleteLocation({ payload }) {
  try {
    const {
      tenantId,
      regionId,
      districtId,
      locationId,
      successHandle,
      redirectAfter,
    } = payload;

    const result = yield call(api.deleteLocation, {
      tenantId, regionId, districtId, locationId,
    });

    yield put(actions.deleteLocationSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Location successfully deleted' } }));
    yield put(actions.getLocationsByDistrict({ tenantId, regionId, districtId }));

    if (redirectAfter) navigate(`/administrator/organizations/regions/${regionId}/district/${districtId}/profile/list`);
    if (successHandle) {
      successHandle();
    }
  } catch (error) {
    yield put(actions.deleteLocationFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.getLocationsByDistrict, getLocationsByDistrict);
  yield takeLatest(actions.createLocation, createLocation);
  yield takeLatest(actions.getLocationDetails, getLocationDetails);
  yield takeLatest(actions.updateLocation, updateLocation);
  yield takeLatest(actions.deleteLocation, deleteLocation);
}
