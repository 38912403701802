const initialState = {
  data: [],
  studentsTeachers: null,
  error: '',
  dataLoading: false,
  isLoaded: false,
};

export const getData = (state) => ({
  ...state,
  dataLoading: true,
  isLoaded: false,
});

export const getDataError = (state) => ({
  ...state,
  dataLoading: false,
  error: 'Error fetching data',
  isLoaded: true,
});

export const getDataSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  dataLoading: false,
  isLoaded: true,
});

export const setStudentsTeachers = (state, { payload }) => ({
  ...state,
  studentsTeachers: payload.reduce((acc, student) => {
    if (Array.isArray(student)) {
      return { ...acc };
    }
    return ({
      ...acc,
      [student.student_id]: Object.keys(student.teachers).map((teacher) => student.teachers[teacher]),
    });
  }, {}),
});

export const refreshCommonData = () => ({
  ...initialState,
});

export default initialState;
