import http from 'http/index';
import initialAuthState from 'redux/auth/handlers';
import * as CasInterop from './interop';

let redirectedFromCAS4 = false;

export const wasRedirectedFromCAS4 = () => redirectedFromCAS4;

export function loadInitialState() {
  const redirect = CasInterop.processSignal();
  CasInterop.eraseSignal();

  CasInterop.log(redirect);

  // Only skip authentication when coming from a different, valid UI configuration
  // This allows us to still authenticate if you just reload the page
  if (!redirect.error) {
    CasInterop.log(`Redirected from ${redirect.source}`);

    if (redirect.source === 'cas4') {
      redirectedFromCAS4 = true;

      // The cache is saved in a { cas3, cas4 } format
      const cachedSession = CasInterop.getPersistedAuth();
      CasInterop.log(cachedSession);

      if (!cachedSession.error) {
        const { data, jwt } = cachedSession;

        if (data.authenticated) {
          CasInterop.log('Found valid auth session from cache');

          http.interceptors.request.use((config) => ({
            ...config,
            headers: {
              ...config.headers,
              authorization: `Bearer: ${jwt}`,
            },
          }));

          return { auth: data };
        }
      } else {
        CasInterop.eraseAuth();
      }
    }
  }

  return {
    auth: {
      ...initialAuthState,
      // Supply checkingAuth to prevent loop redirects with CAS3's UI, because it fetches the current user in an effect
      // hook on the App component and not on mount, where a CasInterop redirect is triggered instead of rendering the
      // old login component.
      checkingAuth: true,
    },
  };
}
