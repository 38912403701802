import i18n from 'i18next';
import * as CasInterop from 'helpers/cas4/interop';

/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
const errorHandler = (error) => {
  const { response: errorResponse, message: mainErrorMessage } = error;
  let message = null;

  // keys error don't have response object
  if (!errorResponse) {
    return mainErrorMessage;
  }

  const errorRecurse = (obj) => {
    for (const objKey in obj) {
      // check for array called errors
      if (objKey === 'errors' && Array.isArray(obj[objKey]) && obj[objKey][0]) {
        return obj[objKey][0]; // get first error message
      }

      // recurse over other objects
      if ((typeof obj[objKey]) === 'object') {
        const recurseMessage = errorRecurse(obj[objKey]);

        if (recurseMessage !== null) {
          return recurseMessage;
        }
      }
    }

    return null;
  };

  if ((typeof errorResponse) === 'object') {
    // errorResponse destructuring
    const { message: errorMessage, data: errorData } = errorResponse;

    if ((typeof errorData) === 'string' && errorData.length) {
      message = errorData;
    } else if ((typeof errorMessage) === 'string') {
      message = errorMessage;
    } else if ((typeof errorData) === 'object') {
      if ((typeof errorData?.errors) === 'object') {
        message = errorRecurse(errorData?.errors);
      }

      if (message == null && (typeof errorData?.message) === 'string') {
        // errorData destructuring
        const { message: errorDataMessage } = errorData;

        message = errorDataMessage;
      }
    }
  }

  // eslint-disable-next-line
  console.log({ message, errorResponse, error });

  if (message !== null) {
    return message;
  }

  switch (errorResponse?.status) {
  case 500:
    return i18n.t('Unable to complete your request. If you believe this message to be in error contact your system administrator');
  case 409:
    return i18n.t('The request couldn\'t be completed due to a conflicting resource call');
  case 403:
    return i18n.t('Token does not have the required permissions');
  case 401:
    if (!error.config.url.includes('.json')) {
      CasInterop.eraseAuth();
      CasInterop.navigate('/sign-in', { reason: '401' });
    }

    return i18n.t('Authorization Error');
  default:
    break;
  }

  // default error
  return i18n.t('Something went wrong');
};

export default errorHandler;
