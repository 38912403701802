import {
  put, call, all, select, takeLatest,
} from 'redux-saga/effects';

import * as api from 'http/spreadsheet';

import { actions } from './index';
import { actions as assessmentActions } from '../assessment';
import { getCollection, getGradeId } from './selectors';

function* selectItem({ payload }) {
  const {
    itemId,
    forExpansionPanel,
    selectedEventSource,
    collectionId,
  } = payload;

  // get materials for selected item
  yield put(assessmentActions.getItemMaterialsRequest({ collectionId, itemId }));
  yield put(actions.spreadsheetItemSelected({ itemId, forExpansionPanel, selectedEventSource }));
}

function* selectStudent({ payload: { data } }) {
  yield put(actions.spreadsheetStudentSelected({ data }));
}

function* submitStudentGrade({ payload }) {
  try {
    const { id } = yield select(getCollection);
    const request = {
      studentEnrollmentId: payload.student_enrollment_id,
      assessmentItemsId: payload.assessment_items_id,
      assessmentItemsScalesEntriesId: payload.assessment_items_scales_entries_id,
    };

    yield call(api.postStudentGrade, id, request);

    yield put(actions.postStudentGradeSuccess());
    yield put(assessmentActions.teacherGetCollectionGrades({ data: id }));
  } catch (error) {
    yield put(actions.postStudentGradeError({ data: error }));
  }
}

function* onOpenArtifactsModal({ payload }) {
  try {
    const { id } = yield select(getCollection);
    const { id: gradeId } = payload;
    const result = yield call(api.getArtifacts, { collectionId: id, gradeId });

    yield put(actions.openArtifactsModalSuccess({ data: result }));
  } catch (error) {
    yield put(actions.openArtifactsModalError({ data: error }));
  }
}

function* onSaveArtifactsModal({ payload }) {
  const { filesToUpload, filesToDelete } = payload;
  const { id: collectionId } = yield select(getCollection);
  const gradeId = yield select(getGradeId);

  try {
    if (Object.keys(payload).includes('comment')) {
      yield call(api.saveScoreComment, { collectionId, gradeId, comment: payload.comment });
    }

    if (filesToDelete.length) {
      const requests = filesToDelete.map((id) => api.deleteArtifact({ collectionId, gradeId, id }));

      yield all(requests);
    }

    if (filesToUpload.length) {
      yield call(api.saveArtifacts, { collectionId, gradeId, files: filesToUpload });
    }

    yield put(actions.saveArtifactsModalSuccess());
    yield put(assessmentActions.teacherGetCollectionGrades({ data: collectionId }));
    yield put(actions.closeArtifactsModal());
  } catch (error) {
    yield put(actions.saveArtifactsModalError());
  }
}

function* deleteStudentGrade({ payload }) {
  try {
    const { id: collectionId } = yield select(getCollection);

    yield call(api.deleteStudentGrade, { collectionId, gradeId: payload });

    yield put(actions.deleteStudentGradeSuccess());
    yield put(assessmentActions.teacherGetCollectionGrades({ data: collectionId }));
  } catch (error) {
    yield put(actions.deleteStudentGradeError(error));
  }
}

function* getGroupsRequest({ payload }) {
  try {
    const result = yield call(api.getGroups, payload);

    yield put(actions.getGroupsSuccess(result));
  } catch (error) {
    yield put(actions.getGroupsError(error));
  }
}

export default function* watchSagas() {
  yield takeLatest(actions.spreadsheetSelectItem, selectItem);
  yield takeLatest(actions.spreadsheetSelectStudent, selectStudent);
  yield takeLatest(actions.getGroupsRequest, getGroupsRequest);
  yield takeLatest(actions.postStudentGrade, submitStudentGrade);
  yield takeLatest(actions.deleteStudentGradeRequest, deleteStudentGrade);
  yield takeLatest(actions.openArtifactsModal, onOpenArtifactsModal);
  yield takeLatest(actions.saveArtifactsModal, onSaveArtifactsModal);
}
