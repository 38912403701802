import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

export const actions = createActions({
  [SHOW_SNACKBAR]: undefined,
  [HIDE_SNACKBAR]: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.showSnackbar, handlers.showSnackbar],
    [actions.hideSnackbar, handlers.hideSnackbar],
  ]),
  initialState,
);

export default reducer;
