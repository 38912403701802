const initialState = {
  data: [],
  error: null,

  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
};

export const getCollectionSupports = (state) => ({
  ...state,
  isLoading: true,
});

export const getCollectionSupportsFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const getCollectionSupportsSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const createCollectionSupport = (state) => ({
  ...state,
  isCreating: true,
});

export const createCollectionSupportFailure = (state, { payload }) => ({
  ...state,
  isCreating: false,
  error: payload,
});

export const createCollectionSupportSuccess = (state) => ({
  ...state,
  isCreating: false,
});

export const updateCollectionSupport = (state) => ({
  ...state,
  isUpdating: true,
});

export const updateCollectionSupportFailure = (state, { payload }) => ({
  ...state,
  isUpdating: false,
  error: payload,
});

export const updateCollectionSupportSuccess = (state, { payload }) => {
  const newData = state.data.map((support) => {
    if (support?.id === payload?.id) return payload;

    return support;
  });

  return ({
    ...state,
    data: newData,
    isUpdating: false,
  });
};

export const deleteCollectionSupport = (state) => ({
  ...state,
  isDeleting: true,
});

export const deleteCollectionSupportFailure = (state, { payload }) => ({
  ...state,
  isDeleting: false,
  error: payload,
});

export const deleteCollectionSupportSuccess = (state) => ({
  ...state,
  isDeleting: false,
});

export const refreshCollectionSupports = (state) => ({
  ...state,
  data: initialState.data,
});

export default initialState;
