const initialState = {
  error: null,

  outputInfo: {},
  isOutputLoading: false,
};

export const generateOutputByCollection = (state) => state;
export const generateOutputByCollectionSuccess = (state) => state;

export const generateOutputByCollectionFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const clearOutputByCollection = (state) => state;
export const clearOutputByCollectionSuccess = (state) => state;

export const clearOutputByCollectionFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const getOutputByCollection = (state) => ({
  ...state,
  outputInfo: initialState.outputInfo,
  isOutputLoading: true,
});

export const getOutputByCollectionSuccess = (state, { payload }) => ({
  ...state,
  outputInfo: payload,
  isOutputLoading: false,
});

export const getOutputByCollectionFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isOutputLoading: false,
});

export const clearOutputInfo = (state) => ({
  ...state,
  outputInfo: initialState.outputInfo,
  isOutputLoading: false,
});

export default initialState;
