import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_SCHOOL_YEARS_BY_TENANT: undefined,
  GET_SCHOOL_YEARS_BY_TENANT_SUCCESS: undefined,
  GET_SCHOOL_YEARS_BY_TENANT_FAILURE: undefined,

  GET_ARCHIVES_BY_YEAR_ID: undefined,
  GET_ARCHIVES_BY_YEAR_ID_SUCCESS: undefined,
  GET_ARCHIVES_BY_YEAR_ID_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getSchoolYearsByTenant, handlers.getSchoolYearsByTenant],
    [actions.getSchoolYearsByTenantSuccess, handlers.getSchoolYearsByTenantSuccess],
    [actions.getSchoolYearsByTenantFailure, handlers.getSchoolYearsByTenantFailure],

    [actions.getArchivesByYearId, handlers.getArchivesByYearId],
    [actions.getArchivesByYearIdSuccess, handlers.getArchivesByYearIdSuccess],
    [actions.getArchivesByYearIdFailure, handlers.getArchivesByYearIdFailure],
  ]),
  initialState,
);

export default reducer;
