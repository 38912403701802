import { navigate } from '@reach/router';

import { takeLatest, call, put } from 'redux-saga/effects';

import { actions as snackbarActions } from 'redux/snackbar';
import * as api from 'http/admin/regions';

import { actions } from './index';

export function* getRegionsByTenant({ payload }) {
  try {
    const { tenantId } = payload;

    const result = yield call(api.getRegions, { parentTenantId: tenantId, active: 1 });

    yield put(actions.getRegionsByTenantSuccess(result));
  } catch (error) {
    yield put(actions.getRegionsByTenantFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* getRegionDetails({ payload }) {
  try {
    const { regionId } = payload;

    const result = yield call(api.getRegionDetails, { regionId });

    yield put(actions.getRegionDetailsSuccess(result));
  } catch (error) {
    yield put(actions.getRegionDetailsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* createRegion({ payload }) {
  try {
    const {
      tenantId, data, successHandle, isOpenAfter,
    } = payload;

    const result = yield call(api.createRegion, { ...data, nameLabel: data.name });

    yield put(actions.createRegionSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Region successfully created' } }));
    yield put(actions.getRegionsByTenant({ tenantId }));

    if (isOpenAfter) navigate(`/administrator/organizations/regions/${result.data.id}/profile/details`);
    if (successHandle) successHandle();
  } catch (error) {
    yield put(actions.createRegionFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export function* updateRegion({ payload }) {
  try {
    const { regionId, data } = payload;

    const region = {
      id: regionId,
      nameLabel: data.name,
      uniqueId: data.uniqueId,
    };

    const result = yield call(api.updateRegion, region);

    yield put(actions.updateRegionSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Region successfully updated' } }));
  } catch (error) {
    yield put(actions.updateRegionFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.getRegionsByTenant, getRegionsByTenant);
  yield takeLatest(actions.getRegionDetails, getRegionDetails);
  yield takeLatest(actions.createRegion, createRegion);
  yield takeLatest(actions.updateRegion, updateRegion);
}
