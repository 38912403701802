import React, { useEffect, useState } from 'react';
import i18n from 'i18n';

import { getUserOrgs } from 'http/admin/users';

import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import ReactSelect from '../ReactSelect';

const StudentLocationSelect = (props) => {
  const {
    handleRegionChange: regionChangeHandler,
    handleDistrictChange: districtChangeHandler,
    handleLocationChange: locationChangeHandler,
    userId,
    hasErrors,
    required = {},
  } = props;

  const [orgsLoading, setOrgsLoading] = useState(false);
  const [orgs, setOrgs] = useState({});

  const [selectedRegionId, setSelectedRegionId] = useState();
  const [regions, setRegions] = useState([]);

  const [selectedDistrictId, setSelectedDistrictId] = useState();
  const [districts, setDistricts] = useState([]);

  const [selectedLocationId, setSelectedLocationId] = useState();
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const userOrgsSideEffect = async () => {
      setOrgsLoading(true);
      const result = await getUserOrgs({ userId });
      setOrgs({
        regions: result.regions,
        districts: result.districts,
        locations: result.locations,
      });
      setRegions(result.regions.map((r) => ({
        value: r.id,
        label: r.nameLabel,
      })));
      setOrgsLoading(false);
    };
    userOrgsSideEffect();
  }, []);

  const handleRegionChange = async (e) => {
    const regionId = e.value;
    setSelectedRegionId(regionId);
    setSelectedDistrictId();
    setSelectedLocationId();

    setDistricts(orgs.districts.filter((d) => (d.parentId === regionId))
      .map((d) => ({
        value: d.id,
        label: d.nameLabel,
      })));

    regionChangeHandler(regionId);
  };

  const handleDistrictChange = async (e) => {
    const districtId = e.value;
    setSelectedDistrictId(districtId);
    setSelectedLocationId();

    setLocations(orgs.locations.filter((l) => (l.parentId === districtId))
      .map((l) => ({
        value: l.id,
        label: l.nameLabel,
      })));

    districtChangeHandler(districtId);
  };

  const handleLocationChange = (e) => {
    const locationId = e.value;
    setSelectedLocationId(locationId);

    locationChangeHandler(locationId);
  };

  const rootDiv = {
    display: 'flex',
    flexDirection: 'column',
  };

  const loadingStyle = {
    marginLeft: '1rem',
  };

  return (
    <div style={rootDiv}>
      {!orgsLoading && (
        <ReactSelect
          outlined
          label="Region"
          value={selectedRegionId}
          onChanged={handleRegionChange}
          options={regions}
          selectHeight={47}
          marginBottom={30}
          required={required.region_id}
          error={hasErrors && !selectedRegionId}
          helperText={i18n.t('Region is required')}
        />
      )}
      {selectedRegionId && (
        <ReactSelect
          outlined
          label="District"
          value={selectedDistrictId}
          onChanged={handleDistrictChange}
          options={districts}
          selectHeight={47}
          marginBottom={30}
          required={required.district_id}
          error={hasErrors && !selectedDistrictId}
          helperText={i18n.t('District is required')}
        />
      )}
      {selectedDistrictId && (
        <ReactSelect
          outlined
          label="Location"
          value={selectedLocationId}
          onChanged={handleLocationChange}
          options={locations}
          selectHeight={47}
          marginBottom={30}
          required={required.school_id}
          error={hasErrors && !selectedLocationId}
          helperText={i18n.t('Location is required')}
        />
      )}
      {orgsLoading && <CircularProgress size={25} style={loadingStyle} />}
    </div>
  );
};

StudentLocationSelect.propTypes = {
  handleRegionChange: PropTypes.func.isRequired,
  handleDistrictChange: PropTypes.func.isRequired,
  handleLocationChange: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  required: PropTypes.object,
};

StudentLocationSelect.defaultProps = {
  required: {},
};

export default StudentLocationSelect;
