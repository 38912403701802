import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import * as collectionsApi from 'http/admin/collections';

import { actions as snackbarActions } from 'redux/snackbar';
import { actions } from './index';

function* generateOutputByCollection({ payload }) {
  try {
    const { data, actionHandle } = payload;
    yield call(collectionsApi.generateOutputByCollection, data);

    yield put(actions.generateOutputByCollectionSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'ISRs have been successfully requested' } }));

    if (actionHandle) actionHandle();
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.generateOutputByCollectionFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* clearOutputByCollection({ payload }) {
  try {
    const { collectionId, data: payloadData, actionHandle } = payload;

    const data = {};
    if (payloadData.organizationType === 'region') {
      data.regionId = payloadData.organizationId;
    } else if (payloadData.organizationType === 'district') {
      data.districtId = payloadData.organizationId;
    } else if (payloadData.organizationType === 'location') {
      data.locationId = payloadData.organizationId;
    }

    yield call(collectionsApi.clearOutputByCollection, { collectionId, data });

    yield put(actions.clearOutputByCollectionSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'ISRs have been successfully cleared' } }));

    if (actionHandle) actionHandle();
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.clearOutputByCollectionFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getOutputByCollection({ payload }) {
  try {
    const { collectionId, params } = payload;

    const result = yield call(collectionsApi.getOutputByCollection, { collectionId, params });

    yield put(actions.getOutputByCollectionSuccess(result));
  } catch (error) {
    yield put(actions.getOutputByCollectionFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

export default function* watchSagas() {
  yield takeEvery(actions.generateOutputByCollection, generateOutputByCollection);
  yield takeEvery(actions.clearOutputByCollection, clearOutputByCollection);
  yield takeEvery(actions.getOutputByCollection, getOutputByCollection);
}
