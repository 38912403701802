const initialState = {
  yearsData: [],
  isLoading: false,
  error: null,

  isArchivesLoading: false,
  archives: [],
};

export const getSchoolYearsByTenant = (state) => ({
  ...state,
  isLoading: true,
});

export const getSchoolYearsByTenantSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  yearsData: payload,
});

export const getSchoolYearsByTenantFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const getArchivesByYearId = (state) => ({
  ...state,
  isArchivesLoading: true,
  archives: [],
});

export const getArchivesByYearIdSuccess = (state, { payload }) => ({
  ...state,
  isArchivesLoading: false,
  archives: payload,
});

export const getArchivesByYearIdFailure = (state, { payload }) => ({
  ...state,
  isArchivesLoading: false,
  error: payload,
});

export default initialState;
