// TODO: Split redux actions

const initialState = {
  data: [],
  isLoading: false,

  userDetails: {},
  detailsIsLoading: false,
  detailsIsUpdating: false,

  isOrgsLoading: false,

  userRegions: [],
  isRegionsLoading: false,

  userDistricts: [],
  isDistrictsLoading: false,

  userLocations: [],
  isLocationsLoading: false,

  userRegionsMember: [],
  isRegionsMemberLoading: false,

  userDistrictsMember: [],
  isDistrictsMemberLoading: false,

  userLocationsMember: [],
  isLocationsMemberLoading: false,

  isActivating: false,
  currentUser: {},

  isAssociateWithRegion: false,
  isDisassociateWithRegion: false,
  isDemoteFromRegionAdmin: false,
  isPromoteToRegionAdmin: false,

  isAssociateWithDistrict: false,
  isDisassociateWithDistrict: false,
  isDemoteFromDistrictAdmin: false,
  isPromoteToDistrictAdmin: false,
  isDistrictUpdating: false,

  isAssociateWithLocation: false,
  isDisassociateWithLocation: false,
  isDemoteFromLocationAdmin: false,
  isPromoteToLocationAdmin: false,

  error: null,
};

export const getUsers = (state) => ({
  ...state,
  isLoading: true,
});

export const getUsersFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: [],
  error: payload,
});

export const getUsersSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload,
  error: null,
});

export const getUserDetails = (state) => ({
  ...state,
  detailsIsLoading: true,
});

export const getUserDetailsFailure = (state, { payload }) => ({
  ...state,
  detailsIsLoading: false,
  userDetails: initialState.userDetails,
  error: payload,
});

export const getUserDetailsSuccess = (state, { payload }) => ({
  ...state,
  detailsIsLoading: false,
  userDetails: payload,
  error: null,
});

export const createUser = (state) => ({
  ...state,
  isLoading: true,
});

export const createUserFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const createUserSuccess = (state) => ({
  ...state,
  isLoading: false,
  error: null,
});

export const updateUser = (state) => ({
  ...state,
  detailsIsUpdating: true,
});

export const updateUserSuccess = (state, { payload }) => ({
  ...state,
  detailsIsUpdating: false,
  userDetails: payload,
});

export const updateUserFailure = (state, { payload }) => ({
  ...state,
  detailsIsUpdating: false,
  error: payload,
});

export const setUserDetails = (state, { payload }) => ({
  ...state,
  currentUser: payload,
});

export const activateUser = (state) => ({
  ...state,
  isActivating: true,
});

export const activateUserSuccess = (state) => ({
  ...state,
  isActivating: false,
});

export const activateUserFailure = (state, { payload }) => ({
  ...state,
  isActivating: false,
  error: payload,
});

export const deactivateUser = (state) => ({
  ...state,
  isActivating: true,
});

export const deactivateUserSuccess = (state) => ({
  ...state,
  isActivating: false,
});

export const deactivateUserFailure = (state, { payload }) => ({
  ...state,
  isActivating: false,
  error: payload,
});

export const getUserOrgs = (state) => ({
  ...state,
  isOrgsLoading: true,
});

export const getUserOrgsFailure = (state, { payload }) => ({
  ...state,
  isRegionsLoading: false,
  userRegions: initialState.userRegions,
  userDistricts: initialState.userDistricts,
  userLocations: initialState.userLocations,
  error: payload,
});

export const getUserOrgsSuccess = (state, { payload }) => ({
  ...state,
  isOrgsLoading: false,
  userRegions: payload.regions,
  userDistricts: payload.districts,
  userLocations: payload.locations,
  error: null,
});

export const getUserRegions = (state) => ({
  ...state,
  isRegionsLoading: true,
});

export const getUserRegionsFailure = (state, { payload }) => ({
  ...state,
  isRegionsLoading: false,
  userRegions: initialState.userRegions,
  error: payload,
});

export const getUserRegionsSuccess = (state, { payload }) => ({
  ...state,
  isRegionsLoading: false,
  userRegions: payload,
  error: null,
});

export const getUserDistricts = (state) => ({
  ...state,
  isDistrictsLoading: true,
});

export const getUserDistrictsFailure = (state, { payload }) => ({
  ...state,
  isDistrictsLoading: false,
  userDistricts: initialState.userDistricts,
  error: payload,
});

export const getUserDistrictsSuccess = (state, { payload }) => ({
  ...state,
  isDistrictsLoading: false,
  userDistricts: payload,
  error: null,
});

export const getUserLocations = (state) => ({
  ...state,
  isLocationsLoading: true,
});

export const getUserLocationsFailure = (state, { payload }) => ({
  ...state,
  isLocationsLoading: false,
  userLocations: initialState.userLocations,
  error: payload,
});

export const getUserLocationsSuccess = (state, { payload }) => ({
  ...state,
  isLocationsLoading: false,
  userLocations: payload,
  error: null,
});

export const associateUserWithRegion = (state) => ({
  ...state,
  isAssociateWithRegion: true,
});

export const associateUserWithRegionFailure = (state, { payload }) => ({
  ...state,
  isAssociateWithRegion: false,
  error: payload,
});

export const associateUserWithRegionSuccess = (state) => ({
  ...state,
  isAssociateWithRegion: false,
  error: null,
});

export const disassociateUserWithRegion = (state) => ({
  ...state,
  isDisassociateWithRegion: true,
});

export const disassociateUserWithRegionFailure = (state, { payload }) => ({
  ...state,
  isDisassociateWithRegion: false,
  error: payload,
});

export const disassociateUserWithRegionSuccess = (state) => ({
  ...state,
  isDisassociateWithRegion: false,
  error: null,
});

export const demoteFromRegionAdmin = (state) => ({
  ...state,
  isDemoteFromRegionAdmin: true,
});

export const demoteFromRegionAdminFailure = (state, { payload }) => ({
  ...state,
  isDemoteFromRegionAdmin: false,
  error: payload,
});

export const demoteFromRegionAdminSuccess = (state) => ({
  ...state,
  isDemoteFromRegionAdmin: false,
  error: null,
});

export const promoteToRegionAdmin = (state) => ({
  ...state,
  isPromoteToRegionAdmin: true,
});

export const promoteToRegionAdminFailure = (state, { payload }) => ({
  ...state,
  isPromoteToRegionAdmin: false,
  error: payload,
});

export const promoteToRegionAdminSuccess = (state) => ({
  ...state,
  isPromoteToRegionAdmin: false,
  error: null,
});

export const associateUserWithDistrict = (state) => ({
  ...state,
  isAssociateWithDistrict: true,
});

export const associateUserWithDistrictFailure = (state, { payload }) => ({
  ...state,
  isAssociateWithDistrict: false,
  error: payload,
});

export const associateUserWithDistrictSuccess = (state) => ({
  ...state,
  isAssociateWithDistrict: false,
  error: null,
});

export const disassociateUserWithDistrict = (state) => ({
  ...state,
  isDisassociateWithDistrict: true,
});

export const disassociateUserWithDistrictFailure = (state, { payload }) => ({
  ...state,
  isDisassociateWithDistrict: false,
  error: payload,
});

export const disassociateUserWithDistrictSuccess = (state) => ({
  ...state,
  isDisassociateWithDistrict: false,
  error: null,
});

export const demoteFromDistrictAdmin = (state) => ({
  ...state,
  isDemoteFromDistrictAdmin: true,
});

export const demoteFromDistrictAdminFailure = (state, { payload }) => ({
  ...state,
  isDemoteFromDistrictAdmin: false,
  error: payload,
});

export const demoteFromDistrictAdminSuccess = (state) => ({
  ...state,
  isDemoteFromDistrictAdmin: false,
  error: null,
});

export const promoteToDistrictAdmin = (state) => ({
  ...state,
  isPromoteToDistrictAdmin: true,
});

export const promoteToDistrictAdminFailure = (state, { payload }) => ({
  ...state,
  isPromoteToDistrictAdmin: false,
  error: payload,
});

export const promoteToDistrictAdminSuccess = (state) => ({
  ...state,
  isPromoteToDistrictAdmin: false,
  error: null,
});

export const updateUserDistrict = (state) => ({
  ...state,
  isDistrictUpdating: true,
});

export const updateUserDistrictFailure = (state, { payload }) => ({
  ...state,
  isDistrictUpdating: false,
  error: payload,
});

export const updateUserDistrictSuccess = (state) => ({
  ...state,
  isDistrictUpdating: false,
  error: null,
});

export const associateUserWithLocation = (state) => ({
  ...state,
  isAssociateWithLocation: true,
});

export const associateUserWithLocationFailure = (state, { payload }) => ({
  ...state,
  isAssociateWithLocation: false,
  error: payload,
});

export const associateUserWithLocationSuccess = (state) => ({
  ...state,
  isAssociateWithLocation: false,
  error: null,
});

export const disassociateUserWithLocation = (state) => ({
  ...state,
  isDisassociateWithLocation: true,
});

export const disassociateUserWithLocationFailure = (state, { payload }) => ({
  ...state,
  isDisassociateWithLocation: false,
  error: payload,
});

export const disassociateUserWithLocationSuccess = (state) => ({
  ...state,
  isDisassociateWithLocation: false,
  error: null,
});

export const demoteFromLocationAdmin = (state) => ({
  ...state,
  isDemoteFromLocationAdmin: true,
});

export const demoteFromLocationAdminFailure = (state, { payload }) => ({
  ...state,
  isDemoteFromLocationAdmin: false,
  error: payload,
});

export const demoteFromLocationAdminSuccess = (state) => ({
  ...state,
  isDemoteFromLocationAdmin: false,
  error: null,
});

export const promoteToLocationAdmin = (state) => ({
  ...state,
  isPromoteToLocationAdmin: true,
});

export const promoteToLocationAdminFailure = (state, { payload }) => ({
  ...state,
  isPromoteToLocationAdmin: false,
  error: payload,
});

export const promoteToLocationAdminSuccess = (state) => ({
  ...state,
  isPromoteToLocationAdmin: false,
  error: null,
});

export const refreshUserRegions = (state) => ({
  ...state,
  userRegions: initialState.userRegions,
});

export const refreshUserDistricts = (state) => ({
  ...state,
  userDistricts: initialState.userDistricts,
});

export const refreshUserLocations = (state) => ({
  ...state,
  userLocations: initialState.userLocations,
});

export const getUserRegionsMember = (state) => ({
  ...state,
  isRegionsMemberLoading: true,
});

export const getUserRegionsMemberFailure = (state, { payload }) => ({
  ...state,
  isRegionsMemberLoading: false,
  userRegionsMember: initialState.userRegionsMember,
  error: payload,
});

export const getUserRegionsMemberSuccess = (state, { payload }) => ({
  ...state,
  isRegionsMemberLoading: false,
  userRegionsMember: payload,
  error: null,
});

export const getUserDistrictsMember = (state) => ({
  ...state,
  isDistrictsMemberLoading: true,
});

export const getUserDistrictsMemberFailure = (state, { payload }) => ({
  ...state,
  isDistrictsMemberLoading: false,
  userDistrictsMember: initialState.userDistrictsMember,
  error: payload,
});

export const getUserDistrictsMemberSuccess = (state, { payload }) => ({
  ...state,
  isDistrictsMemberLoading: false,
  userDistrictsMember: payload,
  error: null,
});

export const getUserLocationsMember = (state) => ({
  ...state,
  isLocationsMemberLoading: true,
});

export const getUserLocationsMemberFailure = (state, { payload }) => ({
  ...state,
  isLocationsMemberLoading: false,
  userLocationsMember: initialState.userLocationsMember,
  error: payload,
});

export const getUserLocationsMemberSuccess = (state, { payload }) => ({
  ...state,
  isLocationsMemberLoading: false,
  userLocationsMember: payload,
  error: null,
});

export const refreshUserDetails = (state) => ({
  ...state,
  userDetails: initialState.userDetails,
});

export default initialState;
