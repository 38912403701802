import moment from 'moment';

const dateFormatter = (date) => (date ? moment.unix(date).format('MM/DD/YYYY') : null);

export const transfersFormatter = (transfers) => transfers.map((transfer) => ({
  ...transfer,
  createdAt: dateFormatter(transfer.createdAt),
  originUniqueDistrictId: transfer.studentOriginDistrictUniqueId,
  destinationUniqueDistrictId: transfer.studentDestinationDistrictUniqueId,
  isDeleted: transfer.deleted,
}));

export default transfersFormatter;
