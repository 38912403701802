import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from 'modules/App';

import i18n from 'i18n';
import configureStore from './redux/configureStore';
import { loadInitialState } from './helpers/cas4/persistence';

import MuiTheme from './theme';
import * as serviceWorker from './serviceWorker';

// Fonts. These are really configured in theme.js
import 'assets/fonts/lato.css';
import AdaptiveWatcher from './components/AdaptiveWatcher';

// Table styling.
import 'react-table-6/react-table.css';
import 'assets/fonts/icomoon/icomoon.css';
import 'assets/icons/domain-icons/icons.css';

// optionally setup mocks for local development
if (process.env.NODE_ENV === 'development' && !!process.env.REACT_APP_MOCKS) {
  // eslint-disable-next-line global-require
  const { worker } = require('./test-utils/http-mocks/browser');
  worker.start();
}

const initialState = loadInitialState();
const store = configureStore(initialState);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <MuiThemeProvider theme={MuiTheme}>
        <ThemeProvider theme={MuiTheme}>
          <AdaptiveWatcher>
            <App />
          </AdaptiveWatcher>
        </ThemeProvider>
      </MuiThemeProvider>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root'),
);

// Fade in the app now that everything is loaded.
document.getElementById('root').style.opacity = 1;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
