const initialStudentsState = {
  loading: false,
  progress: 0,
  isLoaded: false,
  error: '',
  students: [],
};

export const getStudentsProgress = (state, { payload }) => ({
  ...state,
  loading: true,
  progress: payload.data,
});

export const refreshStudents = (state) => ({
  ...state,
  loading: true,
  isLoaded: false,
  error: '',
  progress: 0,
  students: [],
});

export const refreshStudentsProgress = (state) => ({
  ...state,
  progress: 0,
});

export const getStudents = (state, { payload }) => {
  sessionStorage.setItem('studentprogress', '100');
  if (payload.error) {
    return {
      ...state,
      loading: false,
      error: payload.data || 'Error fetching students data',
      isLoaded: false,
      progress: 100,
    };
  }

  return {
    ...state,
    students: payload.data.filter((student) => (
      student.overall_score !== null
                    && student.last_name !== null
                    && student.first_name !== null
                    && student.LL_score !== null
                    && student.MA_score !== null
                    && student.SF_score !== null
                    && student.PD_score !== null
    )),
    loading: false,
    error: '',
    isLoaded: true,
    progress: 100,
  };
};

export default initialStudentsState;
