const initialState = {
  transfers: [],
  isTransfersLoading: false,

  error: null,
};

export const getTransfersByTenant = (state) => ({
  ...state,
  isTransfersLoading: true,
  transfers: initialState.transfers,
});

export const getTransfersByTenantSuccess = (state, { payload }) => ({
  ...state,
  isTransfersLoading: false,
  transfers: payload,
});

export const getTransfersByTenantFailure = (state, { payload }) => ({
  ...state,
  isTransfersLoading: false,
  error: payload,
});

export const createTransfer = (state) => state;

export const createTransferSuccess = (state) => state;

export const createTransferFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const approveTransfer = (state) => state;

export const approveTransferSuccess = (state, { payload }) => {
  const { transferId } = payload;
  const newTransfers = state.transfers.filter((transfer) => transfer.id !== transferId);

  return ({
    ...state,
    transfers: newTransfers,
  });
};

export const approveTransferFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const declineTransfer = (state) => state;

export const declineTransferSuccess = (state, { payload }) => {
  const { transferId } = payload;
  const newTransfers = state.transfers.filter((transfer) => transfer.id !== transferId);

  return ({
    ...state,
    transfers: newTransfers,
  });
};

export const declineTransferFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export default initialState;
