import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

export const getSupports = (id) => http.get(
  cas3ApiRoute(`/data-collections/${id}/supports`),
).then((res) => res.data.dataCollectionsStudentSupports);

export const saveComment = (assignmentId, supportComment) => http.put(
  cas3ApiRoute('/data-collections-student-assignments'),
  { id: assignmentId, supportComment },
);

export const getScoresheetHistory = ({
  collectionId, enrollmentId,
}) => http.get(`/user/collections/${collectionId}/enrollments/${enrollmentId}/scoresheets/list.json`).then((res) => res.data);

export const saveEnrollmentSupports = (studentEnrollmentId, supportIds) => http.put(
  cas3ApiRoute(`/enrollments/${studentEnrollmentId}/supports`),
  { supportIds },
);

export const getStudentItemGradesHistory = ({
  id, studentId, itemId,
}) => http.get(
  cas3ApiRoute(`/data-collections/${id}/students/${studentId}/items/${itemId}/grades/history`),
).then((res) => res.data);

export const getStudentItemsGradesHistory = ({
  collectionId, studentId,
}) => http.get(
  cas3ApiRoute(`/data-collections/${collectionId}/students/${studentId}/grades/history`),
).then((res) => res.data);

export const getEvidence = ({ collectionId, gradeId }) => http.get(
  cas3ApiRoute(`/data-collections/${collectionId}/scores/${gradeId}/artifacts`),
).then((res) => res.data.artifacts);

export const saveEvidence = ({ collectionId, gradeId, file }) => http.post(
  cas3ApiRoute(`/data-collections/${collectionId}/scores/${gradeId}/artifacts`),
  { files: [file] },
).then((res) => res.data);

export const deleteEvidence = ({ collectionId, gradeId, id }) => http.delete(
  cas3ApiRoute(`/data-collections/${collectionId}/scores/${gradeId}/artifacts/${id}`),
).then((res) => res.data);

export const getCustomFields = (tenantId) => http.get(
  cas3ApiRoute('/tenants-fields'),
  {
    params: {
      tenantsId: tenantId,
      entity: 'StudentsEnrollments',
      entityField: '%CUSTOM%',
    },
  },
).then((res) => res.data.tenantsFields);

export const putStudentInputsAndOptions = (data) => http.put(
  cas3ApiRoute('/enrollments'),
  data,
).then((res) => res.data);
