const initialState = {
  data: [],
  isLoading: false,
  error: null,

  regionDetails: {},
  detailsIsLoading: false,
  detailsIsUpdating: false,
  detailsError: null,
};

export const getRegionsByTenant = (state) => ({
  ...state,
  isLoading: true,
});

export const getRegionsByTenantSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload,
});

export const getRegionsByTenantFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const createRegion = (state) => ({
  ...state,
  isLoading: true,
});

export const createRegionSuccess = (state) => ({
  ...state,
  isLoading: false,
});

export const createRegionFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const getRegionDetails = (state) => ({
  ...state,
  detailsIsLoading: true,
});

export const getRegionDetailsSuccess = (state, { payload }) => ({
  ...state,
  detailsIsLoading: false,
  regionDetails: payload,
});

export const getRegionDetailsFailure = (state, { payload }) => ({
  ...state,
  detailsIsLoading: false,
  detailsError: payload,
});

export const updateRegion = (state) => ({
  ...state,
  detailsIsUpdating: true,
});

export const updateRegionSuccess = (state, { payload }) => ({
  ...state,
  detailsIsUpdating: false,
  regionDetails: payload,
});

export const updateRegionFailure = (state, { payload }) => ({
  ...state,
  detailsIsUpdating: false,
  detailsError: payload,
});

export const refreshRegionDetails = (state) => ({
  ...state,
  regionDetails: initialState.regionDetails,
});

export default initialState;
