/* eslint-disable camelcase */
import moment from 'moment';
import { checkDateStatus } from 'helpers/checkDateStatus';

const announcementSort = (a, b) => {
  if (a.createdAt > b.createdAt) return -1;
  if (a.createdAt < b.createdAt) return 1;

  return 0;
};

export const announcementsFormat = (announcements) => announcements.sort(announcementSort)
  .map(({
    id,
    startDate,
    endDate,
    text,
    title,
  }) => {
    const statusObject = checkDateStatus(moment.unix(startDate), moment.unix(endDate));

    return ({
      id,
      text,
      title,
      startDate: moment.unix(startDate),
      endDate: moment.unix(endDate),
      ...statusObject,
    });
  });

export const formatForRequest = ({ startDate, endDate, ...data }) => {
  // Delete unnecessary properties for the request
  delete data.status;
  delete data.statusColor;

  return ({
    startDate: moment(startDate).unix(),
    endDate: moment(endDate).unix(),
    ...data,
  });
};
