import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  SYSTEM_SAVE_TENANT: undefined,
  SYSTEM_SAVE_TENANT_SUCCESS: undefined,
  SYSTEM_SAVE_TENANT_ERROR: undefined,

  SYSTEM_UPDATE_TENANT: undefined,
  SYSTEM_UPDATE_TENANT_SUCCESS: undefined,
  SYSTEM_UPDATE_TENANT_ERROR: undefined,

  SYSTEM_GET_TENANTS: undefined,
  SYSTEM_GET_TENANTS_ERROR: undefined,
  SYSTEM_GET_TENANTS_SUCCESS: undefined,

  SYSTEM_CREATE_TENANT_ADMIN: undefined,
  SYSTEM_CREATE_TENANT_ADMIN_SUCCESS: undefined,
  SYSTEM_CREATE_TENANT_ADMIN_ERROR: undefined,

  SYSTEM_GET_TENANT_ADMINS: undefined,
  SYSTEM_GET_TENANT_ADMINS_SUCCESS: undefined,
  SYSTEM_GET_TENANT_ADMINS_ERROR: undefined,

  SYSTEM_UPDATE_TENANT_ADMIN: undefined,
  SYSTEM_UPDATE_TENANT_ADMIN_SUCCESS: undefined,
  SYSTEM_UPDATE_TENANT_ADMIN_ERROR: undefined,

  SYSTEM_CREATE_SCHOOL_YEAR: undefined,
  SYSTEM_CREATE_SCHOOL_YEAR_SUCCESS: undefined,
  SYSTEM_CREATE_SCHOOL_YEAR_ERROR: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.systemSaveTenant, handlers.systemSaveTenant],
    [actions.systemSaveTenantSuccess, handlers.systemSaveTenantSuccess],
    [actions.systemSaveTenantError, handlers.systemSaveTenantError],

    [actions.systemUpdateTenant, handlers.systemUpdateTenant],
    [actions.systemUpdateTenantSuccess, handlers.systemUpdateTenantSuccess],
    [actions.systemUpdateTenantError, handlers.systemUpdateTenantError],

    [actions.systemCreateTenantAdmin, handlers.systemCreateTenantAdmin],
    [actions.systemCreateTenantAdminSuccess, handlers.systemCreateTenantAdminSuccess],
    [actions.systemCreateTenantAdminError, handlers.systemCreateTenantAdminError],

    [actions.systemGetTenants, handlers.systemGetTenants],
    [actions.systemGetTenantsError, handlers.systemGetTenantsError],
    [actions.systemGetTenantsSuccess, handlers.systemGetTenantsSuccess],

    [actions.systemCreateTenantAdmin, handlers.systemCreateTenantAdmin],
    [actions.systemCreateTenantAdminSuccess, handlers.systemCreateTenantAdminSuccess],
    [actions.systemCreateTenantAdminError, handlers.systemCreateTenantAdminError],

    [actions.systemGetTenantAdmins, handlers.systemGetTenantAdmins],
    [actions.systemGetTenantAdminsSuccess, handlers.systemGetTenantAdminsSuccess],
    [actions.systemGetTenantAdminsError, handlers.systemGetTenantAdminsError],

    [actions.systemUpdateTenantAdmin, handlers.systemUpdateTenantAdmin],
    [actions.systemUpdateTenantAdminSuccess, handlers.systemUpdateTenantAdminSuccess],
    [actions.systemUpdateTenantAdminError, handlers.systemUpdateTenantAdminError],

    [actions.systemCreateSchoolYear, handlers.systemCreateSchoolYear],
    [actions.systemCreateSchoolYearSuccess, handlers.systemCreateSchoolYearSuccess],
    [actions.systemCreateSchoolYearError, handlers.systemCreateSchoolYearError],
  ]),
  initialState,
);

export default reducer;
