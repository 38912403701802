import React from 'react';
import { PropTypes } from 'react-proptypes';
import { Grid, Card, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Background from './images/background.png';

const styles = (theme) => ({
  '@global': {
    body: {
      backgroundImage: `url(${Background})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      height: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
  fullHeight: {
    minHeight: '100vh',
  },
  cardContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  card: {
    width: '100%',
    padding: theme.spacing(4),
    margin: `${theme.spacing(4)}px 0`,
  },
});

const Login = (props) => {
  const { classes, children } = props;
  return (
    <main id="main">
      <Grid container spacing={0} direction="column" alignItems="center" justify="center" className={classes.fullHeight}>
        <Grid item className={classes.cardContainer}>
          <Card className={classes.card}>
            <CardContent>{children}</CardContent>
          </Card>
        </Grid>
      </Grid>
    </main>
  );
};

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(Login);
