import collectionAssessmentItemSets from './collectionAssessmentItemSets/sagas';
import collectionsAssignments from './collectionAssignments/sagas';
import collectionsData from './collectionsData/sagas';
import collectionISRManagement from './collectionISRManagement/sagas';
import collectionSupports from './collectionSupports/sagas';
import collectionFieldTests from './collectionFieldTests/sagas';

export default function* watchSagas() {
  yield* collectionAssessmentItemSets();
  yield* collectionsAssignments();
  yield* collectionsData();
  yield* collectionISRManagement();
  yield* collectionSupports();
  yield* collectionFieldTests();
}
