import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';
import moment from 'moment';

import {
  formatExitingStudent,
  formatStudentRequestData,
  formatStudentsRosters,
  formatPoaEnrollements,
  formatStudentOutputs,
  formatStudentAssignments,
  formatStudentEnrollments,
  formatEnrollmentAssociations,
} from './formatter';

export const getAvailableRosters = ({ userId }) => http.get(
  cas3ApiRoute(`/user-students-associations/${userId}/available`),
).then((res) => formatStudentsRosters(res.data.students, true));

export const getStudentsRosters = ({ userId }) => http.get(
  cas3ApiRoute('/user-students-associations'),
  {
    params: { userId },
  },
).then((res) => formatStudentsRosters(res.data.students));

export const updateStudentsRosters = ({ userId, enrollmentId }) => http.post(
  cas3ApiRoute('/user-students-associations'),
  {
    userId: +userId,
    studentEnrollmentId: enrollmentId,
  },
).then((res) => res.data);

export const getStudentsByLocation = ({ locationId }) => http.get(
  cas3ApiRoute('/students'),
  { params: { locationId } },
).then((res) => res.data);

export const getStudentsByTenant = ({ tenantId }) => http.get(
  cas3ApiRoute('/students'),
  { params: { tenantId } },
).then((res) => res.data);

export const getStudentDetails = ({ studentId, locationId }) => http.get(
  cas3ApiRoute(`/students/${studentId}`),
  { params: { locationId } },
).then((res) => res.data);

export const getStudentDetailsByLocation = ({
  tenantId,
  regionId,
  districtId,
  locationId,
  studentId,
}) => http.get(`/admin/tenants/${tenantId}/regions/${regionId}/districts/${districtId}/locations/${locationId}/students/${studentId}.json`)
  .then((res) => formatExitingStudent(res.data));

export const getStudentDetailsByTenant = ({
  tenantId,
  studentId,
}) => http.get(`/admin/tenants/${tenantId}/students/${studentId}.json`)
  .then((res) => formatExitingStudent(res.data));

export const createStudent = (data) => http.post(
  cas3ApiRoute('/students'),
  data,
).then((res) => res.data.student);

export const updateStudentByLocation = ({
  tenantId,
  regionId,
  districtId,
  locationId,
  studentId,
  data,
}) => http.put(`/admin/tenants/${tenantId}/regions/${regionId}/districts/${districtId}/locations/${locationId}/students/${studentId}.json`,
  formatStudentRequestData(data)).then((res) => res.data);

export const updateStudent = ({ student, enrollment }) => http.put(
  cas3ApiRoute('/students'),
  { student, enrollment },
).then((res) => res.data);

export const removeStudent = ({ associationId }) => http.delete(
  cas3ApiRoute('/user-students-associations'),
  {
    params: { associationId },
  },
);

export const checkStudentExiting = ({
  studentStateId,
}) => http.get(
  cas3ApiRoute(`/students/ssid/${studentStateId}`),
).then((res) => formatExitingStudent(res.data.student));

export const getEnrollmentsByStudent = ({
  tenantId,
  studentId,
}) => http.get(`/admin/tenants/${tenantId}/students/${studentId}/enrollments.json`)
  .then((res) => formatPoaEnrollements(res.data));

export const getPoasByStudent = ({
  tenantId,
  studentId,
}) => http.get(`/admin/tenants/${tenantId}/students/${studentId}/poas.json`)
  .then((res) => res.data);

export const makePoaByStudent = (data) => http.put(cas3ApiRoute('/enrollments'), data);

export const validatePoa = ({
  tenantId,
  studentId,
  poaId,
}) => http.get(`/admin/tenants/${tenantId}/students/${studentId}/poas/${poaId}/validate.json`)
  .then((res) => res.data);

export const getStudentOutputs = ({ studentId }) => http.get(
  cas3ApiRoute('/data-collections-students-outputs'),
  { params: { studentId } },
).then((res) => formatStudentOutputs(res.data.dataCollectionsStudentsOutputs));

export const generateStudentOutput = ({ collectionId, studentId }) => http.post(
  cas3ApiRoute('/reports/individual-student'),
  {
    studentId: +studentId,
    dataCollectionId: collectionId,
  },
);

export const clearStudentOutput = ({ outputId }) => http.delete(
  cas3ApiRoute(`/reports/individual-student/${outputId}`),
);

export const getStudentAssignmentsByEnrollment = ({
  studentEnrollmentId,
}) => http.get(cas3ApiRoute('/data-collections-student-assignments'), {
  params: {
    enrollmentId: studentEnrollmentId,
  },
}).then((res) => formatStudentAssignments(res.data.dataCollectionStudentAssignments).filter((assignment) => !assignment?.hideButton));

export const assignDataCollectionByAssignment = ({ data }) => http.post(
  cas3ApiRoute('/data-collections-student-assignments'),
  data,
);

export const unassignDataCollectionByAssignment = ({ assignmentId }) => http.delete(
  cas3ApiRoute('/data-collections-student-assignments'),
  { params: { assignmentId } },
);

export const getStudentEnrollments = ({ studentId }) => http.get(
  cas3ApiRoute('/enrollments'),
  { params: { studentId } },
).then((res) => formatStudentEnrollments(res.data.enrollments));

export const updateEnrollment = (data) => http.put(
  cas3ApiRoute('/enrollments'),
  data,
).then((res) => res.data);

export const getEnrollmentLocationInfo = ({
  tenantId,
  enrollmentId,
}) => http.get(`/admin/tenants/${tenantId}/enrollments/${enrollmentId}/location/info.json`, {
  params: {
    time: moment().unix(),
  },
}).then((res) => res.data);

export const getEnrollmentAssociations = (enrollmentId) => http.get(
  cas3ApiRoute('/enrollment-associations'),
  { params: { enrollmentId } },
).then((res) => formatEnrollmentAssociations(res.data.enrollmentAssociations));

export const createEnrollmentAssociation = ({ locationId, enrollmentId, userId }) => http.post(
  cas3ApiRoute('/user-students-associations'),
  {
    studentEnrollmentId: enrollmentId,
    parentLocationId: locationId,
    userId,
  },
).then((res) => res.data.enrollmentAssociation);

export const removeEnrollmentAssociation = ({ associationId }) => http.delete(
  cas3ApiRoute('/user-students-associations'),
  { params: { associationId } },
);

export const getStudentLocationMembers = ({ locationId, params }) => http.get(
  cas3ApiRoute(`/locations/${locationId}/members`),
  { params },
).then((res) => res.data.members);
