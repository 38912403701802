// https://qa.kready.org/resetting/reset/bBMpp1hore5FGHzolDxl8bhI3EqO__cYRAad6Qi0RoI
// fos_user_resetting_form[_token]: JpZMK-rZDhy3dME9F2B7lJ6DXJ18dE4sm1BUBGjo5Ys
// fos_user_resetting_form[plainPassword][first]: Welcome1
// fos_user_resetting_form[plainPassword][second]: Welcome1
// _target_path: /en/user/html/collections/list

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: 10,
    marginBottom: 20,
    fontSize: 24,
  },
  button: {
    marginTop: '1rem',
  },
  wrapper: {
    marginBottom: '80px',
  },
  itemDetailsRoot: {
    display: 'flex',
    [theme.breakpoints.down(900)]: {
      flexDirection: 'column',
    },
    marginBottom: '30px',
  },
  forgotSuccess: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: 161,
  },
  formControl: {
    marginBottom: '20px',
  },
  validateRules: {
    paddingLeft: '20px',
    marginBottom: '30px',
  },
  successRule: {
    color: 'green',
  },
  errorRule: {
    color: 'red',
  },
  actions: {
    display: 'flex',
  },
  checkboxContainer: {
    marginBottom: '30px',
  },
}));

export default useStyles;
