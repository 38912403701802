import {
  takeLatest,
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import * as collectionsApi from 'http/admin/collections';

import { actions as snackbarActions } from 'redux/snackbar';
import { actions } from './index';

function* getCollectionAssessmentItemSets({ payload }) {
  try {
    const { collectionId } = payload;

    const result = yield call(collectionsApi.getCollectionAssessmentItemSets, { collectionId });

    yield put(actions.getCollectionAssessmentItemSetsSuccess(result));
  } catch (error) {
    yield put(actions.getCollectionAssessmentItemSetsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* postCollectionAssessmentItemSet({ payload }) {
  try {
    const {
      tenantId,
      collectionId,
      data,
      successHandle,
    } = payload;

    yield call(collectionsApi.postCollectionAssessmentItemSet, { tenantId, collectionId, data });

    yield put(actions.postCollectionAssessmentItemSetSuccess());
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: `Item Set ${data.label} created` } }));

    if (successHandle) successHandle();
  } catch (error) {
    yield put(actions.postCollectionAssessmentItemSetFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* putCollectionAssessmentItemSet({ payload }) {
  try {
    const {
      tenantId,
      collectionId,
      setId,
      data,
      successHandle,
    } = payload;

    const result = yield call(collectionsApi.putCollectionAssessmentItemSet, {
      tenantId,
      collectionId,
      setId,
      data,
    });

    yield put(actions.putCollectionAssessmentItemSetSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: `Item Set ${data.label} updated` } }));

    if (successHandle) successHandle();
  } catch (error) {
    yield put(actions.putCollectionAssessmentItemSetFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* deleteCollectionAssessmentItemSet({ payload }) {
  try {
    const {
      tenantId,
      collectionId,
      setId,
      successHandle,
    } = payload;

    yield call(collectionsApi.deleteCollectionAssessmentItemSet, { tenantId, collectionId, setId });

    yield put(actions.deleteCollectionAssessmentItemSetSuccess({ setId }));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Item Set removed' } }));

    if (successHandle) successHandle();
  } catch (error) {
    yield put(actions.deleteCollectionAssessmentItemSetFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* getCollectionAssessmentSetItems({ payload }) {
  try {
    const { tenantId, collectionId, setId } = payload;

    const result = yield call(collectionsApi.getCollectionAssessmentSetItems, { tenantId, collectionId, setId });

    yield put(actions.getCollectionAssessmentSetItemsSuccess(result));
  } catch (error) {
    yield put(actions.getCollectionAssessmentSetItemsFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* postCollectionAssessmentSetItem({ payload }) {
  try {
    const {
      collectionId,
      setId,
      data,
      successHandle,
    } = payload;

    if (data.key) delete data.key;

    yield call(collectionsApi.postCollectionAssessmentSetItem, { collectionId, setId, data });
    yield put(actions.postCollectionAssessmentSetItemSuccess());

    yield put(actions.getCollectionAssessmentSetItems({ collectionId, setId }));
    if (successHandle) successHandle();
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();
    yield put(actions.postCollectionAssessmentSetItemFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* putCollectionAssessmentSetItem({ payload }) {
  try {
    const { data, actionHandle, setId } = payload;

    const requestData = {
      id: data.id,
      label: data.label,
      priority: data.priority,
    };

    const result = yield call(collectionsApi.putCollectionAssessmentSetItem, { data: requestData, setId });

    if (actionHandle) actionHandle();

    yield put(actions.putCollectionAssessmentSetItemSuccess(result));
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.putCollectionAssessmentSetItemFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}

function* deleteCollectionAssessmentSetItem({ payload }) {
  try {
    const {
      tenantId,
      collectionId,
      setId,
      itemId,
      successHandle,
    } = payload;

    yield call(collectionsApi.deleteCollectionAssessmentSetItem, {
      tenantId,
      collectionId,
      setId,
      itemId,
    });

    yield put(actions.deleteCollectionAssessmentSetItemSuccess({ itemId }));

    if (successHandle) successHandle();

    yield put(actions.getCollectionAssessmentSetItems({ collectionId, setId }));
  } catch (error) {
    if (payload?.actionHandle) payload.actionHandle();

    yield put(actions.deleteCollectionAssessmentSetItemFailure(error));
    yield put(snackbarActions.showSnackbar({ data: { type: 'error', message: error.message } }));
  }
}
export default function* watchSagas() {
  yield takeLatest(actions.getCollectionAssessmentItemSets, getCollectionAssessmentItemSets);
  yield takeLatest(actions.postCollectionAssessmentItemSet, postCollectionAssessmentItemSet);
  yield takeLatest(actions.putCollectionAssessmentItemSet, putCollectionAssessmentItemSet);
  yield takeLatest(actions.deleteCollectionAssessmentItemSet, deleteCollectionAssessmentItemSet);
  yield takeLatest(actions.getCollectionAssessmentSetItems, getCollectionAssessmentSetItems);
  yield takeEvery(actions.postCollectionAssessmentSetItem, postCollectionAssessmentSetItem);
  yield takeEvery(actions.putCollectionAssessmentSetItem, putCollectionAssessmentSetItem);
  yield takeEvery(actions.deleteCollectionAssessmentSetItem, deleteCollectionAssessmentSetItem);
}
