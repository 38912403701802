import React from 'react';
import PropTypes from 'prop-types';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  required: {
    color: theme.palette.error.main,
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
});

const TextInput = (props) => {
  const {
    input,
    meta: { touched, error },
    classes,
    id,
    label,
  } = props;

  return (
    <div className={classes.marginTop}>
      <Typography component="h2" variant="h6" gutterBottom>
        {label}
      </Typography>
      <CKEditor
        id={id}
        editor={ClassicEditor}
        data={input.value}
        config={{
          fontFamily: ['Lato'],
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          input.onChange(data);
        }}
        onBlur={() => {
          input.onBlur();
        }}
        onFocus={() => {
          input.onFocus();
        }}
      />
      {touched && error ? <FormHelperText error>{error}</FormHelperText> : null}
    </div>
  );
};

TextInput.defaultProps = {
  meta: {
    error: '',
  },
};

TextInput.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextInput);
