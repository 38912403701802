import bitAnalyses from 'helpers/bitAnalyses';

const blankFilterState = {
  iep: 'all',
  ell: 'all',
  ses: 'all',
  cares: [],
  races: [],
  genders: [],
  regions: [],
  locations: [],
  districts: [],
  states: [],
  label: 'All Students',
  key: new Date().getTime(),
  enabled: true,
  color: {
    a: 1,
    b: Math.floor(Math.random() * Math.floor(255)),
    g: Math.floor(Math.random() * Math.floor(255)),
    r: Math.floor(Math.random() * Math.floor(255)),
  },
  showColorPicker: false,
  showEditPanel: false,
  students: [],
};

const initialFiltersState = {
  enabled: true,
  list: [],
};

const filterStudents = (filters, students, allRaces) => {
  const {
    iep, ell, ses, cares, races, locations, genders, districts, regions,
  } = filters;

  const filtered = students
    .filter((record) => {
      let result = true;
      const studentRace = bitAnalyses(record.race);

      if (iep === 'true') {
        result = result && (record.iep === true || record.iep === 'Yes');
      }

      if (iep === 'false') {
        result = result && (record.iep === false || record.iep === 'No');
      }

      if (ell === 'true') {
        result = result && (record.ell === true || record.ell === 'Yes');
      }

      if (ell === 'false') {
        result = result && (record.ell === false || record.ell === 'No');
      }

      if (ses === 'true') {
        result = result && (record.ses === true || record.ses === 'Yes');
      }

      if (ses === 'false') {
        result = result && (record.ses === false || record.ses === 'No');
      }

      if (races.length && allRaces.length !== races.length) {
        result = result && studentRace.some((value) => races.includes(value));
      }

      if (cares.length) {
        const isSuitable = cares.find(({ value }) => !!record[value]);

        result = result && isSuitable;
      }

      if (genders.length) {
        if (record.gender) {
          const isSuitable = genders.find((gender) => gender.value.toLowerCase() === record.gender.toLowerCase());

          result = result && (isSuitable);
        } else {
          return false;
        }
      }

      if (locations.length) {
        const isSuitable = locations.find((location) => location.value === record.school_id);

        result = result && (isSuitable);
      }

      if (districts.length) {
        const isSuitable = districts.find((district) => district.value === record.district_id);

        result = result && (isSuitable);
      }

      if (regions.length) {
        const isSuitable = regions.find((region) => region.value === record.region_id);

        result = result && (isSuitable);
      }

      return result;
    });
  return filtered;
};

// There's some weird syntax here for immutably changing the array, so here's where I found out how to do it.
// Just remember that JS Arrays are really Objects.
// https://medium.com/@giltayar/immutably-setting-a-value-in-a-js-array-or-how-an-array-is-also-an-object-55337f4d6702
export default (state = initialFiltersState, { type, data }) => {
  switch (type) {
  case 'SHOW_WHOLE_POPULATION':
    return ({
      ...state,
      enabled: data,
    });
  case 'ADD_FILTER_LAYER_SUCCESS':
    return {
      ...state,
      list: [
        ...state.list,
        ...[
          {
            ...blankFilterState,
            ...{
              label: `Filter ${state.list.length + 1}`,
              key: new Date().getTime(),
              color: {
                a: 1,
                b: Math.floor(Math.random() * Math.floor(255)),
                g: Math.floor(Math.random() * Math.floor(255)),
                r: Math.floor(Math.random() * Math.floor(255)),
              },
              students: data.students,
            },
          },
        ],
      ],
    };
  case 'REMOVE_FILTER_SUCCESS':
    return {
      ...state,
      list: [...state.list.slice(0, data.index), ...state.list.slice(data.index + 1, state.list.length)],
    };
  case 'EDIT_FILTER_SUCCESS': {
    // Update the associated students list.
    const newFilters = state.list.map((item, index) => {
      if (data.index === index) {
        return ({
          ...state.list[data.index],
          ...data.updates,
          students: filterStudents({ ...state.list[data.index], ...data.updates }, data.students, data.races),
        });
      }

      return item;
    });

    return ({
      ...state,
      list: newFilters,
    });
  }
  default:
    return state;
  }
};
