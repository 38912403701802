import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';
import moment from 'moment';

import {
  formatUsers,
  formatUser,
  formatUserRegions,
  formatUserDistricts,
  formatUserLocations,
} from './formatter';

export const getUsers = ({ tenantId }) => http.get(
  cas3ApiRoute('/users'),
  {
    params: { tenantMemberId: tenantId },
  },
).then((res) => formatUsers(res.data.users));

export const getUserDetails = ({ userId }) => http.get(
  cas3ApiRoute(`/users/${userId}`),
).then((res) => formatUser(res.data.user));

export const createUser = ({ data }) => http.post(
  cas3ApiRoute('/users'),
  data,
);

export const updateUser = (data) => http.put(
  cas3ApiRoute('/users'),
  data,
).then((res) => formatUser(res.data.user));

export const activateUser = ({ id }) => http.put(
  cas3ApiRoute('/users'),
  {
    id,
    enabled: true,
    locked: false,
    // roles: roles.split(','), <-- TODO: find out where this is relevant
  },
);

export const deactivateUser = ({ id }) => http.put(
  cas3ApiRoute('/users'),
  { id, enabled: false, locked: true },
).then((res) => res.data);

export const getUserRegions = ({ tenantId, userId }) => http.get(`/admin/tenants/${tenantId}/users/${userId}/regions.json`,
  {
    params: {
      time: moment().unix(),
    },
  }).then((res) => formatUserRegions(res.data));

export const getUserDistricts = ({ tenantId, userId }) => http.get(`/admin/tenants/${tenantId}/users/${userId}/districts.json`,
  {
    params: {
      time: moment().unix(),
    },
  }).then((res) => formatUserDistricts(res.data));

export const getUserLocations = ({ tenantId, userId }) => http.get(`/admin/tenants/${tenantId}/users/${userId}/locations.json`,
  {
    params: {
      time: moment().unix(),
    },
  }).then((res) => formatUserLocations(res.data));

export const getUserOrgs = ({ userId, admin }) => http.get(
  cas3ApiRoute(`/users/${userId}/organizations`),
  { params: { admin } },
).then((res) => res.data);

export const associateUserWithRegion = ({ regionId, userId }) => http.post(
  cas3ApiRoute(`/users/${userId}/organizations`),
  {
    orgType: 'region',
    parentId: regionId,
  },
);

export const disassociateUserWithRegion = ({ userId, regionId }) => http.delete(
  cas3ApiRoute(`/users/${userId}/organizations`),
  {
    params: {
      orgType: 'region',
      parentId: regionId,
    },
  },
);

export const promoteToRegionAdmin = ({ userId, regionId }) => http.put(
  cas3ApiRoute(`/users/${userId}/organizations`),
  {
    orgType: 'region',
    parentId: regionId,
    administrative: true,
  },
);

export const demoteFromRegionAdmin = ({ userId, regionId }) => http.put(
  cas3ApiRoute(`/users/${userId}/organizations`),
  {
    orgType: 'region',
    parentId: regionId,
    administrative: false,
  },
);

export const associateUserWithDistrict = ({
  districtId, userId, memberUniqueId, isAdministrative,
}) => http.post(
  cas3ApiRoute(`/users/${userId}/organizations`),
  {
    orgType: 'district',
    parentId: +districtId,
    memberUniqueId,
    administrative: isAdministrative,
  },
);

export const disassociateUserWithDistrict = ({ userId, districtId }) => http.delete(
  cas3ApiRoute(`/users/${userId}/organizations`),
  {
    params: {
      orgType: 'district',
      parentId: districtId,
    },
  },
);

export const demoteFromDistrictAdmin = ({ userId, districtId }) => http.put(
  cas3ApiRoute(`/users/${userId}/organizations`),
  {
    orgType: 'district',
    parentId: districtId,
    administrative: false,
  },
);

export const promoteToDistrictAdmin = ({ userId, districtId }) => http.put(
  cas3ApiRoute(`/users/${userId}/organizations`),
  {
    orgType: 'district',
    parentId: districtId,
    administrative: true,
  },
);

export const updateDistrict = ({
  userId,
  districtId,
  memberUniqueId,
}) => http.put(cas3ApiRoute(`/users/${userId}/organizations`), {
  orgType: 'district',
  parentId: parseInt(districtId, 10),
  memberUniqueId,
});

export const associateUserWithLocation = ({ locationId, userId, isAdministrative }) => http.post(
  cas3ApiRoute(`/users/${userId}/organizations`),
  {
    orgType: 'location',
    parentId: locationId,
    administrative: isAdministrative,
  },
);

export const disassociateUserWithLocation = ({ locationId, userId }) => http.delete(
  cas3ApiRoute(`/users/${userId}/organizations`),
  {
    params: {
      orgType: 'location',
      parentId: locationId,
    },
  },
);

export const demoteFromLocationAdmin = ({ userId, locationId }) => http.put(
  cas3ApiRoute(`/users/${userId}/organizations`),
  {
    orgType: 'location',
    parentId: locationId,
    administrative: false,
  },
);

export const promoteToLocationAdmin = ({ userId, locationId }) => http.put(
  cas3ApiRoute(`/users/${userId}/organizations`),
  {
    orgType: 'location',
    parentId: locationId,
    administrative: true,
  },
);

export const getUserRegionsMember = ({ tenantId, userId }) => http.get(`/admin/tenants/${tenantId}/users/${userId}/region/member.json`,
  {
    params: {
      time: moment().unix(),
    },
  }).then((res) => res.data);

export const getUserDistrictsMember = ({ tenantId, userId }) => http.get(`/admin/tenants/${tenantId}/users/${userId}/district/member.json`,
  {
    params: {
      time: moment().unix(),
    },
  }).then((res) => res.data);

export const getUserLocationsMember = ({ tenantId, userId }) => http.get(`/admin/tenants/${tenantId}/users/${userId}/location/member.json`,
  {
    params: {
      time: moment().unix(),
    },
  }).then((res) => res.data);

export const isLocationAdmin = (userId, locationId) => http.get(
  cas3ApiRoute(`/users/${userId}/organizations/locations/${locationId}`),
  { params: { admin: true } },
).then((res) => res.data);
