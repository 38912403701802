import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';

import * as api from 'http/teacher/studentProfile';

import { actions } from './index';
import { actions as assessmentActions } from '../assessment/index';

function* getSupportsRequest({ payload: { data } }) {
  try {
    const result = yield call(api.getSupports, data);

    yield put(actions.getSupportsSuccess(result));
  } catch (error) {
    yield put(actions.getSupportsFailure(error));
  }
}

function* saveCommentRequest({ payload: { student, collectionId } }) {
  try {
    yield call(api.saveComment, student.assignment_id, student.support_comment);

    yield put(assessmentActions.teacherGetCollectionStudents({ data: collectionId }));
    yield put(actions.closeCommentsPopup());
    yield put(actions.saveCommentSuccess());
  } catch (error) {
    yield put(actions.saveCommentFailure(error));
  }
}

function* saveSupportsRequest({ payload: { data, collectionId, studentEnrollmentId } }) {
  try {
    yield call(api.saveEnrollmentSupports, studentEnrollmentId, data);

    yield put(actions.getSupportsRequest({ data: collectionId }));
    yield put(actions.closeSupportsPopup());
    yield put(actions.saveSupportsSuccess());
  } catch (error) {
    yield put(actions.saveSupportsFailure(error));
  }
}

function* getScoresheetHistoryRequest({ payload }) {
  try {
    const result = yield call(api.getScoresheetHistory, payload);

    yield put(actions.getScoresheetHistorySuccess(result));
  } catch (error) {
    yield put(actions.getScoresheetHistoryFailure(error));
  }
}

function* getItemHistoryRequest({ payload }) {
  try {
    const result = yield call(api.getStudentItemGradesHistory, payload);

    yield put(actions.getItemHistorySuccess(result));
  } catch (error) {
    yield put(actions.getItemHistoryFailure(error));
  }
}

function* getStudentItemsHistoryRequest({ payload }) {
  try {
    const result = yield call(api.getStudentItemsGradesHistory, payload);

    yield put(actions.getStudentItemsHistorySuccess(result));
  } catch (error) {
    yield put(actions.getStudentItemsHistoryFailure(error));
  }
}

function* getEvidenceRequest({ payload }) {
  try {
    const result = yield call(api.getEvidence, payload);

    yield put(actions.getEvidenceSuccess(result));
    yield put(actions.unsetEvidenceLoading());
  } catch (error) {
    yield put(actions.getEvidenceFailure(error));
    yield put(actions.unsetEvidenceLoading());
  }
}

function* saveEvidenceRequest({ payload }) {
  try {
    const result = yield call(api.saveEvidence, payload);

    yield put(actions.saveEvidenceSuccess(result));
    yield put(actions.getEvidenceRequest(payload));
  } catch (error) {
    yield put(actions.saveEvidenceFailure(error));
  }
}

function* deleteEvidenceRequest({ payload }) {
  try {
    yield call(api.deleteEvidence, payload);

    yield put(actions.deleteEvidenceSuccess());
    yield put(actions.getEvidenceRequest(payload));
  } catch (error) {
    yield put(actions.deleteEvidenceFailure(error));
  }
}

function* fetchCustomFields() {
  try {
    const { tenantId } = yield select((state) => state.auth);
    const result = yield call(api.getCustomFields, tenantId);
    yield put(actions.getCustomFieldsSuccess(result));
  } catch (error) {
    yield put(actions.getCustomFieldsFailure(error));
  }
}

function* updateStudentOptionsAndInputs({ payload }) {
  try {
    const { data } = payload;

    data.input1 = data.input_1;
    data.input2 = data.input_2;
    data.input3 = data.input_3;
    data.input4 = data.input_4;
    data.input5 = data.input_5;
    data.input6 = data.input_6;
    data.input7 = data.input_7;
    data.input8 = data.input_8;
    data.input9 = data.input_9;
    data.input10 = data.input_10;
    data.option1 = data.option_1;
    data.option2 = data.option_2;
    data.option3 = data.option_3;
    data.option4 = data.option_4;
    data.option5 = data.option_5;
    data.option6 = data.option_6;
    data.option7 = data.option_7;
    data.option8 = data.option_8;
    data.option9 = data.option_9;
    data.option10 = data.option_10;

    Object.keys(data).forEach((key) => {
      const test = /((input)|(option))_\d*/g.test(key);
      if (test) { delete data[key]; }
    });

    yield call(api.putStudentInputsAndOptions, data);

    yield put(assessmentActions.teacherGetCollectionStudents({ data: data.collectionId }));
    yield put(actions.updateStudentOptionsAndInputsSuccess());
  } catch (error) {
    yield put(actions.updateStudentOptionsAndInputsFailure(error));
  }
}

export default function* watchDashboardCalls() {
  yield takeLatest(actions.getSupportsRequest, getSupportsRequest);
  yield takeLatest(actions.saveCommentRequest, saveCommentRequest);
  yield takeLatest(actions.saveSupportsRequest, saveSupportsRequest);
  yield takeLatest(actions.getScoresheetHistoryRequest, getScoresheetHistoryRequest);
  yield takeLatest(actions.getItemHistoryRequest, getItemHistoryRequest);
  yield takeLatest(actions.getStudentItemsHistoryRequest, getStudentItemsHistoryRequest);
  yield takeLatest(actions.getEvidenceRequest, getEvidenceRequest);
  yield takeLatest(actions.saveEvidenceRequest, saveEvidenceRequest);
  yield takeLatest(actions.deleteEvidenceRequest, deleteEvidenceRequest);
  yield takeLatest(actions.getCustomFieldsRequest, fetchCustomFields);
  yield takeLatest(actions.updateStudentOptionsAndInputs, updateStudentOptionsAndInputs);
}
