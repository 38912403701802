import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

import { formatForRequest } from './formatter';

export const getGroups = ({ collectionId, isAdminMode = false }) => {
  if (isAdminMode) {
    return http.get(`/admin/collections/${collectionId}/groups.json`).then((res) => res.data);
  }

  return http.get(
    cas3ApiRoute(`/data-collections/${collectionId}/groups`),
  ).then((res) => res.data);
};

export const createGroup = ({
  collectionId,
  data,
}) => http.post(
  cas3ApiRoute(`/data-collections/${collectionId}/groups`),
  formatForRequest(data),
);

export const updateGroup = ({
  collectionId,
  groupId,
  data,
}) => http.put(
  cas3ApiRoute(`/data-collections/${collectionId}/groups`),
  formatForRequest({ id: groupId, ...data }),
);

export const deleteGroup = ({
  collectionId,
  groupId,
}) => http.delete(
  cas3ApiRoute(`/data-collections/${collectionId}/groups`),
  {
    params: { id: groupId },
  },
);
